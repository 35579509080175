import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getAllTemplates,
  getAllTemplatesError,
  getAllTemplatesStatus,
} from '@store/reducers/email-templates/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import { getTemplatesAction } from '@store/actions/email-templates';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allTemplates: getAllTemplates(state),
  allTemplatesError: getAllTemplatesError(state),
  allTemplatesStatus: getAllTemplatesStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTemplatesAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
