const GET_INVESTMENTS_INIT = '@investments/GET_INVESTMENTS_INIT';
const GET_INVESTMENTS_SUCCESS = '@investments/GET_INVESTMENTS_SUCCESS';
const GET_INVESTMENTS_FAILURE = '@investments/GET_INVESTMENTS_FAILURE';

const GET_SINGLE_INVESTMENT_INIT = '@investments/GET_SINGLE_INVESTMENT_INIT';
const GET_SINGLE_INVESTMENT_SUCCESS = '@investments/GET_SINGLE_INVESTMENT_SUCCESS';
const GET_SINGLE_INVESTMENT_FAILURE = '@investments/GET_SINGLE_INVESTMENT_FAILURE';

export {
  GET_INVESTMENTS_FAILURE,
  GET_INVESTMENTS_SUCCESS,
  GET_INVESTMENTS_INIT,
  GET_SINGLE_INVESTMENT_INIT,
  GET_SINGLE_INVESTMENT_SUCCESS,
  GET_SINGLE_INVESTMENT_FAILURE,
};
