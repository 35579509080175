import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSliderAction, deleteSliderAction } from '@store/actions/slider';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import { getSlider, getStatus, getAllSliderQueryParams, getErrors } from '@store/reducers/slider/selectors';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  sliderItems: getSlider(state),
  queryParams: getAllSliderQueryParams(state),
  status: getStatus(state),
  errors: getErrors(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSliderAction,
      deleteSliderAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
