import moment from 'moment';

const expiresInToDate = tokenExpiresIn => moment().add(parseInt(tokenExpiresIn, 10), 'seconds').toString();

const USER_LOCAL_STORAGE_KEYS = {
  userRefreshToken: 'userRefreshTokenAdmin',
  userAccessToken: 'userAccessTokenAdmin',
  isUserLoggedIn: 'isUserLoggedInAdmin',
  userTokenExpiresIn: 'userTokenExpiresInAdmin',
};

const getUserAccessTokenFromStorage = () => localStorage.getItem(USER_LOCAL_STORAGE_KEYS.userAccessToken);
const getUserRefreshTokenFromStorage = () => localStorage.getItem(USER_LOCAL_STORAGE_KEYS.userRefreshToken);
const getIsUserLoggedInFromStorage = () => localStorage.getItem(USER_LOCAL_STORAGE_KEYS.isUserLoggedIn) === 'true';
const getUserTokenExpiresInFromStorage = () => localStorage.getItem(USER_LOCAL_STORAGE_KEYS.userTokenExpiresIn);

const setUserLoggedInStorage = ({ accessToken, refreshToken, tokenExpiresIn }) => {
  localStorage.setItem(USER_LOCAL_STORAGE_KEYS.userAccessToken, accessToken);
  localStorage.setItem(USER_LOCAL_STORAGE_KEYS.userRefreshToken, refreshToken);
  localStorage.setItem(USER_LOCAL_STORAGE_KEYS.userTokenExpiresIn, expiresInToDate(tokenExpiresIn));
  localStorage.setItem(USER_LOCAL_STORAGE_KEYS.isUserLoggedIn, 'true');
};

const setUserLoggedOutStorage = () => {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEYS.userAccessToken);
  localStorage.removeItem(USER_LOCAL_STORAGE_KEYS.userRefreshToken);
  localStorage.removeItem(USER_LOCAL_STORAGE_KEYS.userTokenExpiresIn);
  localStorage.setItem(USER_LOCAL_STORAGE_KEYS.isUserLoggedIn, 'false');
};

export {
  getIsUserLoggedInFromStorage,
  getUserTokenExpiresInFromStorage,
  getUserAccessTokenFromStorage,
  getUserRefreshTokenFromStorage,
  setUserLoggedInStorage,
  setUserLoggedOutStorage,
};
