const UPLOAD_SINGLE_PHOTO_INIT = '@single-upload/UPLOAD_SINGLE_PHOTO_INIT';
const UPLOAD_SINGLE_PHOTO_SUCCESS = '@single-upload/UPLOAD_SINGLE_PHOTO_SUCCESS';
const UPLOAD_SINGLE_PHOTO_FAILURE = '@single-upload/UPLOAD_SINGLE_PHOTO_FAILURE';

const UPLOAD_SINGLE_FILE_INIT = '@single-upload/UPLOAD_SINGLE_FILE_INIT';
const UPLOAD_SINGLE_FILE_SUCCESS = '@single-upload/UPLOAD_SINGLE_FILE_SUCCESS';
const UPLOAD_SINGLE_FILE_FAILURE = '@single-upload/UPLOAD_SINGLE_FILE_FAILURE';

export {
  UPLOAD_SINGLE_PHOTO_INIT,
  UPLOAD_SINGLE_PHOTO_SUCCESS,
  UPLOAD_SINGLE_PHOTO_FAILURE,
  UPLOAD_SINGLE_FILE_INIT,
  UPLOAD_SINGLE_FILE_SUCCESS,
  UPLOAD_SINGLE_FILE_FAILURE,
};
