import { GET_COUNTRIES_FAILURE, GET_COUNTRIES_INIT, GET_COUNTRIES_SUCCESS } from '@store/action-types/investors';
import client from '@api-sdk/client';

const getCountriesInit = () => ({ type: GET_COUNTRIES_INIT });
const getCountriesSuccess = countries => ({ type: GET_COUNTRIES_SUCCESS, payload: { countries } });
const getCountriesFailure = error => ({ type: GET_COUNTRIES_FAILURE, payload: { error } });

const getCountries = () => async dispatch => {
  dispatch(getCountriesInit());

  try {
    const countriesResponse = await client.getCountries();

    dispatch(getCountriesSuccess(countriesResponse.response.countries));
  } catch (error) {
    dispatch(getCountriesFailure(error));
  }
};

export default getCountries;
