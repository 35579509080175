import {
  EDIT_FEATURED_OFFER_INIT,
  EDIT_FEATURED_OFFER_SUCCESS,
  EDIT_FEATURED_OFFER_FAILURE,
} from '@store/action-types/featured-offers';
import Client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { set, toPath } from 'lodash';
import getSingleFeaturedOffer from '@store/actions/featured-offers/getSingleFeaturedOffer';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';
import { removeEmptyFeaturedOffer } from './utils';

const editFeaturedOfferInit = () => ({ type: EDIT_FEATURED_OFFER_INIT });
const editFeaturedOfferSuccess = () => ({ type: EDIT_FEATURED_OFFER_SUCCESS });
const editFeaturedOfferFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_FEATURED_OFFER_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_FEATURED_OFFER_FAILURE, payload: { error: mappedErrors } };
};

const editFeaturedOffer = ({
  featuredOfferTitle,
  featuredOfferOffers,
  featuredOfferId,
  saveAndBack = false,
}) => async dispatch => {
  dispatch(editFeaturedOfferInit());

  try {
    await Client.editFeaturedOffer({
      slug: featuredOfferId,
      data: { title: featuredOfferTitle, offers: removeEmptyFeaturedOffer(featuredOfferOffers) },
    });
    await dispatch(getSingleFeaturedOffer(featuredOfferId));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('featuredOffer.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editFeaturedOfferSuccess());

    if (saveAndBack) {
      history.push(ROUTES.FEATURED_OFFERS);
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('featuredOffer.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editFeaturedOfferFailure(error));
  }
};

export default editFeaturedOffer;
