import { GET_ROLE_USERS_INIT, GET_ROLE_USERS_SUCCESS, GET_ROLE_USERS_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';

const getRoleUsersInit = () => ({ type: GET_ROLE_USERS_INIT });
const getRoleUsersSuccess = response => ({
  type: GET_ROLE_USERS_SUCCESS,
  payload: { response },
});
const getRoleUsersFailure = error => ({ type: GET_ROLE_USERS_FAILURE, payload: { error } });

const getRoleUsers = roleId => async dispatch => {
  dispatch(getRoleUsersInit());

  try {
    const roleUsersResponse = await client.getRoleUsers({
      slugs: [roleId, 'users'],
    });

    dispatch(getRoleUsersSuccess(roleUsersResponse.response));
  } catch (error) {
    dispatch(getRoleUsersFailure(error?.errors?.[0]?.code));
  }
};

export default getRoleUsers;
