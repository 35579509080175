import {
  EDIT_STATIC_CONTENT_INIT,
  EDIT_STATIC_CONTENT_SUCCESS,
  EDIT_STATIC_CONTENT_FAILURE,
} from '@store/action-types/static-content';
import { enqueueSnackbar } from '@store/actions/toast';
import client from '@api-sdk/client';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';
import { isEmpty } from 'lodash';

const editStaticContentInit = () => ({ type: EDIT_STATIC_CONTENT_INIT });
const editStaticContentSuccess = () => ({ type: EDIT_STATIC_CONTENT_SUCCESS });
const editStaticContentFailure = error => ({ type: EDIT_STATIC_CONTENT_FAILURE, payload: { error } });

const editStaticContent = ({
  staticContentData,
  elementId,
  saveAndBack = false,
  previousElementId,
}) => async dispatch => {
  dispatch(editStaticContentInit());

  try {
    const editStaticContentResponse = await client.editStaticContent({
      data: staticContentData,
      slug: elementId,
    });

    await dispatch(editStaticContentSuccess(editStaticContentResponse));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('staticContent.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      if (isEmpty(previousElementId)) {
        return history.push(ROUTES.STATIC_CONTENT);
      }

      history.push(`${ROUTES.STATIC_CONTENT}/${previousElementId}`);
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('staticContent.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editStaticContentFailure(error));
  }
};

export default editStaticContent;
