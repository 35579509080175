import { VALID_DOCUMENT_MIME_TYPES, VALID_IMAGE_MIME_TYPES } from '../const';

const validateImageType = files => {
  return Array.from(files).every(file => {
    return !(VALID_IMAGE_MIME_TYPES.indexOf(file?.type) === -1);
  });
};

const validateDocumentType = files => {
  return Array.from(files).every(file => {
    return !(VALID_DOCUMENT_MIME_TYPES.indexOf(file?.type) === -1);
  });
};

const getFileSize = file => {
  if (file?.size === 0) {
    return '0 B';
  }
  const k = 1024;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(file?.size) / Math.log(k));
  return `${parseFloat((file?.size / k ** i).toFixed(2))} ${sizes[i]}`;
};

const validateFileSize = (files, minUploadLimit, maxUploadLimit) => {
  return Array.from(files).every(file => {
    return file?.size >= minUploadLimit && file?.size < maxUploadLimit;
  });
};

export { validateImageType, validateDocumentType, validateFileSize, getFileSize };
