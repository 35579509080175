import {
  GET_FEATURED_OFFERS_INIT,
  GET_FEATURED_OFFERS_SUCCESS,
  GET_FEATURED_OFFERS_FAILURE,
  GET_SINGLE_FEATURED_OFFER_INIT,
  GET_SINGLE_FEATURED_OFFER_SUCCESS,
  GET_SINGLE_FEATURED_OFFER_FAILURE,
  EDIT_FEATURED_OFFER_INIT,
  EDIT_FEATURED_OFFER_FAILURE,
  EDIT_FEATURED_OFFER_SUCCESS,
} from '@store/action-types/featured-offers';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  status: FETCH_STATUS.IDLE,
  featuredOffers: [],
  singleFeaturedOffer: {},
  error: undefined,
};

const featuredOffersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FEATURED_OFFERS_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        featuredOffers: [],
        error: undefined,
      };
    }
    case GET_FEATURED_OFFERS_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        featuredOffers: payload?.featuredOffers,
        error: undefined,
      };
    }
    case GET_FEATURED_OFFERS_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        featuredOffers: [],
        error: payload?.error,
      };
    }
    case GET_SINGLE_FEATURED_OFFER_INIT: {
      return {
        ...state,
        singleFeaturedOffer: {},
        status: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case GET_SINGLE_FEATURED_OFFER_SUCCESS: {
      return {
        ...state,
        singleFeaturedOffer: payload?.singleFeaturedOffer,
        status: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_SINGLE_FEATURED_OFFER_FAILURE: {
      return {
        ...state,
        singleFeaturedOffer: {},
        status: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case EDIT_FEATURED_OFFER_INIT: {
      return {
        ...state,
        error: undefined,
      };
    }
    case EDIT_FEATURED_OFFER_SUCCESS: {
      return {
        ...state,
        error: undefined,
      };
    }
    case EDIT_FEATURED_OFFER_FAILURE: {
      return {
        ...state,
        error: payload?.error,
      };
    }
    default:
      return state;
  }
};

export default featuredOffersReducer;
