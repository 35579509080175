import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    textAlign: 'center',
    fontSize: 16,
    letterSpacing: '-0.5px',
    lineHeight: 1,
  },
  danger: {
    color: '#d62e2e',
  },
  success: {
    color: '#66bf1a',
  },
}));

export default useStyles;
