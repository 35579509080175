import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import i18next from 'i18next';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, Table } from '@shared/components';
import { Button, Message } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Skeleton } from '@shared/layout-components';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import PERMISSIONS from '@shared/consts/permissions';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';
import { mapErrorCodes } from './utils';

const Main = ({
  actions,
  allTemplates,
  allTemplatesError,
  allTemplatesStatus,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.EMAIL_TEMPLATES);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getTemplatesAction();

  useMountWithTranslate(() => {
    actions.getTemplatesAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.EMAIL_TEMPLATES]) {
      if (
        !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID) ||
        !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_GET_CONFIG) ||
        !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_TEST_TEMPLATE)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.EMAIL_TEMPLATES);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_EDIT) && PERMISSIONS.EMAIL_TEMPLATE_EDIT,
          !isUserPermitted(PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID) && PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID,
          !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_GET_CONFIG) && PERMISSIONS.EMAIL_TEMPLATE_GET_CONFIG,
          !isUserPermitted(PERMISSIONS.EMAIL_TEMPLATE_TEST_TEMPLATE) && PERMISSIONS.EMAIL_TEMPLATE_TEST_TEMPLATE,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('list.templateName'),
        accessor: 'name',
        Cell: ({ row }) => {
          return (
            <div>
              <b>{row?.original?.name?.[i18next.language]}</b>
            </div>
          );
        },
      },
      {
        Header: t('list.templateSubject'),
        accessor: 'subject',
        Cell: ({ row }) => {
          return <div>{row?.original?.subject?.[i18next.language]}</div>;
        },
      },
      {
        Header: t('list.templateEdit'),
        className: 'text-center',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_EMAIL_TEMPLATE_BY_ID) ? (
            <Button primary as={Link} to={`/email-templates/${row?.original?.id}/edit`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null,
      },
    ],
    [],
  );

  const renderTable = () => {
    if (isEmpty(allTemplates)) {
      return <Message>{t('notFoundTemplates')}</Message>;
    }

    return <Table hideFilters hideFooter data={allTemplates} columns={columns} />;
  };

  return (
    <Card width="md">
      <CardHeader>
        <CardTitle title={t('mainListTitle')} />
        <CardToolbar>
          <Button disabled={allTemplatesStatus === FETCH_STATUS.LOADING} color="black" onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
        </CardToolbar>
      </CardHeader>
      {allTemplatesError && mapErrorCodes({ errorCode: allTemplatesError, t })}
      <CardBody transparent>
        {allTemplatesStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE ? (
          <Skeleton variant={SKELETON_VARIANT.TABLE} />
        ) : (
          renderTable()
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
