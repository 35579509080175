const getAllTemplates = state => state?.emailTemplates?.allTemplates;
const getAllTemplatesError = state => state?.emailTemplates?.allTemplatesError;
const getAllTemplatesStatus = state => state?.emailTemplates?.allTemplatesStatus;

const getAllTemplatesConfig = state => state?.emailTemplates?.allTemplatesConfig;
const getAllTemplatesConfigError = state => state?.emailTemplates?.allTemplatesConfigError;
const getAllTemplatesConfigStatus = state => state?.emailTemplates?.allTemplatesConfigStatus;

const getSingleTemplate = state => state?.emailTemplates?.singleTemplate;
const getSingleTemplateError = state => state?.emailTemplates?.singleTemplateError;
const getSingleTemplateStatus = state => state?.emailTemplates?.singleTemplateStatus;

export {
  getAllTemplates,
  getAllTemplatesError,
  getAllTemplatesStatus,
  getAllTemplatesConfig,
  getAllTemplatesConfigError,
  getAllTemplatesConfigStatus,
  getSingleTemplate,
  getSingleTemplateError,
  getSingleTemplateStatus,
};
