import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  topWrapper: {
    display: 'flex',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  removeButton: {
    position: 'absolute',
    borderColor: '#ba000d',
    color: '#ba000d',
    right: 0,
    top: 0,
  },
  select: {
    marginRight: 5,
  },
  labelInput: {
    marginLeft: 5,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: 5,
    },
  },
  datePicker: {
    maxWidth: 180,
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
}));

export default useStyles;
