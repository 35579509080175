import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getStaticContentElementAction, editStaticContentAction } from '@store/actions/static-content';
import {
  getStaticContentElement,
  getStaticContentElementStatus,
  getBreadcrumbs,
} from '@store/reducers/static-content/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  status: getStaticContentElementStatus(state),
  element: getStaticContentElement(state),
  breadcrumbs: getBreadcrumbs(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getStaticContentElementAction, editStaticContentAction }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
