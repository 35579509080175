import { EDIT_CONFIG_INIT, EDIT_CONFIG_SUCCESS, EDIT_CONFIG_FAILURE } from '@store/action-types/settings';
import Client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { transformedConfigData } from '@store/actions/settings/editConfig/utils';

const editConfigInit = () => ({ type: EDIT_CONFIG_INIT });
const editConfigSuccess = config => ({ type: EDIT_CONFIG_SUCCESS, payload: { config } });
const editConfigFailure = error => ({ type: EDIT_CONFIG_FAILURE, payload: { error } });

const editConfig = configData => async dispatch => {
  dispatch(editConfigInit());

  try {
    const config = await Client.editConfig({ data: transformedConfigData(configData) });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('config.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editConfigSuccess(config.response));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('config.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    dispatch(editConfigFailure(error));
  }
};

export default editConfig;
