import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSinglePermissionAction, editPermissionAction } from '@store/actions/permissions';
import {
  getSinglePermissionStatus,
  getSinglePermission,
  getPermissionErrors,
  getPermissionEditStatus,
} from '@store/reducers/permissions/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singlePermission: getSinglePermission(state),
  singleStatus: getSinglePermissionStatus(state),
  errors: getPermissionErrors(state),
  editStatus: getPermissionEditStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSinglePermissionAction,
      editPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
