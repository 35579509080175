import { FRONTEND_SET_PASSWORD_URL_PATTERN } from '@api-sdk/consts';
import { CREATE_USER_INIT, CREATE_USER_SUCCESS, CREATE_USER_FAILURE } from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { set, toPath, isEmpty } from 'lodash';
import history from '@store/history';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const createUserInit = () => ({ type: CREATE_USER_INIT });
const createUserSuccess = () => ({ type: CREATE_USER_SUCCESS });

const createUserFailure = error => {
  if (!error?.errors) {
    return { type: CREATE_USER_FAILURE, payload: { errors: error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    if (item.code === 'USER:NOT_UNIQUE_USER') {
      return set(acc, 'email', item.code?.split(':')?.pop() || item.message);
    }

    const path = toPath(item.context)?.filter(key => key !== 'children' && key !== 'data');
    set(acc, path, item.code?.split(':')?.pop() || item.message);
    return acc;
  }, {});
  return { type: CREATE_USER_FAILURE, payload: { errors: mappedErrors } };
};

const createUser = ({ userData, saveAndBack = false }) => async dispatch => {
  dispatch(createUserInit());
  const errors = {};

  if (isEmpty(userData?.firstName)) {
    errors.firstName = 'FIRST_NAME_IS_EMPTY';
  }

  if (isEmpty(userData?.lastName)) {
    errors.lastName = 'LAST_NAME_IS_EMPTY';
  }

  if (!isEmpty(errors)) {
    return dispatch(createUserFailure(errors));
  }

  if (isEmpty(userData?.position)) {
    dispatch(createUserFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    const createUserResponse = await client.createUser({
      data: {
        ...userData,
        resetPasswordUrlPattern: userData?.isAdmin
          ? `${window.location.origin}/set-password/:resetPasswordToken`
          : `${FRONTEND_SET_PASSWORD_URL_PATTERN}/pl/ustaw-haslo/:resetPasswordToken`,
      },
    });

    await dispatch(createUserSuccess(createUserResponse));

    if (saveAndBack) {
      history.push(ROUTES.USERS);
    } else {
      history.push(`/users/${createUserResponse.response?.id}`);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(createUserFailure(error));
  }
};

export default createUser;
