import {
  EDIT_PERMISSION_INIT,
  EDIT_PERMISSION_SUCCESS,
  EDIT_PERMISSION_FAILURE,
} from '@store/action-types/permissions';
import { enqueueSnackbar } from '@store/actions/toast';
import history from '@store/history';
import client from '@api-sdk/client';
import { set, toPath, isEmpty } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const editPermissionInit = () => ({ type: EDIT_PERMISSION_INIT });
const editPermissionSuccess = () => ({ type: EDIT_PERMISSION_SUCCESS });
const editPermissionFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_PERMISSION_FAILURE, payload: { errors: error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    if (item?.code === 'PERMISSION:NOT_UNIQUE_NAME') {
      set(acc, 'name', item.code?.split(':')?.pop() || item.message);
    }

    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );
    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  return { type: EDIT_PERMISSION_FAILURE, payload: { errors: mappedErrors } };
};

const editPermission = ({ permissionData, saveAndBack = false }) => async dispatch => {
  dispatch(editPermissionInit());

  if (isEmpty(permissionData?.name)) {
    return dispatch(editPermissionFailure({ name: 'NAME_IS_EMPTY' }));
  }

  try {
    const editPermissionResponse = await client.editPermission({ data: permissionData, slug: permissionData?.id });

    await dispatch(editPermissionSuccess(editPermissionResponse));

    if (saveAndBack) {
      history.push(ROUTES.PERMISSION_MANAGEMENT);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('permission.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('permission.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editPermissionFailure(error));
  }
};

export default editPermission;
