import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32,
    },
  },
  brand: {
    height: 80,
  },
}));

export default useStyles;
