import { RESET_PASSWORD_INIT, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '@store/action-types/user';
import Client from '@api-sdk/client';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const submitResetPasswordInit = () => ({ type: RESET_PASSWORD_INIT });
const submitResetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });
const submitResetPasswordFailure = error => ({ type: RESET_PASSWORD_FAILURE, payload: { error } });

const validateResetPassword = resetToken => async dispatch => {
  dispatch(submitResetPasswordInit());

  try {
    await Client.validateResetPasswordToken({ slug: resetToken });
    return dispatch(submitResetPasswordSuccess());
  } catch (error) {
    history.push(ROUTES.LOGIN);
    return dispatch(submitResetPasswordFailure(['RESET_TOKEN_NOT_VALID']));
  }
};

export default validateResetPassword;
