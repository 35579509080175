import registerModules from './registerModules';

class Client {
  constructor() {
    registerModules(this);
  }

  register({ action, alias, requestParams }) {
    this[alias] = ({ ...customParams } = {}) => action({ ...requestParams, ...customParams });
  }

  registerMock({ action, alias, requestParams }) {
    this[alias] = ({ ...customParams } = {}) => action({ ...requestParams, ...customParams });
  }
}

export default new Client();
