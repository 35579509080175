const GET_EMAIL_TEMPLATES_INIT = '@email-templates/GET_TEMPLATES_INIT';
const GET_EMAIL_TEMPLATES_SUCCESS = '@email-templates/GET_TEMPLATES_SUCCESS';
const GET_EMAIL_TEMPLATES_FAILURE = '@email-templates/GET_TEMPLATES_FAILURE';

const GET_EMAIL_TEMPLATES_CONFIG_INIT = '@email-templates/GET_EMAIL_TEMPLATES_CONFIG_INIT';
const GET_EMAIL_TEMPLATES_CONFIG_SUCCESS = '@email-templates/GET_EMAIL_TEMPLATES_CONFIG_SUCCESS';
const GET_EMAIL_TEMPLATES_CONFIG_FAILURE = '@email-templates/GET_EMAIL_TEMPLATES_CONFIG_FAILURE';

const GET_SINGLE_EMAIL_TEMPLATE_INIT = '@email-templates/GET_SINGLE_EMAIL_TEMPLATE_INIT';
const GET_SINGLE_EMAIL_TEMPLATE_SUCCESS = '@email-templates/GET_SINGLE_EMAIL_TEMPLATE_SUCCESS';
const GET_SINGLE_EMAIL_TEMPLATE_FAILURE = '@email-templates/GET_SINGLE_EMAIL_TEMPLATE_FAILURE';

const EDIT_EMAIL_TEMPLATE_INIT = '@email-templates/EDIT_EMAIL_TEMPLATE_INIT';
const EDIT_EMAIL_TEMPLATE_SUCCESS = '@email-templates/EDIT_EMAIL_TEMPLATE_SUCCESS';
const EDIT_EMAIL_TEMPLATE_FAILURE = '@email-templates/EDIT_EMAIL_TEMPLATE_FAILURE';

const SEND_TEST_EMAIL_INIT = '@email-templates/SEND_TEST_EMAIL_INIT';
const SEND_TEST_EMAIL_SUCCESS = '@email-templates/SEND_TEST_EMAIL_SUCCESS';
const SEND_TEST_EMAIL_FAILURE = '@email-templates/SEND_TEST_EMAIL_FAILURE';

export {
  GET_EMAIL_TEMPLATES_INIT,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAILURE,
  GET_EMAIL_TEMPLATES_CONFIG_INIT,
  GET_EMAIL_TEMPLATES_CONFIG_SUCCESS,
  GET_EMAIL_TEMPLATES_CONFIG_FAILURE,
  GET_SINGLE_EMAIL_TEMPLATE_INIT,
  GET_SINGLE_EMAIL_TEMPLATE_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATE_FAILURE,
  EDIT_EMAIL_TEMPLATE_INIT,
  EDIT_EMAIL_TEMPLATE_SUCCESS,
  EDIT_EMAIL_TEMPLATE_FAILURE,
  SEND_TEST_EMAIL_INIT,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_FAILURE,
};
