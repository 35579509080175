import {
  EDIT_CONFIG_FAILURE,
  EDIT_CONFIG_INIT,
  EDIT_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  GET_CONFIG_INIT,
  GET_CONFIG_SUCCESS,
} from '@store/action-types/settings';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  data: {},
  status: FETCH_STATUS.IDLE,
  error: undefined,
};

const settingsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CONFIG_INIT: {
      return {
        ...state,
        data: {},
        error: undefined,
        status: FETCH_STATUS.IDLE,
      };
    }
    case EDIT_CONFIG_SUCCESS:
    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        data: payload.config,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_CONFIG_INIT: {
      return { ...state };
    }
    case GET_CONFIG_FAILURE: {
      return {
        ...state,
        data: {},
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case EDIT_CONFIG_FAILURE: {
      return {
        ...state,
        error: payload?.error,
      };
    }
    default:
      return state;
  }
};

export default settingsReducer;
