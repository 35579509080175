import {
  USER_LOGIN_INIT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  GET_USER_INFO_INIT,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_SUCCESS,
  CLEAR_ERROR,
} from '@store/action-types/user';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  userData: {},
  status: FETCH_STATUS.IDLE,
  error: [],
  token: undefined,
  isLoggedIn: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_INIT: {
      return { ...state, status: FETCH_STATUS.LOADING };
    }
    case USER_LOGOUT: {
      return { ...initialState };
    }
    case USER_LOGIN_SUCCESS: {
      return {
        token: action.payload.userData.token,
        isLoggedIn: true,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case USER_LOGIN_FAILURE: {
      return {
        userData: {},
        isLoggedIn: false,
        error: action.payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_USER_INFO_INIT: {
      return { ...state };
    }
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userData: action.payload.user,
      };
    }
    case GET_USER_INFO_FAILURE: {
      return {
        ...state,
      };
    }
    case RESET_PASSWORD_INIT: {
      return {
        ...state,
        error: undefined,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: undefined,
      };
    }
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        error: action?.payload?.error,
      };
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        error: undefined,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
