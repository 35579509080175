import React from 'react';
import { bool, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { ICONS, STATIC_CONTENT_TYPE } from '@screens/static-content/consts';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const ElementLink = ({ item, disabled }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  const mapElementType = () => {
    switch (item.type) {
      case STATIC_CONTENT_TYPE.TEXT: {
        return ICONS.TEXT;
      }
      case STATIC_CONTENT_TYPE.IMAGE: {
        return ICONS.IMAGE;
      }
      case STATIC_CONTENT_TYPE.RICH_TEXT: {
        return ICONS.RICH_TEXT;
      }
      default: {
        return '';
      }
    }
  };

  return disabled ? (
    <div className={styles.wrapper}>
      <Icon name={mapElementType()} style={{ color: 'rgba(0,0,0,.6)' }} />
      <Typography className={styles.name}>{t(item.name)}</Typography>
    </div>
  ) : (
    <Link to={`/static-content/element/${item?.id}`} icon labelPosition="right">
      <div className={styles.wrapper}>
        <Icon name={mapElementType()} style={{ color: 'rgba(0,0,0,.6)' }} />
        <Typography className={styles.name}>{t(item.name)}</Typography>
      </div>
    </Link>
  );
};

ElementLink.propTypes = {
  item: shape({}),
  disabled: bool,
};

ElementLink.defaultProps = {
  item: {},
  disabled: false,
};

export default ElementLink;
