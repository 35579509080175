import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    minHeight: 240,
  },
  selectContainer: {
    display: 'flex',
  },
  smallSelectContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  defaultElement: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'translateY(100%)',
  },
  card: {
    height: '100%',
  },
  cardContent: {
    height: '100%',
  },
  select: {
    minWidth: '50px',
    margin: '0 16px 0 0',
    [theme.breakpoints.down('md')]: {
      marginTop: 5,
    },
  },
}));

export default useStyles;
