import {
  GET_SLIDER_INIT,
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAILURE,
  GET_SINGLE_SLIDER_INIT,
  GET_SINGLE_SLIDER_SUCCESS,
  GET_SINGLE_SLIDER_FAILURE,
  CREATE_SLIDER_INIT,
  CREATE_SLIDER_SUCCESS,
  CREATE_SLIDER_FAILURE,
  EDIT_SLIDER_INIT,
  EDIT_SLIDER_SUCCESS,
  EDIT_SLIDER_FAILURE,
  DELETE_SLIDER_INIT,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAILURE,
} from '@store/action-types/slider';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  status: FETCH_STATUS.IDLE,
  allSliders: [],
  singleSlider: {},
  error: undefined,
  queryParams: {},
};

const sliderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SLIDER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        allSliders: [],
        error: undefined,
        queryParams: {},
      };
    }
    case GET_SLIDER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        allSliders: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        pagination: payload?.allSliders?.pagination,
        error: undefined,
      };
    }
    case GET_SLIDER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        pagination: {},
        allSliders: [],
        error: payload?.error,
      };
    }
    case GET_SINGLE_SLIDER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        singleSlider: {},
        error: undefined,
      };
    }
    case EDIT_SLIDER_SUCCESS:
    case GET_SINGLE_SLIDER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        singleSlider: payload?.singleSlider,
        error: undefined,
      };
    }
    case GET_SINGLE_SLIDER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singleSlider: {},
        error: payload?.error,
      };
    }
    case EDIT_SLIDER_INIT:
    case CREATE_SLIDER_INIT:
    case CREATE_SLIDER_SUCCESS: {
      return {
        ...state,
        error: undefined,
      };
    }
    case DELETE_SLIDER_FAILURE:
    case EDIT_SLIDER_FAILURE:
    case CREATE_SLIDER_FAILURE: {
      return {
        ...state,
        error: payload?.error,
      };
    }
    case DELETE_SLIDER_INIT:
    case DELETE_SLIDER_SUCCESS: {
      return {
        ...state,
        error: undefined,
      };
    }
    default:
      return state;
  }
};

export default sliderReducer;
