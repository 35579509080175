import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  tableImage: {
    height: 64,
    width: 96,
    // maxHeight: 64,
    // maxWidth: 64,
    objectFit: 'cover',
    borderRadius: 3,
  },
}));

export default useStyles;
