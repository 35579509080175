import React from 'react';
import { func, shape } from 'prop-types';
import { Button, Box, List, ListItem, TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { PositionSelect } from '@shared/components/OfferForm/components';
import useMount from '@shared/utils/useMountHook';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const imgArr = [
  'https://images.unsplash.com/photo-1522050212171-61b01dd24579?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1499916078039-922301b0eb9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=80',
  'https://images.unsplash.com/photo-1521783593447-5702b9bfd267?ixlib=rb-1.2.1&auto=format&fit=crop&w=821&q=80',
];

const GalleryBox = ({
  handleAddArrayToData,
  handleAddElementInElement,
  setElementData,
  setElementDataWithArray,
  handleRemoveElementFromElement,
  arrayOfElementsSetPosition,
  elementValue,
}) => {
  const styles = useStyles();

  useMount(() => {
    isEmpty(elementValue) && handleAddArrayToData();
  });

  const handleInputChange = (e, galleryId) => {
    const key = e.target.name;
    const value = e.target.value;

    setElementDataWithArray(galleryId, key, value);
  };

  const handleSelectChange = (e, index) => {
    const newIndex = Number(e.target.value);
    arrayOfElementsSetPosition(index, newIndex);
  };

  const handleClick = () => {
    const position = elementValue?.elements.length + 1;
    handleAddElementInElement(position);
  };

  const handleRemoveRow = galleryId => {
    handleRemoveElementFromElement(galleryId);
  };

  const handleSetLabel = e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementData(key, value);
  };

  const renderListItem = () => {
    return elementValue?.elements?.map?.((item, index) => {
      return (
        <ListItem className={styles.listItem} key={`gallery-item-${index}`}>
          <div className={styles.listItemCell}>
            <img className={styles.image} src={imgArr[index]} alt="estate" />
          </div>
          <TextField
            onChange={e => handleInputChange(e, index)}
            value={elementValue.elements[index]?.label || ''}
            variant="outlined"
            name="label"
            label="Label"
            size="small"
          />
          <TextField
            onChange={e => handleInputChange(e, index)}
            value={elementValue.elements[index]?.subLabel || ''}
            variant="outlined"
            name="subLabel"
            label="Sub label"
            size="small"
          />
          <div className={styles.listItemCell}>
            <PositionSelect
              handleChange={e => handleSelectChange(e, index)}
              numberOfOptions={elementValue?.elements?.length}
              id={index}
            />
          </div>
          <div className={styles.listItemCell}>
            <IconButton className={styles.deleteButton} onClick={() => handleRemoveRow(index)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </ListItem>
      );
    });
  };

  return (
    <Box>
      <div className={styles.headerWrapper}>
        <Button
          variant="outlined"
          disabled={elementValue?.elements?.length > 3}
          className={styles.button}
          onClick={handleClick}
        >
          add new
        </Button>
      </div>
      <div>
        <TextField
          value={elementValue?.title}
          variant="outlined"
          size="small"
          label="Gallery label"
          onChange={handleSetLabel}
        />
      </div>
      <div className={styles.listItemLabel}>
        <div className={styles.listItemCell}>Image</div>
        <div className={styles.listItemCell} />
        <div className={styles.listItemCell} />
        <div className={styles.listItemCell} />
        <div className={styles.listItemCell} />
        <div className={styles.listItemCell}>Position</div>
        <div className={styles.listItemCell}>Remove</div>
      </div>
      <List className={styles.list}>{renderListItem()}</List>
    </Box>
  );
};

GalleryBox.propTypes = {
  handleAddArrayToData: func.isRequired,
  handleAddElementInElement: func.isRequired,
  setElementData: func.isRequired,
  setElementDataWithArray: func.isRequired,
  handleRemoveElementFromElement: func.isRequired,
  arrayOfElementsSetPosition: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default GalleryBox;
