import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { string, elementType, bool, shape } from 'prop-types';
import ROUTES from '@shared/consts/routes';
import { getIsUserLoggedInFromStorage } from '@api-sdk/utils';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, isRestricted, user, ...rest } = props;
  const isLoggedIn = getIsUserLoggedInFromStorage();

  return (
    <Route
      {...rest}
      render={matchProps =>
        isLoggedIn && isRestricted ? (
          <Redirect to={ROUTES.HOME} />
        ) : (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: elementType.isRequired,
  layout: elementType.isRequired,
  path: string,
  isRestricted: bool,
  user: shape({}).isRequired,
};

RouteWithLayout.defaultProps = {
  path: undefined,
  isRestricted: false,
};

export default RouteWithLayout;
