import React, { useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar } from '@shared/components';
import { Button, Form, Grid, Table } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import InputField from '@shared/components/translatable/InputField';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';

const Main = ({ actions, singleRole, singleRoleError, singleRoleStatus }) => {
  const [t] = useTranslation(TRANSLATIONS.ROLES);
  const [roleData, setRoleData] = useState({ name: '', description: '' });

  const onChangeContent = (e, { value, name }) => setRoleData(prevState => ({ ...prevState, [name]: value }));

  const onCreateRole = ({ saveAndBack }) => {
    actions.createRoleAction({ roleData, saveAndBack });
  };

  const renderRoleInfo = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('singleRole.roleInformation')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="table-left">{t('singleRole.nameField')}</Table.Cell>
            <Table.Cell textAlign="right">
              <Grid>
                <InputField
                  name="name"
                  disableIcon
                  onChange={onChangeContent}
                  value={roleData?.name}
                  errorMessage={singleRoleError?.name}
                />
              </Grid>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-left">{t('singleRole.descriptionField')}</Table.Cell>
            <Table.Cell textAlign="right">
              <Grid>
                <InputField
                  name="description"
                  icon="font"
                  onChange={onChangeContent}
                  value={roleData?.description}
                  rows={5}
                  multiline
                  errorMessage={singleRoleError?.description}
                />
              </Grid>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderMenuButtons = () => {
    return (
      <CardHeader>
        <CardTitle title={t('singleRole.createTitle')} />
        <CardToolbar>
          <Button style={{ marginRight: 5 }} as={Link} type="button" to={ROUTES.ROLES}>
            {t('actionButtons.goBack')}
          </Button>
          <Button
            style={{ marginRight: 5 }}
            disabled={singleRoleStatus === FETCH_STATUS.LOADING}
            primary
            onClick={onCreateRole}
          >
            {t('actionButtons.add')}
          </Button>
          <Button
            disabled={singleRoleStatus === FETCH_STATUS.LOADING}
            color="facebook"
            onClick={() => onCreateRole({ saveAndBack: true })}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        </CardToolbar>
      </CardHeader>
    );
  };

  return (
    <Card className="admin-roles">
      {renderMenuButtons()}
      <CardBody transparent>
        <Form loading={singleRoleStatus === FETCH_STATUS.LOADING}>
          <Grid columns={2}>
            <Grid.Column>{renderRoleInfo()}</Grid.Column>
          </Grid>
        </Form>
      </CardBody>
      {renderMenuButtons()}
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
