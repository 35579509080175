import React from 'react';
import { List, Label, Icon, Form, Grid, Button, Input } from 'semantic-ui-react';
import AttachmentsItemModel from '../../../../models/elementModels/AttachmentsElementModel/AttachmentsItemModel';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

class AttachmentsElement extends React.PureComponent {
  elementImageRef = React.createRef();

  constructor(props) {
    super(props);
    this.onArrowDown = this.onArrowDown.bind(this);
    this.onArrowUp = this.onArrowUp.bind(this);
    this.onRemoveAttachmentItem = this.onRemoveAttachmentItem.bind(this);
    this.onToggleRemoveAttachmentItem = this.onToggleRemoveAttachmentItem.bind(this);
    this.onUploadAttachment = this.onUploadAttachment.bind(this);
  }

  onUploadAttachment(e) {
    const { onChange, sectionNumber, element } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];

    if (file) {
      reader.onloadend = () => {
        onChange(prevState => ({
          offerSections: prevState.offerSections.reduce((acc, section) => {
            if (section.position === sectionNumber) {
              return acc.concat([
                {
                  ...section,
                  elements: section.elements.reduce((elementsAcc, el) => {
                    if (element.position === el.position) {
                      return elementsAcc.concat([
                        {
                          ...el,
                          elements: el.elements.concat([
                            {
                              ...AttachmentsItemModel,
                              attachment: {
                                displayName: file.name,
                                file: {
                                  fileName: file.name,
                                  base64: reader.result,
                                  delete: false,
                                },
                              },
                              position: el?.elements?.[el?.elements?.length - 1]?.position + 1 || 1,
                              backendIndex: el?.elements?.length || 0,
                            },
                          ]),
                        },
                      ]);
                    }
                    return elementsAcc.concat([el]);
                  }, []),
                },
              ]);
            }
            return acc.concat([section]);
          }, []),
        }));
        this.elementImageRef.current.inputRef.current.value = '';
      };

      return reader.readAsDataURL(file);
    }
  }

  onRemoveAttachmentItem(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove attachment #${innerElementPosition}? Attachment will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > innerElementPosition) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onToggleRemoveAttachmentItem(innerElementPosition, toBeRemoved) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove attachment #${innerElementPosition}? Attachment will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc.concat([
                              {
                                ...innerElement,
                                attachment: {
                                  ...innerElement.attachment,
                                  file: {
                                    ...innerElement?.attachment?.file,
                                    fileName: innerElement?.attachment?.displayName,
                                    delete: toBeRemoved,
                                  },
                                },
                              },
                            ]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onArrowUp(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.elements.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowDown(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.elements.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  render() {
    const { element, t, isViewMode } = this.props;

    return (
      <div>
        {isViewMode ? null : (
          <Form.Group>
            <Grid container stackable columns={2} divided verticalAlign="middle">
              <Grid.Column>
                <Label size="big">{t('attachment.newItem')}</Label>
                <Input ref={this.elementImageRef} onChange={this.onUploadAttachment} type="file" />
              </Grid.Column>
            </Grid>
          </Form.Group>
        )}

        <List divided size="big" relaxed="very">
          {element?.elements
            ?.sort?.((a, b) => a.position - b.position)
            ?.map?.((innerElement, index) => {
              return (
                <List.Item key={innerElement.position}>
                  <List.Content>
                    <List.Header>
                      <Grid columns={2} verticalAlign="middle">
                        <Grid.Column>
                          <span
                            style={
                              innerElement?.attachment?.file?.delete
                                ? { textDecoration: 'line-through' }
                                : { textDecoration: 'none' }
                            }
                          >
                            #{index + 1}. <Icon name="file alternate" />{' '}
                            {innerElement?.attachment?.displayName}
                          </span>
                        </Grid.Column>
                        {isViewMode ? null : (
                          <Grid.Column>
                            <Button
                              icon
                              className="mr-2 ml-2"
                              disabled={innerElement.position === element?.elements?.[0]?.position}
                              onClick={() => this.onArrowUp(innerElement.position)}
                            >
                              <Icon name="arrow up" />
                            </Button>
                            <Button
                              icon
                              className="mr-2 ml-2"
                              disabled={
                                innerElement.position === element?.elements?.[element?.elements?.length - 1]?.position
                              }
                              onClick={() => this.onArrowDown(innerElement.position)}
                            >
                              <Icon name="arrow down" />
                            </Button>
                            {innerElement?.attachment?.url && (
                              <Button
                                icon
                                className="mr-2 ml-2"
                                color="red"
                                onClick={() => this.onToggleRemoveAttachmentItem(innerElement.position, true)}
                              >
                                <Icon name="trash" />
                                Mark to delete
                              </Button>
                            )}
                            {!innerElement?.attachment?.url && (
                              <Button
                                className="mr-2 ml-2"
                                icon
                                color="red"
                                onClick={() => this.onRemoveAttachmentItem(innerElement.position)}
                              >
                                <Icon name="trash" />
                                {t('delete')}
                              </Button>
                            )}
                            {innerElement?.attachment?.file?.delete && (
                              <Button
                                icon
                                color="blue"
                                className="mr-2 ml-2"
                                onClick={() => this.onToggleRemoveAttachmentItem(innerElement.position, false)}
                              >
                                <Icon name="undo" />
                                Undo deletion
                              </Button>
                            )}
                          </Grid.Column>
                        )}
                      </Grid>
                    </List.Header>
                  </List.Content>
                </List.Item>
              );
            })}
        </List>
      </div>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(AttachmentsElement);
