export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const getAbsoluteUrl = iconUrl => {
  const url = window.location.href;
  const path = window.location.pathname;
  const search = window.location.search;
  const urlWithoutPath = url.replace(path, '').replace(search, '');

  if (url === `${window.location.origin}/`) {
    return `${window.location.origin}/${iconUrl}`;
  }

  return `${urlWithoutPath}/${iconUrl}`;
};
