import Client from '@api-sdk/client';
import {
  GET_PERMISSIONS_FAILURE,
  GET_PERMISSIONS_INIT,
  GET_PERMISSIONS_SUCCESS,
} from '@store/action-types/permissions';

const getPermissionsInit = () => ({ type: GET_PERMISSIONS_INIT });
const getPermissionsSuccess = allPermissions => ({ type: GET_PERMISSIONS_SUCCESS, payload: { allPermissions } });
const getPermissionsFailure = error => ({ type: GET_PERMISSIONS_FAILURE, payload: { error } });

const getPermissions = () => async dispatch => {
  dispatch(getPermissionsInit());

  try {
    const allPermissions = await Client.getPermissions();
    dispatch(getPermissionsSuccess(allPermissions.response));
  } catch (error) {
    dispatch(getPermissionsFailure(error));
  }
};

export default getPermissions;
