import React, { useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { Button, Typography, Tabs, Tab } from '@material-ui/core';
import removeItem from '@shared/utils/removeItem';
import { getAbsoluteUrl } from '@metronic/helpers';
import SVG from 'react-inlinesvg';
import changePosition from '@shared/utils/changePosition';
import { MainContent, SectionCard } from './components';

const OfferForm = ({ data, setData, isEditForm }) => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangeMainContent = (key, value) => {
    setData({
      ...data,
      header: {
        ...data.header,
        [key]: value,
      },
    });
  };

  const handleAddSection = () => {
    setData({
      ...data,
      sections: [
        ...data.sections,
        {
          position: data.sections.length + 1,
          elements: [],
        },
      ],
    });
  };

  const handleAddElement = position => {
    const array = [...data?.sections];
    const elementsArray = [
      {
        type: '',
        position: data?.sections[position]?.elements?.length + 1,
        grid: {
          desktop: 6,
          tablet: 6,
          mobile: 12,
        },
        data: {},
      },
    ];

    array[position].elements = [...array[position].elements, ...elementsArray];

    setData({
      ...data,
      sections: [...array],
    });
  };

  const handleAddArrayToData = (cardId, elementId) => {
    const array = [...data?.sections];
    const dataWithArray = data.sections[cardId].elements[elementId].data;
    dataWithArray.elements = [];

    array[cardId].elements[elementId].data = dataWithArray;

    setData({
      ...data,
      sections: array,
    });
  };

  const handleAddElementInElement = (cardId, elementId, position) => {
    const array = [...data?.sections];
    const newElement = { position };
    array[cardId].elements[elementId].data.elements = [
      ...array[cardId]?.elements[elementId]?.data?.elements,
      newElement,
    ];

    setData({
      ...data,
      sections: array,
    });
  };

  const handleRemoveElementFromElement = (cardId, elementId, position) => {
    const array = [...data?.sections];

    removeItem(array[cardId].elements[elementId].data.elements, position, callback => {
      array[cardId].elements[elementId].data.elements = callback.array;

      setData({
        ...data,
        sections: array,
      });
    });
  };

  const handleRemoveSection = cardId => {
    const array = [...data.sections];
    removeItem(array, cardId, callback => [
      setData({
        ...data,
        sections: callback.array,
      }),
    ]);
  };

  const handleRemoveElement = (cardId, elementId) => {
    const array = [...data.sections];
    removeItem(array[cardId].elements, elementId, callback => {
      array[cardId].elements = callback.array;
      setData({
        ...data,
        sections: array,
      });
    });
  };

  const setElementData = (cardId, elementId, key, value) => {
    const array = [...data.sections];
    array[cardId].elements[elementId].data = {
      ...array[cardId].elements[elementId].data,
      [key]: value,
    };
    setData({
      ...data,
      sections: array,
    });
  };

  const setElementDataWithArray = (cardId, elementId, index, key, value) => {
    const array = [...data?.sections];

    array[cardId].elements[elementId].data.elements[index] = {
      ...array[cardId].elements[elementId].data.elements[index],
      [key]: value,
    };

    setData({
      ...data,
      sections: array,
    });
  };

  const setElementType = (cardId, elementId, type) => {
    const array = [...data?.sections];
    array[cardId].elements[elementId].type = type;
    array[cardId].elements[elementId].data = {};

    setData({
      ...data,
      sections: array,
    });
  };

  const setElementGrid = (cardId, elementId, name, gridValue) => {
    const array = [...data.sections];

    array[cardId].elements[elementId].grid[name] = gridValue;

    setData({
      ...data,
      sections: array,
    });
  };

  const setSectionPosition = (cardId, oldIndex, newIndex) => {
    let array = [...data.sections];

    changePosition(array, oldIndex, newIndex, callback => {
      array = callback.newArray;

      setData({
        ...data,
        sections: array,
      });
    });
  };

  const setElementPosition = (cardId, oldIndex, newIndex) => {
    const array = [...data.sections];

    changePosition(array[cardId].elements, oldIndex, newIndex, callback => {
      array[cardId].elements = callback.newArray;
      setData({
        ...data,
        sections: array,
      });
    });
  };

  const arrayOfElementsSetPosition = (cardId, elementId, oldIndex, newIndex) => {
    const array = [...data?.sections];

    changePosition(array[cardId].elements[elementId].data.elements, oldIndex, newIndex, callback => {
      array[cardId].elements[elementId].data.elements = callback.newArray;
      setData({
        ...data,
        sections: array,
      });
    });
  };

  const renderSectionCard = () => {
    return data?.sections?.map?.((item, index) => (
      <SectionCard
        key={`section-card-${index}`}
        elements={data?.sections[index]?.elements}
        numberOfSections={data?.sections.length}
        handleAddElementInElement={handleAddElementInElement}
        handleAddElement={handleAddElement}
        handleAddArrayToData={handleAddArrayToData}
        handleRemoveElementFromElement={handleRemoveElementFromElement}
        handleRemoveElement={handleRemoveElement}
        handleRemoveSection={handleRemoveSection}
        setElementType={setElementType}
        setElementGrid={setElementGrid}
        setElementPosition={setElementPosition}
        setSectionPosition={setSectionPosition}
        setElementData={setElementData}
        setElementDataWithArray={setElementDataWithArray}
        arrayOfElementsSetPosition={arrayOfElementsSetPosition}
        cardId={index}
      />
    ));
  };

  return (
    <div>
      <div className="alert alert-custom alert-white alert-shadow gutter-b py-2 px-4">
        <div className="alert-icon alert-icon-top align-items-center">
          <SVG src={getAbsoluteUrl('/images/icons/info-circle.svg')} />
        </div>
        <div className="alert-text">
          <Typography variant="h5">Offer {isEditForm ? 'Editor' : 'Creator'}</Typography>
        </div>
        <div className="alert-close">
          {tab === 1 && (
            <Button variant="outlined" color="primary" onClick={handleAddSection}>
              New section
            </Button>
          )}
          <Tabs value={tab} onChange={handleChange} indicatorColor="primary" textColor="primary">
            <Tab label="General" />
            <Tab label="Sections" />
          </Tabs>
        </div>
      </div>
      <div>
        {tab === 0 && <MainContent handleChangeMainContent={handleChangeMainContent} headerData={data.header} />}
      </div>
      <div>{tab === 1 && renderSectionCard()}</div>
    </div>
  );
};

OfferForm.propTypes = {
  data: shape({}).isRequired,
  setData: func.isRequired,
  isEditForm: bool,
};

OfferForm.defaultProps = {
  isEditForm: false,
};

export default OfferForm;
