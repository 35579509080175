import { DELETE_OFFER_INIT, DELETE_OFFER_SUCCESS, DELETE_OFFER_FAILURE } from '@store/action-types/offers';
import client from '@api-sdk/client';
import getOffers from '@store/actions/offers/getOffers';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteOfferInit = () => ({ type: DELETE_OFFER_INIT });
const deleteOfferSuccess = singleOffer => ({
  type: DELETE_OFFER_SUCCESS,
  payload: { singleOffer },
});
const getSingleOfferFailure = error => ({ type: DELETE_OFFER_FAILURE, payload: { error } });

const deleteOffer = id => async dispatch => {
  dispatch(deleteOfferInit());

  try {
    const singleOffer = await client.deleteOffer({ slug: id });
    await dispatch(deleteOfferSuccess(singleOffer));
    dispatch(getOffers());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('offer.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(getSingleOfferFailure(error));
  }
};

export default deleteOffer;
