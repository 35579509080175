import {
  GET_STATIC_CONTENT_INIT,
  GET_STATIC_CONTENT_SUCCESS,
  GET_STATIC_CONTENT_FAILURE,
} from '@store/action-types/static-content';
import client from '@api-sdk/client';

const getStaticContentInit = () => ({ type: GET_STATIC_CONTENT_INIT });
const getStaticContentSuccess = staticContent => ({ type: GET_STATIC_CONTENT_SUCCESS, payload: { staticContent } });
const getStaticContentFailure = error => ({ type: GET_STATIC_CONTENT_FAILURE, payload: { error } });

const getStaticContent = () => async dispatch => {
  dispatch(getStaticContentInit());

  try {
    const staticContent = await client.getStaticContent();
    dispatch(getStaticContentSuccess(staticContent.response));
  } catch (error) {
    dispatch(getStaticContentFailure(error));
  }
};

export default getStaticContent;
