import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader } from '@shared/components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Skeleton } from '@shared/layout-components';
import { STATIC_CONTENT_TYPE } from '@screens/static-content/consts';
import { Breadcrumbs, ElementLink, ParentLink } from '@screens/static-content/components';
import useMount from '@shared/utils/useMountHook';
import PERMISSIONS from '@shared/consts/permissions';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';

const Main = ({ actions, status, parent, breadcrumbs, permittedPermissions }) => {
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getStaticContentParentAction(params.parentId);
  }, [params.parentId]);

  const refreshPage = () => actions.getStaticContentParentAction(params.parentId);

  const renderParents = () => {
    return Object?.values(parent?.children)?.map(item => {
      if (item.type === STATIC_CONTENT_TYPE.COLLECTION) {
        return <ParentLink item={item} />;
      }

      return null;
    });
  };

  const renderElements = () => {
    return Object?.values(parent?.children)?.map(item => {
      if (item.type !== STATIC_CONTENT_TYPE.COLLECTION) {
        return <ElementLink item={item} disabled={!isUserPermitted(PERMISSIONS.GET_STATIC_CONTENT_ELEMENT_BY_ID)} />;
      }

      return null;
    });
  };

  return (
    <Card width="md">
      <CardHeader>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <div>
          <Button
            as={Link}
            to={
              breadcrumbs?.[breadcrumbs?.length - 2]?.id
                ? `/static-content/${breadcrumbs?.[breadcrumbs?.length - 2]?.id}`
                : '/static-content'
            }
          >
            {t('actionButtons.goBack')}
          </Button>
          <Button color="black" disabled={status === FETCH_STATUS.IDLE} onClick={refreshPage}>
            {t('actionButtons.refresh')}
          </Button>
        </div>
      </CardHeader>
      <CardBody transparent>
        {status === FETCH_STATUS.IDLE ? (
          <Skeleton variant={SKELETON_VARIANT.STATIC_CONTENT} />
        ) : (
          <div>
            {renderParents()}
            {renderElements()}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  parent: shape({}),
  status: string,
  breadcrumbs: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
};

Main.defaultProps = {
  parent: {},
  status: undefined,
  breadcrumbs: [],
  permittedPermissions: [],
};

export default Main;
