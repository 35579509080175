import {
  REMOVE_USER_FROM_ROLE_INIT,
  REMOVE_USER_FROM_ROLE_SUCCESS,
  REMOVE_USER_FROM_ROLE_FAILURE,
} from '@store/action-types/roles';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const removeUserFromRoleInit = clickedUserId => ({ type: REMOVE_USER_FROM_ROLE_INIT, payload: { clickedUserId } });
const removeUserFromRoleSuccess = response => ({
  type: REMOVE_USER_FROM_ROLE_SUCCESS,
  payload: { response },
});
const removeUserFromRoleFailure = error => ({ type: REMOVE_USER_FROM_ROLE_FAILURE, payload: { error } });

const removeUserFromRole = ({ roleId, userId }) => async dispatch => {
  dispatch(removeUserFromRoleInit(userId));

  try {
    const removeUserFromRoleResponse = await client.removeUserFromRole({
      slugs: [roleId, 'users', userId],
    });

    dispatch(removeUserFromRoleSuccess(removeUserFromRoleResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.user.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(removeUserFromRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.user.error.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default removeUserFromRole;
