import React from 'react';
import { string } from 'prop-types';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { Icon } from 'semantic-ui-react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  icon: {
    marginLeft: '8px !important',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const Tooltip = ({ tooltip, children, color, name, placement }) => {
  const styles = useStyles();

  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<BootstrapTooltip id="button-tooltip">{tooltip || children}</BootstrapTooltip>}
    >
      <Icon className={styles.icon} name={name} color={color} />
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  tooltip: string,
  children: string,
  color: string,
  name: string,
  placement: string,
};

Tooltip.defaultProps = {
  tooltip: '',
  children: '',
  color: '',
  name: 'info circle',
  placement: 'right',
};

export default Tooltip;
