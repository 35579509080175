import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: '#ffffff',
    padding: '24px',
    border: '1px solid #f1f1f1',
    borderRadius: 4,
    marginTop: 24,
  },
  transparentBackground: {
    background: 'none',
    border: 'none',
    padding: '24px 0',
  },
}));

export default useStyles;
