import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getAllTemplatesConfig,
  getAllTemplatesConfigError,
  getAllTemplatesConfigStatus,
  getSingleTemplateError,
  getSingleTemplateStatus,
  getSingleTemplate,
} from '@store/reducers/email-templates/selectors';
import {
  getTemplatesConfigAction,
  sendTestEmailAction,
  getSingleTemplateAction,
  editTemplateAction,
} from '@store/actions/email-templates';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allTemplatesConfig: getAllTemplatesConfig(state),
  allTemplatesConfigError: getAllTemplatesConfigError(state),
  allTemplatesConfigStatus: getAllTemplatesConfigStatus(state),
  singleTemplate: getSingleTemplate(state),
  singleTemplateError: getSingleTemplateError(state),
  singleTemplateStatus: getSingleTemplateStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getTemplatesConfigAction,
      sendTestEmailAction,
      getSingleTemplateAction,
      editTemplateAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
