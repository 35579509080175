const handleErrorResponse = async error => {
  return Promise.reject({
    requestBody: error?.response?.config?.data ? JSON.parse(error?.response?.config?.data) : {},
    response: error?.response?.data?.data,
    errors: error?.response?.data?.errors,
    status: error?.response?.status,
  });
};

export default handleErrorResponse;
