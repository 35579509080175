const GET_SLIDER_INIT = '@slider/GET_SLIDER_INIT';
const GET_SLIDER_SUCCESS = '@slider/GET_SLIDER_SUCCESS';
const GET_SLIDER_FAILURE = '@slider/GET_SLIDER_FAILURE';

const GET_SINGLE_SLIDER_INIT = '@slider/GET_SINGLE_SLIDER_INIT';
const GET_SINGLE_SLIDER_SUCCESS = '@slider/GET_SINGLE_SLIDER_SUCCESS';
const GET_SINGLE_SLIDER_FAILURE = '@slider/GET_SINGLE_SLIDER_FAILURE';

const DELETE_SLIDER_INIT = '@slider/DELETE_SLIDER_INIT';
const DELETE_SLIDER_SUCCESS = '@slider/DELETE_SLIDER_SUCCESS';
const DELETE_SLIDER_FAILURE = '@slider/DELETE_SLIDER_FAILURE';

const EDIT_SLIDER_INIT = '@slider/EDIT_SLIDER_INIT';
const EDIT_SLIDER_SUCCESS = '@slider/EDIT_SLIDER_SUCCESS';
const EDIT_SLIDER_FAILURE = '@slider/EDIT_SLIDER_FAILURE';

const CREATE_SLIDER_INIT = '@slider/CREATE_SLIDER_INIT';
const CREATE_SLIDER_SUCCESS = '@slider/CREATE_SLIDER_SUCCESS';
const CREATE_SLIDER_FAILURE = '@slider/CREATE_SLIDER_FAILURE';

export {
  GET_SLIDER_INIT,
  GET_SLIDER_SUCCESS,
  GET_SLIDER_FAILURE,
  GET_SINGLE_SLIDER_INIT,
  GET_SINGLE_SLIDER_SUCCESS,
  GET_SINGLE_SLIDER_FAILURE,
  DELETE_SLIDER_INIT,
  DELETE_SLIDER_SUCCESS,
  DELETE_SLIDER_FAILURE,
  EDIT_SLIDER_INIT,
  EDIT_SLIDER_SUCCESS,
  EDIT_SLIDER_FAILURE,
  CREATE_SLIDER_INIT,
  CREATE_SLIDER_SUCCESS,
  CREATE_SLIDER_FAILURE,
};
