import React, { useEffect } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router';
import UserForm from '@screens/users/components/UserForm';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { isEmpty } from 'lodash';
import { Tab } from 'semantic-ui-react';
import UserAgreements from '@screens/users/edit-user/components/UserAgreements';
import { useTranslation } from 'react-i18next';
import PERMISSIONS from '@shared/consts/permissions';
import TRANSLATIONS from '@translations/translationNamespaces';
import { NotFoundMessage } from '@shared/components';
import PersonalPermissionsList from './components/PersonalPermissionsList';
import UserRolesList from './components/UserRolesList';
import './styles.scss';

const Main = ({
  actions,
  user,
  status,
  countries,
  countriesStatus,
  errors,
  personalPermissions,
  personalPermissionsStatus,
  allPermissions,
  allPermissionsStatus,
  clickedPermissionsIds,
  allRolesStatus,
  userRoles,
  userRolesStatus,
  filteredRoles,
  userAgreements,
  userAgreementsError,
  userAgreementsStatus,
  checkPermissionStatus,
  permittedPermissions,
}) => {
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.USERS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSingleUserAction(params.id);
    isEmpty(countries) && actions.getCountriesAction();
  });

  useEffect(() => {
    if (checkPermissionStatus === FETCH_STATUS.SUCCESS) {
      if (isUserPermitted(PERMISSIONS.GET_USER_AGREEMENTS)) {
        actions.getUserAgreementsAction(params.id);
      }

      if (isUserPermitted(PERMISSIONS.GET_USER_ROLES)) {
        actions.getUserRolesAction(params.id);
        actions.getRolesAction();
      }

      if (isUserPermitted(PERMISSIONS.GET_PERSONAL_PERMISSIONS)) {
        actions.getPersonalPermissionsAction(params.id);
        actions.getPermissionsAction();
      }
    }
  }, [checkPermissionStatus]);

  const handlePermissionRefresh = () => {
    actions.getPermissionsAction();
    actions.getPersonalPermissionsAction(params.id);
  };

  const handleRolesRefresh = () => {
    actions.getRolesAction();
    actions.getUserRolesAction(params.id);
  };

  const UserModel = {
    active: user?.active,
    email: user?.email,
    firstName: user?.firstName,
    lastName: user?.lastName,
    isAdmin: user?.isAdmin,
    investorData: {
      address: {
        address: user?.address?.address,
        zip: user?.address?.zip,
        city: user?.address?.city,
        state: user?.address?.state?.id,
        country: user?.address?.country?.id,
      },
      bankAccount: {
        country: user?.bankAccount?.country?.id,
        bankName: user?.bankAccount?.bankName,
        accountNumber: user?.bankAccount?.accountNumber,
      },
      phoneNumber: user?.phoneNumber,
    },
  };

  const panes = [
    {
      menuItem: t('tab.user'),
      render: () => (
        <Tab.Pane>
          <UserForm
            isViewMode={!isUserPermitted(PERMISSIONS.USER_EDIT)}
            user={UserModel}
            countries={countries}
            loading={
              status === FETCH_STATUS.IDLE ||
              countriesStatus === FETCH_STATUS.IDLE ||
              checkPermissionStatus === FETCH_STATUS.IDLE
            }
            handleReloadUser={actions.getSingleUserAction}
            handleSaveUser={actions.editUserAction}
            usersErrors={errors}
            userAgreements={userAgreements}
            userAgreementsError={userAgreementsError}
            userAgreementsStatus={userAgreementsStatus}
          />
        </Tab.Pane>
      ),
    },
    isUserPermitted(PERMISSIONS.GET_USER_AGREEMENTS) && {
      menuItem: t('tab.agreements'),
      render: () => {
        return (
          <Tab.Pane>
            {userAgreements?.length > 0 ? (
              <UserAgreements
                userAgreements={userAgreements}
                userAgreementsError={userAgreementsError}
                userAgreementsStatus={userAgreementsStatus}
              />
            ) : (
              <NotFoundMessage title={t('notFoundAgreements')} />
            )}
          </Tab.Pane>
        );
      },
    },
    user?.isAdmin &&
      isUserPermitted(PERMISSIONS.GET_USER_ROLES) && {
        menuItem: t('tab.roles'),
        render: () => (
          <Tab.Pane>
            {userRoles?.length > 0 ? (
              <UserRolesList
                allRoles={filteredRoles}
                allRolesStatus={allRolesStatus}
                userRoles={userRoles}
                status={userRolesStatus}
                addRoleToUser={actions.addRoleToUserAction}
                removeRoleFromUser={actions.removeRoleFromUserAction}
                handleRefresh={handleRolesRefresh}
                showAssignButtons={isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE)}
                showRemoveButtons={isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE)}
              />
            ) : (
              <NotFoundMessage title={t('notFoundUserRoles')} />
            )}
          </Tab.Pane>
        ),
      },
    isUserPermitted(PERMISSIONS.GET_PERSONAL_PERMISSIONS) && {
      menuItem: t('tab.permissions'),
      render: () => (
        <Tab.Pane>
          {personalPermissions?.length > 0 ? (
            <PersonalPermissionsList
              personalPermissions={personalPermissions}
              status={personalPermissionsStatus}
              allPermissions={allPermissions}
              allPermissionsStatus={allPermissionsStatus}
              addPersonalPermissionToUser={actions.addPersonalPermissionToUserAction}
              removePersonalPermissionFromUser={actions.removePersonalPermissionFromUserAction}
              clickedPermissionsIds={clickedPermissionsIds}
              handleRefresh={handlePermissionRefresh}
            />
          ) : (
            <NotFoundMessage title={t('notFoundUserPermissions')} />
          )}
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="admin-users">
      <h2>{t('editUser')}</h2>
      <Tab panes={panes} />
    </div>
  );
};

Main.propTypes = {
  actions: shape({ getSingleUserAction: func }).isRequired,
  countries: arrayOf(shape({})),
  user: shape({}),
  status: string,
  countriesStatus: string,
  personalPermissions: arrayOf(shape({})),
  personalPermissionsStatus: string,
  allPermissions: arrayOf(shape({})),
  allPermissionsStatus: string,
  clickedPermissionsIds: arrayOf(string),
  allRolesStatus: string,
  userRoles: arrayOf(shape({})),
  userRolesStatus: string,
  filteredRoles: arrayOf(shape({})),
  errors: arrayOf(shape({})),
};

Main.defaultProps = {
  countries: [],
  user: {},
  status: undefined,
  countriesStatus: undefined,
  personalPermissions: [],
  personalPermissionsStatus: undefined,
  allPermissions: [],
  allPermissionsStatus: undefined,
  clickedPermissionsIds: [],
  allRolesStatus: undefined,
  userRoles: [],
  userRolesStatus: undefined,
  filteredRoles: [],
  errors: [],
};

export default Main;
