const clearQueryParamsAndReload = reloadAction => {
  window.history.replaceState(null, null, window.location.pathname);
  reloadAction();
};

const mapQueryParams = ({ activeFilters, pagination, order } = {}) => {
  const filters = Object?.entries?.(activeFilters || {})?.reduce?.((acc, [key, item]) => {
    acc[key] = {
      type: 'filter',
      prototypeFieldName: item.prototypeFieldName,
      searchValue: item.searchValue,
    };
    return acc;
  }, {});

  const pag = Object?.entries?.(pagination || {})?.reduce?.((acc, [key, item]) => {
    acc[key] = {
      type: 'pagination',
      prototypeFieldName: item.prototypeFieldName,
      searchValue: item.searchValue,
    };
    return acc;
  }, {});

  const ord = Object?.entries?.(order || {})?.reduce?.((acc, [key, item]) => {
    acc[key] = {
      type: 'order',
      direction: item.direction,
      column: item.column,
    };
    return acc;
  }, {});

  return { ...filters, ...pag, order: { ...ord, type: 'order' } };
};

const getQueryParameters = (preparedParameters, type) => {
  return Object?.entries?.(preparedParameters)?.reduce?.((acc, [key, value]) => {
    if (value?.type === type) {
      acc[key] = value;
    }
    return acc;
  }, {});
};

const getUrlQueryParams = () => {
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      if (key.includes('filters')) {
        const prototypeField = key?.substring?.(key?.lastIndexOf?.('[') + 1, key?.lastIndexOf?.(']'));
        const fieldName = key?.substring?.(key?.indexOf?.('[') + 1, key?.indexOf?.(']'));

        if (prototypeField === 'from') {
          result.dateFrom = { type: 'filter', searchValue: value, prototypeFieldName: prototypeField };
        } else if (prototypeField === 'to') {
          result.dateTo = { type: 'filter', searchValue: value, prototypeFieldName: prototypeField };
        } else if (
          fieldName === 'isVisible' ||
          fieldName === 'isOpen' ||
          fieldName === 'active' ||
          fieldName === 'isFeatured' ||
          fieldName === 'required' ||
          fieldName === 'isAdmin' ||
          fieldName === 'isSuperAdmin'
        ) {
          result[fieldName] = { type: 'filter', searchValue: !!value, prototypeFieldName: prototypeField };
        } else {
          result[fieldName] = { type: 'filter', searchValue: value, prototypeFieldName: prototypeField };
        }
      } else if (key.includes('pagination')) {
        const fieldName = key?.substring?.(key?.indexOf?.('[') + 1, key?.indexOf?.(']'));

        result[fieldName] = { type: 'pagination', searchValue: value };
      } else if (key.includes('order')) {
        const prototypeField = key?.substring?.(key?.lastIndexOf?.('[') + 1, key?.lastIndexOf?.(']'));
        const fieldName = key?.substring?.(key?.indexOf?.('[') + 1, key?.indexOf?.(']'));

        result.order = { type: 'order', ...result?.order };
        result.order[fieldName] = {
          ...result?.order?.[fieldName],
          [prototypeField]: value,
          backendIndex: fieldName,
        };
      } else {
        result[key] = value;
      }
    }
    return result;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const preparedParameters = paramsToObject(entries);

  return {
    activeFilters: getQueryParameters(preparedParameters, 'filter'),
    pagination: getQueryParameters(preparedParameters, 'pagination'),
    order: getQueryParameters(preparedParameters, 'order')?.order,
    all: preparedParameters,
  };
};

export { clearQueryParamsAndReload, mapQueryParams, getUrlQueryParams };
