const getAllFaq = state => state?.faq?.allFaq;
const getSingleFaq = state => state?.faq?.singleFaq;
const getFaqStatus = state => state?.faq?.status;
const getSingleStatus = state => state?.faq?.singleStatus;
const getDeleteFaqStatus = state => state?.faq?.deleteStatus;
const getEditFaqStatus = state => state?.faq?.editStatus;
const getCreateFaqStatus = state => state?.faq?.createStatus;
const getFaqError = state => state?.faq?.error;
const getAllFaqQueryParams = state => state?.faq?.queryParams;

export {
  getAllFaq,
  getSingleFaq,
  getFaqStatus,
  getDeleteFaqStatus,
  getEditFaqStatus,
  getFaqError,
  getCreateFaqStatus,
  getSingleStatus,
  getAllFaqQueryParams,
};
