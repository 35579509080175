import {
  EDIT_PAGE_INIT,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_FAILURE,
  GET_SINGLE_PAGE_SUCCESS,
} from '@store/action-types/page';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { set, toPath } from 'lodash';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';

const editPageInit = () => ({ type: EDIT_PAGE_INIT });
const editPageSuccess = () => ({ type: EDIT_PAGE_SUCCESS });
const editPageFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_PAGE_FAILURE, payload: { error: mappedErrors } };
};
const updateSinglePage = page => ({ type: GET_SINGLE_PAGE_SUCCESS, payload: { page } });

const transformPageDataRequest = ({ pageData, imageData, filesData }) => ({
  active: pageData?.active,
  image: imageData,
  slug: pageData?.slug,
  title: pageData?.title,
  subTitle: pageData?.subTitle,
  content: pageData?.content,
  seoTitle: pageData?.seoTitle,
  seoDescription: pageData?.seoDescription,
  attachments: filesData
    ?.filter?.(item => !item.file.delete)
    ?.map?.(file => ({
      displayName: file?.displayName,
      file: {
        fileName: file?.file?.fileName,
        base64: file?.file?.base64,
      },
    })),
});

const transformPageDataResponse = editPageResponse => ({
  ...editPageResponse?.response,
  attachments: editPageResponse?.response?.attachments?.map?.((attachment, index) => ({
    ...attachment,
    file: { fileName: attachment.displayName, delete: false },
    isNewFile: false,
    fileItemIndex: index,
  })),
});

const editPage = ({ pageData, imageData, filesData, saveAndBack = false }) => async dispatch => {
  dispatch(editPageInit());

  try {
    const editPageResponse = await client.editPage({
      slug: pageData?.id,
      data: transformPageDataRequest({ pageData, imageData, filesData }),
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    await dispatch(editPageSuccess());

    if (saveAndBack) {
      dispatch(editPageSuccess());
      return history.push(ROUTES.PAGES);
    }

    return dispatch(updateSinglePage(transformPageDataResponse(editPageResponse)));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    return dispatch(editPageFailure(error));
  }
};

export default editPage;
