import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getStaticContentParentAction } from '@store/actions/static-content';
import {
  getStaticContentParentStatus,
  getStaticContentParent,
  getBreadcrumbs,
} from '@store/reducers/static-content/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  status: getStaticContentParentStatus(state),
  parent: getStaticContentParent(state),
  breadcrumbs: getBreadcrumbs(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getStaticContentParentAction }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
