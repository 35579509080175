import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 5,
    },
  },
  leftHeaderWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  labelInput: {
    marginRight: '16px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 5,
    },
  },
  button: {
    minHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      minHeight: 30,
      height: 30,
      width: 64,
    },
  },
}));

export default useStyles;
