import React, { useEffect, useState } from 'react';
import { arrayOf, shape } from 'prop-types';
import clsx from 'clsx';
import { Table as SemanticTable, Dropdown, Pagination, Button, Icon } from 'semantic-ui-react';
import { useTable, useFilters } from 'react-table';
import { mapQueryParams, clearQueryParamsAndReload } from '@shared/components/Table/utils';
import { isEmpty, uniqBy } from 'lodash';
import { Loader, Skeleton } from '@shared/layout-components';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import moment from 'moment';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TableFilter from './TableFilter';
import TableOrder from './TableOrder';
import useStyles from './styles';
import './styles.scss';

const Table = ({
  columns = [],
  data = [],
  hideFooter = false,
  hideFilters = false,
  searchAction,
  queryParams,
  reloadAction,
  isLoading,
  notFoundMessage,
  className,
}) => {
  const [t] = useTranslation(TRANSLATIONS.COMMON);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }, useFilters);
  const styles = useStyles();
  const [activeFilters, setActiveFilters] = useState(queryParams?.activeFilters);
  const [pagination, setPagination] = useState(queryParams?.pagination);
  const [order, setOrder] = useState(queryParams?.order);
  const [isClearing, setIsClearing] = useState(false);

  useEffect(() => {
    setActiveFilters(activeFilters || queryParams?.activeFilters);
    setPagination(pagination || queryParams?.pagination);
    setOrder(order || queryParams?.order);
  }, [isLoading]);

  useEffect(() => {
    if (window?.location?.search?.length > 1) {
      setActiveFilters(queryParams?.activeFilters);
      setPagination(queryParams?.pagination);
      setOrder(queryParams?.order);
    } else {
      setActiveFilters({});
      setPagination({});
      setOrder({});
    }
  }, []);

  const onChangePagination = activePage => {
    setPagination(prevState => ({ ...prevState, pageNumber: { type: 'pagination', searchValue: activePage } }));

    searchAction({
      activeFilters: mapQueryParams({
        activeFilters,
        pagination: { ...pagination, pageNumber: { type: 'pagination', searchValue: activePage } },
        order,
      }),
    });
  };

  const onSearchAction = () => {
    setPagination(prevState => ({
      ...prevState,
      pageNumber: { type: 'pagination', searchValue: 1 },
    }));

    searchAction({
      activeFilters: mapQueryParams({
        activeFilters,
        pagination: {
          ...pagination,
          pageNumber: { type: 'pagination', searchValue: 1 },
        },
        order,
      }),
    });
  };

  const onChangeSearch = ({ name, value, filterPrototype }) => {
    switch (name) {
      case 'dateFrom': {
        setActiveFilters(prevState => ({
          ...prevState,
          dateFrom: { searchValue: moment(value).format('YYYY-MM-DD'), prototypeFieldName: 'from' },
        }));
        break;
      }
      case 'dateTo': {
        setActiveFilters(prevState => ({
          ...prevState,
          dateTo: { searchValue: moment(value).format('YYYY-MM-DD'), prototypeFieldName: 'to' },
        }));
        break;
      }
      default: {
        setActiveFilters(prevState => ({
          ...prevState,
          [name]: { searchValue: value, prototypeFieldName: Object?.keys?.(filterPrototype?.prototype)?.[0] },
        }));
      }
    }
  };

  const onChangeItemsPerPage = pageSize => {
    setPagination(prevState => ({
      ...prevState,
      pageSize: { type: 'pagination', searchValue: pageSize },
      pageNumber: { type: 'pagination', searchValue: 1 },
    }));

    searchAction({
      activeFilters: mapQueryParams({
        activeFilters,
        pagination: {
          ...pagination,
          pageSize: { type: 'pagination', searchValue: pageSize },
          pageNumber: { type: 'pagination', searchValue: 1 },
        },
        order,
      }),
    });
  };

  const onChangeOrder = ({ column, direction, backendIndex }) => {
    setOrder(prevState => ({
      ...prevState,
      type: 'order',
      [backendIndex]: {
        ...prevState?.[backendIndex],
        column,
        direction,
        backendIndex,
      },
    }));

    searchAction({
      activeFilters: mapQueryParams({
        activeFilters,
        pagination,
        order: {
          ...order,
          type: 'order',
          [backendIndex]: {
            ...order?.[backendIndex],
            column,
            direction,
            backendIndex,
          },
        },
      }),
    });
  };

  const renderTableBody = () => {
    if (isEmpty(data)) {
      return (
        <SemanticTable.Body>
          <SemanticTable.Row>
            <SemanticTable.Cell colSpan={999}>{notFoundMessage}</SemanticTable.Cell>
          </SemanticTable.Row>
        </SemanticTable.Body>
      );
    }

    return (
      <SemanticTable.Body {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <SemanticTable.Row key={`row-${row.id}`}>
              {row.cells.map(cell => {
                return (
                  <SemanticTable.Cell
                    key={`cell-${cell?.row?.original?.id}`}
                    {...cell.getCellProps({ className: clsx(styles.tableCell, cell.column.className) })}
                  >
                    {cell.render('Cell')}
                  </SemanticTable.Cell>
                );
              })}
              {queryParams?.configuredOrder?.length > 0 && <SemanticTable.Cell />}
            </SemanticTable.Row>
          );
        })}
      </SemanticTable.Body>
    );
  };

  const renderTableHeader = () => (
    <SemanticTable.Header className="common-table--headers-wrapper">
      {headerGroups.map(headerGroup => (
        <SemanticTable.Row {...headerGroup.getHeaderGroupProps()} className="common-table--headers">
          {headerGroup.headers.map(column => (
            <SemanticTable.HeaderCell
              {...column.getHeaderProps({ className: column.className })}
              verticalAlign="middle"
            >
              <TableOrder column={column} queryParams={queryParams} order={order} onChangeOrder={onChangeOrder} />
            </SemanticTable.HeaderCell>
          ))}
          {queryParams?.configuredOrder?.length > 0 && (
            <SemanticTable.HeaderCell className="text-right">
              <OverlayTrigger
                placement="left"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <Tooltip id="order-table-tooltip">
                    <p>{t('commonTable.tableTooltipOrder')}</p>
                  </Tooltip>
                }
              >
                <Icon className="order-tooltip" size="large" color="black" name="info circle" />
              </OverlayTrigger>
            </SemanticTable.HeaderCell>
          )}
        </SemanticTable.Row>
      ))}
      {!hideFilters &&
        headerGroups.map(headerGroup => (
          <SemanticTable.Row {...headerGroup.getHeaderGroupProps()} className="common-table--headers">
            {headerGroup.headers.map(column => (
              <SemanticTable.HeaderCell
                {...column.getHeaderProps({ className: column.className })}
                verticalAlign="middle"
              >
                <TableFilter
                  column={column}
                  searchAction={searchAction}
                  queryParams={queryParams}
                  activeFilters={activeFilters}
                  setActiveFilters={setActiveFilters}
                  onSearchAction={onSearchAction}
                  onChangeSearch={onChangeSearch}
                />
              </SemanticTable.HeaderCell>
            ))}
            {queryParams?.configuredOrder?.length > 0 && <SemanticTable.HeaderCell />}
          </SemanticTable.Row>
        ))}
      {!hideFilters && (
        <SemanticTable.Row className="text-right">
          <SemanticTable.HeaderCell colSpan={999} className="common-table--action-buttons">
            <Button
              color="blue"
              content={t('commonTable.search')}
              icon="search"
              labelPosition="left"
              className="table-search-button"
              onClick={() => onSearchAction()}
            />
            <Button
              color="black"
              content={t('commonTable.clear')}
              icon="close"
              labelPosition="left"
              className="table-clear-button"
              onClick={() =>
                clearQueryParamsAndReload(async () => {
                  setIsClearing(true);
                  setActiveFilters({});
                  setPagination({});
                  setOrder({});
                  await reloadAction();
                  setIsClearing(false);
                })
              }
            />
            <OverlayTrigger
              placement="left"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">
                  <p>{t('commonTable.tableTooltipSearch')}</p>
                  <p>{t('commonTable.tableTooltipClear')}</p>
                </Tooltip>
              }
            >
              <Icon className="table-tooltip" size="large" color="blue" name="info circle" />
            </OverlayTrigger>
          </SemanticTable.HeaderCell>
        </SemanticTable.Row>
      )}
    </SemanticTable.Header>
  );

  const renderTableFooter = () => {
    const itemsPerPage = () =>
      uniqBy(
        [
          { key: '5', text: '5', value: '5' },
          { key: '10', text: '10', value: '10' },
          { key: '20', text: '20', value: '20' },
          { key: '50', text: '50', value: '50' },
          {
            key: queryParams?.configuredPagination?.pageSize.toString(),
            text: queryParams?.configuredPagination?.pageSize.toString(),
            value: queryParams?.configuredPagination?.pageSize.toString(),
          },
        ],
        'key',
      );

    return (
      <SemanticTable.Footer>
        <SemanticTable.Row>
          <SemanticTable.HeaderCell colSpan={999}>
            <div className="common-table--footer">
              <div className="common-table--footer-left">
                <div className="common-table--footer-total-items">
                  <p>{t('commonTable.totalItems')}</p> <span>{queryParams?.configuredPagination?.totalItems}</span>
                </div>
                <div className="common-table--footer-page-size">
                  <p>{t('commonTable.itemsPerPage')}</p>
                  <Dropdown
                    placeholder={t('commonTable.itemsPerPage')}
                    value={
                      pagination?.pageSize?.searchValue.toString() ||
                      queryParams?.configuredPagination?.pageSize.toString()
                    }
                    options={itemsPerPage()}
                    onChange={(e, { value }) => {
                      onChangeItemsPerPage(value);
                    }}
                  />
                </div>
              </div>
              <Pagination
                activePage={pagination?.pageNumber?.searchValue || queryParams?.configuredPagination?.pageNumber}
                boundaryRange={1}
                onPageChange={(e, { activePage }) => onChangePagination(activePage)}
                siblingRange={1}
                totalPages={queryParams?.configuredPagination?.totalPages}
                ellipsisItem={null}
              />
            </div>
          </SemanticTable.HeaderCell>
        </SemanticTable.Row>
      </SemanticTable.Footer>
    );
  };

  return (
    <div className={className}>
      {!isLoading ? (
        <SemanticTable unstackable padded {...getTableProps}>
          {!isClearing && renderTableHeader()}
          {isLoading ? (
            <SemanticTable.Body>
              <SemanticTable.Row>
                <SemanticTable.Cell colSpan={999}>
                  <Loader form />
                </SemanticTable.Cell>
              </SemanticTable.Row>
            </SemanticTable.Body>
          ) : (
            renderTableBody()
          )}
          {!isLoading && !hideFooter && renderTableFooter()}
        </SemanticTable>
      ) : (
        <Skeleton variant={SKELETON_VARIANT.TABLE} />
      )}
    </div>
  );
};

Table.propTypes = {
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
};

export default Table;
