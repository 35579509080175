import React from 'react';
import { FormControlLabel, Switch, TextField } from '@material-ui/core';
import { func, shape } from 'prop-types';

const YouTubeVideo = ({ elementValue, setElementData }) => {
  const handleSetElementData = (e, isAutoPlay) => {
    let key;
    let value;

    if (isAutoPlay) {
      key = 'autoPlay';
      value = e.target.checked;

      setElementData(key, value);
      return;
    }

    key = e.target.name;
    value = e.target.value;
    setElementData(key, value);
  };

  return (
    <div>
      <div>
        <TextField
          variant="outlined"
          onChange={handleSetElementData}
          value={elementValue.label || ''}
          name="label"
          size="small"
          label="Label"
          autoComplete="off"
        />
        <FormControlLabel
          value="start"
          control={<Switch color="primary" onChange={e => handleSetElementData(e, true)} />}
          label="Auto play"
          labelPlacement="start"
          checked={elementValue.autoPlay || false}
        />
      </div>
      <TextField
        autoComplete="off"
        variant="outlined"
        onChange={handleSetElementData}
        name="url"
        placeholder="e.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        size="small"
        label="URL"
        fullWidth
        value={elementValue.url || ''}
      />
    </div>
  );
};

YouTubeVideo.propTypes = {
  elementValue: shape({}).isRequired,
  setElementData: func.isRequired,
};

export default YouTubeVideo;
