import React from 'react';
import { Form, Grid } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';
import { SwitchField, InputField } from '../../../../../components';

class YoutubeElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChangeAutoPlay = this.onChangeAutoPlay.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
  }

  onChangeAutoPlay(e, { name, checked }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: checked,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeUrl(e, { name, value }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      [name]: value,
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <Form.Group>
        <Grid container stackable columns={2} divided>
          <SwitchField
            name="autoPlay"
            label={t('video.autoPlay')}
            value={element?.autoPlay}
            onChange={this.onChangeAutoPlay}
            disabled={isViewMode}
            tooltip={t('tooltip.youtube.autoPlay')}
          />
          <InputField
            tooltip={t('tooltip.youtube.url')}
            type="text"
            name="url"
            errorMessage={t(`${TRANSLATIONS.ERRORS}:${elementErrors?.url || ''}`)}
            label={t('video.url')}
            value={element?.url}
            icon="linkify"
            placeholder="e.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ"
            onChange={this.onChangeUrl}
            disabled={isViewMode}
          />
        </Grid>
      </Form.Group>
    );
  }
}

export default withTranslation([TRANSLATIONS.OFFERS, TRANSLATIONS.ERRORS])(YoutubeElement);
