import {
  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_SINGLE_USER_SUCCESS,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import getSingleUserAction from '@store/actions/user-management/getSingleUser';
import { isEmpty, set, toPath } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const editUserInit = () => ({ type: EDIT_USER_INIT });
const editUserSuccess = () => ({ type: EDIT_USER_SUCCESS });
const editUserFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_USER_FAILURE, payload: { errors: error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  return { type: EDIT_USER_FAILURE, payload: { errors: mappedErrors } };
};

const getSingleUserSuccess = singleUser => ({ type: GET_SINGLE_USER_SUCCESS, payload: { singleUser } });

const editUser = ({ userData, saveAndBack = false }) => async dispatch => {
  dispatch(editUserInit());

  const errors = {};
  const emailRegexp = /\S+@\S+\.\S+/;

  if (isEmpty(userData?.firstName)) {
    errors.firstName = 'FIRST_NAME_IS_EMPTY';
  }

  if (isEmpty(userData?.lastName)) {
    errors.lastName = 'LAST_NAME_IS_EMPTY';
  }

  if (!emailRegexp.test(userData?.email)) {
    errors.email = 'EMAIL_INVALID_FORMAT';
  }

  if (!isEmpty(errors)) {
    dispatch(getSingleUserSuccess(userData));
    return dispatch(editUserFailure(errors));
  }

  try {
    const editUserResponse = await client.editUser({ data: userData, slug: userData?.id });

    await dispatch(editUserSuccess(editUserResponse));
    await dispatch(getSingleUserAction(userData?.id));

    if (saveAndBack) {
      history.push(ROUTES.USERS);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(getSingleUserSuccess(userData));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editUserFailure(error));
  }
};

export default editUser;
