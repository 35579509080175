import { GET_USER_INFO_FAILURE, GET_USER_INFO_INIT, GET_USER_INFO_SUCCESS } from '@store/action-types/user';
import Client from '@api-sdk/client';

const getUserInfoInit = () => ({ type: GET_USER_INFO_INIT });
const getUserInfoSuccess = user => ({ type: GET_USER_INFO_SUCCESS, payload: { user } });
const getUserInfoFailure = error => ({ type: GET_USER_INFO_FAILURE, payload: { error } });

const getUserInfo = () => async dispatch => {
  dispatch(getUserInfoInit());

  try {
    const user = await Client.getUserInfo();
    dispatch(getUserInfoSuccess(user?.response));
  } catch (error) {
    dispatch(getUserInfoFailure(error));
  }
};

export default getUserInfo;
