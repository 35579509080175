import Client from '@api-sdk/client';
import {
  GET_SINGLE_PERMISSION_FAILURE,
  GET_SINGLE_PERMISSION_INIT,
  GET_SINGLE_PERMISSION_SUCCESS,
} from '@store/action-types/permissions';

const getSinglePermissionInit = () => ({ type: GET_SINGLE_PERMISSION_INIT });
const getSinglePermissionSuccess = singlePermission => ({
  type: GET_SINGLE_PERMISSION_SUCCESS,
  payload: { singlePermission },
});
const getSinglePermissionFailure = error => ({ type: GET_SINGLE_PERMISSION_FAILURE, payload: { error } });

const getSinglePermission = permissionId => async dispatch => {
  dispatch(getSinglePermissionInit());

  try {
    const singlePermission = await Client.getSinglePermission({ slug: permissionId });
    dispatch(getSinglePermissionSuccess(singlePermission.response));
  } catch (error) {
    dispatch(getSinglePermissionFailure(error));
  }
};

export default getSinglePermission;
