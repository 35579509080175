import React from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { func, number, string } from 'prop-types';

const PositionSelect = ({ className, id, handleChange, numberOfOptions }) => {
  const renderOption = () => {
    const items = [];

    for (let i = 0; i <= numberOfOptions - 1; i += 1) {
      items.push(
        <MenuItem key={`option-${i}`} value={i}>
          {i + 1}
        </MenuItem>,
      );
    }

    return items;
  };

  return (
    <FormControl variant="outlined" size="small">
      <Select className={className} onChange={e => handleChange(e, id)} value={id} select="true" variant="outlined">
        {renderOption()}
      </Select>
    </FormControl>
  );
};

PositionSelect.propTypes = {
  className: string,
  id: number.isRequired,
  handleChange: func.isRequired,
  numberOfOptions: number.isRequired,
};

PositionSelect.defaultProps = {
  className: '',
};

export default PositionSelect;
