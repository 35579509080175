import React from 'react';
import { func, shape } from 'prop-types';
import PermissionForm from '@screens/permissions/components/PermissionForm/PermissionForm';

const Main = ({ actions, errors }) => {
  return <PermissionForm isCreate handleAddPermission={actions.createPermissionAction} permissionsErrors={errors} />;
};

Main.propTypes = {
  actions: shape({ createPermissionAction: func }).isRequired,
  errors: shape({}),
};

Main.defaultProps = {
  errors: {},
};

export default Main;
