import React from 'react';
import useStyles from './styles';

const Footer = () => {
  const styles = useStyles();

  return <footer className={styles.footer} />;
};

export default Footer;
