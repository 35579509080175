import {
  GET_USER_ROLES_INIT,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';

const getUserRolesInit = () => ({ type: GET_USER_ROLES_INIT });
const getUserRolesSuccess = userRoles => ({ type: GET_USER_ROLES_SUCCESS, payload: { userRoles } });
const getUserRolesFailure = errors => ({ type: GET_USER_ROLES_FAILURE, payload: { errors } });

const getUserRoles = userId => async dispatch => {
  dispatch(getUserRolesInit());

  try {
    const userRoles = await client.getUserRoles({ slug: `${userId}/roles` });
    dispatch(getUserRolesSuccess(userRoles.response));
  } catch (error) {
    dispatch(getUserRolesFailure(error));
  }
};

export default getUserRoles;
