import React from 'react';
import { Table, Button, Icon, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link } from 'react-router-dom';

const UserAgreements = ({ userAgreements, userAgreementsError, userAgreementsStatus }) => {
  const [t] = useTranslation(TRANSLATIONS.USERS);

  return (
    <div className="user-agreements">
      <h3>{t('userAgreements.userAgreementsTitle')}</h3>
      {userAgreementsError && <Message color="red">{t('loadAgreementsError')}</Message>}
      <Table striped>
        <Table.Header>
          <Table.Row verticalAlign="middle">
            <Table.HeaderCell>{t('userAgreements.agreementTarget')}</Table.HeaderCell>
            <Table.HeaderCell>{t('userAgreements.agreementType')}</Table.HeaderCell>
            <Table.HeaderCell>{t('userAgreements.agreementIsRequired')}</Table.HeaderCell>
            <Table.HeaderCell>{t('userAgreements.agreementIsAccepted')}</Table.HeaderCell>
            <Table.HeaderCell>{t('userAgreements.agreementOrderId')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userAgreements?.map?.(agreement => {
            return (
              <>
                <Table.Row style={{ fontWeight: '600' }}>
                  <Table.Cell>{agreement.target}</Table.Cell>
                  <Table.Cell>{agreement.type}</Table.Cell>
                  <Table.Cell>
                    {agreement.isRequired ? (
                      <Icon name="check circle" color="green" />
                    ) : (
                      <Icon name="check circle" color="red" />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {agreement.isAccepted ? (
                      <Icon name="check circle" color="green" />
                    ) : (
                      <Icon name="check circle" color="red" />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {agreement.orderId ? (
                      <Button primary as={Link} to={`/orders/${agreement.orderId}/edit`}>
                        {t('userAgreements.agreementOrderId')}
                      </Button>
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colspan={99} className="agreement-row">
                    <span style={{ fontWeight: 600 }}>{t('userAgreements.agreementContent')}: </span>
                    <span dangerouslySetInnerHTML={{ __html: agreement.content }} />
                  </Table.Cell>
                </Table.Row>
              </>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};

export default UserAgreements;
