import {
  GET_ROLE_PERMISSIONS_INIT,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAILURE,
} from '@store/action-types/roles';
import client from '@api-sdk/client';

const getRolePermissionsInit = () => ({ type: GET_ROLE_PERMISSIONS_INIT });
const getRolePermissionsSuccess = response => ({
  type: GET_ROLE_PERMISSIONS_SUCCESS,
  payload: { response },
});
const getRolePermissionsFailure = error => ({ type: GET_ROLE_PERMISSIONS_FAILURE, payload: { error } });

const getRolePermissions = roleId => async dispatch => {
  dispatch(getRolePermissionsInit());

  try {
    const rolePermissionsResponse = await client.getRolePermissions({
      slugs: [roleId, 'permission'],
    });

    dispatch(getRolePermissionsSuccess(rolePermissionsResponse.response));
  } catch (error) {
    dispatch(getRolePermissionsFailure(error?.errors?.[0]?.code));
  }
};

export default getRolePermissions;
