import React from 'react';
import { arrayOf, func, number, shape, string } from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  FormControl,
  Card,
  MenuItem,
  CardContent,
  CardHeader,
  Select,
  InputLabel,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import {
  TextBox,
  ImageBox,
  GalleryBox,
  Map,
  Counter,
  Timeline,
  BarChart,
  PieChart,
  YouTubeVideo,
  PositionSelect,
} from '@shared/components/OfferForm/components';
import useStyles from './styles';

const SectionElement = ({
  elements,
  elementId,
  addElementInElement,
  addArrayToData,
  removeElement,
  handleSetType,
  handleSetGrid,
  handleSetElementPosition,
  handleSetElementData,
  handleArrayOfElementsSetPosition,
  removeElementFromElement,
  handleSetElementDataWithArray,
  grid,
  elementType,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'), {
    defaultMatches: true,
  });
  const arrayOfElementsSetPosition = (oldIndex, newIndex) => {
    handleArrayOfElementsSetPosition(elementId, oldIndex, newIndex);
  };

  const handleAddArrayToData = () => {
    addArrayToData(elementId);
  };

  const handleAddElementInElement = position => {
    addElementInElement(elementId, position);
  };

  const handleRemoveElementFromElement = position => {
    removeElementFromElement(elementId, position);
  };

  const setElementData = (key, value) => {
    handleSetElementData(elementId, key, value);
  };

  const setElementDataWithArray = (index, key, value) => {
    handleSetElementDataWithArray(elementId, index, key, value);
  };

  const setElementType = e => {
    handleSetType(elementId, e.target.value);
  };

  const setElementGrid = e => {
    const name = e.target.name;
    const gridValue = e.target.value;

    handleSetGrid(elementId, name, gridValue);
  };

  const SectionOptions = () => {
    return (
      <div className={grid?.width === 3 || isXs ? styles.smallSelectContainer : styles.selectContainer}>
        <FormControl className={styles.select} variant="outlined" size="small">
          <InputLabel id="section-type">Type</InputLabel>
          <Select name="type" id="section-type" value={elementType} onChange={setElementType} label="Type">
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="gallery">Gallery</MenuItem>
            <MenuItem value="map">Map</MenuItem>
            <MenuItem value="timeline">Project Timeline</MenuItem>
            <MenuItem value="counter">Counters</MenuItem>
            <MenuItem value="youtube">YouTube Video</MenuItem>
            <MenuItem value="pieChart">Pie Chart</MenuItem>
            <MenuItem value="barChart">Bar Chart</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.select} variant="outlined" size="small">
          <InputLabel id="section-desktop-width">Desktop</InputLabel>
          <Select
            name="desktop"
            id="section-desktop-width"
            value={grid?.desktop}
            onChange={setElementGrid}
            label="Desktop"
          >
            <MenuItem value={3}>25%</MenuItem>
            <MenuItem value={6}>50%</MenuItem>
            <MenuItem value={9}>75%</MenuItem>
            <MenuItem value={12}>100%</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.select} variant="outlined" size="small">
          <InputLabel id="section-tablet-width">Tablet</InputLabel>
          <Select name="tablet" id="section-tablet-width" value={grid?.tablet} onChange={setElementGrid} label="Tablet">
            <MenuItem value={3}>25%</MenuItem>
            <MenuItem value={6}>50%</MenuItem>
            <MenuItem value={9}>75%</MenuItem>
            <MenuItem value={12}>100%</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={styles.select} variant="outlined" size="small">
          <InputLabel id="section-mobile-width">Mobile</InputLabel>
          <Select name="mobile" id="section-mobile-width" value={grid?.mobile} onChange={setElementGrid} label="Mobile">
            <MenuItem value={3}>25%</MenuItem>
            <MenuItem value={6}>50%</MenuItem>
            <MenuItem value={9}>75%</MenuItem>
            <MenuItem value={12}>100%</MenuItem>
          </Select>
        </FormControl>
        <PositionSelect
          className={styles.select}
          handleChange={e => handleSetElementPosition(e, elementId)}
          numberOfOptions={elements.length}
          id={elementId}
        />
        {elements.length > 1 && <Button onClick={() => removeElement(elementId)}>remove</Button>}
      </div>
    );
  };

  const renderElementContent = type => {
    switch (type) {
      case 'textBox':
        return <TextBox setElementData={setElementData} elementValue={elements[elementId].data} />;
      case 'imageBox':
        return <ImageBox setElementData={setElementData} elementValue={elements[elementId].data} />;
      case 'galleryBox':
        return (
          <GalleryBox
            handleAddArrayToData={handleAddArrayToData}
            handleAddElementInElement={handleAddElementInElement}
            setElementData={setElementData}
            setElementDataWithArray={setElementDataWithArray}
            handleRemoveElementFromElement={handleRemoveElementFromElement}
            arrayOfElementsSetPosition={arrayOfElementsSetPosition}
            elementValue={elements[elementId].data}
          />
        );
      case 'map':
        return <Map setElementData={setElementData} elementValue={elements[elementId].data} />;
      case 'timeline':
        return (
          <Timeline
            handleAddArrayToData={handleAddArrayToData}
            handleAddElementInElement={handleAddElementInElement}
            setElementData={setElementData}
            setElementDataWithArray={setElementDataWithArray}
            handleRemoveElementFromElement={handleRemoveElementFromElement}
            arrayOfElementsSetPosition={arrayOfElementsSetPosition}
            elementValue={elements[elementId].data}
          />
        );
      case 'counter':
        return (
          <Counter
            handleAddArrayToData={handleAddArrayToData}
            handleAddElementInElement={handleAddElementInElement}
            setElementData={setElementData}
            setElementDataWithArray={setElementDataWithArray}
            handleRemoveElementFromElement={handleRemoveElementFromElement}
            arrayOfElementsSetPosition={arrayOfElementsSetPosition}
            elementValue={elements[elementId].data}
          />
        );
      case 'pieChart':
        return (
          <PieChart
            handleAddArrayToData={handleAddArrayToData}
            handleAddElementInElement={handleAddElementInElement}
            setElementData={setElementData}
            setElementDataWithArray={setElementDataWithArray}
            handleRemoveElementFromElement={handleRemoveElementFromElement}
            arrayOfElementsSetPosition={arrayOfElementsSetPosition}
            elementValue={elements[elementId].data}
          />
        );
      case 'barChart':
        return (
          <BarChart
            handleAddArrayToData={handleAddArrayToData}
            handleAddElementInElement={handleAddElementInElement}
            setElementData={setElementData}
            setElementDataWithArray={setElementDataWithArray}
            handleRemoveElementFromElement={handleRemoveElementFromElement}
            arrayOfElementsSetPosition={arrayOfElementsSetPosition}
            elementValue={elements[elementId].data}
          />
        );
      case 'youtube':
        return <YouTubeVideo setElementData={setElementData} elementValue={elements[elementId].data} />;
      default:
        return (
          <div className={styles.defaultElement}>
            <Typography className="text-dark-75 font-weight-bolder mb-1 font-size-lg text-muted" variant="h4">
              Select an element type
            </Typography>
          </div>
        );
    }
  };

  return (
    <Grid item xs={12} lg={grid?.desktop} className={styles.wrapper}>
      <Card className={styles.card}>
        <CardHeader title={<SectionOptions />} />
        <Divider />
        <CardContent className={styles.cardContent}>{renderElementContent(elementType)}</CardContent>
      </Card>
    </Grid>
  );
};

SectionElement.propTypes = {
  elementId: number.isRequired,
  elementType: string.isRequired,
  elements: arrayOf(shape({})).isRequired,
  grid: shape({}).isRequired,
  handleSetElementPosition: func.isRequired,
  handleSetGrid: func.isRequired,
  handleSetType: func.isRequired,
  handleSetElementData: func.isRequired,
  handleSetElementDataWithArray: func.isRequired,
  addElementInElement: func.isRequired,
  addArrayToData: func.isRequired,
  handleArrayOfElementsSetPosition: func.isRequired,
  removeElementFromElement: func.isRequired,
  removeElement: func.isRequired,
};

export default SectionElement;
