import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleAgreementAction, editAgreementAction, getTypesAndTargetsAction } from '@store/actions/agreements';
import {
  getSingleAgreement,
  getSingleAgreementStatus,
  getEditStatus,
  getErrors,
  getTargets,
  getTypes,
  getTypesStatus,
} from '@store/reducers/agreements/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleAgreement: getSingleAgreement(state),
  singleStatus: getSingleAgreementStatus(state),
  editStatus: getEditStatus(state),
  errors: getErrors(state),
  targets: getTargets(state),
  types: getTypes(state),
  typeStatus: getTypesStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleAgreementAction,
      editAgreementAction,
      getTypesAndTargetsAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
