import { CREATE_SLIDER_INIT, CREATE_SLIDER_SUCCESS, CREATE_SLIDER_FAILURE } from '@store/action-types/slider';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import { set, toPath, isEmpty } from 'lodash';
import ROUTES from '@shared/consts/routes';

const createSliderInit = () => ({ type: CREATE_SLIDER_INIT });
const createSliderSuccess = () => ({ type: CREATE_SLIDER_SUCCESS });
const createSliderFailure = (error, sliderData, imageData) => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  if (isEmpty(sliderData?.guestButtonLabel?.pl) || isEmpty(sliderData?.guestButtonLabel?.en)) {
    mappedErrors.guestButtonLabel = 'GUEST_BUTTON_LABEL_IS_EMPTY';
  }

  if (isEmpty(imageData?.image?.base64)) {
    mappedErrors.image = 'IMAGE_IS_REQUIRED';
  }

  return { type: CREATE_SLIDER_FAILURE, payload: { error: mappedErrors } };
};

const transformedSliderDataResponse = ({ sliderData, imageData }) => ({
  position: sliderData?.position,
  active: sliderData?.active,
  name: sliderData?.name,
  content: sliderData?.content,
  guestButtonLabel: sliderData?.guestButtonLabel,
  guestButtonUrl: sliderData?.guestButtonUrl,
  loggedUserButtonLabel: sliderData?.loggedUserButtonLabel,
  loggedUserButtonUrl: sliderData?.loggedUserButtonUrl,
  image: imageData?.image,
});

const createSlider = ({ sliderData, imageData, saveAndBack = false }) => async dispatch => {
  dispatch(createSliderInit());

  try {
    const sliderResponse = await client.createSlider({
      data: transformedSliderDataResponse({ sliderData, imageData }),
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.SLIDER_MANAGEMENT);
    } else {
      history.push(`/slider/${sliderResponse.response?.id}`);
    }

    return dispatch(createSliderSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(createSliderFailure(error, sliderData, imageData));
  }
};

export default createSlider;
