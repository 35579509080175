import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSinglePage, getPagesStatus, getSinglePageErrors } from '@store/reducers/page/selectors';
import { getSinglePageAction, editPageAction } from '@store/actions/page';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singlePage: getSinglePage(state),
  pageStatus: getPagesStatus(state),
  pageErrors: getSinglePageErrors(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSinglePageAction,
      editPageAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
