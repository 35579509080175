import React, { useMemo } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import objectPath from 'object-path';
import { useHtmlClassService } from '@metronic/providers';
import { Sticky } from 'semantic-ui-react';
import { Container } from '@material-ui/core';
import { Topbar } from './components';

const Header = ({ getMenu, logoutUser, user }) => {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    };
  }, [uiService]);

  return (
    <Sticky>
      <div style={{ background: '#fff', boxShadow: '0 6px 12px 0 rgb(78 76 133 / 4%)' }}>
        <Container style={{ padding: 0 }}>
          <div className="header" id="kt_header" style={{ height: 80 }} {...layoutProps.headerAttributes}>
            <div
              style={{ padding: 0 }}
              className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-end`}
            >
              <Topbar getMenu={getMenu} logoutUser={logoutUser} user={user} />
            </div>
          </div>
        </Container>
      </div>
    </Sticky>
  );
};

Header.propTypes = {
  getMenu: func,
  topbarMenuItems: arrayOf(shape({})),
  logoutUser: func,
  user: shape({}),
};

Header.defaultProps = {
  getMenu: () => {},
  topbarMenuItems: [],
  logoutUser: () => {},
  user: {},
};

export default Header;
