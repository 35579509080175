import {
  DELETE_AGREEMENT_INIT,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
} from '@store/action-types/agreements';
import Client from '@api-sdk/client';
import getErrorCode from '@shared/utils/getErrorCode';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteAgreementInit = () => ({ type: DELETE_AGREEMENT_INIT });
const deleteAgreementSuccess = agreementId => ({ type: DELETE_AGREEMENT_SUCCESS, payload: { agreementId } });
const deleteAgreementFailure = error => ({ type: DELETE_AGREEMENT_FAILURE, payload: { error } });

const deleteAgreement = id => async dispatch => {
  dispatch(deleteAgreementInit());

  try {
    await Client.deleteAgreement({ slug: id });
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deleteAgreementSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    return dispatch(deleteAgreementFailure(getErrorCode(error?.errors)));
  }
};

export default deleteAgreement;
