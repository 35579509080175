import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { InputField } from '@screens/offers/NewOfferForm/components';
import TRANSLATIONS from '@translations/translationNamespaces';

const GoogleConfig = ({ isLoading, status, configData, setConfigData }) => {
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);

  const onInputChange = (e, { name, value }) =>
    setConfigData(prevState => ({
      ...prevState,
      [name]: value,
    }));

  return (
    <Form loading={status === FETCH_STATUS.IDLE || isLoading}>
      <Grid container stackable columns={2}>
        <Grid.Column>
          <InputField
            icon="google"
            onChange={onInputChange}
            label={t('form.googleAnalyticsId')}
            name="googleAnalyticsId"
            value={configData?.googleAnalyticsId}
          />
        </Grid.Column>
        <Grid.Column>
          <InputField
            icon="map"
            onChange={onInputChange}
            label={t('form.googleMapsId')}
            name="googleMapsId"
            value={configData?.googleMapsId}
          />
        </Grid.Column>
      </Grid>
    </Form>
  );
};

GoogleConfig.propTypes = {
  isLoading: bool,
  status: string,
  configData: shape({}),
  setConfigData: func,
};

GoogleConfig.defaultProps = {
  isLoading: false,
  status: undefined,
  configData: {},
  setConfigData: () => {},
};

export default GoogleConfig;
