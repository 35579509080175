import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import './Map.scss';

const Marker = ({ onClick }) => (
  <div className="map-marker-wrapper">
    <Icon className="map-marker" name="map marker" style={{ fontSize: 32, color: '#f34d4d', margin: 0 }} onClick={onClick} />
  </div>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func,
};

export default Marker;
