const getAllUsers = state => state?.userManagement?.allUsers;
const getSingleUser = state => state?.userManagement?.singleUser;
const getStatus = state => state?.userManagement?.status;
const getErrors = state => state?.userManagement?.errors;

const getPersonalPermissions = state => state?.userManagement?.personalPermissions;
const getPersonalPermissionsStatus = state => state?.userManagement?.personalPermissionsStatus;
const getPersonalPermissionsErrors = state => state?.userManagement?.personalPermissionsErrors;

const getUserRoles = state => state?.userManagement?.userRoles;
const getUserRolesStatus = state => state?.userManagement?.roleStatus;
const getUserRolesErrors = state => state?.userManagement?.roleErrors;

const getClickedPermissionsIds = state => state?.userManagement?.clickedPermissionIds;
const getClickedRolesIds = state => state?.userManagement?.clickedRolesIds;

const getUserAgreements = state => state?.userManagement?.userAgreements;
const getUserAgreementsError = state => state?.userManagement?.userAgreementsError;
const getUserAgreementsStatus = state => state?.userManagement?.userAgreementsStatus;

const getAllUsersQueryParams = state => state?.userManagement?.queryParams;

const getFilteredRoles = state => {
  return state?.staffManagement?.roles?.allRoles?.reduce?.((acc, role) => {
    const assignedRole = state?.userManagement?.userRoles?.find(r => r.id === role.id);

    if (assignedRole) {
      return acc;
    }
    return acc.concat([role]);
  }, []);
};

export {
  getSingleUser,
  getErrors,
  getAllUsers,
  getStatus,
  getPersonalPermissionsErrors,
  getPersonalPermissionsStatus,
  getPersonalPermissions,
  getClickedPermissionsIds,
  getClickedRolesIds,
  getUserRoles,
  getUserRolesStatus,
  getUserRolesErrors,
  getFilteredRoles,
  getUserAgreements,
  getUserAgreementsError,
  getUserAgreementsStatus,
  getAllUsersQueryParams,
};
