import {
  ADD_ROLE_TO_USER_INIT,
  ADD_ROLE_TO_USER_SUCCESS,
  ADD_ROLE_TO_USER_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import getUserRolesAction from '@store/actions/user-management/getUserRoles';
import translateToastMessage from '@shared/utils/translateToastMessage';

const addRoleToUserInit = clickedRolesId => ({
  type: ADD_ROLE_TO_USER_INIT,
  payload: { clickedRolesId },
});
const addRoleToUserSuccess = () => ({ type: ADD_ROLE_TO_USER_SUCCESS });
const addRoleToUserFailure = errors => ({
  type: ADD_ROLE_TO_USER_FAILURE,
  payload: { errors },
});

const addRoleToUser = (roleId, userId) => async dispatch => {
  dispatch(addRoleToUserInit(roleId));

  try {
    await client.addRoleToUser({ slug: `${roleId}/users/${userId}` });
    await dispatch(addRoleToUserSuccess());
    await dispatch(getUserRolesAction(userId));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.role.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.role.error.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(addRoleToUserFailure(error));
  }
};

export default addRoleToUser;
