import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  editorLabel: {
    padding: '0 2px',
  },
  gridItem: {
    margin: '5px 0',
  },
  helperText: {
    margin: '3px 14px 0',
    color: '#e53935',
  },
}));

export default useStyles;
