import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  formContainer: {
    minWidth: 360,
    maxWidth: 360,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainerMobile: {
    minWidth: 240,
    maxWidth: 255,
  },
  input: {
    margin: '8px 0',
    maxWidth: 360,
    background: '#f5f5f5',
  },
  helperText: {},
  successMessageContainer: {
    minHeight: 420,
    minWidth: 420,
    borderRadius: 8,
  },
  successMessageHeader: {
    background: '#d4edda',
    color: '#155724',
    padding: 10,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  successIcon: {
    fontSize: 24,
    margin: '0 10px 2px 0',
  },
  successMessageBody: {
    padding: 10,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    background: '#f3f3f3',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px solid #d4edda',
  },
  backToLoginButton: {
    margin: '10px 0',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 32,
    height: 28,
    [theme.breakpoints.up('md')]: {
      height: 32,
    },
  },
}));

export default useStyles;
