import React, { useState } from 'react';
import { bool, func, shape } from 'prop-types';
import clsx from 'clsx';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import { validateDocumentType, validateFileSize, validateImageType } from '@screens/page/utils';
import { TranslatableTextField } from '@shared/components/translatable';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const Image = ({ elementData, setElementData, isLoading, isViewMode }) => {
  const [imageUploadError, setImageUploadError] = useState('');
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);
  const styles = useStyles();

  const handleImageChange = e => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if ((validateDocumentType([file]) || validateImageType([file])) && validateFileSize([file], 0, 10000000)) {
      setImageUploadError('');

      if (file) {
        reader.onloadend = () => {
          return setElementData(prevState => ({
            ...prevState,
            image: {
              fileName: file.name,
              delete: false,
              base64: reader.result,
            },
          }));
        };

        return reader.readAsDataURL(file);
      }
      return setImageUploadError(
        'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
      );
    }
    return setImageUploadError(
      'Wrong file type or size, we accept common image file types, image file size cannot exceed 10 MB',
    );
  };

  const handleTextFieldChange = (e, { value, language }) => {
    setElementData(prevState => ({
      ...prevState,
      alt: {
        ...prevState?.alt,
        [language]: value,
      },
    }));
  };

  const handleRemoveImage = () =>
    setElementData(prevState => ({
      ...prevState,
      image: {
        base64: undefined,
        delete: true,
        name: undefined,
      },
    }));

  return (
    <Segment>
      <Form loading={isLoading}>
        <Form.Group className={styles.formWrapper}>
          <Grid container stackable columns={1} divided>
            <TranslatableTextField
              disabled={isViewMode}
              onChange={handleTextFieldChange}
              value={elementData?.alt}
              label="Alt"
              icon="font"
            />
          </Grid>
          {isViewMode ? null : (
            <Grid container stackable columns={1} divided>
              <Grid.Column>
                <Form.Field>
                  <label aria-controls="" htmlFor="create-page-add-file">
                    <p>{t('imageUpload')}</p>
                    <input
                      id="create-page-add-file"
                      type="file"
                      onChange={handleImageChange}
                      accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                    />
                  </label>
                </Form.Field>
              </Grid.Column>
            </Grid>
          )}

          {elementData?.image?.base64 && (
            <Grid container stackable columns={1} divided>
              <Grid.Column className="d-flex flex-column align-items-center justify-content-center">
                <Form.Field className="d-flex flex-column align-items-center justify-content-center">
                  {imageUploadError && (
                    <p style={{ color: 'red' }} key={imageUploadError}>
                      {imageUploadError}
                    </p>
                  )}

                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label>{t('imagePreview')}</label>
                  {!elementData?.delete && (
                    <img
                      className={clsx(styles.imagePreview, elementData?.image?.delete && styles.deletedPhoto)}
                      src={elementData?.image?.base64}
                      alt=""
                    />
                  )}
                </Form.Field>
                {isViewMode ? null : (
                  <Button className="mt-3" onClick={handleRemoveImage}>
                    {elementData?.image?.delete ? t('actionButtons.undo') : t('actionButtons.remove')}
                  </Button>
                )}
              </Grid.Column>
            </Grid>
          )}
        </Form.Group>
      </Form>
    </Segment>
  );
};

Image.propTypes = {
  elementData: shape({}),
  setElementData: func,
  isLoading: bool,
  isViewMode: bool,
};

Image.defaultProps = {
  elementData: {},
  setElementData: () => {},
  isLoading: true,
  isViewMode: false,
};

export default Image;
