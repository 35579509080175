const GET_FEATURED_OFFERS_INIT = '@featured-offers/GET_FEATURED_OFFERS_INIT';
const GET_FEATURED_OFFERS_SUCCESS = '@featured-offers/GET_FEATURED_OFFERS_SUCCESS';
const GET_FEATURED_OFFERS_FAILURE = '@featured-offers/GET_FEATURED_OFFERS_FAILURE';

const GET_SINGLE_FEATURED_OFFER_INIT = '@featured-offers/GET_SINGLE_FEATURED_OFFER_INIT';
const GET_SINGLE_FEATURED_OFFER_SUCCESS = '@featured-offers/GET_SINGLE_FEATURED_OFFER_SUCCESS';
const GET_SINGLE_FEATURED_OFFER_FAILURE = '@featured-offers/GET_SINGLE_FEATURED_OFFER_FAILURE';

const EDIT_FEATURED_OFFER_INIT = '@featured-offers/EDIT_FEATURED_OFFER_INIT';
const EDIT_FEATURED_OFFER_SUCCESS = '@featured-offers/EDIT_FEATURED_OFFER_SUCCESS';
const EDIT_FEATURED_OFFER_FAILURE = '@featured-offers/EDIT_FEATURED_OFFER_FAILURE';

export {
  GET_FEATURED_OFFERS_INIT,
  GET_FEATURED_OFFERS_SUCCESS,
  GET_FEATURED_OFFERS_FAILURE,
  GET_SINGLE_FEATURED_OFFER_INIT,
  GET_SINGLE_FEATURED_OFFER_SUCCESS,
  GET_SINGLE_FEATURED_OFFER_FAILURE,
  EDIT_FEATURED_OFFER_INIT,
  EDIT_FEATURED_OFFER_SUCCESS,
  EDIT_FEATURED_OFFER_FAILURE,
};
