import { DELETE_ROLE_INIT, DELETE_ROLES_SUCCESS, DELETE_ROLES_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteRoleInit = () => ({ type: DELETE_ROLE_INIT });
const deleteRoleSuccess = role => ({
  type: DELETE_ROLES_SUCCESS,
  payload: { role },
});
const deleteRoleFailure = error => ({ type: DELETE_ROLES_FAILURE, payload: { error } });

const deleteRole = roleId => async dispatch => {
  dispatch(deleteRoleInit());

  try {
    const deleteRoleResponse = await client.deleteRole({ slug: roleId });

    await dispatch(deleteRoleSuccess(deleteRoleResponse.response));
    history.push(ROUTES.ROLES);
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(deleteRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default deleteRole;
