import { REFRESH_TOKEN_FAILURE, USER_LOGOUT } from '@store/action-types/user';
import { setUserLoggedOutStorage } from '@api-sdk/utils';
import ROUTES from '@shared/consts/routes';

const logoutUser = ({ history }) => async dispatch => {
  setUserLoggedOutStorage();
  dispatch({ type: REFRESH_TOKEN_FAILURE });
  dispatch({ type: USER_LOGOUT });
  history.push(ROUTES.LOGIN);
  window.location.reload();
};

export default logoutUser;
