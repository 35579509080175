const GET_PERMISSIONS_INIT = '@permissions/GET_PERMISSIONS_INIT';
const GET_PERMISSIONS_SUCCESS = '@permissions/GET_PERMISSIONS_SUCCESS';
const GET_PERMISSIONS_FAILURE = '@permissions/GET_PERMISSIONS_FAILURE';

const GET_SINGLE_PERMISSION_INIT = '@permissions/GET_SINGLE_PERMISSION_INIT';
const GET_SINGLE_PERMISSION_SUCCESS = '@permissions/GET_SINGLE_PERMISSION_SUCCESS';
const GET_SINGLE_PERMISSION_FAILURE = '@permissions/GET_SINGLE_PERMISSION_FAILURE';

const CREATE_PERMISSION_INIT = '@permissions/CREATE_PERMISSION_INIT';
const CREATE_PERMISSION_SUCCESS = '@permissions/CREATE_PERMISSION_SUCCESS';
const CREATE_PERMISSION_FAILURE = '@permissions/CREATE_PERMISSION_FAILURE';

const EDIT_PERMISSION_INIT = '@permissions/EDIT_PERMISSION_INIT';
const EDIT_PERMISSION_SUCCESS = '@permissions/EDIT_PERMISSION_SUCCESS';
const EDIT_PERMISSION_FAILURE = '@permissions/EDIT_PERMISSION_FAILURE';

const DELETE_PERMISSION_INIT = '@permissions/DELETE_PERMISSION_INIT';
const DELETE_PERMISSION_SUCCESS = '@permissions/DELETE_PERMISSION_SUCCESS';
const DELETE_PERMISSION_FAILURE = '@permissions/DELETE_PERMISSION_FAILURE';

export {
  GET_PERMISSIONS_INIT,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAILURE,
  GET_SINGLE_PERMISSION_INIT,
  GET_SINGLE_PERMISSION_SUCCESS,
  GET_SINGLE_PERMISSION_FAILURE,
  CREATE_PERMISSION_INIT,
  CREATE_PERMISSION_SUCCESS,
  CREATE_PERMISSION_FAILURE,
  EDIT_PERMISSION_INIT,
  EDIT_PERMISSION_SUCCESS,
  EDIT_PERMISSION_FAILURE,
  DELETE_PERMISSION_INIT,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,

};
