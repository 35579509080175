import {
  ADD_PERSONAL_PERMISSION_TO_USER_INIT,
  ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS,
  ADD_PERSONAL_PERMISSION_TO_USER_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const addPersonalPermissionToUserInit = clickedPermissionId => ({
  type: ADD_PERSONAL_PERMISSION_TO_USER_INIT,
  payload: { clickedPermissionId },
});
const addPersonalPermissionToUserSuccess = personalPermissions => ({
  type: ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS,
  payload: { personalPermissions },
});
const addPersonalPermissionToUserFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  return { type: ADD_PERSONAL_PERMISSION_TO_USER_FAILURE, payload: { errors: mappedErrors } };
};

const addPersonalPermissionToUser = (userId, permissionId) => async dispatch => {
  dispatch(addPersonalPermissionToUserInit(permissionId));

  try {
    const addPersonalPermissionToUserResponse = await client.addPersonalPermissionToUser({
      slug: `${userId}/personal-permissions/${permissionId}`,
    });

    await dispatch(addPersonalPermissionToUserSuccess(addPersonalPermissionToUserResponse?.response));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.permission.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.permission.error.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(addPersonalPermissionToUserFailure(error));
  }
};

export default addPersonalPermissionToUser;
