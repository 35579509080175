import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  label: {
    width: 'fit-content',
    display: 'flex !important',
  },
  positionInput: {
    maxWidth: 70,
  },
  select: {
    margin: '0 8px',
    width: '100%',
  },
  switchFieldWrapper: {
    width: 100,
  },
  switchField: {
    marginTop: 6,
  },
}));

export default useStyles;
