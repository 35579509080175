const GET_PAGES_INIT = '@page/GET_PAGES_INIT';
const GET_PAGES_SUCCESS = '@page/GET_PAGES_SUCCESS';
const GET_PAGES_FAILURE = '@page/GET_PAGES_FAILURE';

const GET_SINGLE_PAGE_INIT = '@page/GET_SINGLE_PAGE_INIT';
const GET_SINGLE_PAGE_SUCCESS = '@page/GET_SINGLE_PAGE_SUCCESS';
const GET_SINGLE_PAGE_FAILURE = '@page/GET_SINGLE_PAGE_FAILURE';

const CREATE_PAGE_INIT = '@page/CREATE_PAGE_INIT';
const CREATE_PAGE_SUCCESS = '@page/CREATE_PAGE_SUCCESS';
const CREATE_PAGE_FAILURE = '@page/CREATE_PAGE_FAILURE';

const EDIT_PAGE_INIT = '@page/EDIT_PAGE_INIT';
const EDIT_PAGE_SUCCESS = '@page/EDIT_PAGE_SUCCESS';
const EDIT_PAGE_FAILURE = '@page/EDIT_PAGE_FAILURE';

const DELETE_PAGE_INIT = '@page/DELETE_PAGE_INIT';
const DELETE_PAGE_SUCCESS = '@page/DELETE_PAGE_SUCCESS';
const DELETE_PAGE_FAILURE = '@page/DELETE_PAGE_FAILURE';

export {
  GET_PAGES_FAILURE,
  GET_PAGES_SUCCESS,
  GET_PAGES_INIT,
  GET_SINGLE_PAGE_INIT,
  GET_SINGLE_PAGE_SUCCESS,
  GET_SINGLE_PAGE_FAILURE,
  CREATE_PAGE_INIT,
  CREATE_PAGE_SUCCESS,
  CREATE_PAGE_FAILURE,
  EDIT_PAGE_INIT,
  EDIT_PAGE_SUCCESS,
  EDIT_PAGE_FAILURE,
  DELETE_PAGE_INIT,
  DELETE_PAGE_SUCCESS,
  DELETE_PAGE_FAILURE,
};
