import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 999,
    background: 'rgb(255, 255, 255, 1) !important',
    overflow: 'hidden',
  },
  linearWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  circularWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullscreenWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullscreenCircularProgress: {
    margin: theme.spacing(3),
  },
  fullscreenTitle: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(0),
  },
  fullscreenSubtitle: {
    margin: theme.spacing(1),
  },
  circularProgress: {
    margin: '20px 0',
  },
  formWrapper: {
    minHeight: 320,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
