import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  'page-image-upload': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: '8px',
    margin: '5px',
  },
  'page-image-upload--buttons': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  'page-image-upload--preview': {
    maxWidth: 300,
  },
  'page-bottom-bar': {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    padding: '2rem 2.25rem',
  },
  'page-attachments': {
    width: '100%',
    padding: '8px',
    margin: '5px',
  },
  'page-attachments--container': {
    width: '100%',
  },
  'page-attachments--dropzone': {
    height: '80px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #000',
    cursor: 'pointer',
  },
  'page-attachments--file-list': {
    padding: '8px',
    margin: '5px',
  },
  'file-item': {
    marginTop: '5px',
    cursor: 'pointer',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginBottom: '5px',
  },
  label: {
    width: 'fit-content',
    display: 'flex !important',
  },
}));

export default useStyles;
