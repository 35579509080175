import React, { useEffect } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import PermissionForm from '@screens/permissions/components/PermissionForm/PermissionForm';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';

const Main = ({ actions, singlePermission, singleStatus, editStatus, errors, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSinglePermissionAction(params.id);
  });

  useEffect(() => {
    editStatus === FETCH_STATUS.IDLE && actions.getSinglePermissionAction(params.id);
  }, [actions]);

  return (
    <PermissionForm
      permission={singlePermission}
      loading={singleStatus === FETCH_STATUS.IDLE}
      handleSavePermission={actions.editPermissionAction}
      handleReloadPermission={actions.getSinglePermissionAction}
      permissionsErrors={errors}
      isViewMode={!isUserPermitted(PERMISSIONS.PERMISSION_EDIT)}
    />
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  singlePermission: shape({}),
  errors: shape({}),
  permittedPermissions: arrayOf(shape({})),
  singleStatus: string,
  editStatus: string,
};

Main.defaultProps = {
  singlePermission: {},
  permittedPermissions: [],
  errors: {},
  singleStatus: undefined,
  editStatus: undefined,
};

export default Main;
