import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  changePositionButton: {
    minHeight: '100%',
    borderRight: 'none',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    border: '1px solid #E4E6EF',
  },
  pickColorButton: {
    minHeight: '100%',
    borderLeft: 'none',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: '1px solid #E4E6EF',
  },
}));

export default useStyles;
