import React from 'react';
import { Snackbar as MaterialSnackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { arrayOf, bool, func, string, shape } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import useStyles from './styles';

const Snackbar = ({ open, handleClose, status, message }) => {
  const styles = useStyles();

  return (
    <MaterialSnackbar
      autoHideDuration={4000}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      open={status === FETCH_STATUS.SUCCESS && open}
      onClose={handleClose}
    >
      <Alert variant="filled" severity="success" className={styles.alert}>
        {message}
      </Alert>
    </MaterialSnackbar>
  );
};

Snackbar.propTypes = {
  message: string,
  status: string,
  open: bool,
  handleClose: func,
  errors: arrayOf(shape({})),
};

Snackbar.defaultProps = {
  message: '',
  status: '',
  open: false,
  handleClose: () => {},
  errors: [],
};

export default Snackbar;
