import React from 'react';
import { element } from 'prop-types';
import { withRouter } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { getAbsoluteUrl } from '@metronic/helpers';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { Footer } from './components';
import useStyles from './styles';

const Main = ({ children }) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className={styles.root}>
      {children}
      <SVG
        src={getAbsoluteUrl('/images/icons/login-visual.svg')}
        className={isSm ? `${styles.image} ${isXs ? styles.mobileImage : styles.tabletImage}` : styles.image}
      />
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: element.isRequired,
};

export default withRouter(Main);
