import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { Button, TextField, MenuItem, FormControl, InputLabel, Select, Grid } from '@material-ui/core';
import { GOOGLE_API_KEY } from '@api-sdk/consts';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const mapContainerStyle = {
  height: '360px',
  width: '100%',
};

const mapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
};

const Map = ({ setElementData, elementValue }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
  });
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.OFFERS);
  const [mapLoad, setMapLoad] = useState(false);
  const [location, setLocation] = useState('');
  const [selectedMarker, setSelectedMarker] = useState(false);
  const [mapZoom, setMapZoom] = useState(15);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isInvalidAddress, setIsInvalidAddress] = useState(true);

  if (loadError) return 'Error';
  if (!isLoaded) return 'Loading...';

  const isEmpty = str => {
    return !str.trim().length;
  };

  const getLatitude = async address => {
    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);

      setElementData('latitude', lat);
      setElementData('longitude', lng);
      setElementData('address', results[0].formatted_address);

      setIsInvalidAddress(false);
    } catch (error) {
      setIsInvalidAddress(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    getLatitude(location);

    if (isEmpty(location)) {
      setIsError(true);
      setErrorMessage(t('addOffer.addressEmpty'));
      return;
    }

    if (isInvalidAddress) {
      setIsError(true);
      setErrorMessage(t('addOffer.addressInvalid'));
      return;
    }

    if (!isEmpty(location)) {
      setIsError(false);
      setErrorMessage(null);
    }

    if (!isInvalidAddress) {
      setIsError(false);
      setErrorMessage(null);
    }

    setMapLoad(true);
  };

  const handleSetElementData = e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementData(key, value);
  };

  const handleSelect = e => setMapZoom(e.target.value);

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <FormControl variant="outlined" className={styles.input} fullWidth>
              <TextField
                error={isError}
                size="small"
                name="address"
                id="map-address"
                label={isError ? errorMessage : 'Address'}
                placeholder="e.g. Scott Street, Manhattan"
                autoComplete="off"
                variant="outlined"
                value={location}
                onChange={e => setLocation(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" size="small" className={styles.input} fullWidth>
              <InputLabel id="map-zoom">Zoom</InputLabel>
              <Select name="zoom" id="map-zoom" value={mapZoom} onChange={handleSelect} label="Zoom">
                <MenuItem value={15}>1</MenuItem>
                <MenuItem value={16}>2</MenuItem>
                <MenuItem value={17}>3</MenuItem>
                <MenuItem value={18}>4</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="outlined" fullWidth>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {mapLoad && (
        <>
          <div className={styles.mapHeaderWrapper}>
            <TextField
              size="small"
              name="address"
              id="map-visible-address"
              label="Visible address"
              autoComplete="off"
              variant="outlined"
              value={elementValue.address || ''}
              fullWidth
              onChange={handleSetElementData}
            />
            <TextField
              size="small"
              name="label"
              id="map-label"
              label="Label"
              autoComplete="off"
              variant="outlined"
              value={elementValue.label || ''}
              fullWidth
              onChange={handleSetElementData}
            />
          </div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            // onClick={e => handleSetMarker(e)}
            zoom={mapZoom}
            center={{
              lat: elementValue.latitude,
              lng: elementValue.longitude,
            }}
            options={mapOptions}
          >
            <Marker
              position={{
                lat: elementValue.latitude,
                lng: elementValue.longitude,
              }}
              onClick={() => setSelectedMarker(true)}
            />
            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: elementValue.latitude,
                  lng: elementValue.longitude,
                }}
                onCloseClick={() => {
                  setSelectedMarker(null);
                }}
              >
                <div>{location}</div>
              </InfoWindow>
            )}
          </GoogleMap>
        </>
      )}
    </div>
  );
};

Map.propTypes = {
  elementValue: shape({}).isRequired,
  setElementData: func.isRequired,
};

export default Map;
