import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import useMount from '@shared/utils/useMountHook';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import UserForm from '../components/UserForm';

const Main = ({ actions, countries, countriesStatus, errors }) => {
  const [t] = useTranslation(TRANSLATIONS.USERS);

  const UserModel = {
    active: false,
    email: '',
    firstName: '',
    lastName: '',
    isAdmin: false,
    investorData: {
      address: {
        address: '',
        zip: '',
        city: '',
        state: '',
        country: '',
      },
      bankAccount: {
        country: '',
        bankName: '',
        accountNumber: '',
      },
      phoneNumber: '',
    },
  };

  useMount(() => {
    actions.getCountriesAction();
  });

  return (
    <>
      <h2>{t('addUser')}</h2>
      <UserForm
        isCreate
        user={UserModel}
        handleAddUser={actions.createUserAction}
        countries={countries}
        loading={countriesStatus === FETCH_STATUS.IDLE}
        usersErrors={errors}
      />
    </>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  countries: arrayOf(shape({})),
  countriesStatus: string,
  errors: arrayOf(shape({})),
};

Main.defaultProps = {
  countries: [],
  countriesStatus: undefined,
  errors: [],
};

export default Main;
