import {
  REMOVE_PERMISSION_FROM_ROLE_INIT,
  REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSION_FROM_ROLE_FAILURE,
} from '@store/action-types/roles';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const removePermissionFromRoleInit = clickedPermissionId => ({
  type: REMOVE_PERMISSION_FROM_ROLE_INIT,
  payload: { clickedPermissionId },
});
const removePermissionFromRoleSuccess = response => ({
  type: REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
  payload: { response },
});
const removePermissionFromRoleFailure = error => ({ type: REMOVE_PERMISSION_FROM_ROLE_FAILURE, payload: { error } });

const removePermissionToRole = ({ roleId, permissionId }) => async dispatch => {
  dispatch(removePermissionFromRoleInit(permissionId));

  try {
    const removePermissionFromRole = await client.removePermissionFromRole({
      slugs: [roleId, 'permissions', permissionId],
    });

    dispatch(removePermissionFromRoleSuccess(removePermissionFromRole.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.permission.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(removePermissionFromRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.permission.error.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default removePermissionToRole;
