import client from '@api-sdk/client';
import {
  SEND_TEST_EMAIL_INIT,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_FAILURE,
} from '@store/action-types/email-templates';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const sendTestEmailInit = () => ({ type: SEND_TEST_EMAIL_INIT });
const sendTestEmailSuccess = response => ({ type: SEND_TEST_EMAIL_SUCCESS, payload: { response } });
const sendTestEmailFailure = error => ({ type: SEND_TEST_EMAIL_FAILURE, payload: { error } });

const getSingleTemplate = ({ type, email, subject, body }) => async dispatch => {
  dispatch(sendTestEmailInit());

  try {
    const emailTemplateResponse = await client.sendTestMessage({ data: { type, email, subject, body } });

    dispatch(sendTestEmailSuccess(emailTemplateResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('emailTemplates.send'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(sendTestEmailFailure(error));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('emailTemplates.error.send'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default getSingleTemplate;
