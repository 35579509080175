import { USER_LOGIN_INIT, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE } from '@store/action-types/user';
import { CLIENT_SECRET } from '@api-sdk/consts';
import client from '@api-sdk/client';
import ROUTES from '@shared/consts/routes';
import { setUserLoggedInStorage } from '@api-sdk/utils';
import { isEmpty } from 'lodash';
import getErrorCode from '@shared/utils/getErrorCode';

const loginUserInit = () => ({ type: USER_LOGIN_INIT });
const loginUserSuccess = userData => ({ type: USER_LOGIN_SUCCESS, payload: { userData } });
const loginUserFailure = error => ({ type: USER_LOGIN_FAILURE, payload: { error } });

const loginUser = ({ history, userLogin, userPassword }) => async dispatch => {
  dispatch(loginUserInit());

  const arrayOfErrors = [];

  if (isEmpty(userLogin) || isEmpty(userPassword)) {
    isEmpty(userLogin) && arrayOfErrors.push('EMAIL_EMPTY');
    isEmpty(userPassword) && arrayOfErrors.push('PASSWORD_EMPTY');
  }

  if (!isEmpty(arrayOfErrors)) {
    dispatch(loginUserFailure(arrayOfErrors));
    return;
  }

  try {
    const data = {
      email: userLogin,
      password: userPassword,
      grantType: 'password',
      clientId: '_INITIAL_CLIENT',
      forAdmin: true,
      clientSecret: CLIENT_SECRET,
    };

    const userData = await client.loginUser({ data });
    setUserLoggedInStorage({
      accessToken: userData?.response?.accessToken,
      refreshToken: userData?.response?.refreshToken,
      tokenExpiresIn: userData?.response?.expiresIn,
    });
    history.push(ROUTES.HOME);
    return dispatch(loginUserSuccess(userData?.response));
  } catch (error) {
    return dispatch(loginUserFailure(getErrorCode(error?.errors)));
  }
};

export default loginUser;
