import { bindActionCreators } from 'redux';
import getSplashScreen from '@store/actions/splash-screen/getSplashScreen';
import { getMenuItems, getSplashScreenStatus } from '@store/reducers/splash-screen/selectors';
import { connect } from 'react-redux';
import logoutUser from '@store/actions/user/logoutUser';
import { getLoggedUser } from '@store/reducers/user/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  menuItems: getMenuItems(state),
  splashScreenStatus: getSplashScreenStatus(state),
  user: getLoggedUser(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logoutUser,
      getSplashScreen,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
