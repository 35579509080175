import {
  GET_STATIC_CONTENT_ELEMENT_INIT,
  GET_STATIC_CONTENT_ELEMENT_SUCCESS,
  GET_STATIC_CONTENT_ELEMENT_FAILURE,
} from '@store/action-types/static-content';
import client from '@api-sdk/client';

const getStaticContentElementInit = () => ({ type: GET_STATIC_CONTENT_ELEMENT_INIT });
const getStaticContentElementSuccess = staticContentElement => ({
  type: GET_STATIC_CONTENT_ELEMENT_SUCCESS,
  payload: { staticContentElement },
});
const getStaticContentElementFailure = error => ({ type: GET_STATIC_CONTENT_ELEMENT_FAILURE, payload: { error } });

const getStaticContentElement = elementId => async dispatch => {
  dispatch(getStaticContentElementInit());

  try {
    const staticContentElement = await client.getStaticContentElement({ slug: elementId });
    dispatch(getStaticContentElementSuccess(staticContentElement.response));
  } catch (error) {
    dispatch(getStaticContentElementFailure(error));
  }
};

export default getStaticContentElement;
