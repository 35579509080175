import { EDIT_AGREEMENT_INIT, EDIT_AGREEMENT_SUCCESS, EDIT_AGREEMENT_FAILURE } from '@store/action-types/agreements';
import Client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const editAgreementInit = () => ({ type: EDIT_AGREEMENT_INIT });
const editAgreementSuccess = () => ({ type: EDIT_AGREEMENT_SUCCESS });
const editAgreementFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_AGREEMENT_FAILURE, payload: { error: mappedErrors } };
};

const editAgreement = ({ agreementData, saveAndBack = false }) => async dispatch => {
  dispatch(editAgreementInit());

  try {
    await Client.editAgreement({
      slug: agreementData?.id,
      data: {
        active: agreementData?.active,
        required: agreementData?.required,
        name: agreementData?.name,
        content: agreementData?.content,
        target: agreementData?.target,
        type: agreementData?.type,
      },
    });

    if (saveAndBack) {
      history.push(ROUTES.AGREEMENTS);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    return dispatch(editAgreementSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    return dispatch(editAgreementFailure(error));
  }
};

export default editAgreement;
