const GET_ROLES_INIT = '@roles/GET_ROLES_INIT';
const GET_ROLES_SUCCESS = '@roles/GET_ROLES_SUCCESS';
const GET_ROLES_FAILURE = '@roles/GET_ROLES_FAILURE';

const GET_SINGLE_ROLE_INIT = '@roles/GET_SINGLE_ROLE_INIT';
const GET_SINGLE_ROLE_SUCCESS = '@roles/GET_SINGLE_ROLE_SUCCESS';
const GET_SINGLE_ROLE_FAILURE = '@roles/GET_SINGLE_ROLE_FAILURE';

const CREATE_ROLE_INIT = '@roles/CREATE_ROLE_INIT';
const CREATE_ROLE_SUCCESS = '@roles/CREATE_ROLE_SUCCESS';
const CREATE_ROLE_FAILURE = '@roles/CREATE_ROLE_FAILURE';

const EDIT_ROLE_INIT = '@roles/EDIT_ROLE_INIT';
const EDIT_ROLE_SUCCESS = '@roles/EDIT_ROLE_SUCCESS';
const EDIT_ROLE_FAILURE = '@roles/EDIT_ROLE_FAILURE';

const DELETE_ROLE_INIT = '@roles/DELETE_ROLE_INIT';
const DELETE_ROLES_SUCCESS = '@roles/DELETE_ROLES_SUCCESS';
const DELETE_ROLES_FAILURE = '@roles/DELETE_ROLES_FAILURE';

const ADD_PERMISSION_TO_ROLE_INIT = '@roles/ADD_PERMISSION_TO_ROLE_INIT';
const ADD_PERMISSION_TO_ROLE_SUCCESS = '@roles/ADD_PERMISSION_TO_ROLE_SUCCESS';
const ADD_PERMISSION_TO_ROLE_FAILURE = '@roles/ADD_PERMISSION_TO_ROLE_FAILURE';

const GET_ROLE_PERMISSIONS_INIT = '@roles/GET_ROLE_PERMISSIONS_INIT';
const GET_ROLE_PERMISSIONS_SUCCESS = '@roles/GET_ROLE_PERMISSIONS_SUCCESS';
const GET_ROLE_PERMISSIONS_FAILURE = '@roles/GET_ROLE_PERMISSIONS_FAILURE';

const REMOVE_PERMISSION_FROM_ROLE_INIT = '@roles/REMOVE_PERMISSION_FROM_ROLE_INIT';
const REMOVE_PERMISSION_FROM_ROLE_SUCCESS = '@roles/REMOVE_PERMISSION_FROM_ROLE_SUCCESS';
const REMOVE_PERMISSION_FROM_ROLE_FAILURE = '@roles/REMOVE_PERMISSION_FROM_ROLE_FAILURE';

const ADD_USER_TO_ROLE_INIT = '@roles/ADD_USER_TO_ROLE_INIT';
const ADD_USER_TO_ROLE_SUCCESS = '@roles/ADD_USER_TO_ROLE_SUCCESS';
const ADD_USER_TO_ROLE_FAILURE = '@roles/ADD_USER_TO_ROLE_FAILURE';

const GET_ROLE_USERS_INIT = '@roles/GET_ROLE_USERS_INIT';
const GET_ROLE_USERS_SUCCESS = '@roles/GET_ROLE_USERS_SUCCESS';
const GET_ROLE_USERS_FAILURE = '@roles/GET_ROLE_USERS_FAILURE';

const REMOVE_USER_FROM_ROLE_INIT = '@roles/REMOVE_USER_FROM_ROLE_INIT';
const REMOVE_USER_FROM_ROLE_SUCCESS = '@roles/REMOVE_USER_FROM_ROLE_SUCCESS';
const REMOVE_USER_FROM_ROLE_FAILURE = '@roles/REMOVE_USER_FROM_ROLE_FAILURE';

export {
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_SINGLE_ROLE_INIT,
  GET_SINGLE_ROLE_SUCCESS,
  GET_SINGLE_ROLE_FAILURE,
  CREATE_ROLE_INIT,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  EDIT_ROLE_INIT,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAILURE,
  ADD_PERMISSION_TO_ROLE_INIT,
  ADD_PERMISSION_TO_ROLE_SUCCESS,
  ADD_PERMISSION_TO_ROLE_FAILURE,
  GET_ROLE_PERMISSIONS_INIT,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAILURE,
  REMOVE_PERMISSION_FROM_ROLE_INIT,
  REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSION_FROM_ROLE_FAILURE,
  ADD_USER_TO_ROLE_INIT,
  ADD_USER_TO_ROLE_SUCCESS,
  ADD_USER_TO_ROLE_FAILURE,
  GET_ROLE_USERS_INIT,
  GET_ROLE_USERS_SUCCESS,
  GET_ROLE_USERS_FAILURE,
  REMOVE_USER_FROM_ROLE_INIT,
  REMOVE_USER_FROM_ROLE_SUCCESS,
  REMOVE_USER_FROM_ROLE_FAILURE,
};
