import React from 'react';
import { Message } from 'semantic-ui-react';

const ERRORS = {
  WAIT_UNTIL_IS_EMPTY: 'TRANSACTION_MANAGER:WAIT_UNTIL_IS_EMPTY',
  CANT_EXTEND_WAIT_UNTIL: 'TRANSACTION_MANAGER:CANT_EXTEND_WAIT_UNTIL',
  WRONG_ORDER_STATUS_FOR_ACCEPT: 'TRANSACTION_MANAGER:WRONG_ORDER_STATUS_FOR_ACCEPT',
  NOT_FOUND: 'TRANSACTION:NOT_FOUND',
  WRONG_TRANSACTION_STATUS_FOR_ACCEPT: 'TRANSACTION_MANAGER:WRONG_TRANSACTION_STATUS_FOR_ACCEPT',
  NAME_IS_EMPTY: 'NAME_IS_EMPTY',
  DESCRIPTION_IS_EMPTY: 'DESCRIPTION_IS_EMPTY',
};

const mapErrorCodes = ({ errorCode, t }) => {
  switch (errorCode) {
    case ERRORS.WAIT_UNTIL_IS_EMPTY: {
      return (
        <Message size="big" color="red">
          {t('errors.waitUntilIsEmpty')}
        </Message>
      );
    }
    case ERRORS.CANT_EXTEND_WAIT_UNTIL: {
      return (
        <Message size="big" color="red">
          {t('errors.cantExtendWaitUntil')}
        </Message>
      );
    }
    case ERRORS.WRONG_ORDER_STATUS_FOR_ACCEPT: {
      return (
        <Message size="big" color="red">
          {t('errors.wrongOrderStatusForAccept')}
        </Message>
      );
    }
    case ERRORS.NOT_FOUND: {
      return (
        <Message size="big" color="red">
          {t('errors.notFound')}
        </Message>
      );
    }
    case ERRORS.WRONG_TRANSACTION_STATUS_FOR_ACCEPT: {
      return (
        <Message size="big" color="red">
          {t('errors.wrongTransactionStatusForAccept')}
        </Message>
      );
    }
    case ERRORS.NAME_IS_EMPTY: {
      return (
        <Message size="big" color="red">
          {t('errors.nameIsRequired')}
        </Message>
      );
    }
    case ERRORS.DESCRIPTION_IS_EMPTY: {
      return (
        <Message size="big" color="red">
          {t('errors.descriptionIsRequired')}
        </Message>
      );
    }
    default: {
      return (
        <Message size="big" color="red">
          {t('errors.genericError')}
        </Message>
      );
    }
  }
};

export default mapErrorCodes;
