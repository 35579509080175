import React from 'react';
import { func, shape } from 'prop-types';
import { newPageObject } from '@screens/page/const';
import PageForm from '@screens/page/components/PageForm';

const Main = ({ actions, pageErrors }) => {
  return (
    <PageForm isCreateMode page={newPageObject()} onCreatePage={actions.createPageAction} pageErrors={pageErrors} />
  );
};

Main.propTypes = {
  actions: shape({ createPageAction: func }).isRequired,
  pageErrors: shape({}),
};
Main.defaultProps = {
  pageErrors: {},
};

export default Main;
