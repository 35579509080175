import { connect } from 'react-redux';
import { getConfig, getConfigStatus } from '@store/reducers/settings/selectors';
import { getConfigAction, editConfigAction } from '@store/actions/settings';
import { editPaypalConfigAction, getPaypalConfigAction } from '@store/actions/paypal';
import { getPaypalConfig, getPaypalStatus, getPaypalError } from '@store/reducers/paypal/selectors';
import { bindActionCreators, compose } from 'redux';
import Main from './Main';

const mapStateToProps = state => ({
  config: getConfig(state),
  status: getConfigStatus(state),
  paypalConfig: getPaypalConfig(state),
  paypalError: getPaypalError(state),
  paypalStatus: getPaypalStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getConfigAction,
      editConfigAction,
      editPaypalConfigAction,
      getPaypalConfigAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
