import {
  GET_SINGLE_FEATURED_OFFER_INIT,
  GET_SINGLE_FEATURED_OFFER_SUCCESS,
  GET_SINGLE_FEATURED_OFFER_FAILURE,
} from '@store/action-types/featured-offers';
import Client from '@api-sdk/client';

const getSingleFeaturedOfferInit = () => ({ type: GET_SINGLE_FEATURED_OFFER_INIT });
const getSingleFeaturedOfferSuccess = singleFeaturedOffer => ({
  type: GET_SINGLE_FEATURED_OFFER_SUCCESS,
  payload: { singleFeaturedOffer },
});
const getSingleFeaturedOfferFailure = error => ({ type: GET_SINGLE_FEATURED_OFFER_FAILURE, payload: { error } });

const getSingleFeaturedOffer = featuredOfferId => async dispatch => {
  dispatch(getSingleFeaturedOfferInit());

  try {
    const featuredOffersResponse = await Client.getSingleFeaturedOffer({ slug: featuredOfferId });
    return dispatch(getSingleFeaturedOfferSuccess(featuredOffersResponse.response));
  } catch (error) {
    return dispatch(getSingleFeaturedOfferFailure(error));
  }
};

export default getSingleFeaturedOffer;
