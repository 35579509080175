import client from '@api-sdk/client';
import {
  GET_SINGLE_EMAIL_TEMPLATE_INIT,
  GET_SINGLE_EMAIL_TEMPLATE_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATE_FAILURE,
} from '@store/action-types/email-templates';

const getSingleTemplateInit = () => ({ type: GET_SINGLE_EMAIL_TEMPLATE_INIT });
const getSingleTemplateSuccess = response => ({ type: GET_SINGLE_EMAIL_TEMPLATE_SUCCESS, payload: { response } });
const getSingleTemplateFailure = error => ({ type: GET_SINGLE_EMAIL_TEMPLATE_FAILURE, payload: { error } });

const getSingleTemplate = templateId => async dispatch => {
  dispatch(getSingleTemplateInit());

  try {
    const singleTemplateResponse = await client.getSingleTemplate({ slug: templateId });

    dispatch(getSingleTemplateSuccess(singleTemplateResponse.response));
  } catch (error) {
    dispatch(getSingleTemplateFailure(error));
  }
};

export default getSingleTemplate;
