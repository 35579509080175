const getAllPermissions = state => state?.staffManagement?.permission?.allPermissions;
const getPermissionStatus = state => state?.staffManagement?.permission?.status;
const getSinglePermission = state => state?.staffManagement?.permission?.singlePermission;
const getSinglePermissionStatus = state => state?.staffManagement?.permission?.singleStatus;
const getPermissionErrors = state => state?.staffManagement?.permission?.errors;
const getPermissionEditStatus = state => state?.staffManagement?.permission?.editStatus;

const getFilteredPermissions = state => {
  return state?.staffManagement?.permission?.allPermissions?.reduce?.((acc, permission) => {
    const assignedPermission = state?.userManagement?.personalPermissions?.find?.(perm => perm.id === permission.id);
    if (assignedPermission) {
      return acc;
    }
    return acc.concat([permission]);
  }, []);
};

export {
  getAllPermissions,
  getPermissionStatus,
  getSinglePermission,
  getSinglePermissionStatus,
  getPermissionErrors,
  getPermissionEditStatus,
  getFilteredPermissions,
};
