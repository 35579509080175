import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import {
  getRolePermissions,
  getRolePermissionsError,
  getRolePermissionsStatus,
  getRoleUsers,
  getRoleUsersError,
  getRoleUsersStatus,
  getSingleRole,
  getSingleRoleError,
  getSingleRoleStatus,
  getFilteredPermissions,
  getClickedPermissionIds,
  getClickedUsersIds,
  getFilteredUsers,
} from '@store/reducers/roles/selectors';
import {
  getSingleRoleAction,
  editRoleAction,
  deleteRoleAction,
  getRolePermissionsAction,
  addPermissionToRoleAction,
  removePermissionFromRoleAction,
  addUserToRoleAction,
  removeUserFromRoleAction,
  getRoleUsersAction,
} from '@store/actions/roles';
import { getPermissionsAction } from '@store/actions/permissions';
import { getUsersAction } from '@store/actions/user-management';
import { checkPermissionAction, setIsPermissionCheckedAction } from '@store/actions/check-permission';
import {
  getPermittedPermissions,
  getIsPermissionChecked,
  getPermissionCheckStatus,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleRole: getSingleRole(state),
  singleRoleError: getSingleRoleError(state),
  singleRoleStatus: getSingleRoleStatus(state),
  allPermissions: getFilteredPermissions(state),
  rolePermissions: getRolePermissions(state),
  rolePermissionsError: getRolePermissionsError(state),
  rolePermissionsStatus: getRolePermissionsStatus(state),
  roleUsers: getRoleUsers(state),
  roleUsersError: getRoleUsersError(state),
  roleUsersStatus: getRoleUsersStatus(state),
  clickedPermissionIds: getClickedPermissionIds(state),
  clickedUserIds: getClickedUsersIds(state),
  allUsers: getFilteredUsers(state),
  permittedPermissions: getPermittedPermissions(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleRoleAction,
      editRoleAction,
      deleteRoleAction,
      getPermissionsAction,
      getRolePermissionsAction,
      addPermissionToRoleAction,
      removePermissionFromRoleAction,
      getRoleUsersAction,
      addUserToRoleAction,
      removeUserFromRoleAction,
      getUsersAction,
      checkPermissionAction,
      setIsPermissionCheckedAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
