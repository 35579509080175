import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { getAbsoluteUrl } from '@metronic/helpers';
import SVG from 'react-inlinesvg';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Loader } from '@shared/layout-components';
import { Button } from 'semantic-ui-react';
import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const AsideMenuList = ({ layoutProps, sidebarMenuItems, menuStatus, logoutUser }) => {
  const history = useHistory();
  const [t] = useTranslation(TRANSLATIONS.COMMON);
  const isMobileMenu = useMediaQuery('(max-width: 991px)');
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return url === location.pathname ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open ` : '';
  };

  const renderMenuItem = menuItem => {
    return (
      <li
        className={`menu-item ${getMenuItemActive(menuItem.href, false)}`}
        aria-haspopup="true"
        key={`menu-${menuItem.href}`}
      >
        <NavLink className="menu-link" to={menuItem.href}>
          <span className="svg-icon menu-icon">
            <SVG src={getAbsoluteUrl(menuItem.icon)} />
          </span>
          <span className="menu-text">{menuItem.title}</span>
        </NavLink>
      </li>
    );
  };

  const renderSubMenu = submenuItem => {
    return (
      <li className="menu-item menu-item-submenu menu-item-open" key={`submenu-${submenuItem.title}`}>
        <NavLink className="menu-link" to={submenuItem.href}>
          <span className="svg-icon menu-icon">
            <SVG src={getAbsoluteUrl(submenuItem.icon)} />
          </span>
          <span className="menu-text">{submenuItem.title}</span>
        </NavLink>
        <div className="menu-submenu ">
          <ul className="menu-subnav">
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{submenuItem.title}</span>
                </span>
              </li>
              {submenuItem?.subMenuItems?.map(item => {
                return (
                  <li
                    className={`menu-item ${getMenuItemActive(item.href)}`}
                    aria-haspopup="true"
                    key={`submenu-${item.title}`}
                  >
                    <NavLink className="menu-link" to={item.href}>
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">{item.title}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </ul>
        </div>
      </li>
    );
  };

  const renderHeader = headerItem => {
    return (
      <li className="menu-section " key={`section-${headerItem.title}`}>
        <h4 className="menu-text">{headerItem.title}</h4>
        <i className="menu-icon flaticon-more-v2" />
      </li>
    );
  };

  return (
    <>
      {menuStatus === FETCH_STATUS.IDLE ? (
        <Loader />
      ) : (
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          <li className={`menu-item ${getMenuItemActive('/', false)}`} aria-haspopup="true">
            <NavLink className="menu-link d-flex align-items-center" to="/">
              <span className="svg-icon menu-icon">
                <SVG src={getAbsoluteUrl('images/icons/dashboard.svg')} />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {sidebarMenuItems?.map(item => {
            switch (item.type) {
              case 'section': {
                return renderHeader(item);
              }
              case 'link': {
                return renderMenuItem(item);
              }
              case 'submenu': {
                return renderSubMenu(item);
              }
              default: {
                return null;
              }
            }
          })}
          {isMobileMenu && (
            <li className="d-flex justify-content-center align-items-center mt-5">
              <Button onClick={() => logoutUser({ history })}>{t('logout')}</Button>
            </li>
          )}
        </ul>
      )}
    </>
  );
};

AsideMenuList.propTypes = {
  layoutProps: shape({}),
  sidebarMenuItems: arrayOf(shape({})),
  menuStatus: string.isRequired,
  logoutUser: func.isRequired,
};

AsideMenuList.defaultProps = {
  layoutProps: {},
  sidebarMenuItems: [],
};

export default AsideMenuList;
