import React from 'react';
import { oneOf } from 'prop-types';
import { Skeleton as MaterialSkeleton } from '@material-ui/lab';
import { Grid, Table } from 'semantic-ui-react';
import styles from './styles.module.scss';
import { getRandomInt } from './utils';
import { SKELETON_VARIANT } from './consts';

const Skeleton = ({ variant }) => {
  switch (variant) {
    case SKELETON_VARIANT.TABLE: {
      return (
        <Table unstackable padded>
          <Table.Header>
            <Table.Row>
              {Array(5)
                .fill(true)
                .map(() => (
                  <Table.HeaderCell>
                    <MaterialSkeleton animation="wave" width={`${getRandomInt(75, 100)}%`} />
                  </Table.HeaderCell>
                ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {Array(8)
              .fill(true)
              .map(() => (
                <Table.Row>
                  {Array(5)
                    .fill(true)
                    .map(() => (
                      <Table.Cell>
                        <MaterialSkeleton animation="wave" width={`${getRandomInt(75, 100)}%`} />
                      </Table.Cell>
                    ))}
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      );
    }

    case SKELETON_VARIANT.STATIC_CONTENT: {
      return (
        <div className={styles.staticContentWrapper}>
          {Array(7)
            .fill(true)
            .map(() => (
              <div className={styles.staticContentItem}>
                <MaterialSkeleton animation="wave" className="mr-2" width={20} height={20} variant="circle" />
                <MaterialSkeleton animation="wave" width={`${getRandomInt(250, 320)}px`} />
              </div>
            ))}
        </div>
      );
    }

    case SKELETON_VARIANT.PREVIEW: {
      return (
        <>
          <Grid columns={2} stackable>
            {Array(2)
              .fill(true)
              .map(() => (
                <Grid.Column>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <MaterialSkeleton width={`${getRandomInt(60, 90)}%`} />
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Array(5)
                        .fill(true)
                        .map(() => (
                          <Table.Row>
                            <Table.Cell textAlign="left">
                              <MaterialSkeleton width={`${getRandomInt(180, 350)}px`} />
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                              <div className="d-flex align-items-center justify-content-end">
                                <MaterialSkeleton width={`${getRandomInt(180, 350)}px`} />
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              ))}
          </Grid>
          <Grid columns={1}>
            <Grid.Column>
              <Table unstackable padded>
                <Table.Header>
                  <Table.Row>
                    {Array(5)
                      .fill(true)
                      .map(() => (
                        <Table.HeaderCell>
                          <MaterialSkeleton animation="wave" width={`${getRandomInt(75, 100)}%`} />
                        </Table.HeaderCell>
                      ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Array(5)
                    .fill(true)
                    .map(() => (
                      <Table.Row>
                        {Array(5)
                          .fill(true)
                          .map(() => (
                            <Table.Cell>
                              <MaterialSkeleton animation="wave" width={`${getRandomInt(75, 100)}%`} />
                            </Table.Cell>
                          ))}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </>
      );
    }

    default:
      return (
        <div>
          <MaterialSkeleton width="95%" />
          <MaterialSkeleton width="90%" />
          <MaterialSkeleton width="85%" />
          <MaterialSkeleton width="80%" />
          <MaterialSkeleton width="75%" />
          <MaterialSkeleton width="69%" />
        </div>
      );
  }
};

Skeleton.propTypes = {
  variant: oneOf([SKELETON_VARIANT.TABLE]),
};

Skeleton.defaultProps = {
  variant: '',
};

export default Skeleton;
