import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getCreateFaqStatus, getFaqError } from '@store/reducers/faq/selectors';
import { createFaqAction } from '@store/actions/faq';
import Main from './Main';

const mapStateToProps = state => ({
  createStatus: getCreateFaqStatus(state),
  errors: getFaqError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createFaqAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
