import React from 'react';
import { Checkbox, Form, Grid, Icon, Label } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { withTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from '@screens/offers/NewOfferForm/components/TranslatableTextField/styles.scss';

class SwitchField extends React.PureComponent {
  render() {
    const {
      onChange,
      name,
      value,
      label,
      className,
      errorMessage,
      t,
      tooltip,
      checkboxClassName,
      disabled,
      onKeyUp,
    } = this.props;

    return (
      <Grid.Column>
        <Form.Field className={className && className}>
          {errorMessage && (
            <Label color="red" className="mb-5">
              {t(errorMessage)}
            </Label>
          )}
          <Form.Field className="d-flex justify-content-between">
            <label>
              {label}{' '}
              {tooltip && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                >
                  <Icon className={styles.icon} name="info circle" />
                </OverlayTrigger>
              )}
            </label>
          </Form.Field>
          <Checkbox
            className={checkboxClassName && checkboxClassName}
            name={name}
            toggle
            checked={value}
            onChange={onChange}
            disabled={disabled}
            onKeyUp={onKeyUp}
          />
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default withTranslation(TRANSLATIONS.ERRORS)(SwitchField);
