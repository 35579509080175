import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { bool, func, string } from 'prop-types';

const ButtonWithLoader = ({
  children,
  variant,
  type,
  className,
  disabled,
  loading,
  disableElevation,
  onClick,
  ...props
}) => {
  return (
    <Button
      className={className}
      type={type}
      variant={variant}
      disabled={disabled || loading}
      disableElevation={disableElevation}
      onClick={onClick}
      {...props}
    >
      {loading ? <CircularProgress size={19} /> : children}
    </Button>
  );
};

ButtonWithLoader.propTypes = {
  children: string.isRequired,
  variant: string,
  className: string,
  type: string,
  disabled: bool,
  loading: bool,
  disableElevation: bool,
  onClick: func,
};

ButtonWithLoader.defaultProps = {
  variant: 'outlined',
  className: undefined,
  type: 'submit',
  disabled: false,
  loading: false,
  disableElevation: false,
  onClick: undefined,
};

export default ButtonWithLoader;
