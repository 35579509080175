const GET_FAQ_INIT = '@faq/GET_FAQ_INIT';
const GET_FAQ_SUCCESS = '@faq/GET_FAQ_SUCCESS';
const GET_FAQ_FAILURE = '@faq/GET_FAQ_FAILURE';

const GET_SINGLE_FAQ_INIT = '@faq/GET_SINGLE_FAQ_INIT';
const GET_SINGLE_FAQ_SUCCESS = '@faq/GET_SINGLE_FAQ_SUCCESS';
const GET_SINGLE_FAQ_FAILURE = '@faq/GET_SINGLE_FAQ_FAILURE';

const DELETE_FAQ_INIT = '@faq/DELETE_FAQ_INIT';
const DELETE_FAQ_SUCCESS = '@faq/DELETE_FAQ_SUCCESS';
const DELETE_FAQ_FAILURE = '@faq/DELETE_FAQ_FAILURE';

const EDIT_FAQ_INIT = '@faq/EDIT_FAQ_INIT';
const EDIT_FAQ_SUCCESS = '@faq/EDIT_FAQ_SUCCESS';
const EDIT_FAQ_FAILURE = '@faq/EDIT_FAQ_FAILURE';

const CREATE_FAQ_INIT = '@faq/CREATE_FAQ_INIT';
const CREATE_FAQ_SUCCESS = '@faq/CREATE_FAQ_SUCCESS';
const CREATE_FAQ_FAILURE = '@faq/CREATE_FAQ_FAILURE';

const CLEAR_STATUS = '@faq/CLEAR_STATUS';

export {
  GET_FAQ_FAILURE,
  GET_FAQ_SUCCESS,
  GET_FAQ_INIT,
  GET_SINGLE_FAQ_INIT,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_FAILURE,
  DELETE_FAQ_INIT,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILURE,
  EDIT_FAQ_INIT,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_FAILURE,
  CREATE_FAQ_INIT,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAILURE,
  CLEAR_STATUS,
};
