import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleSliderAction, editSliderAction } from '@store/actions/slider';
import { getErrors, getSingleSlider, getStatus } from '@store/reducers/slider/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleSlider: getSingleSlider(state),
  status: getStatus(state),
  errors: getErrors(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getSingleSliderAction, editSliderAction }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
