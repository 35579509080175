const SliderModel = {
  position: 1,
  active: true,
  name: {
    pl: '',
  },
  content: {
    pl: '',
  },
  guestButtonLabel: {
    pl: '',
  },
  guestButtonUrl: {
    pl: '',
  },
  loggedUserButtonLabel: {
    pl: '',
  },
  loggedUserButtonUrl: {
    pl: '',
  },
};

export { SliderModel };
