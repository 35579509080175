import { RESET_PASSWORD_INIT, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '@store/action-types/user';
import Client from '@api-sdk/client';
import { isEmpty, isEqual } from 'lodash';
import getErrorCode from '@shared/utils/getErrorCode';

const submitResetPasswordInit = () => ({ type: RESET_PASSWORD_INIT });
const submitResetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });
const submitResetPasswordFailure = error => ({ type: RESET_PASSWORD_FAILURE, payload: { error } });

const submitResetPassword = (password, resetPasswordToken) => async dispatch => {
  dispatch(submitResetPasswordInit());

  const arrayOfErrors = [];

  if (isEmpty(password.newPassword) || isEmpty(password.repeatPassword)) {
    arrayOfErrors.push('PASSWORD_EMPTY');
  }

  if (!isEqual(password.newPassword, password.repeatPassword)) {
    arrayOfErrors.push('PASSWORD_NOT_SAME');
  }

  if (!isEmpty(arrayOfErrors)) {
    dispatch(submitResetPasswordFailure(arrayOfErrors));
    return;
  }

  try {
    await Client.submitResetPassword({
      data: { newPassword: password.newPassword },
      slug: resetPasswordToken,
    });

    return dispatch(submitResetPasswordSuccess());
  } catch (error) {
    return dispatch(submitResetPasswordFailure(getErrorCode(error?.errors)));
  }
};

export default submitResetPassword;
