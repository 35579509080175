import React from 'react';
import { Grid, Label, Form } from 'semantic-ui-react';
import TRANSLATIONS from '@translations/translationNamespaces';
import { useTranslation } from 'react-i18next';

const VariableBadges = ({ config }) => {
  const [t] = useTranslation(TRANSLATIONS.EMAIL_TEMPLATES);
  const requiredVariables = config?.variables?.filter?.(item => item.required);
  const optionalVariables = config?.variables?.filter?.(item => !item.required);

  return (
    <Form className="variable-badges">
      <h3>{t('variablesDisclaimer')}</h3>
      <h4>{t('variablesDisclaimerRequired')}</h4>
      <Grid columns={1} container>
        <Grid.Row>
          {requiredVariables?.map?.(item => (
            <Label
              style={{ height: 'fit-content', width: 'fit-content' }}
              size="big"
              color="red"
            >{`{{${item?.name}}}`}</Label>
          ))}
          {optionalVariables?.map?.(item => (
            <Label style={{ height: 'fit-content', width: 'fit-content' }} size="big">{`{{${item?.name}}}`}</Label>
          ))}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

VariableBadges.defaultProps = {
  config: { variables: [] },
};

export default VariableBadges;
