import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useMount from '@shared/utils/useMountHook';
import { arrayOf, shape, string } from 'prop-types';
import { Container } from '@material-ui/core';
import { Button, Divider, Form, Grid, Segment, Select } from 'semantic-ui-react';
import { SwitchField, TranslatableTextField } from '@screens/offers/NewOfferForm/components';
import { InputField } from '@shared/components/translatable';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import TRANSLATIONS from '@translations/translationNamespaces';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const Main = ({ actions, featuredOffer, status, errors, offerOptions, offerStatus, permittedPermissions }) => {
  const styles = useStyles();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [featuredOfferTitle, setFeaturedOfferTitle] = useState({});
  const [featuredOfferOffers, setFeaturedOfferOffers] = useState({});
  const [t] = useTranslation(TRANSLATIONS.FEATURED_OFFERS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;
  const isViewMode = !isUserPermitted(PERMISSIONS.FEATURED_OFFER_EDIT);

  useMount(() => {
    actions.getSingleFeaturedOfferAction(params.id);
  });

  useMountWithTranslate(() => {
    actions.getOffersAction();
  });

  useEffect(() => {
    isEmpty(featuredOfferTitle) && setFeaturedOfferTitle(featuredOffer?.title);
    isEmpty(featuredOfferOffers) &&
      setFeaturedOfferOffers(
        featuredOffer?.offers?.map(item => ({
          fixedPosition: item.fixedPosition,
          position: item.position,
          offer: item.offerId,
        })),
      );

    return () => {
      setFeaturedOfferTitle({});
      setFeaturedOfferOffers([]);
    };
  }, [featuredOffer.title, featuredOffer.offers]);

  const handleRefresh = async () => {
    await setFeaturedOfferOffers([]);
    await setFeaturedOfferTitle({});
    await actions.getSingleFeaturedOfferAction(params.id);
  };

  const handleRemoveFeaturedOffer = offerIndex =>
    setFeaturedOfferOffers(prevState => prevState?.filter?.((item, index) => index !== offerIndex));

  const handleSave = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await actions.editFeaturedOfferAction({
      featuredOfferTitle,
      featuredOfferOffers,
      featuredOfferId: params.id,
      saveAndBack,
    });
    await setIsLoading(false);
  };

  const handleChangeContent = (e, { value, language }) =>
    setFeaturedOfferTitle(prevState => ({
      ...prevState,
      [language]: value,
    }));

  const handleAddFeaturedOffer = () =>
    setFeaturedOfferOffers(prevState => [...prevState, { position: '', fixedPosition: false, offer: '' }]);

  const handleOfferChange = ({ value, name, offerIndex }) =>
    setFeaturedOfferOffers(prevState => [
      ...prevState?.map((item, index) => (index === offerIndex ? { ...item, [name]: value } : item)),
    ]);

  const handleSwitch = ({ name, offerIndex }) =>
    setFeaturedOfferOffers(prevState => [
      ...prevState?.map((item, index) => (index === offerIndex ? { ...item, [name]: !item[name] } : item)),
    ]);

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{t('editFeaturedOffer')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.FEATURED_OFFERS}>
          {t('actionButtons.goBack')}
        </Button>
        <Button
          style={{ marginRight: '5px' }}
          type="submit"
          secondary
          disabled={status === FETCH_STATUS.IDLE || isLoading}
          onClick={handleRefresh}
        >
          {t('actionButtons.reload')}
        </Button>
        {isViewMode ? null : (
          <Button
            style={{ marginRight: '5px' }}
            variant="outlined"
            primary
            disabled={status === FETCH_STATUS.IDLE || isLoading}
            onClick={handleSave}
          >
            {t('actionButtons.save')}
          </Button>
        )}

        {isViewMode ? null : (
          <Button
            variant="outlined"
            color="facebook"
            disabled={status === FETCH_STATUS.IDLE || isLoading}
            onClick={() => handleSave({ saveAndBack: true })}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment>
        <Form loading={offerStatus === FETCH_STATUS.IDLE || status === FETCH_STATUS.IDLE || isLoading}>
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <Grid.Column className="d-flex justify-content-around align-items-center">
                {t(featuredOffer?.name)}
              </Grid.Column>
              <Grid.Column className="d-flex justify-content-around align-items-center">
                <SwitchField
                  className="text-center"
                  value={featuredOffer?.returnAlreadyInvested}
                  label={t('form.returnAlreadyInvested')}
                  disabled
                />
                <SwitchField
                  className="text-center"
                  value={featuredOffer?.shuffle}
                  label={t('form.shuffle')}
                  disabled
                />
              </Grid.Column>
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={2} divided>
              <TranslatableTextField
                name="title"
                label={t('form.title')}
                value={featuredOfferTitle}
                errorMessage={error(errors?.title)}
                icon="font"
                onChange={handleChangeContent}
                labelClassName={styles.label}
                disabled={isViewMode}
              />
            </Grid>
          </Form.Group>
          <Divider />
          <Form.Group>
            <Grid container stackable columns={1} divided>
              {isViewMode ? null : (
                <Grid.Column>
                  <Button onClick={handleAddFeaturedOffer}>{t('addOffer')}</Button>
                </Grid.Column>
              )}
              {featuredOfferOffers?.map?.((offer, index) => (
                <Grid key={index} container stackable columns={4} divided>
                  <div className="d-flex justify-content-center">
                    <SwitchField
                      disabled={isViewMode}
                      className={clsx(styles.switchFieldWrapper, 'd-flex flex-column align-items-center mr-4')}
                      checkboxClassName={styles.switchField}
                      value={offer.fixedPosition}
                      label={t('fixedPosition')}
                      name="fixedPosition"
                      onChange={(e, { name }) => handleSwitch({ name, offerIndex: index })}
                    />

                    <InputField
                      disabled={isViewMode}
                      onChange={(e, { value, name }) => handleOfferChange({ value, name, offerIndex: index })}
                      name="position"
                      label={t('position')}
                      value={offer.position}
                      icon="numbered list"
                    />

                    <Grid.Column className={styles.select}>
                      <Form.Field>
                        <label>{t('offer')}</label>
                        <Select
                          disabled={isViewMode}
                          onChange={(e, { value, name }) => handleOfferChange({ value, name, offerIndex: index })}
                          options={offerOptions}
                          value={offer.offer}
                          name="offer"
                        />
                      </Form.Field>
                    </Grid.Column>

                    {isViewMode ? null : (
                      <Grid.Column style={{ padding: '22px 0 3px' }}>
                        <Button
                          color="red"
                          onClick={() => {
                            // eslint-disable-next-line no-restricted-globals,no-alert
                            if (confirm(`${t('deleteFeaturedOffersDisclaimer')}?`)) {
                              handleRemoveFeaturedOffer(index);
                            }
                          }}
                        >
                          {t('actionButtons.remove')}
                        </Button>
                      </Grid.Column>
                    )}
                  </div>
                </Grid>
              ))}
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  featuredOffer: shape({}),
  status: string,
  errors: shape({}),
  offerOptions: shape({}),
  offerStatus: string,
  permittedPermissions: arrayOf(shape({})),
};

Main.defaultProps = {
  featuredOffer: {},
  status: undefined,
  errors: undefined,
  offerOptions: {},
  offerStatus: undefined,
  permittedPermissions: [],
};

export default Main;
