const GET_TRANSACTIONS_INIT = '@transactions/GET_TRANSACTIONS_INIT';
const GET_TRANSACTIONS_SUCCESS = '@transactions/GET_TRANSACTIONS_SUCCESS';
const GET_TRANSACTIONS_FAILURE = '@transactions/GET_TRANSACTIONS_FAILURE';

const GET_SINGLE_TRANSACTION_INIT = '@transactions/GET_SINGLE_TRANSACTION_INIT';
const GET_SINGLE_TRANSACTION_SUCCESS = '@transactions/GET_SINGLE_TRANSACTION_SUCCESS';
const GET_SINGLE_TRANSACTION_FAILURE = '@transactions/GET_SINGLE_TRANSACTION_FAILURE';

const ACCEPT_TRANSACTION_INIT = '@transactions/ACCEPT_TRANSACTION_INIT';
const ACCEPT_TRANSACTION_SUCCESS = '@transactions/ACCEPT_TRANSACTION_SUCCESS';
const ACCEPT_TRANSACTION_FAILURE = '@transactions/ACCEPT_TRANSACTION_FAILURE';

const EXTEND_TRANSACTION_INIT = '@transactions/EXTEND_TRANSACTION_INIT';
const EXTEND_TRANSACTION_SUCCESS = '@transactions/EXTEND_TRANSACTION_SUCCESS';
const EXTEND_TRANSACTION_FAILURE = '@transactions/EXTEND_TRANSACTION_FAILURE';

export {
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_INIT,
  GET_SINGLE_TRANSACTION_INIT,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
  ACCEPT_TRANSACTION_INIT,
  ACCEPT_TRANSACTION_SUCCESS,
  ACCEPT_TRANSACTION_FAILURE,
  EXTEND_TRANSACTION_INIT,
  EXTEND_TRANSACTION_SUCCESS,
  EXTEND_TRANSACTION_FAILURE,
};
