import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { submitResetPasswordAction, validateResetPasswordTokenAction } from '@store/actions/user';
import { getError } from '@store/reducers/user/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  passwordError: getError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      submitResetPasswordAction,
      validateResetPasswordTokenAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
