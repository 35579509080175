import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAllRoles, getAllRolesError, getAllRolesStatus } from '@store/reducers/roles/selectors';
import { getRolesAction } from '@store/actions/roles';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allRoles: getAllRoles(state),
  allRolesError: getAllRolesError(state),
  allRolesStatus: getAllRolesStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getRolesAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
