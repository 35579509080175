import React, { useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Button, Grid, Icon, Table, Popup, Form } from 'semantic-ui-react';
import { Container, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import ROUTES from '@shared/consts/routes';
import { Link } from 'react-router-dom';
import InputField from '@shared/components/translatable/InputField';

const PersonalPermissionsList = ({
  allPermissions,
  personalPermissions,
  status,
  allPermissionsStatus,
  addPersonalPermissionToUser,
  removePersonalPermissionFromUser,
  clickedPermissionsIds,
  handleRefresh,
}) => {
  const [t] = useTranslation(TRANSLATIONS.USERS);
  const params = useParams();
  const handleAddPersonalPermissionToUser = permissionId => addPersonalPermissionToUser(params.id, permissionId);
  const handleRemovePersonalPermissionFromUser = permissionId =>
    removePersonalPermissionFromUser(params.id, permissionId);
  const [searchPermissionValue, setSearchPermissionValue] = useState('');
  const [searchAddedPermissionValue, setSearchAddedPermissionValue] = useState('');

  const renderAllPermissions = () => (
    <Grid.Column className="edit-permissions-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colspan={2}>{t('personalPermissions.availablePermissions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <div style={{ padding: '10px' }}>
          <InputField
            icon="search"
            value={searchPermissionValue}
            label={t('searchPermission')}
            onChange={(e, { value }) => setSearchPermissionValue(value)}
          />
        </div>
        <Table.Body>
          {allPermissions?.length > 0 ? (
            allPermissions
              ?.filter?.(item => item?.name?.toLowerCase?.()?.includes?.(searchPermissionValue?.toLowerCase?.()))
              ?.map?.(permission => (
                <Table.Row key={permission?.id}>
                  <Table.Cell>
                    <Popup trigger={<Button>{permission?.name}</Button>} content={permission?.description} basic />
                  </Table.Cell>
                  <Table.Cell style={{ width: '60px' }} collapsing textAlign="center">
                    {clickedPermissionsIds?.includes?.(permission?.id) && status === FETCH_STATUS.LOADING ? (
                      <CircularProgress active size="2rem" />
                    ) : (
                      <Button
                        color="green"
                        icon
                        className="m-0"
                        onClick={() => handleAddPersonalPermissionToUser(permission?.id)}
                      >
                        <Icon name="angle double right" style={{ color: '#ffffff' }} />
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
          ) : (
            <div className="empty-list">{t('emptyList')}</div>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );

  const renderPersonalPermissions = () => (
    <Grid.Column className="edit-permissions-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colspan={2}>{t('personalPermissions.personalPermissions')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <div style={{ padding: '10px' }}>
          <InputField
            icon="search"
            value={searchAddedPermissionValue}
            label={t('searchPermission')}
            onChange={(e, { value }) => setSearchAddedPermissionValue(value)}
          />
        </div>
        <Table.Body>
          {personalPermissions?.length > 0 ? (
            personalPermissions
              ?.filter?.(item => item?.name?.toLowerCase?.()?.includes?.(searchAddedPermissionValue?.toLowerCase?.()))
              ?.map?.(permission => (
                <Table.Row key={permission?.id}>
                  <Table.Cell collapsing textAlign="center" style={{ width: '60px' }}>
                    {clickedPermissionsIds?.includes?.(permission?.id) && status === FETCH_STATUS.LOADING ? (
                      <CircularProgress active size="2rem" />
                    ) : (
                      <Button
                        color="red"
                        icon
                        className="m-0"
                        onClick={() => handleRemovePersonalPermissionFromUser(permission?.id)}
                      >
                        <Icon name="angle double left" style={{ color: '#ffffff' }} />
                      </Button>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup trigger={<Button>{permission?.name}</Button>} content={permission?.description} basic />
                  </Table.Cell>
                </Table.Row>
              ))
          ) : (
            <div className="empty-list">{t('emptyList')}</div>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );

  return (
    <Form loading={status === FETCH_STATUS.IDLE || allPermissionsStatus === FETCH_STATUS.IDLE}>
      <Container maxWidth="md" className="py-10">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mb-5">{t('personalPermissions.title')}</h2>
          <div>
            <Button as={Link} type="button" to={ROUTES.USERS}>
              {t('actionButtons.goBack')}
            </Button>
            <Button onClick={handleRefresh} color="black">
              {t('actionButtons.reload')}
            </Button>
          </div>
        </div>

        <Grid columns={2}>
          {renderAllPermissions()}
          {renderPersonalPermissions()}
        </Grid>
      </Container>
    </Form>
  );
};

PersonalPermissionsList.propTypes = {
  allPermissions: arrayOf(shape({})),
  personalPermissions: arrayOf(shape({})),
  allPermissionsErrors: arrayOf(shape({})),
  errors: arrayOf(shape({})),
  allPermissionsStatus: string,
  status: string,
  addPersonalPermissionToUser: func,
  removePersonalPermissionFromUser: func,
  clickedPermissionsIds: arrayOf(shape({})),
  handleRefresh: func,
};

PersonalPermissionsList.defaultProps = {
  allPermissions: [],
  personalPermissions: [],
  allPermissionsErrors: [],
  errors: [],
  status: undefined,
  allPermissionsStatus: undefined,
  addPersonalPermissionToUser: () => {},
  removePersonalPermissionFromUser: () => {},
  clickedPermissionsIds: [],
  handleRefresh: () => {},
};

export default PersonalPermissionsList;
