import React from 'react';
import { Tab, Dropdown, FormControl } from 'react-bootstrap';
import { arrayOf, shape } from 'prop-types';

// #todo refactor date picker

const initialFilter = [
  {
    name: 'city',
    type: 'string',
  },
  {
    name: 'created',
    type: 'datetime',
  },
  {
    name: 'state',
    type: 'select',
    values: ['AL', 'VA', 'TN', 'NJ', 'MO'],
  },
];

const Filter = ({ filters }) => {
  const renderDateInput = ({ name }) => {
    return (
      <div className="card-toolbar mx-1" key={`date-${name}`}>
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle text-capitalize"
            variant="transparent"
            id="dropdown-toggle-top"
          >
            {name}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <input type="date" />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderSelect = ({ name, values }) => {
    return (
      <div className="card-toolbar mx-1" key={`select-${name}`}>
        <Dropdown className="dropdown-inline" drop="down" alignRight>
          <Dropdown.Toggle
            className="btn btn-light-primary btn-sm font-weight-bolder dropdown-toggle text-capitalize"
            variant="transparent"
            id="dropdown-toggle-top"
          >
            {name}
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
            <ul className="navi navi-hover">
              {values?.map(state => {
                return (
                  <li className="navi-item" key={`state-${state}`}>
                    <a href={() => false} className="navi-link">
                      <span className="navi-text">{state}</span>
                    </a>
                  </li>
                );
              })}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

  const renderTextInput = ({ name }) => {
    return (
      <div className="card-toolbar mx-1" key={`input-text-${name}`}>
        <FormControl size="sm" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder={name} />
      </div>
    );
  };

  return (
    <Tab.Container>
      {filters?.map(item => {
        switch (item.type) {
          case 'string': {
            return renderTextInput(item);
          }
          case 'datetime': {
            return renderDateInput(item);
          }
          case 'select': {
            return renderSelect(item);
          }
          default: {
            return null;
          }
        }
      })}
    </Tab.Container>
  );
};

Filter.propTypes = {
  filters: arrayOf(shape({})),
};

Filter.defaultProps = {
  filters: initialFilter,
};

export default Filter;
