import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  listItem: {
    listStyle: 'none',
    marginTop: 10,
  },
  inputNumber: {
    maxWidth: 150,
    margin: '0 3px',
  },
  inputText: {
    marginRight: '3px',
  },
  select: {
    margin: '0 3px',
  },
}));

export default useStyles;
