const GET_SERIES_INIT = '@series/GET_SERIES_INIT';
const GET_SERIES_SUCCESS = '@series/GET_SERIES_SUCCESS';
const GET_SERIES_FAILURE = '@series/GET_SERIES_FAILURE';

const GET_SINGLE_SERIES_INIT = '@series/GET_SINGLE_SERIES_INIT';
const GET_SINGLE_SERIES_SUCCESS = '@series/GET_SINGLE_SERIES_SUCCESS';
const GET_SINGLE_SERIES_FAILURE = '@series/GET_SINGLE_SERIES_FAILURE';

const EDIT_SERIES_INIT = '@series/EDIT_SERIES_INIT';
const EDIT_SERIES_FAILURE = '@series/EDIT_SERIES_FAILURE';
const EDIT_SERIES_SUCCESS = '@series/EDIT_SERIES_SUCCESS';

const CREATE_SERIES_INIT = '@series/CREATE_SERIES_INIT';
const CREATE_SERIES_SUCCESS = '@series/CREATE_SERIES_SUCCESS';
const CREATE_SERIES_FAILURE = '@series/CREATE_SERIES_FAILURE';

const DELETE_SERIES_INIT = '@series/DELETE_SERIES_INIT';
const DELETE_SERIES_SUCCESS = '@series/DELETE_SERIES_SUCCESS';
const DELETE_SERIES_FAILURE = '@series/DELETE_SERIES_FAILURE';

const GET_SERIES_OPTIONS_INIT = '@series/GET_SERIES_OPTIONS_INIT';
const GET_SERIES_OPTIONS_SUCCESS = '@series/GET_SERIES_OPTIONS_SUCCESS';
const GET_SERIES_OPTIONS_FAILURE = '@series/GET_SERIES_OPTIONS_FAILURE';

export {
  GET_SERIES_INIT,
  GET_SERIES_SUCCESS,
  GET_SERIES_FAILURE,
  GET_SINGLE_SERIES_INIT,
  GET_SINGLE_SERIES_SUCCESS,
  GET_SINGLE_SERIES_FAILURE,
  EDIT_SERIES_INIT,
  EDIT_SERIES_FAILURE,
  EDIT_SERIES_SUCCESS,
  CREATE_SERIES_INIT,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_FAILURE,
  DELETE_SERIES_INIT,
  DELETE_SERIES_SUCCESS,
  DELETE_SERIES_FAILURE,
  GET_SERIES_OPTIONS_INIT,
  GET_SERIES_OPTIONS_SUCCESS,
  GET_SERIES_OPTIONS_FAILURE,
};
