import moment from 'moment';

const mapQueryParams = (key, paramObj) => {
  switch (paramObj?.type || paramObj?.type?.type) {
    case 'permissionCheck': {
      return `permissions=${encodeURIComponent(paramObj?.permissionsToCheck)}`;
    }
    case 'filter': {
      if (paramObj?.searchValue && paramObj?.prototypeFieldName === 'from') {
        return paramObj?.searchValue !== 'Invalid date'
          ? `filters%5Bdate%5D%5B${paramObj?.prototypeFieldName}%5D=${moment(paramObj?.searchValue).format(
              'YYYY-MM-DD',
            )}`
          : '';
      }
      if (paramObj?.searchValue && paramObj?.prototypeFieldName === 'to') {
        return paramObj?.searchValue !== 'Invalid date'
          ? `filters%5Bdate%5D%5B${paramObj?.prototypeFieldName}%5D=${moment(paramObj?.searchValue).format(
              'YYYY-MM-DD',
            )}`
          : '';
      }
      if (
        key === 'isVisible' ||
        key === 'isOpen' ||
        key === 'active' ||
        key === 'isFeatured' ||
        key === 'required' ||
        key === 'isAdmin' ||
        key === 'isSuperAdmin'
      ) {
        return paramObj?.searchValue
          ? `filters%5B${key}%5D%5B${paramObj?.prototypeFieldName}%5D=${paramObj?.searchValue ? 1 : 0}`
          : '';
      }
      return paramObj?.searchValue
        ? `filters%5B${key}%5D%5B${paramObj?.prototypeFieldName}%5D=${paramObj?.searchValue}`
        : '';
    }
    case 'pagination': {
      return `pagination%5B${key}%5D=${paramObj?.searchValue}`;
    }
    case 'order': {
      const orderMap = { ...paramObj };
      delete orderMap.type;

      return Object?.entries?.(orderMap)
        ?.map?.(([key, value]) => {
          return `order%5B${key}%5D%5Bcolumn%5D=${value?.column}&order%5B${key}%5D%5Bdirection%5D=${value?.direction}`;
        })
        .join('&');
    }
    default: {
      return '';
      // return `${key}=${paramObj?.searchValue}`;
    }
  }
};

export default mapQueryParams;
