import {
  REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const removePersonalPermissionFromUserInit = clickedPermissionId => ({
  type: REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT,
  payload: { clickedPermissionId },
});
const removePersonalPermissionFromUserSuccess = personalPermissions => ({
  type: REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS,
  payload: { personalPermissions },
});
const removePersonalPermissionFromUserFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.message || item.code);

    return acc;
  }, {});

  return { type: REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE, payload: { errors: mappedErrors } };
};

const removePersonalPermissionFromUser = (userId, permissionId) => async dispatch => {
  dispatch(removePersonalPermissionFromUserInit(permissionId));

  try {
    const removePersonalPermissionFromUserResponse = await client.removePersonalPermissionFromUser({
      slug: `${userId}/personal-permissions/${permissionId}`,
    });

    await dispatch(removePersonalPermissionFromUserSuccess(removePersonalPermissionFromUserResponse?.response));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.permission.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.permission.error.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(removePersonalPermissionFromUserFailure(error));
  }
};

export default removePersonalPermissionFromUser;
