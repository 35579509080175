import React, { useEffect, useMemo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  allAgreements,
  agreementsStatus,
  actions,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.AGREEMENTS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    actions.getAgreementsAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.AGREEMENTS]) {
      if (
        !isUserPermitted(PERMISSIONS.AGREEMENT_CREATE) ||
        !isUserPermitted(PERMISSIONS.AGREEMENT_DELETE) ||
        !isUserPermitted(PERMISSIONS.AGREEMENT_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_AGREEMENT_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.AGREEMENTS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.AGREEMENT_CREATE) && PERMISSIONS.AGREEMENT_CREATE,
          !isUserPermitted(PERMISSIONS.AGREEMENT_DELETE) && PERMISSIONS.AGREEMENT_DELETE,
          !isUserPermitted(PERMISSIONS.AGREEMENT_EDIT) && PERMISSIONS.AGREEMENT_EDIT,
          !isUserPermitted(PERMISSIONS.GET_AGREEMENT_BY_ID) && PERMISSIONS.GET_AGREEMENT_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const refreshPage = () => actions.getAgreementsAction();

  const columns = useMemo(
    () => [
      {
        Header: t('name'),
        accessor: 'name',
        columnName: 'name',
      },
      {
        Header: t('type'),
        accessor: 'type',
        columnName: 'type',
      },
      {
        Header: t('target'),
        accessor: 'target',
        columnName: 'target',
      },
      {
        Header: t('active'),
        className: 'text-center',
        columnName: 'active',
        Cell: ({ row }) => {
          const isActive = row?.original?.active;
          return isActive ? t('yes') : t('no');
        },
      },
      {
        Header: t('required'),
        columnName: 'required',
        className: 'text-center',
        Cell: ({ row }) => {
          const isRequired = row?.original?.required;
          return isRequired ? t('yes') : t('no');
        },
      },
      {
        accessor: 'actions',
        className: 'text-right',
        Cell: ({ row }) => {
          return (
            <>
              {isUserPermitted(PERMISSIONS.GET_AGREEMENT_BY_ID) ? (
                <Button as={Link} to={`/agreements/${row.original.id}`}>
                  {t('edit')}
                </Button>
              ) : null}

              {isUserPermitted(PERMISSIONS.AGREEMENT_DELETE) ? (
                <Button
                  color="red"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals,no-alert
                    if (confirm(`${t('deleteAgreementDisclaimer')} ${row.original.name}?`)) {
                      actions.deleteAgreementAction(row.original.id);
                    }
                  }}
                >
                  {t('remove')}
                </Button>
              ) : null}
            </>
          );
        },
      },
    ],
    [t],
  );

  const renderContent = () => {
    return (
      <Table
        className="table-responsive"
        isLoading={agreementsStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
        data={allAgreements}
        columns={columns}
        queryParams={queryParams}
        searchAction={actions.getAgreementsAction}
        reloadAction={refreshPage}
        notFoundMessage={
          <NotFoundMessage
            title={t('notFoundAgreement')}
            subtitle={t('clickBelow')}
            buttonText={t('create')}
            to={ROUTES.AGREEMENT_CREATE}
          />
        }
      />
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button disabled={agreementsStatus === FETCH_STATUS.LOADING} color="black" onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.AGREEMENT_CREATE) ? (
            <Button primary as={Link} to={ROUTES.AGREEMENT_CREATE}>
              {t('actionButtons.add')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getAgreementsAction: func,
    getAgreementsTypesAction: func,
    getSingleAgreementAction: func,
    deleteAgreementAction: func,
  }).isRequired,
  allAgreements: arrayOf(shape({})),
  row: shape({}),
  singleAgreement: shape({}),
  agreementsStatus: string,
  singleStatus: string,
  deleteStatus: string,
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  allAgreements: [],
  row: {},
  singleAgreement: {},
  agreementsStatus: undefined,
  singleStatus: undefined,
  deleteStatus: undefined,
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
