import {
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_SINGLE_USER_INIT,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  GET_PERSONAL_PERMISSIONS_INIT,
  GET_PERSONAL_PERMISSIONS_SUCCESS,
  GET_PERSONAL_PERMISSIONS_FAILURE,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE,
  ADD_PERSONAL_PERMISSION_TO_USER_INIT,
  ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS,
  ADD_PERSONAL_PERMISSION_TO_USER_FAILURE,
  GET_USER_ROLES_INIT,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  ADD_ROLE_TO_USER_INIT,
  ADD_ROLE_TO_USER_SUCCESS,
  ADD_ROLE_TO_USER_FAILURE,
  REMOVE_ROLE_FROM_USER_INIT,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
} from '@store/action-types/userManagement';
import {
  GET_USER_AGREEMENTS_INIT,
  GET_USER_AGREEMENTS_SUCCESS,
  GET_USER_AGREEMENTS_FAILURE,
} from '@store/action-types/agreements';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allUsers: [],
  singleUser: {},
  status: FETCH_STATUS.IDLE,
  personalPermissionsStatus: FETCH_STATUS.IDLE,
  errors: undefined,
  personalPermissionsErrors: undefined,
  personalPermissions: [],
  clickedPermissionIds: [],
  userRoles: [],
  roleStatus: undefined,
  roleError: undefined,
  userRolesStatus: undefined,
  userAgreements: [],
  userAgreementsStatus: FETCH_STATUS.IDLE,
  userAgreementsError: undefined,
  queryParams: {},
};

const userManagementReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_USERS_INIT: {
      return {
        ...state,
        allUsers: [],
        status: FETCH_STATUS.LOADING,
        errors: undefined,
        queryParams: {},
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        allUsers: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        errors: undefined,
      };
    }
    case GET_USERS_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        allUsers: [],
        errors: payload?.errors,
      };
    }
    case GET_SINGLE_USER_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        singleUser: {},
        errors: undefined,
      };
    }
    case GET_SINGLE_USER_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        singleUser: payload?.singleUser,
        errors: undefined,
      };
    }
    case GET_SINGLE_USER_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singleUser: {},
        errors: payload?.errors,
      };
    }
    case EDIT_USER_INIT:
    case EDIT_USER_SUCCESS:
    case CREATE_USER_INIT:
    case CREATE_USER_SUCCESS: {
      return {
        ...state,
        errors: undefined,
      };
    }
    case EDIT_USER_FAILURE:
    case CREATE_USER_FAILURE: {
      return {
        ...state,
        errors: payload?.errors,
      };
    }
    case GET_PERSONAL_PERMISSIONS_INIT: {
      return {
        ...state,
        personalPermissionsStatus: FETCH_STATUS.IDLE,
        personalPermissionsErrors: undefined,
        personalPermissions: [],
      };
    }
    case GET_PERSONAL_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        personalPermissionsStatus: FETCH_STATUS.SUCCESS,
        personalPermissions: payload?.personalPermissions,
        personalPermissionsErrors: undefined,
      };
    }
    case GET_PERSONAL_PERMISSIONS_FAILURE: {
      return {
        ...state,
        personalPermissionsStatus: FETCH_STATUS.FAILURE,
        personalPermissions: [],
        personalPermissionsErrors: payload?.personalPermissionsErrors,
      };
    }
    case ADD_PERSONAL_PERMISSION_TO_USER_INIT: {
      return {
        ...state,
        clickedPermissionIds: state?.clickedPermissionIds?.concat?.([payload?.clickedPermissionId]),
        personalPermissionsStatus: FETCH_STATUS.LOADING,
        personalPermissionsErrors: undefined,
      };
    }
    case ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS: {
      return {
        ...state,
        clickedPermissionIds: [],
        personalPermissions: Object.values(payload?.personalPermissions),
        personalPermissionsErrors: undefined,
        personalPermissionsStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case ADD_PERSONAL_PERMISSION_TO_USER_FAILURE: {
      return {
        ...state,
        clickedPermissionIds: [],
        personalPermissionsErrors: payload?.errors,
        personalPermissionsStatus: FETCH_STATUS.FAILURE,
      };
    }
    case REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT: {
      return {
        ...state,
        clickedPermissionIds: state?.clickedPermissionIds?.concat?.([payload?.clickedPermissionId]),
        personalPermissionsErrors: undefined,
        personalPermissionsStatus: FETCH_STATUS.LOADING,
      };
    }
    case REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS: {
      return {
        ...state,
        clickedPermissionIds: [],
        personalPermissions: Object.values(payload?.personalPermissions),
        personalPermissionsErrors: undefined,
        personalPermissionsStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE: {
      return {
        ...state,
        clickedPermissionIds: [],
        personalPermissionsErrors: payload?.errors,
        personalPermissionsStatus: FETCH_STATUS.FAILURE,
      };
    }
    case ADD_ROLE_TO_USER_INIT: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.LOADING,
      };
    }
    case ADD_ROLE_TO_USER_SUCCESS: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case ADD_ROLE_TO_USER_FAILURE: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.FAILURE,
      };
    }
    case REMOVE_ROLE_FROM_USER_INIT: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.LOADING,
      };
    }
    case REMOVE_ROLE_FROM_USER_SUCCESS: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case REMOVE_ROLE_FROM_USER_FAILURE: {
      return {
        ...state,
        userRolesStatus: FETCH_STATUS.FAILURE,
      };
    }
    case GET_USER_ROLES_INIT: {
      return {
        ...state,
        userRoles: [],
        roleStatus: FETCH_STATUS.IDLE,
        roleErrors: undefined,
      };
    }
    case GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        userRoles: payload?.userRoles,
        roleStatus: FETCH_STATUS.SUCCESS,
        roleErrors: undefined,
      };
    }
    case GET_USER_ROLES_FAILURE: {
      return {
        ...state,
        userRoles: [],
        roleStatus: FETCH_STATUS.FAILURE,
        roleErrors: payload?.errors,
      };
    }
    case GET_USER_AGREEMENTS_INIT: {
      return {
        ...state,
        userAgreementsStatus: FETCH_STATUS.LOADING,
        userAgreementsError: undefined,
      };
    }
    case GET_USER_AGREEMENTS_SUCCESS: {
      return {
        ...state,
        userAgreements: payload.userAgreements,
        userAgreementsStatus: FETCH_STATUS.SUCCESS,
        userAgreementsError: undefined,
      };
    }
    case GET_USER_AGREEMENTS_FAILURE: {
      return {
        ...state,
        userAgreementsStatus: FETCH_STATUS.FAILURE,
        userAgreementsError: payload.error,
      };
    }
    default:
      return state;
  }
};

export default userManagementReducer;
