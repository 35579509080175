import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
  },
  formContainer: {
    minWidth: 360,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainerMobile: {
    maxWidth: '240px',
    minWidth: '240px',
  },
  input: {
    background: '#f5f5f5',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 32,
    height: 28,
    [theme.breakpoints.up('md')]: {
      height: 32,
    },
  },
}));

export default useStyles;
