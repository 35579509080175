import {
  GET_STATIC_CONTENT_PARENT_INIT,
  GET_STATIC_CONTENT_PARENT_SUCCESS,
  GET_STATIC_CONTENT_PARENT_FAILURE,
} from '@store/action-types/static-content';
import client from '@api-sdk/client';

const getStaticContentParentInit = () => ({ type: GET_STATIC_CONTENT_PARENT_INIT });
const getStaticContentParentSuccess = staticContentParent => ({
  type: GET_STATIC_CONTENT_PARENT_SUCCESS,
  payload: { staticContentParent },
});
const getStaticContentParentFailure = error => ({ type: GET_STATIC_CONTENT_PARENT_FAILURE, payload: { error } });

const getStaticContentParent = id => async dispatch => {
  dispatch(getStaticContentParentInit());

  try {
    const staticContentParent = await client.getStaticContentParent({ slug: id });
    dispatch(getStaticContentParentSuccess(staticContentParent.response));
  } catch (error) {
    dispatch(getStaticContentParentFailure(error));
  }
};

export default getStaticContentParent;
