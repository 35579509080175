import {
  EDIT_ROLE_INIT,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  GET_SINGLE_ROLE_SUCCESS,
} from '@store/action-types/roles';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import { isEmpty } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const editRoleInit = () => ({ type: EDIT_ROLE_INIT });
const editRoleSuccess = role => ({
  type: EDIT_ROLE_SUCCESS,
  payload: { role },
});
const editRoleFailure = error => ({ type: EDIT_ROLE_FAILURE, payload: { error } });

const getSingleRoleSuccess = role => ({
  type: GET_SINGLE_ROLE_SUCCESS,
  payload: { role },
});

const editRole = ({ roleId, roleData, saveAndBack = false }) => async dispatch => {
  dispatch(editRoleInit());
  const errors = {};

  if (isEmpty(roleData?.name)) {
    errors.name = 'NAME_IS_EMPTY';
  }

  if (isEmpty(roleData?.description)) {
    errors.description = 'DESCRIPTION_IS_EMPTY';
  }

  if (!isEmpty(errors)) {
    return dispatch(editRoleFailure(errors));
  }

  try {
    const editRoleResponse = await client.editRole({
      slug: roleId,
      data: { name: roleData?.name, description: roleData?.description },
    });

    if (saveAndBack) {
      history.push(ROUTES.ROLES);
    }

    await dispatch(editRoleSuccess(editRoleResponse.response));
    dispatch(getSingleRoleSuccess(editRoleResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(editRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default editRole;
