import { GET_SINGLE_FAQ_INIT, GET_SINGLE_FAQ_FAILURE, GET_SINGLE_FAQ_SUCCESS } from '@store/action-types/faq';
import Client from '@api-sdk/client';

const getSingleFaqInit = () => ({ type: GET_SINGLE_FAQ_INIT });
const getSingleFaqSuccess = singleFaq => ({
  type: GET_SINGLE_FAQ_SUCCESS,
  payload: { singleFaq },
});
const getSingleFaqFailure = error => ({ type: GET_SINGLE_FAQ_FAILURE, payload: { error } });

const getSingleFaq = faqId => async dispatch => {
  dispatch(getSingleFaqInit());

  try {
    const singleFaq = await Client.getSingleFaq({ slug: faqId });
    dispatch(getSingleFaqSuccess(singleFaq.response));
  } catch (error) {
    dispatch(getSingleFaqFailure(error));
  }
};

export default getSingleFaq;
