const VALID_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/svg+xml',
  'image/svg',
  'image/bmp',
];

export default VALID_IMAGE_MIME_TYPES;
