import React from 'react';
import { Modal } from 'react-bootstrap';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { Loader } from '@shared/layout-components';
import { Button, List, ListItem, TextField } from '@material-ui/core';
import { arrayOf, bool, func, shape, string } from 'prop-types';

const SearchModal = ({
  show,
  handleClose,
  status,
  data,
  searchValue,
  setSearchValue,
  isError,
  errorMessage,
  handleAdd,
  title,
  isEmptyMessage,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === FETCH_STATUS.IDLE ? (
          <Loader />
        ) : (
          <>
            {data?.length === 0 ? (
              <div className="h3 text-muted text-center p-10">{isEmptyMessage}</div>
            ) : (
              <>
                <TextField
                  label="Name"
                  value={searchValue}
                  fullWidth
                  error={isError}
                  helperText={isError && errorMessage}
                  variant="outlined"
                  onChange={e => setSearchValue(e.target.value)}
                />
                <List>
                  {data
                    ?.filter?.(item => {
                      if (!searchValue) return true;
                      return item.name.toLowerCase().includes(searchValue.toLowerCase());
                    })
                    ?.map?.(item => {
                      return (
                        <ListItem key={`modal-body-${item.id}`} className="d-flex justify-content-between my-1">
                          <span>{item.name}</span>
                          <Button onClick={() => handleAdd(item.id)}>add</Button>
                        </ListItem>
                      );
                    })}
                </List>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
        <Button className="ml-2" variant="outlined" color="primary" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SearchModal.propTypes = {
  show: bool,
  isError: bool,
  handleClose: func,
  setSearchValue: func,
  handleAdd: func,
  data: arrayOf(shape({})),
  status: string,
  searchValue: string,
  errorMessage: string,
  title: string,
  isEmptyMessage: string,
};

SearchModal.defaultProps = {
  show: false,
  isError: false,
  handleClose: () => {},
  setSearchValue: () => {},
  handleAdd: () => {},
  data: [],
  status: undefined,
  searchValue: undefined,
  errorMessage: undefined,
  title: undefined,
  isEmptyMessage: undefined,
};

export default SearchModal;
