const getAllAgreements = state => state?.agreements?.allAgreements;
const getAgreementsStatus = state => state?.agreements?.status;
const getSingleAgreement = state => state?.agreements?.singleAgreement;
const getSingleAgreementStatus = state => state?.agreements?.singleStatus;
const getCreateStatus = state => state?.agreements?.createStatus;
const getEditStatus = state => state?.agreements?.editStatus;
const getDeleteStatus = state => state?.agreements?.deleteStatus;
const getErrors = state => state?.agreements?.error;
const getTypes = state => state?.agreements?.types;
const getTargets = state => state?.agreements?.targets;
const getTypesStatus = state => state?.agreements?.typesStatus;
const getAllAgreementsQueryParams = state => state?.agreements?.queryParams;

export {
  getAgreementsStatus,
  getAllAgreements,
  getSingleAgreement,
  getSingleAgreementStatus,
  getCreateStatus,
  getEditStatus,
  getDeleteStatus,
  getErrors,
  getTypes,
  getTargets,
  getTypesStatus,
  getAllAgreementsQueryParams,
};
