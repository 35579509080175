import { GET_AGREEMENTS_INIT, GET_AGREEMENTS_SUCCESS, GET_AGREEMENTS_FAILURE } from '@store/action-types/agreements';
import Client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getAgreementsInit = () => ({ type: GET_AGREEMENTS_INIT });
const getAgreementsSuccess = ({ response, activeFilters, pagination }) => ({
  type: GET_AGREEMENTS_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredPagination: response?.pagination,
    pagination,
  },
});
const getAgreementsFailure = error => ({ type: GET_AGREEMENTS_FAILURE, payload: { error } });

const getAgreements = ({ activeFilters, pagination } = {}) => async dispatch => {
  dispatch(getAgreementsInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const agreements = await Client.getAgreements({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getAgreementsSuccess({
        response: agreements.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
      }),
    );
  } catch (error) {
    dispatch(getAgreementsFailure(error));
  }
};

export default getAgreements;
