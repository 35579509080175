import { DELETE_FAQ_FAILURE, DELETE_FAQ_INIT, DELETE_FAQ_SUCCESS } from '@store/action-types/faq';
import Client from '@api-sdk/client';
import getFaq from '@store/actions/faq/getFaq';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteFaqInit = () => ({ type: DELETE_FAQ_INIT });
const deleteFaqSuccess = () => ({ type: DELETE_FAQ_SUCCESS });
const deleteFaqFailure = error => ({ type: DELETE_FAQ_FAILURE, payload: { error } });

const deleteFaq = id => async dispatch => {
  dispatch(deleteFaqInit());

  try {
    await Client.deleteFaq({ slug: id });
    dispatch(getFaq());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('faq.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deleteFaqSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('faq.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'danger',
        },
      }),
    );
    return dispatch(deleteFaqFailure(error));
  }
};

export default deleteFaq;
