import {
  GET_AGREEMENTS_INIT,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENTS_FAILURE,
  GET_SINGLE_AGREEMENT_INIT,
  GET_SINGLE_AGREEMENT_SUCCESS,
  GET_SINGLE_AGREEMENT_FAILURE,
  CREATE_AGREEMENT_INIT,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
  EDIT_AGREEMENT_INIT,
  EDIT_AGREEMENT_SUCCESS,
  EDIT_AGREEMENT_FAILURE,
  DELETE_AGREEMENT_INIT,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  GET_TYPES_AND_TARGETS_INIT,
  GET_TYPES_AND_TARGETS_SUCCESS,
  GET_TYPES_AND_TARGETS_FAILURE,
} from '@store/action-types/agreements';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allAgreements: [],
  singleAgreement: {},
  status: FETCH_STATUS.IDLE,
  singleStatus: undefined,
  createStatus: undefined,
  editStatus: undefined,
  deleteStatus: undefined,
  typesStatus: undefined,
  error: undefined,
};

const agreementsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_AGREEMENTS_INIT: {
      return {
        ...state,
        singleStatus: undefined,
        status: FETCH_STATUS.LOADING,
        typesStatus: undefined,
      };
    }
    case GET_AGREEMENTS_SUCCESS: {
      return {
        allAgreements: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
        },
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_AGREEMENTS_FAILURE: {
      return {
        allAgreements: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_AGREEMENT_INIT: {
      return {
        ...state,
        singleAgreement: {},
        singleStatus: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        singleAgreement: payload.singleAgreement,
        error: undefined,
        status: undefined,
        singleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_AGREEMENT_FAILURE: {
      return {
        ...state,
        singleAgreement: {},
        error: payload.error,
        singleStatus: FETCH_STATUS.FAILURE,
      };
    }
    case CREATE_AGREEMENT_INIT: {
      return {
        ...state,
        createStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case CREATE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        createStatus: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case CREATE_AGREEMENT_FAILURE: {
      return {
        ...state,
        createStatus: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case EDIT_AGREEMENT_INIT: {
      return {
        ...state,
        editStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case EDIT_AGREEMENT_SUCCESS: {
      return {
        ...state,
        editStatus: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case EDIT_AGREEMENT_FAILURE: {
      return {
        ...state,
        editStatus: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case DELETE_AGREEMENT_INIT: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case DELETE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.SUCCESS,
        allAgreements: state?.allAgreements?.filter?.(agreement => agreement.id !== payload.agreementId),
        error: undefined,
      };
    }
    case DELETE_AGREEMENT_FAILURE: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case GET_TYPES_AND_TARGETS_INIT: {
      return { ...state, typesStatus: FETCH_STATUS.IDLE };
    }
    case GET_TYPES_AND_TARGETS_SUCCESS: {
      return {
        ...state,
        typesStatus: FETCH_STATUS.SUCCESS,
        types: payload?.types,
        targets: payload?.targets,
      };
    }
    case GET_TYPES_AND_TARGETS_FAILURE: {
      return {
        ...state,
        typesStatus: FETCH_STATUS.FAILURE,
        types: [],
        targets: [],
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default agreementsReducer;
