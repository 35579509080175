import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getPermissionsAction, deletePermissionAction } from '@store/actions/permissions';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import { getAllPermissions, getPermissionStatus } from '@store/reducers/permissions/selectors';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allPermissions: getAllPermissions(state),
  permissionStatus: getPermissionStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPermissionsAction,
      deletePermissionAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
