import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '@store/reducers';

const initialState = {};

const store =
  process.env.NODE_ENV === 'production'
    ? createStore(rootReducer, initialState, applyMiddleware(thunk))
    : createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));

export default store;
