import {
  GET_FEATURED_OFFERS_INIT,
  GET_FEATURED_OFFERS_SUCCESS,
  GET_FEATURED_OFFERS_FAILURE,
} from '@store/action-types/featured-offers';
import Client from '@api-sdk/client';

const getFeaturedOffersInit = () => ({ type: GET_FEATURED_OFFERS_INIT });
const getFeaturedOffersSuccess = featuredOffers => ({ type: GET_FEATURED_OFFERS_SUCCESS, payload: { featuredOffers } });
const getFeaturedOffersFailure = error => ({ type: GET_FEATURED_OFFERS_FAILURE, payload: { error } });

const getFeaturedOffers = () => async dispatch => {
  dispatch(getFeaturedOffersInit());

  try {
    const featuredOffersResponse = await Client.getFeaturedOffers();
    return dispatch(getFeaturedOffersSuccess(featuredOffersResponse.response));
  } catch (error) {
    return dispatch(getFeaturedOffersFailure(error));
  }
};

export default getFeaturedOffers;
