import React from 'react';
import { Checkbox, Form, Grid, Label } from 'semantic-ui-react';

class SwitchField extends React.PureComponent {
  render() {
    const { onChange, name, value, label, errorMessage } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          {errorMessage && (
            <Label color="red" className="mb-5">
              {errorMessage}
            </Label>
          )}
          <label>{label}</label>
          <Checkbox name={name} toggle checked={value} onChange={onChange} />
        </Form.Field>
      </Grid.Column>
    );
  }
}

export default SwitchField;
