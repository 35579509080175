import React from 'react';
import { number, arrayOf, shape, func } from 'prop-types';
import { PositionSelect, SectionElement } from '@shared/components/OfferForm/components';
import { Button, Grid } from '@material-ui/core';
import useStyles from './styles';

const SectionCard = ({
  elements,
  cardId,
  numberOfSections,
  handleAddElement,
  arrayOfElementsSetPosition,
  handleAddArrayToData,
  handleAddElementInElement,
  handleRemoveElementFromElement,
  handleRemoveElement,
  handleRemoveSection,
  setElementType,
  setElementGrid,
  setElementPosition,
  setSectionPosition,
  setElementData,
  setElementDataWithArray,
}) => {
  const styles = useStyles();

  const removeElement = elementId => {
    handleRemoveElement(cardId, elementId);
  };

  const handleArrayOfElementsSetPosition = (elementId, oldIndex, newIndex) => {
    arrayOfElementsSetPosition(cardId, elementId, oldIndex, newIndex);
  };

  const addElementInElement = (elementId, position) => {
    handleAddElementInElement(cardId, elementId, position);
  };

  const removeElementFromElement = (elementId, position) => {
    handleRemoveElementFromElement(cardId, elementId, position);
  };

  const addArrayToData = elementId => {
    handleAddArrayToData(cardId, elementId);
  };

  const handleSetElementData = (elementId, key, value) => {
    setElementData(cardId, elementId, key, value);
  };

  const handleSetElementDataWithArray = (elementId, index, key, value) => {
    setElementDataWithArray(cardId, elementId, index, key, value);
  };

  const handleSetType = (elementId, type) => {
    setElementType(cardId, elementId, type);
  };

  const handleSetGrid = (elementId, name, gridValue) => {
    setElementGrid(cardId, elementId, name, gridValue);
  };

  const handleSetElementPosition = (e, oldIndex) => {
    const newIndex = e.target.value;

    setElementPosition(cardId, oldIndex, newIndex);
  };

  const handleSetSectionPosition = (e, oldIndex) => {
    const newIndex = e.target.value;

    setSectionPosition(cardId, oldIndex, newIndex);
  };

  const renderElements = () => {
    return elements?.map((item, index) => {
      return (
        <SectionElement
          key={`section-element-${index}`}
          elements={elements}
          elementType={elements[index].type}
          grid={elements[index].grid}
          elementId={index}
          addElementInElement={addElementInElement}
          removeElement={removeElement}
          addArrayToData={addArrayToData}
          handleSetType={handleSetType}
          handleSetGrid={handleSetGrid}
          removeElementFromElement={removeElementFromElement}
          handleSetElementPosition={handleSetElementPosition}
          handleSetElementData={handleSetElementData}
          handleSetElementDataWithArray={handleSetElementDataWithArray}
          handleArrayOfElementsSetPosition={handleArrayOfElementsSetPosition}
        />
      );
    });
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Section</span>
        </h3>
        <div>
          <Button
            size="large"
            className="mr-2"
            variant="outlined"
            color="secondary"
            onClick={() => handleRemoveSection(cardId)}
          >
            remove
          </Button>
          <PositionSelect
            className={styles.select}
            handleChange={handleSetSectionPosition}
            numberOfOptions={numberOfSections}
            id={cardId}
          />
          <Button size="large" variant="outlined" onClick={() => handleAddElement(cardId)}>
            New element
          </Button>
        </div>
      </div>
      <div className="card-body pt-3 pb-0">
        <Grid container spacing={4}>
          {renderElements()}
        </Grid>
      </div>
      <div className="card-footer pb-5 pt-0 border-0" />
    </div>
  );
};

SectionCard.propTypes = {
  cardId: number,
  numberOfSections: number.isRequired,
  elements: arrayOf(shape({})),
  handleAddElement: func,
  setElementGrid: func,
  setElementPosition: func,
  setElementType: func,
  setSectionPosition: func,
  setElementData: func,
  setElementDataWithArray: func,
  handleAddArrayInData: func,
  arrayOfElementsSetPosition: func,
  handleAddArrayToData: func,
  handleAddElementInElement: func,
  handleRemoveElementFromElement: func,
  handleRemoveElement: func,
  handleRemoveSection: func,
};

SectionCard.defaultProps = {
  cardId: null,
  elements: [],
  handleAddElement: () => {},
  setElementGrid: () => {},
  setElementPosition: () => {},
  setSectionPosition: () => {},
  setElementType: () => {},
  setElementData: () => {},
  setElementDataWithArray: () => {},
  handleAddArrayInData: () => {},
  arrayOfElementsSetPosition: () => {},
  handleAddArrayToData: () => {},
  handleAddElementInElement: () => {},
  handleRemoveElementFromElement: () => {},
  handleRemoveElement: () => {},
  handleRemoveSection: () => {},
};

export default SectionCard;
