import {
  GET_SERIES_INIT,
  GET_SERIES_SUCCESS,
  GET_SERIES_FAILURE,
  GET_SINGLE_SERIES_INIT,
  GET_SINGLE_SERIES_SUCCESS,
  GET_SINGLE_SERIES_FAILURE,
  EDIT_SERIES_INIT,
  EDIT_SERIES_SUCCESS,
  EDIT_SERIES_FAILURE,
  CREATE_SERIES_INIT,
  CREATE_SERIES_SUCCESS,
  CREATE_SERIES_FAILURE,
  GET_SERIES_OPTIONS_INIT,
  GET_SERIES_OPTIONS_FAILURE,
  GET_SERIES_OPTIONS_SUCCESS,
} from '@store/action-types/series';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allSeries: [],
  singleSeries: {},
  status: FETCH_STATUS.IDLE,
  error: undefined,
  queryParams: {},
};

const offersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SERIES_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        queryParams: {},
      };
    }
    case GET_SERIES_SUCCESS: {
      return {
        allSeries: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SERIES_FAILURE: {
      return {
        allSeries: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_SERIES_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_SERIES_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        singleSeries: payload.singleSeries.response,
        error: undefined,
      };
    }
    case GET_SINGLE_SERIES_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singleSeries: {},
        error: payload.error,
      };
    }
    case EDIT_SERIES_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    }
    case EDIT_SERIES_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_SERIES_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CREATE_SERIES_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    }
    case CREATE_SERIES_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case CREATE_SERIES_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case GET_SERIES_OPTIONS_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
      };
    }
    case GET_SERIES_OPTIONS_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case GET_SERIES_OPTIONS_SUCCESS: {
      return {
        ...state,
        seriesOptions: payload.seriesOptions,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    default:
      return state;
  }
};

export default offersReducer;
