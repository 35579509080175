import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imgWrapper: {
    maxWidth: 300,
    maxHeight: 300,
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      maxWidth: '260px',
      maxHeight: '260px',
    },
  },
  img: {
    minHeight: '90px',
    maxWidth: '100%',
    maxHeight: '100%',
    borderRadius: '10px',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  button: {
    marginBottom: '16px',
  },
  uploadButton: {
    transform: 'translateY(66%)',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
