import React from 'react';
import { Form, Grid, Segment, Label, Button, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { GithubPicker } from 'react-color';
import BarChartItemModel from '../../../../models/elementModels/BarChartElementModel/BarChartItemModel';
import { TranslatableTextField, InputField } from '../../../../../components';

class BarChartElement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onChangeTranslatableContent = this.onChangeTranslatableContent.bind(this);
    this.onChangeContent = this.onChangeContent.bind(this);
    this.onRemoveChartValue = this.onRemoveChartValue.bind(this);
    this.onAddChartValue = this.onAddChartValue.bind(this);
    this.onArrowUp = this.onArrowUp.bind(this);
    this.onArrowDown = this.onArrowDown.bind(this);
  }

  onChangeTranslatableContent(e, { name, value, language, innerElementPosition }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: {
                                ...innerElement[name],
                                [language]: value,
                              },
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onChangeContent(e, { name, value, innerElementPosition }) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        if (innerElement.position === innerElementPosition) {
                          return innerElementsAcc.concat([
                            {
                              ...innerElement,
                              [name]: value,
                            },
                          ]);
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onRemoveChartValue(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Are you sure you want to remove chart value? Chart element changes will be discarded.`)) {
      onChange(prevState => ({
        offerSections: prevState.offerSections.reduce((acc, section) => {
          if (section.position === sectionNumber) {
            return acc.concat([
              {
                ...section,
                elements: section.elements.reduce((elementsAcc, el) => {
                  if (element.position === el.position) {
                    return elementsAcc.concat([
                      {
                        ...el,
                        elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                          if (innerElement.position === innerElementPosition) {
                            return innerElementsAcc;
                          }
                          if (innerElement.position > innerElementPosition) {
                            return innerElementsAcc.concat([{ ...innerElement, position: innerElement.position - 1 }]);
                          }
                          return innerElementsAcc.concat([innerElement]);
                        }, []),
                      },
                    ]);
                  }
                  return elementsAcc.concat([el]);
                }, []),
              },
            ]);
          }
          return acc.concat([section]);
        }, []),
      }));
    }
  }

  onAddChartValue() {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.concat([
                        {
                          ...BarChartItemModel,
                          position: el?.elements?.[el?.elements?.length - 1]?.position + 1 || 1,
                          backendIndex: el?.elements?.length || 0,
                        },
                      ]),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowUp(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const prevItem = el.elements.find(item => item.position === innerElementPosition - 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === prevItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: prevItem.position });
                        }
                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...prevItem, position: matchingItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  onArrowDown(innerElementPosition) {
    const { onChange, sectionNumber, element } = this.props;

    onChange(prevState => ({
      offerSections: prevState.offerSections.reduce((acc, section) => {
        if (section.position === sectionNumber) {
          return acc.concat([
            {
              ...section,
              elements: section.elements.reduce((elementsAcc, el) => {
                if (element.position === el.position) {
                  return elementsAcc.concat([
                    {
                      ...el,
                      elements: el.elements.reduce((innerElementsAcc, innerElement) => {
                        const nextItem = el.elements.find(item => item.position === innerElementPosition + 1);
                        const matchingItem = el.elements.find(item => item.position === innerElementPosition);

                        if (innerElement.position === matchingItem.position) {
                          return innerElementsAcc.concat({ ...nextItem, position: matchingItem.position });
                        }
                        if (innerElement.position === nextItem.position) {
                          return innerElementsAcc.concat({ ...matchingItem, position: nextItem.position });
                        }
                        return innerElementsAcc.concat([innerElement]);
                      }, []),
                    },
                  ]);
                }
                return elementsAcc.concat([el]);
              }, []),
            },
          ]);
        }
        return acc.concat([section]);
      }, []),
    }));
  }

  render() {
    const { element, elementErrors, t, isViewMode } = this.props;

    return (
      <>
        {isViewMode ? null : (
          <Form.Group>
            <Grid container stackable columns={2} divided verticalAlign="middle">
              <Grid.Column>
                <Button onClick={() => this.onAddChartValue()}>
                  <Icon name="plus" />
                  Add chart value
                </Button>
              </Grid.Column>
            </Grid>
          </Form.Group>
        )}

        {element.elements.map(innerElement => (
          <Segment key={innerElement.position}>
            <Form.Group>
              <Grid container stackable columns={1} divided>
                <Grid.Row>
                  <Label>
                    {innerElement.position}. {t('chart.counter')}
                  </Label>
                  {isViewMode ? null : (
                    <>
                      <Button
                        icon
                        className="mr-2 ml-2"
                        disabled={innerElement.position === element.elements[0].position}
                        onClick={() => this.onArrowUp(innerElement.position)}
                      >
                        <Icon name="arrow up" />
                      </Button>
                      <Button
                        icon
                        className="mr-2 ml-2"
                        disabled={innerElement.position === element.elements[element.elements.length - 1].position}
                        onClick={() => this.onArrowDown(innerElement.position)}
                      >
                        <Icon name="arrow down" />
                      </Button>
                      <Button
                        className="mr-2 ml-2"
                        icon
                        color="red"
                        onClick={() => this.onRemoveChartValue(innerElement.position)}
                      >
                        <Icon name="trash" />
                      </Button>
                    </>
                  )}
                </Grid.Row>
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={2} divided>
                <TranslatableTextField
                  disabled={isViewMode}
                  icon="font"
                  label={t('chart.label')}
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.label}
                  name="label"
                  value={innerElement.label}
                  onChange={(e, { name, language, value }) =>
                    this.onChangeTranslatableContent(e, {
                      name,
                      language,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
              </Grid>
            </Form.Group>
            <Form.Group>
              <Grid container stackable columns={2} divided>
                <InputField
                  disabled={isViewMode}
                  icon="hashtag"
                  type="number"
                  label={t('chart.value')}
                  name="value"
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.value}
                  value={innerElement.value}
                  onChange={(e, { name, value }) =>
                    this.onChangeContent(e, {
                      name,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
                <InputField
                  disabled={isViewMode}
                  icon="circle"
                  type="text"
                  label={t('chart.color')}
                  name="color"
                  errorMessage={elementErrors?.elements?.[innerElement?.backendIndex]?.color}
                  value={innerElement.color}
                  onChange={(e, { name, value }) =>
                    this.onChangeContent(e, {
                      name,
                      value,
                      innerElementPosition: innerElement.position,
                    })
                  }
                />
                {isViewMode ? null : (
                  <GithubPicker
                    triangle="hide"
                    width="auto"
                    color={innerElement?.color || '#000000'}
                    onChangeComplete={color => {
                      this.onChangeContent(false, {
                        name: 'color',
                        value: color?.hex,
                        innerElementPosition: innerElement.position,
                      });
                    }}
                  />
                )}
              </Grid>
            </Form.Group>
          </Segment>
        ))}
      </>
    );
  }
}

export default withTranslation(TRANSLATIONS.OFFERS)(BarChartElement);
