import React from 'react';
import Input from '@shared/components/translatable/InputField';
import DatePickerField from '@shared/components/translatable/DatePickerField';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { SwitchField } from '@screens/offers/NewOfferForm/components';

const TABLE_FILTER_TYPES = {
  TEXT: 'text',
  CHOICE: 'choice',
  NUMERIC: 'numeric',
  BOOLEAN: 'boolean',
  DATE_TIME_RANGE: 'dateTimeRange',
};

const TableFilter = ({
  searchAction,
  queryParams,
  column,
  activeFilters,
  setActiveFilters,
  onSearchAction,
  onChangeSearch,
}) => {
  const [t] = useTranslation(TRANSLATIONS.COMMON);
  const filterPrototype = queryParams?.configuredFilters?.find?.(item => item.name === column.columnName);

  const handleKeyUp = event => {
    if (event.key === 'Enter') {
      onSearchAction();
    }
  };

  const renderFilter = () => {
    switch (filterPrototype?.type) {
      case TABLE_FILTER_TYPES.DATE_TIME_RANGE: {
        return (
          <div className="common-table--filter-wrapper">
            <div>
              <DatePickerField
                placeholder={t(`commonTable.placeholders.${filterPrototype?.name}.dateFrom`)}
                icon="calendar alternate"
                name="dateFrom"
                clearable
                value={activeFilters?.dateFrom?.searchValue}
                onChange={(e, { value, name }) => {
                  onChangeSearch({ name, value, filterPrototype });
                }}
                className="m-b-2 mb-2"
                onKeyUp={handleKeyUp}
              />
              <DatePickerField
                placeholder={t(`commonTable.placeholders.${filterPrototype?.name}.dateTo`)}
                icon="calendar alternate"
                value={activeFilters?.dateTo?.searchValue}
                name="dateTo"
                clearable
                onChange={(e, { value, name }) => {
                  onChangeSearch({ name, value, filterPrototype });
                }}
                onKeyUp={handleKeyUp}
              />
            </div>
          </div>
        );
      }
      case TABLE_FILTER_TYPES.BOOLEAN: {
        return (
          <div className="common-table--filter-wrapper d-flex justify-content-center">
            <SwitchField
              className="text-center d-flex justify-content-center"
              name={filterPrototype.name}
              value={activeFilters?.[filterPrototype?.name]?.searchValue}
              onChange={(e, { name, checked }) => onChangeSearch({ name, value: checked, filterPrototype })}
              onKeyUp={handleKeyUp}
            />
          </div>
        );
      }
      case TABLE_FILTER_TYPES.NUMERIC: {
        return (
          <div className="common-table--filter-wrapper">
            <Input
              disableIcon
              placeholder={t(`commonTable.placeholders.${filterPrototype?.name}`)}
              name={filterPrototype.name}
              value={activeFilters?.[filterPrototype?.name]?.searchValue}
              onChange={(e, { name, value }) => onChangeSearch({ name, value, filterPrototype })}
              onKeyUp={handleKeyUp}
            />
          </div>
        );
      }
      case TABLE_FILTER_TYPES.TEXT: {
        return (
          <div className="common-table--filter-wrapper">
            <Input
              disableIcon
              placeholder={t(`commonTable.placeholders.${filterPrototype?.name}`)}
              name={filterPrototype.name}
              value={activeFilters?.[filterPrototype?.name]?.searchValue}
              onChange={(e, { name, value }) => onChangeSearch({ name, value, filterPrototype })}
              onKeyUp={handleKeyUp}
            />
          </div>
        );
      }
      case TABLE_FILTER_TYPES.CHOICE: {
        const dropdownOptions = Object.entries(filterPrototype?.choices).map(([key, value]) => {
          return {
            key,
            text: key,
            value,
          };
        });

        return (
          <div className="common-table--filter-wrapper">
            <Dropdown
              name={filterPrototype.name}
              placeholder={t(`commonTable.placeholders.${filterPrototype?.name}`)}
              value={activeFilters?.[filterPrototype?.name]?.searchValue}
              onChange={(e, { name, value }) => {
                onChangeSearch({ name, value, filterPrototype });
              }}
              fluid
              style={{ minWidth: '150px' }}
              clearable
              options={dropdownOptions}
              onKeyUp={handleKeyUp}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };

  return renderFilter();
};

export default TableFilter;
