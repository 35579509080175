import {
  EDIT_PAYPAL_CONFIG_INIT,
  EDIT_PAYPAL_CONFIG_SUCCESS,
  EDIT_PAYPAL_CONFIG_FAILURE,
} from '@store/action-types/paypal';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const editPaypalConfigInit = () => ({ type: EDIT_PAYPAL_CONFIG_INIT });
const editPaypalConfigSuccess = paypalConfig => ({ type: EDIT_PAYPAL_CONFIG_SUCCESS, payload: { paypalConfig } });
const editPaypalConfigFailure = error => ({ type: EDIT_PAYPAL_CONFIG_FAILURE, payload: { error } });

const editPaypalConfig = paypalData => async dispatch => {
  dispatch(editPaypalConfigInit());

  try {
    const editPaypalConfigResponse = await client.editPaypalConfig({
      data: {
        maxAmount: paypalData?.maxAmount,
        defaultCredentials: {
          sandboxClientId: paypalData?.defaultCredentials?.sandboxClientId,
          sandboxClientSecret: paypalData?.defaultCredentials?.sandboxClientSecret,
          productionClientId: paypalData?.defaultCredentials?.productionClientId,
          productionClientSecret: paypalData?.defaultCredentials?.productionClientSecret,
          productionMode: paypalData?.defaultCredentials?.productionMode,
        },
      },
    });
    await dispatch(editPaypalConfigSuccess(editPaypalConfigResponse));

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('paypal.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('paypal.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editPaypalConfigFailure(error));
  }
};

export default editPaypalConfig;
