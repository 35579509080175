import { RESET_PASSWORD_INIT, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE } from '@store/action-types/user';
import Client from '@api-sdk/client';
import getErrorCode from '@shared/utils/getErrorCode';
import { isEmpty } from 'lodash';

const resetPasswordInit = () => ({ type: RESET_PASSWORD_INIT });
const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });
const resetPasswordFailure = error => ({ type: RESET_PASSWORD_FAILURE, payload: { error } });

const resetPassword = email => async dispatch => {
  dispatch(resetPasswordInit());

  if (isEmpty(email)) {
    dispatch(resetPasswordFailure(['EMAIL_EMPTY']));
    return;
  }

  try {
    await Client.resetPassword({
      data: { email, activationUrlPattern: `${window.location.origin}/reset-password/:resetPasswordToken` },
    });

    return dispatch(resetPasswordSuccess());
  } catch (error) {
    return dispatch(resetPasswordFailure(getErrorCode(error?.errors)));
  }
};

export default resetPassword;
