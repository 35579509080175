import React from 'react';
import { array, element, object, oneOfType, string } from 'prop-types';
import clsx from 'clsx';

const CardToolbar = ({ children, className }) => <div className={clsx('card-toolbar', className)}>{children}</div>;

CardToolbar.propTypes = {
  children: oneOfType([element, string, object, array]).isRequired,
  className: string,
};

CardToolbar.defaultProps = {
  className: '',
};

export default CardToolbar;
