import React from 'react';
import { func, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const Topbar = ({ logoutUser, user }) => {
  const history = useHistory();
  const [t] = useTranslation(TRANSLATIONS.COMMON);

  return (
    <div className="topbar align-items-center">
      <div className="d-flex align-items-center justify-content-center" style={{ marginRight: 16, fontWeight: 600 }}>
        {user?.userData?.firstName || user?.userData?.lastName ? (
          <span>
            Zalogowany:
            <span style={{ marginLeft: 4 }}>
              {user?.userData?.firstName} {user?.userData?.lastName}
            </span>
          </span>
        ) : null}
      </div>
      <Button type="button" onClick={() => logoutUser({ history })}>
        {t('logout')}
      </Button>
    </div>
  );
};

Topbar.propTypes = {
  logoutUser: func.isRequired,
  user: shape({}),
};

Topbar.defaultProps = {
  user: {},
};

export default Topbar;
