import React from 'react';
import { bool, string } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useStyles from './styles';

const Loader = ({ fullscreen, title, subtitle, form, open, disableEnterTransition, className }) => {
  const styles = useStyles();

  return fullscreen ? (
    <Backdrop
      transitionDuration={{
        enter: disableEnterTransition ? 0 : 240,
        exit: 240,
        appear: 240,
      }}
      open={open}
      className={styles.backdrop}
    >
      <Box className={styles.fullscreenWrapper}>
        <CircularProgress
          variant="indeterminate"
          color="inherit"
          size={60}
          className={styles.fullscreenCircularProgress}
        />
        <Box className={styles.fullscreenWrapper}>
          <Typography variant="h4" className={styles.fullscreenTitle}>
            {title}
          </Typography>
          <Typography variant="h6" className={styles.fullscreenSubtitle}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  ) : (
    <Box className={clsx(styles.circularWrapper, form && styles.formWrapper)}>
      <CircularProgress className={clsx(styles.circularProgress, className)} />
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle2">{subtitle}</Typography>
    </Box>
  );
};

Loader.propTypes = {
  fullscreen: bool,
  title: string,
  subtitle: string,
  form: bool,
  open: bool,
  disableEnterTransition: bool,
};

Loader.defaultProps = {
  fullscreen: false,
  title: '',
  subtitle: '',
  form: false,
  open: false,
  disableEnterTransition: false,
};

export default Loader;
