import i18next from 'i18next';
import toast_en from '@translations/en/toast.json';
import toast_pl from '@translations/pl/toast.json';
import { get } from 'lodash';

const translateToastMessage = path => {
  const language = i18next.language;

  const translations = {
    en: toast_en,
    pl: toast_pl,
  };

  return get(translations?.[language], path) || path;
};

export default translateToastMessage;
