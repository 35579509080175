import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { Button, Grid, TextField } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useStyles from './styles';

const firstImage =
  'https://images.unsplash.com/photo-1498373419901-52eba931dc4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80';
const secondImage =
  'https://images.unsplash.com/photo-1556955112-28cde3817b0a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80';

const ImageBox = ({ setElementData, elementValue }) => {
  const styles = useStyles();
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [imgLink, setImgLink] = useState(firstImage);

  const handleSetElementData = (e, isImage) => {
    let key;
    let value;

    if (isImage) {
      setIsImageVisible(true);

      key = 'imageBase64';
      value = imgLink;

      setElementData(key, value);
      return;
    }

    key = e.target.name;
    value = e.target.value;

    setElementData(key, value);
  };

  const handleImageChange = () => {
    imgLink === firstImage && setImgLink(secondImage);
    imgLink === secondImage && setImgLink(firstImage);

    const key = 'imageBase64';
    setElementData(key, imgLink);
  };

  const renderImage = () => {
    return (
      <Grid className={styles.wrapper} container spacing={2}>
        <Grid item xs={12} sm={5} lg={6} className={styles.imgWrapper}>
          <img className={styles.img} src={imgLink} alt="First" />
        </Grid>
        <Grid item xs={12} sm={7} lg={6} className={styles.inputWrapper}>
          <TextField
            className={styles.input}
            onChange={handleSetElementData}
            name="label"
            label="Title"
            variant="outlined"
            fullWidth
            value={elementValue?.label || ''}
          />
          <TextField
            className={styles.input}
            onChange={handleSetElementData}
            name="subLabel"
            label="Subtitle"
            variant="outlined"
            margin="normal"
            value={elementValue.subLabel || ''}
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderHeaderButtons = () => {
    return (
      <>
        <Button
          startIcon={<CloudUploadIcon />}
          className={styles.button}
          variant="outlined"
          onClick={handleImageChange}
          size="small"
        >
          Change photo
        </Button>
      </>
    );
  };

  const renderButton = () => {
    return (
      <Button
        className={styles.uploadButton}
        variant="outlined"
        size="large"
        startIcon={<CloudUploadIcon />}
        onClick={e => handleSetElementData(e, true)}
        fullWidth
      >
        Upload
      </Button>
    );
  };

  return (
    <div>
      <div className={styles.buttonWrapper}>{isImageVisible && renderHeaderButtons()}</div>
      {isImageVisible ? renderImage() : renderButton()}
    </div>
  );
};

ImageBox.propTypes = {
  elementValue: shape({}).isRequired,
  setElementData: func.isRequired,
};

export default ImageBox;
