const GET_CONFIG_INIT = '@settings/GET_CONFIG_INIT';
const GET_CONFIG_SUCCESS = '@settings/GET_CONFIG_SUCCESS';
const GET_CONFIG_FAILURE = '@settings/GET_CONFIG_FAILURE';

const EDIT_CONFIG_INIT = '@settings/EDIT_CONFIG_INIT';
const EDIT_CONFIG_SUCCESS = '@settings/EDIT_CONFIG_SUCCESS';
const EDIT_CONFIG_FAILURE = '@settings/EDIT_CONFIG_FAILURE';

export {
  GET_CONFIG_INIT,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE,
  EDIT_CONFIG_INIT,
  EDIT_CONFIG_SUCCESS,
  EDIT_CONFIG_FAILURE,
};
