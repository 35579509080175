import React from 'react';
import { array, bool, element, number, object, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const Message = ({ children, danger, className }) => {
  const styles = useStyles();

  return (
    <Typography className={clsx(styles.root, danger ? styles.danger : styles.success, className)}>
      {children}
    </Typography>
  );
};

Message.propTypes = {
  children: oneOfType([string, number, array, object, element]).isRequired,
  danger: bool,
  className: string,
};

Message.defaultProps = {
  danger: false,
  className: '',
};

export default Message;
