import {
  GET_TRANSACTIONS_INIT,
  GET_TRANSACTIONS_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  GET_SINGLE_TRANSACTION_INIT,
  GET_SINGLE_TRANSACTION_SUCCESS,
  GET_SINGLE_TRANSACTION_FAILURE,
  ACCEPT_TRANSACTION_INIT,
  ACCEPT_TRANSACTION_SUCCESS,
  ACCEPT_TRANSACTION_FAILURE,
  EXTEND_TRANSACTION_INIT,
  EXTEND_TRANSACTION_SUCCESS,
  EXTEND_TRANSACTION_FAILURE,
} from '@store/action-types/transactions';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allTransactions: [],
  singleTransaction: {},
  status: FETCH_STATUS.IDLE,
  transactionError: undefined,
  error: undefined,
  queryParams: {},
};

const transactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_TRANSACTIONS_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        queryParams: {},
      };
    }
    case GET_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        transactionError: undefined,
        status: FETCH_STATUS.SUCCESS,
        allTransactions: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
      };
    }
    case GET_TRANSACTIONS_FAILURE: {
      return {
        ...state,
        allTransactions: [],
        transactionError: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_TRANSACTION_INIT: {
      return {
        ...state,
        error: undefined,
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        singleTransaction: payload.transaction,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_TRANSACTION_FAILURE: {
      return {
        ...state,
        error: payload.error,
        singleTransaction: {},
        status: FETCH_STATUS.FAILURE,
      };
    }
    case EXTEND_TRANSACTION_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: undefined,
      };
    }
    case EXTEND_TRANSACTION_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case EXTEND_TRANSACTION_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case ACCEPT_TRANSACTION_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        error: undefined,
      };
    }
    case ACCEPT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case ACCEPT_TRANSACTION_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default transactionReducer;
