import {
  GET_SINGLE_USER_INIT,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';

const getSingleUserInit = () => ({ type: GET_SINGLE_USER_INIT });
const getSingleUserSuccess = singleUser => ({ type: GET_SINGLE_USER_SUCCESS, payload: { singleUser } });
const getSingleUserFailure = errors => ({ type: GET_SINGLE_USER_FAILURE, payload: { errors } });

const getSingleUser = userId => async dispatch => {
  dispatch(getSingleUserInit());

  try {
    const singleUser = await client.getSingleUser({ slug: userId });
    dispatch(getSingleUserSuccess(singleUser.response));
  } catch (error) {
    dispatch(getSingleUserFailure(error));
  }
};

export default getSingleUser;
