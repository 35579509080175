import { GET_SLIDER_INIT, GET_SLIDER_SUCCESS, GET_SLIDER_FAILURE } from '@store/action-types/slider';
import client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getSliderInit = () => ({ type: GET_SLIDER_INIT });
const getSliderSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_SLIDER_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getSliderFailure = error => ({ type: GET_SLIDER_FAILURE, payload: { error } });

const getSlider = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getSliderInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const slider = await client.getSlider({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getSliderSuccess({
        response: slider.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getSliderFailure(error));
  }
};

export default getSlider;
