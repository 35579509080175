import React from 'react';
import { func, shape, string } from 'prop-types';
import { Button, Form, Grid } from 'semantic-ui-react';
import clsx from 'clsx';
import { VALID_IMAGE_MIME_TYPES } from '@screens/page/const';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const languages = ['pl', 'en'];
const languageFlags = {
  en: <i className="us flag" />,
  pl: <i className="pl flag" />,
};

const TranslatableRichTextField = ({
  label,
  name,
  onImageChange,
  imageUploadError,
  onRemoveImage,
  image,
  imageUrl,
}) => {
  const [t] = useTranslation(TRANSLATIONS.SLIDER);
  const styles = useStyles();

  return (
    <>
      {languages.map(language => {
        return (
          <Grid.Column key={`${name}-${language}`}>
            <Form.Field>
              <label aria-controls="" htmlFor="create-page-add-file">
                <p>
                  {languageFlags[language]} {label}
                </p>
                <input
                  id="create-page-add-file"
                  type="file"
                  onChange={e => onImageChange(e, { name, language })}
                  accept={VALID_IMAGE_MIME_TYPES.join(', ')}
                />
              </label>
            </Form.Field>
            {(image[language]?.base64 || imageUrl) && (
              <Grid container stackable columns={1} divided>
                <Grid.Column className="d-flex flex-column align-items-center justify-content-center">
                  <Form.Field className="d-flex flex-column align-items-center justify-content-center">
                    {imageUploadError && (
                      <p style={{ color: 'red' }} key={imageUploadError}>
                        {imageUploadError}
                      </p>
                    )}

                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>{t('imagePreview')}</label>
                    {!image[language]?.delete && (
                      <img
                        style={{ maxWidth: '100%' }}
                        className={clsx(styles.imagePreview, image[language]?.delete && styles.deletedPhoto)}
                        src={image[language]?.base64 || imageUrl[language]}
                        alt=""
                      />
                    )}
                  </Form.Field>
                  <Button className="mt-3" onClick={onRemoveImage}>
                    {image[language]?.delete ? t('actionButtons.undo') : t('actionButtons.remove')}
                  </Button>
                </Grid.Column>
              </Grid>
            )}
          </Grid.Column>
        );
      })}
    </>
  );
};

TranslatableRichTextField.propTypes = {
  label: string,
  name: string,
  onImageChange: func,
  imageUploadError: string,
  onRemoveImage: func,
  image: shape({}),
  imageUrl: string,
};

TranslatableRichTextField.defaultProps = {
  label: '',
  name: '',
  onImageChange: () => {},
  imageUploadError: '',
  onRemoveImage: () => {},
  image: {},
  imageUrl: '',
};

export default TranslatableRichTextField;
