import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#181c32',
    fontWeight: 600,
  },
}));

export default useStyles;
