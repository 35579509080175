import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getPagesStatus, getSinglePageErrors } from '@store/reducers/page/selectors';
import { createPageAction } from '@store/actions/page';
import Main from './Main';

const mapStateToProps = state => ({
  pageStatus: getPagesStatus(state),
  pageErrors: getSinglePageErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createPageAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
