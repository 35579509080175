import {
  GET_SPLASH_SCREEN_INIT,
  GET_SPLASH_SCREEN_SUCCESS,
  GET_SPLASH_SCREEN_FAILURE,
} from '@store/action-types/splash-screen';
import client from '@api-sdk/client';

const getSplashScreenInit = () => ({ type: GET_SPLASH_SCREEN_INIT });
const getSplashScreenSuccess = splashScreen => ({
  type: GET_SPLASH_SCREEN_SUCCESS,
  payload: { splashScreen },
});
const getSplashScreenFailure = error => ({ type: GET_SPLASH_SCREEN_FAILURE, payload: { error } });

const getSplashScreen = () => async dispatch => {
  dispatch(getSplashScreenInit());

  try {
    const splashScreen = await client.getSplashScreen();
    dispatch(getSplashScreenSuccess(splashScreen?.response));
  } catch (error) {
    dispatch(getSplashScreenFailure(error));
  }
};

export default getSplashScreen;
