import { DELETE_SLIDER_INIT, DELETE_SLIDER_SUCCESS, DELETE_SLIDER_FAILURE } from '@store/action-types/slider';
import client from '@api-sdk/client';
import getSlider from '@store/actions/slider/getSlider';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteSliderInit = () => ({ type: DELETE_SLIDER_INIT });
const deleteSliderSuccess = () => ({ type: DELETE_SLIDER_SUCCESS });
const deleteSliderFailure = error => {
  const mappedErrors = error?.errors?.map?.(err => err?.code?.split?.(':')?.pop() || err?.message);

  return { type: DELETE_SLIDER_FAILURE, payload: { error: mappedErrors } };
};

const deleteSlider = id => async dispatch => {
  dispatch(deleteSliderInit());

  try {
    await client.deleteSlider({ slug: id });
    dispatch(getSlider());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(deleteSliderSuccess(id));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'danger',
        },
      }),
    );
    return dispatch(deleteSliderFailure(error));
  }
};

export default deleteSlider;
