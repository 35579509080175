import client from '@api-sdk/client';
import {
  EDIT_EMAIL_TEMPLATE_INIT,
  EDIT_EMAIL_TEMPLATE_SUCCESS,
  EDIT_EMAIL_TEMPLATE_FAILURE,
} from '@store/action-types/email-templates';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const editTemplateInit = () => ({ type: EDIT_EMAIL_TEMPLATE_INIT });
const editTemplateSuccess = response => ({ type: EDIT_EMAIL_TEMPLATE_SUCCESS, payload: { response } });
const editTemplateFailure = error => ({ type: EDIT_EMAIL_TEMPLATE_FAILURE, payload: { error } });

const editTemplate = ({ templateId, name, subject, body, saveAndBack = false }) => async dispatch => {
  dispatch(editTemplateInit());

  try {
    const editTemplateResponse = await client.editTemplate({ slug: templateId, data: { name, subject, body } });

    if (saveAndBack) {
      history.push(ROUTES.EMAIL_TEMPLATES);
    }

    dispatch(editTemplateSuccess(editTemplateResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('emailTemplates.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(editTemplateFailure(error));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('emailTemplates.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default editTemplate;
