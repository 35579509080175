const removeItem = (array, id, callback) => {
  const settings = [...array];
  const filteredArray = settings?.filter((item, index) => index !== id);

  for (let i = id; i < filteredArray?.length; i += 1) {
    filteredArray[i].position -= 1;
  }

  callback({ array: filteredArray });
};

export default removeItem;
