import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  label: {
    display: 'flex !important',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  icon: {
    fontSize: 22,
  },
}));

export default useStyles;
