import React, { useEffect, useState } from 'react';
import { arrayOf, bool, func, shape } from 'prop-types';
import { Button, Form, Grid, Segment } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { InputField } from '@shared/components/translatable';
import { Container } from '@material-ui/core';

const PermissionForm = ({
  loading,
  isCreate,
  handleReloadPermission,
  handleAddPermission,
  handleSavePermission,
  permission,
  permissionsErrors,
  isViewMode,
}) => {
  const [permissionData, setPermissionData] = useState(permission);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.PERMISSIONS);
  const [error] = useTranslation(TRANSLATIONS.ERRORS);

  useEffect(() => {
    setPermissionData(permission);
  }, [permission]);

  useEffect(() => {
    if (!permission?.id) {
      setPermissionData({
        ...permission,
        id: params.id,
      });
    }
  }, [permission]);

  const onChange = (e, { name, value }) =>
    setPermissionData(prevState => ({
      ...prevState,
      [name]: value,
    }));

  const handleSave = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await handleSavePermission({ permissionData, saveAndBack });
    await setIsLoading(false);
  };

  const handleCreate = async ({ saveAndBack }) => {
    await setIsLoading(true);
    await handleAddPermission({ permissionData, saveAndBack });
    await setIsLoading(false);
  };

  const renderMenuButtons = () => (
    <Grid columns={2}>
      <Grid.Column>
        <h2>{isCreate ? t('addPermission') : t('editPermission')}</h2>
      </Grid.Column>
      <Grid.Column textAlign="right">
        <Button style={{ marginRight: '5px' }} as={Link} type="button" to={ROUTES.PERMISSION_MANAGEMENT}>
          {t('actionButtons.goBack')}
        </Button>

        {!isCreate && (
          <Button
            disabled={isLoading || loading}
            type="submit"
            secondary
            onClick={() => handleReloadPermission(permission?.id)}
            style={{ marginRight: '5px' }}
          >
            {t('actionButtons.reload')}
          </Button>
        )}

        {!isCreate && !isViewMode ? (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading || loading}
            variant="outlined"
            primary
            onClick={handleSave}
          >
            {t('actionButtons.save')}
          </Button>
        ) : null}

        {!isCreate && !isViewMode ? (
          <Button
            disabled={isLoading || loading}
            variant="outlined"
            color="facebook"
            onClick={() => handleSave({ saveAndBack: true })}
          >
            {t('actionButtons.saveAndBack')}
          </Button>
        ) : null}

        {isCreate && (
          <Button
            style={{ marginRight: '5px' }}
            disabled={isLoading || loading}
            variant="outlined"
            primary
            onClick={handleCreate}
          >
            {t('actionButtons.add')}
          </Button>
        )}

        {isCreate && (
          <Button
            disabled={isLoading || loading}
            variant="outlined"
            color="facebook"
            onClick={() => handleCreate({ saveAndBack: true })}
          >
            {t('actionButtons.addAndBack')}
          </Button>
        )}
      </Grid.Column>
    </Grid>
  );

  return (
    <Container maxWidth="md">
      {renderMenuButtons()}
      <Segment>
        <Form className="series-form" loading={loading || isLoading}>
          <Form.Group>
            <Grid container stackable divided>
              <InputField
                disabled={isViewMode}
                name="name"
                label={t('form.name')}
                icon="font"
                onChange={onChange}
                value={permissionData?.name}
                errorMessage={error(permissionsErrors?.name)}
              />
            </Grid>
          </Form.Group>
          <Form.Group>
            <Grid container stackable divided>
              <InputField
                disabled={isViewMode}
                multiline
                name="description"
                label={t('form.description')}
                onChange={onChange}
                value={permissionData?.description}
                rows={5}
                errorMessage={error(permissionsErrors?.description)}
              />
            </Grid>
          </Form.Group>
        </Form>
      </Segment>
      {renderMenuButtons()}
    </Container>
  );
};

PermissionForm.propTypes = {
  isCreate: bool,
  loading: bool,
  isViewMode: bool,
  handleReloadPermission: func,
  handleAddPermission: func,
  handleSavePermission: func,
  permission: shape({}),
  permissionsErrors: arrayOf(shape({})),
};

PermissionForm.defaultProps = {
  isCreate: false,
  loading: false,
  isViewMode: false,
  handleReloadPermission: () => {},
  handleAddPermission: () => {},
  handleSavePermission: () => {},
  permission: {},
  permissionsErrors: [],
};

export default PermissionForm;
