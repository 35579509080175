import React from 'react';
import { array, element, oneOf, oneOfType, string } from 'prop-types';
import { Container } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';

const Card = ({ children, width, className }) => {
  const styles = useStyles();

  return (
    <Container maxWidth={width} className={className}>
      <div className={clsx(styles.root, className)}>{children}</div>
    </Container>
  );
};

Card.propTypes = {
  width: oneOf(['lg', 'md', 'sm', 'xl', 'xs', '100%']),
  children: oneOfType([element, array, string]).isRequired,
  className: string,
};

Card.defaultProps = {
  width: 'lg',
  className: '',
};

export default Card;
