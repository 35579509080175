import React from 'react';
import { bool, func, number, oneOfType, shape, string } from 'prop-types';
import { Form } from 'semantic-ui-react';
import { FormHelperText, Grid } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useStyles from './styles';

const TranslatedTextField = ({
  xs,
  sm,
  md,
  lg,
  value,
  label,
  name,
  helperText,
  multiline,
  rows,
  rowsMax,
  onChange,
  onBlur,
  errors,
  disabled,
  editor,
  ...props
}) => {
  const styles = useStyles();

  const renderTextField = () => {
    return (
      <>
        <Grid className={styles.gridItem} item xs={xs} sm={sm} md={md} lg={lg}>
          <Form.Input
            value={value?.en || ''}
            name={name}
            label={`${label} - EN`}
            onChange={e => onChange(e, 'en')}
            disabled={disabled}
            {...props}
          />
        </Grid>
        <Grid className={styles.gridItem} item xs={xs} sm={sm} md={md} lg={lg}>
          <Form.Input
            variant="outlined"
            value={value?.pl || ''}
            name={name}
            label={`${label} - PL`}
            onChange={e => onChange(e, 'pl')}
            disabled={disabled}
            {...props}
          />
        </Grid>
      </>
    );
  };

  const renderCKEditor = () => {
    return (
      <>
        <Grid className={styles.gridItem} item xs={xs} sm={sm} md={md} lg={lg}>
          <Form.Field>
            <label className={styles.editorLabel}>{label} - EN</label>
            <CKEditor
              editor={ClassicEditor}
              data={value?.en || ''}
              onInit={e => {
                e.setData(value?.en);
              }}
              onChange={(e, editorData) => {
                const data = editorData.getData();
                onChange(data, 'en');
              }}
              onBlur={(e, editorData) => {
                const data = editorData.getData();
                onBlur(data, 'en');
              }}
              disabled={disabled}
            />
            {Boolean(errors?.[name]?.en) && (
              <FormHelperText className={styles.helperText}>{errors?.[name]?.en}</FormHelperText>
            )}
          </Form.Field>
        </Grid>
        <Grid className={styles.gridItem} item xs={xs} sm={sm} md={md} lg={lg}>
          <Form.Field>
            <label className={styles.editorLabel}>{label} - PL</label>
            <CKEditor
              editor={ClassicEditor}
              data={value?.pl || ''}
              onInit={e => {
                e.setData(value?.pl);
              }}
              onChange={(e, editorData) => {
                const data = editorData.getData();
                onChange(data, 'pl');
              }}
              onBlur={(e, editorData) => {
                const data = editorData.getData();
                onBlur(data, 'pl');
              }}
              disabled={disabled}
            />
            {Boolean(errors?.[name]?.pl) && (
              <FormHelperText className={styles.helperText}>{errors?.[name]?.pl}</FormHelperText>
            )}
          </Form.Field>
        </Grid>
      </>
    );
  };

  return editor ? renderCKEditor() : renderTextField();
};

TranslatedTextField.propTypes = {
  xs: oneOfType([number, bool]),
  sm: oneOfType([number, bool]),
  md: oneOfType([number, bool]),
  lg: oneOfType([number, bool]),
  multiline: bool,
  editor: bool,
  disabled: bool,
  rows: number,
  rowsMax: number,
  onChange: func,
  onBlur: func,
  label: string,
  name: string,
  helperText: string,
  value: shape({
    en: oneOfType([string, number]),
    pl: oneOfType([string, number]),
  }),
};

TranslatedTextField.defaultProps = {
  xs: 12,
  sm: false,
  md: false,
  lg: false,
  multiline: false,
  editor: false,
  disabled: false,
  rows: null,
  rowsMax: null,
  onChange: () => {},
  onBlur: () => {},
  label: '',
  name: '',
  helperText: '',
  value: {
    en: '',
    pl: '',
  },
};

export default TranslatedTextField;
