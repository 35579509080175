import { connect } from 'react-redux';
import { getPermissionsAction } from '@store/actions/permissions';
import {
  getPermissionStatus,
  getPermissionErrors,
  getFilteredPermissions,
} from '@store/reducers/permissions/selectors';
import { checkPermissionAction, setIsPermissionCheckedAction } from '@store/actions/check-permission';
import {
  getPermittedPermissions,
  getPermissionCheckStatus,
  getIsPermissionChecked,
} from '@store/reducers/check-permission/selectors';
import {
  getSingleUserAction,
  editUserAction,
  getPersonalPermissionsAction,
  addPersonalPermissionToUserAction,
  removePersonalPermissionFromUserAction,
  getUserRolesAction,
  addRoleToUserAction,
  removeRoleFromUserAction,
} from '@store/actions/user-management';
import { getUserAgreementsAction } from '@store/actions/agreements';
import { getRolesAction } from '@store/actions/roles';
import { getCountriesAction } from '@store/actions/investors';
import { getCountries, getCountriesStatus } from '@store/reducers/investors/selectors';
import {
  getErrors,
  getPersonalPermissionsErrors,
  getPersonalPermissionsStatus,
  getSingleUser,
  getStatus,
  getPersonalPermissions,
  getClickedPermissionsIds,
  getClickedRolesIds,
  getUserRoles,
  getUserRolesStatus,
  getFilteredRoles,
  getUserAgreements,
  getUserAgreementsError,
  getUserAgreementsStatus,
} from '@store/reducers/user-management/selectors';
import { bindActionCreators, compose } from 'redux';
import { getAllRoles, getAllRolesStatus } from '@store/reducers/roles/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  user: getSingleUser(state),
  status: getStatus(state),
  countries: getCountries(state),
  countriesStatus: getCountriesStatus(state),
  errors: getErrors(state),
  personalPermissions: getPersonalPermissions(state),
  personalPermissionsStatus: getPersonalPermissionsStatus(state),
  personalPermissionsErrors: getPersonalPermissionsErrors(state),
  allPermissions: getFilteredPermissions(state),
  allPermissionsStatus: getPermissionStatus(state),
  allPermissionsErrors: getPermissionErrors(state),
  clickedPermissionsIds: getClickedPermissionsIds(state),
  clickedRolesIds: getClickedRolesIds(state),
  allRoles: getAllRoles(state),
  allRolesStatus: getAllRolesStatus(state),
  userRoles: getUserRoles(state),
  userRolesStatus: getUserRolesStatus(state),
  filteredRoles: getFilteredRoles(state),
  userAgreements: getUserAgreements(state),
  userAgreementsError: getUserAgreementsError(state),
  userAgreementsStatus: getUserAgreementsStatus(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleUserAction,
      getCountriesAction,
      editUserAction,
      getPersonalPermissionsAction,
      getPermissionsAction,
      addPersonalPermissionToUserAction,
      removePersonalPermissionFromUserAction,
      getUserRolesAction,
      getRolesAction,
      addRoleToUserAction,
      removeRoleFromUserAction,
      getUserAgreementsAction,
      checkPermissionAction,
      setIsPermissionCheckedAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
