import React from 'react';
import { func, shape } from 'prop-types';
import { PositionSelect } from '@shared/components/OfferForm/components';
import { Button, TextField, Divider, FormControlLabel, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useMount from '@shared/utils/useMountHook';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const Timeline = ({
  handleAddArrayToData,
  handleAddElementInElement,
  setElementData,
  setElementDataWithArray,
  handleRemoveElementFromElement,
  arrayOfElementsSetPosition,
  elementValue,
}) => {
  const styles = useStyles();

  useMount(() => {
    isEmpty(elementValue) && handleAddArrayToData();
  });

  const handleAddNewRow = () => {
    const position = elementValue?.elements.length + 1;
    handleAddElementInElement(position);
  };

  const handleSetElementData = e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementData(key, value);
  };

  const handleSetDate = (date, timelineId) => {
    const key = 'date';
    setElementDataWithArray(timelineId, key, date);
  };

  const handleRemoveRow = timelineId => {
    handleRemoveElementFromElement(timelineId);
  };

  const handleSetColumnSettings = (e, timelineId) => {
    let key = e.target.name;
    let value = e.target.value;

    if (value === 'isCurrent') {
      key = e.target.value;
      value = e.target.checked;
      setElementDataWithArray(timelineId, key, value);
      return;
    }

    setElementDataWithArray(timelineId, key, value);
  };

  const handleCkeEditorChange = (timelineId, data) => {
    const key = 'readMore';

    setElementDataWithArray(timelineId, key, data);
  };

  const handleSelectChange = (e, timelineId) => {
    const newPosition = e.target.value;

    arrayOfElementsSetPosition(timelineId, newPosition);
  };

  const renderTimelineSettings = () => {
    return elementValue?.elements?.map?.((item, index) => {
      return (
        <React.Fragment key={`timeline-${index}`}>
          <Divider className="my-3" />
          <div className="d-flex flex-column">
            <div className={`${styles.topWrapper} my-2`}>
              <PositionSelect
                handleChange={e => handleSelectChange(e, index)}
                numberOfOptions={elementValue?.elements.length}
                id={index}
                className={styles.select}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  size="small"
                  variant="inline"
                  inputVariant="outlined"
                  format="dd/MM/yyyy"
                  margin="normal"
                  className={`${styles.datePicker} m-0`}
                  id={`date-picker-inline-${index}`}
                  label="Date"
                  onChange={date => handleSetDate(date, index)}
                  value={elementValue?.elements[index].date}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField
                className={styles.labelInput}
                size="small"
                label="Label"
                value={elementValue?.elements[index].label || ''}
                onChange={e => handleSetColumnSettings(e, index)}
                variant="outlined"
                name="label"
              />
              <FormControlLabel
                value="isCurrent"
                control={
                  <Checkbox
                    onChange={e => handleSetColumnSettings(e, index)}
                    checked={elementValue?.elements[index].isCurrent || false}
                    color="primary"
                  />
                }
                label="Current"
                labelPlacement="start"
              />
              {elementValue?.elements?.length > 1 && (
                <Button onClick={() => handleRemoveRow(index)} variant="outlined" className={styles.removeButton}>
                  remove
                </Button>
              )}
            </div>
            <div>
              <CKEditor
                editor={ClassicEditor}
                data={elementValue?.elements[index]?.readMore || ''}
                onChange={(e, editor) => {
                  const data = editor.getData();
                  handleCkeEditorChange(index, data);
                }}
              />
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <TextField
          variant="outlined"
          label="Timeline Label"
          size="small"
          name="label"
          value={elementValue?.label || ''}
          onChange={handleSetElementData}
        />
        <div>
          <Button variant="outlined" className="mr-1" onClick={handleAddNewRow}>
            add
          </Button>
        </div>
      </div>
      <div>{renderTimelineSettings()}</div>
    </div>
  );
};

Timeline.propTypes = {
  handleAddArrayToData: func.isRequired,
  handleAddElementInElement: func.isRequired,
  setElementData: func.isRequired,
  setElementDataWithArray: func.isRequired,
  handleRemoveElementFromElement: func.isRequired,
  arrayOfElementsSetPosition: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default Timeline;
