import React from 'react';
import { bool, func, oneOf, oneOfType, string } from 'prop-types';
import useStyles from './styles';

const Switch = ({ label, checked, onChange, position, className, name }) => {
  const styles = useStyles();
  const isFlexRow = position === 'flex-row';
  const isFlexRowReverse = position === 'flex-row-reverse';
  let labelMargin = 'mr-6';

  if (isFlexRowReverse) labelMargin = 'ml-6';
  if (!isFlexRowReverse && !isFlexRow) labelMargin = 'ml-0';

  return (
    <div className={`${styles.fitContent} ${className} d-flex ${position} justify-content-center text-nowrap`}>
      <label htmlFor="site-enabled-checkbox" className={`col-3 col-form-label ${labelMargin}`}>
        {label}
      </label>
      <div className="col-3">
        <span className={`${isFlexRow && styles.fixPosition} switch switch-success text-nowrap`}>
          <label>
            <input name={name} id="site-enabled-checkbox" type="checkbox" onChange={onChange} checked={checked} />
            <span />
          </label>
        </span>
      </div>
    </div>
  );
};

Switch.propTypes = {
  checked: oneOfType([string, bool]).isRequired,
  onChange: func.isRequired,
  label: string,
  name: string,
  className: string,
  position: oneOf(['flex-row', 'flex-column', 'flex-column-reverse', 'flex-row-reverse']),
};

Switch.defaultProps = {
  label: '',
  name: '',
  className: '',
  position: 'flex-row',
};

export default Switch;
