import { DELETE_PAGE_INIT, DELETE_PAGE_SUCCESS, DELETE_PAGE_FAILURE } from '@store/action-types/page';
import Client from '@api-sdk/client';
import getPages from '@store/actions/page/getPages';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deletePageInit = () => ({ type: DELETE_PAGE_INIT });
const deletePageSuccess = () => ({ type: DELETE_PAGE_SUCCESS });
const deletePageFailure = error => ({ type: DELETE_PAGE_FAILURE, payload: { error } });

const deletePage = id => async dispatch => {
  dispatch(deletePageInit());

  try {
    const pages = await Client.deletePage({ slug: id });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    await dispatch(deletePageSuccess(pages.response));
    dispatch(getPages());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('page.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );

    dispatch(deletePageFailure(error));
  }
};

export default deletePage;
