import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLoggedUser } from '@store/reducers/user/selectors';
import PrivateRouteWithLayout from './PrivateRouteWithLayout';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = state => ({
  user: getLoggedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteWithLayout);
