const GET_STATIC_CONTENT_INIT = '@static-content/GET_STATIC_CONTENT_INIT';
const GET_STATIC_CONTENT_SUCCESS = '@static-content/GET_STATIC_CONTENT_SUCCESS';
const GET_STATIC_CONTENT_FAILURE = '@static-content/GET_STATIC_CONTENT_FAILURE';

const GET_STATIC_CONTENT_PARENT_INIT = '@static-content/GET_STATIC_CONTENT_PARENT_INIT';
const GET_STATIC_CONTENT_PARENT_SUCCESS = '@static-content/GET_STATIC_CONTENT_PARENT_SUCCESS';
const GET_STATIC_CONTENT_PARENT_FAILURE = '@static-content/GET_STATIC_CONTENT_PARENT_FAILURE';

const GET_STATIC_CONTENT_ELEMENT_INIT = '@static-content/GET_STATIC_CONTENT_ELEMENT_INIT';
const GET_STATIC_CONTENT_ELEMENT_SUCCESS = '@static-content/GET_STATIC_CONTENT_ELEMENT_SUCCESS';
const GET_STATIC_CONTENT_ELEMENT_FAILURE = '@static-content/GET_STATIC_CONTENT_ELEMENT_FAILURE';

const EDIT_STATIC_CONTENT_INIT = '@static-content/EDIT_STATIC_CONTENT_INIT';
const EDIT_STATIC_CONTENT_SUCCESS = '@static-content/EDIT_STATIC_CONTENT_SUCCESS';
const EDIT_STATIC_CONTENT_FAILURE = '@static-content/EDIT_STATIC_CONTENT_FAILURE';

export {
  GET_STATIC_CONTENT_INIT,
  GET_STATIC_CONTENT_SUCCESS,
  GET_STATIC_CONTENT_FAILURE,
  GET_STATIC_CONTENT_PARENT_INIT,
  GET_STATIC_CONTENT_PARENT_SUCCESS,
  GET_STATIC_CONTENT_PARENT_FAILURE,
  GET_STATIC_CONTENT_ELEMENT_INIT,
  GET_STATIC_CONTENT_ELEMENT_SUCCESS,
  GET_STATIC_CONTENT_ELEMENT_FAILURE,
  EDIT_STATIC_CONTENT_INIT,
  EDIT_STATIC_CONTENT_SUCCESS,
  EDIT_STATIC_CONTENT_FAILURE,
};
