import {
  GET_ORDERS_INIT,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILURE,
  GET_SINGLE_ORDER_INIT,
  GET_SINGLE_ORDER_SUCCESS,
  GET_SINGLE_ORDER_FAILURE,
  EDIT_ORDER_INIT,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAILURE,
  ACCEPT_ORDER_INIT,
  ACCEPT_ORDER_SUCCESS,
  ACCEPT_ORDER_FAILURE,
  REJECT_ORDER_INIT,
  REJECT_ORDER_SUCCESS,
  REJECT_ORDER_FAILURE,
  CREATE_ORDER_INIT,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAILURE,
} from '@store/action-types/orders';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allOrders: [],
  allOrdersStatus: FETCH_STATUS.IDLE,
  allOrdersError: undefined,
  singleOrder: {},
  singleOrderStatus: FETCH_STATUS.IDLE,
  singleOrderError: undefined,
  singleOrderInputErrors: undefined,
  queryParams: {},
  createOrderErrors: {},
};

const investmentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ORDERS_INIT: {
      return {
        ...state,
        allOrdersStatus: FETCH_STATUS.LOADING,
        allOrdersError: undefined,
        queryParams: {},
      };
    }
    case GET_ORDERS_SUCCESS: {
      return {
        ...state,
        allOrders: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        allOrdersError: undefined,
        allOrdersStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_ORDERS_FAILURE: {
      return {
        ...state,
        allOrdersError: payload.error,
        allOrdersStatus: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_ORDER_INIT: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.LOADING,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case GET_SINGLE_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrder: payload.order,
        singleOrderStatus: FETCH_STATUS.SUCCESS,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case GET_SINGLE_ORDER_FAILURE: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.FAILURE,
        singleOrderError: payload.error,
      };
    }
    case EDIT_ORDER_INIT: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.LOADING,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case EDIT_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrder: payload.order,
        singleOrderStatus: FETCH_STATUS.SUCCESS,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case EDIT_ORDER_FAILURE: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.FAILURE,
        singleOrderError: payload.error,
        singleOrderInputErrors: payload.errors,
      };
    }
    case ACCEPT_ORDER_INIT: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.LOADING,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case ACCEPT_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrder: payload.order,
        singleOrderStatus: FETCH_STATUS.SUCCESS,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case ACCEPT_ORDER_FAILURE: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.FAILURE,
        singleOrderError: payload.error,
      };
    }
    case REJECT_ORDER_INIT: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.LOADING,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case REJECT_ORDER_SUCCESS: {
      return {
        ...state,
        singleOrder: payload.order,
        singleOrderStatus: FETCH_STATUS.SUCCESS,
        singleOrderError: undefined,
        singleOrderInputErrors: undefined,
      };
    }
    case REJECT_ORDER_FAILURE: {
      return {
        ...state,
        singleOrderStatus: FETCH_STATUS.FAILURE,
        singleOrderError: payload.error,
      };
    }
    case CREATE_ORDER_INIT:
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        createOrderErrors: undefined,
      };
    }
    case CREATE_ORDER_FAILURE: {
      return {
        ...state,
        createOrderErrors: payload?.error,
      };
    }
    default:
      return state;
  }
};

export default investmentsReducer;
