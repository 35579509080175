import {
  USER_LOGIN_INIT,
  REFRESH_TOKEN_INIT,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  SET_MAINTENANCE_MODE,
} from '@store/action-types/user';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = { status: FETCH_STATUS.IDLE };

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_INIT: {
      return { status: FETCH_STATUS.LOADING };
    }
    case REFRESH_TOKEN_INIT: {
      return {
        status: REFRESH_TOKEN_INIT,
      };
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        status: REFRESH_TOKEN_SUCCESS,
      };
    }
    case REFRESH_TOKEN_FAILURE: {
      return {
        status: REFRESH_TOKEN_FAILURE,
      };
    }
    case SET_MAINTENANCE_MODE: {
      return {
        ...state,
        maintenance: {
          ...state?.maintenance,
          isMaintenanceMode: true,
          config: action?.payload?.config,
        },
      };
    }

    default:
      return { ...state, status: FETCH_STATUS.IDLE };
  }
};

export default globalReducer;
