import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { TranslatableRichTextField } from '@screens/offers/NewOfferForm/components';

const RichText = ({ elementData, setElementData, isLoading, label }) => {
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  const handleRichTextFieldChange = (e, { value, language }) =>
    setElementData(prevState => ({
      ...prevState,
      content: {
        ...prevState?.content,
        [language]: value,
      },
    }));

  return (
    <Segment>
      <Form loading={isLoading}>
        <Form.Group>
          <Grid container stackable columns={1}>
            <TranslatableRichTextField
              label={t(label)}
              value={elementData?.content}
              onChange={handleRichTextFieldChange}
            />
          </Grid>
        </Form.Group>
      </Form>
    </Segment>
  );
};

RichText.propTypes = {
  elementData: shape({}),
  setElementData: func,
  isLoading: bool,
  isViewMode: bool,
  label: string,
};

RichText.defaultProps = {
  elementData: {},
  setElementData: () => {},
  isLoading: false,
  isViewMode: false,
  label: '',
};

export default RichText;
