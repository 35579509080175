import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardToolbar,
  NotFoundMessage,
  Table,
  Tooltip,
} from '@shared/components';
import ROUTES from '@shared/consts/routes';
import { arrayOf, func, shape, string } from 'prop-types';
import useMount from '@shared/utils/useMountHook';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  status,
  allUsers,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.USERS);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const refreshPage = () => actions.getUsersAction();

  useMount(() => {
    actions.getUsersAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.USERS]) {
      if (
        !isUserPermitted(PERMISSIONS.USER_CREATE) ||
        !isUserPermitted(PERMISSIONS.USER_DELETE) ||
        !isUserPermitted(PERMISSIONS.USER_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_USER_BY_ID) ||
        !isUserPermitted(PERMISSIONS.GET_PERSONAL_PERMISSIONS) ||
        !isUserPermitted(PERMISSIONS.GET_USER_ROLES) ||
        !isUserPermitted(PERMISSIONS.GET_USER_LIST) ||
        !isUserPermitted(PERMISSIONS.GET_USER_AGREEMENTS) ||
        !isUserPermitted(PERMISSIONS.ADD_PERSONAL_PERMISSION_TO_USER)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.USERS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.USER_CREATE) && PERMISSIONS.USER_CREATE,
          !isUserPermitted(PERMISSIONS.USER_DELETE) && PERMISSIONS.USER_DELETE,
          !isUserPermitted(PERMISSIONS.USER_EDIT) && PERMISSIONS.USER_EDIT,
          !isUserPermitted(PERMISSIONS.GET_USER_BY_ID) && PERMISSIONS.GET_USER_BY_ID,
          !isUserPermitted(PERMISSIONS.GET_PERSONAL_PERMISSIONS) && PERMISSIONS.GET_PERSONAL_PERMISSIONS,
          !isUserPermitted(PERMISSIONS.GET_USER_ROLES) && PERMISSIONS.GET_USER_ROLES,
          !isUserPermitted(PERMISSIONS.GET_USER_LIST) && PERMISSIONS.GET_USER_LIST,
          !isUserPermitted(PERMISSIONS.GET_USER_AGREEMENTS) && PERMISSIONS.GET_USER_AGREEMENTS,
          !isUserPermitted(PERMISSIONS.ADD_PERSONAL_PERMISSION_TO_USER) && PERMISSIONS.ADD_PERSONAL_PERMISSION_TO_USER,
        ]);
      }
    }
  }, [permittedPermissions]);

  const columns = useMemo(
    () => [
      {
        Header: t('table.email'),
        accessor: 'email',
        columnName: 'email',
        columnOrder: 'email',
        Cell: ({ row }) => {
          const isActive = row?.original?.active;

          return (
            <div className="d-flex align-items-center">
              <Tooltip
                placement="top"
                name={isActive ? 'check circle outline' : 'times circle outline'}
                tooltip={isActive ? t('tooltip.userActive') : t('tooltip.userInactive')}
                color={isActive ? 'green' : 'red'}
              />
              {row?.original?.email}
            </div>
          );
        },
      },
      {
        Header: t('table.firstName'),
        accessor: 'firstName',
        columnName: 'firstName',
        columnOrder: 'firstName',
      },
      {
        Header: t('table.lastName'),
        accessor: 'lastName',
        columnName: 'lastName',
        columnOrder: 'lastName',
      },
      {
        Header: t('table.createdAt'),
        accessor: 'createdAt',
        columnName: 'createdAt',
        columnOrder: 'createdAt',
      },
      {
        Header: t('table.userType'),
        accessor: 'userType',
        className: 'text-center',
        Cell: ({ row }) => <div>{t(row?.original?.userType)}</div>,
      },
      {
        Header: t('table.isAdmin'),
        accessor: 'isAdmin',
        columnName: 'isAdmin',
        className: 'text-center',
        Cell: ({ row }) => (row?.original?.isAdmin ? t('table.yes') : t('table.no')),
      },
      {
        Header: t('table.isSuperAdmin'),
        accessor: 'isSuperAdmin',
        columnName: 'isSuperAdmin',
        className: 'text-center',
        Cell: ({ row }) => (row?.original?.isSuperAdmin ? t('table.yes') : t('table.no')),
      },
      {
        accessor: 'edit',
        className: 'text-right',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_USER_BY_ID) ? (
            <Button as={Link} to={`/users/${row.original.id}`}>
              {t('actionButtons.edit')}
            </Button>
          ) : null,
      },
      {
        accessor: 'remove',
        className: 'text-right',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.USER_DELETE) ? (
            <Button
              color="red"
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals,no-alert
                if (confirm(`${t('deleteUserDisclaimer')} ${row.original.email}?`)) {
                  actions.deleteUserAction(row.original.id);
                }
              }}
            >
              {t('actionButtons.remove')}
            </Button>
          ) : null,
      },
    ],
    [t],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={status === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allUsers}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getUsersAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundUsers')}
          subtitle={t('clickBelow')}
          buttonText={t('actionButtons.add')}
          to={ROUTES.USER_CREATE}
        />
      }
    />
  );

  return (
    <Card width="100%">
      <CardHeader>
        <CardTitle title={t('mainTitle')} />
        <CardToolbar>
          <Button disabled={status === FETCH_STATUS.LOADING} color="black" onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.USER_CREATE) ? (
            <Button as={Link} primary to={ROUTES.USER_CREATE}>
              {t('actionButtons.add')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({ getUsersAction: func }).isRequired,
  row: shape({}),
  allUsers: arrayOf(shape({})),
  status: string,
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  allUsers: [],
  row: {},
  status: undefined,
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
