const getAllRoles = state => state?.staffManagement?.roles?.allRoles;
const getAllRolesStatus = state => state?.staffManagement?.roles?.allRolesStatus;
const getAllRolesError = state => state?.staffManagement?.roles?.allRolesError;

const getSingleRole = state => state?.staffManagement?.roles?.role;
const getSingleRoleError = state => state?.staffManagement?.roles?.roleError;
const getSingleRoleStatus = state => state?.staffManagement?.roles?.roleStatus;

const getRolePermissions = state => state?.staffManagement?.roles?.rolePermissions?.permissions;
const getRolePermissionsError = state => state?.staffManagement?.roles?.rolePermissions?.permissionError;
const getRolePermissionsStatus = state => state?.staffManagement?.roles?.rolePermissions?.permissionStatus;
const getClickedPermissionIds = state => state?.staffManagement?.roles?.rolePermissions?.clickedPermissionIds;

const getRoleUsers = state => state?.staffManagement?.roles?.roleUsers?.users;
const getRoleUsersError = state => state?.staffManagement?.roles?.roleUsers?.userError;
const getRoleUsersStatus = state => state?.staffManagement?.roles?.roleUsers?.userStatus;
const getClickedUsersIds = state => state?.staffManagement?.roles?.roleUsers?.clickedUsersIds;

const getFilteredPermissions = state => {
  return state?.staffManagement?.permission?.allPermissions?.reduce?.((acc, permission) => {
    const assignedPermission = state?.staffManagement?.roles?.rolePermissions?.permissions?.find(
      perm => perm.id === permission.id,
    );

    if (assignedPermission) {
      return acc;
    }
    return acc.concat([permission]);
  }, []);
};

const getFilteredUsers = state => {
  return state?.userManagement?.allUsers?.reduce?.((acc, user) => {
    const assignedPermission = state?.staffManagement?.roles?.roleUsers?.users?.find(u => u.id === user.id);

    if (assignedPermission) {
      return acc;
    }
    return acc.concat([user]);
  }, []);
};

export {
  getAllRoles,
  getAllRolesError,
  getAllRolesStatus,
  getSingleRole,
  getSingleRoleError,
  getSingleRoleStatus,
  getRolePermissions,
  getRolePermissionsStatus,
  getRolePermissionsError,
  getRoleUsers,
  getRoleUsersError,
  getRoleUsersStatus,
  getFilteredPermissions,
  getClickedPermissionIds,
  getClickedUsersIds,
  getFilteredUsers,
};
