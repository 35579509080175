import { makeStyles } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';

const useStyles = makeStyles({
  wrapper: {
    color: indigo[300],
  },
  backButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default useStyles;
