const transformedConfigData = configData => ({
  ...configData,
  socialMediaIcons: configData?.socialMediaIcons?.map?.(item => ({
    isEnabled: item?.isEnabled,
    url: item?.url,
    position: item?.position,
  })),
});

export { transformedConfigData };
