import { GET_FAQ_INIT, GET_FAQ_FAILURE, GET_FAQ_SUCCESS } from '@store/action-types/faq';
import Client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getFaqInit = () => ({ type: GET_FAQ_INIT });
const getFaqSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_FAQ_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getFaqFailure = error => ({ type: GET_FAQ_FAILURE, payload: { error } });

const getFaq = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getFaqInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const faq = await Client.getFaq({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getFaqSuccess({
        response: faq.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getFaqFailure(error));
  }
};

export default getFaq;
