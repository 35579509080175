import {
  GET_INVESTMENTS_INIT,
  GET_INVESTMENTS_SUCCESS,
  GET_INVESTMENTS_FAILURE,
  GET_SINGLE_INVESTMENT_INIT,
  GET_SINGLE_INVESTMENT_SUCCESS,
  GET_SINGLE_INVESTMENT_FAILURE,
} from '@store/action-types/investments';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allInvestments: [],
  allInvestmentsStatus: FETCH_STATUS.IDLE,
  allInvestmentsError: undefined,
  singleInvestment: {},
  singleInvestmentStatus: FETCH_STATUS.IDLE,
  singleInvestmentError: undefined,
  queryParams: {},
};

const investmentsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INVESTMENTS_INIT: {
      return {
        ...state,
        allInvestmentsStatus: FETCH_STATUS.LOADING,
        allInvestmentsError: undefined,
      };
    }
    case GET_INVESTMENTS_SUCCESS: {
      return {
        ...state,
        allInvestments: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
        },
        allInvestmentsError: undefined,
        allInvestmentsStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_INVESTMENTS_FAILURE: {
      return {
        ...state,
        allInvestmentsError: payload.error,
        allInvestmentsStatus: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_INVESTMENT_INIT: {
      return {
        ...state,
        singleInvestmentStatus: FETCH_STATUS.LOADING,
        singleInvestmentError: undefined,
      };
    }
    case GET_SINGLE_INVESTMENT_SUCCESS: {
      return {
        ...state,
        singleInvestment: payload.investment,
        singleInvestmentStatus: FETCH_STATUS.SUCCESS,
        singleInvestmentError: undefined,
      };
    }
    case GET_SINGLE_INVESTMENT_FAILURE: {
      return {
        ...state,
        singleInvestmentStatus: FETCH_STATUS.FAILURE,
        singleInvestmentError: payload.error,
      };
    }
    default:
      return state;
  }
};

export default investmentsReducer;
