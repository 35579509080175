import { EDIT_SLIDER_INIT, EDIT_SLIDER_SUCCESS, EDIT_SLIDER_FAILURE } from '@store/action-types/slider';
import Client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import { isEmpty, set, toPath } from 'lodash';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const editSliderInit = () => ({ type: EDIT_SLIDER_INIT });
const editSliderSuccess = singleSlider => ({ type: EDIT_SLIDER_SUCCESS, payload: { singleSlider } });
const editSliderFailure = (error, sliderData, imageData) => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context).filter(
      item => item !== 'children' && item !== 'data' && item !== 'pl' && item !== 'en',
    );

    set(acc, path, item.code?.split(':')?.pop() || item.message);

    return acc;
  }, {});

  if (isEmpty(sliderData?.guestButtonLabel?.pl) || isEmpty(sliderData?.guestButtonLabel?.en)) {
    mappedErrors.guestButtonLabel = 'GUEST_BUTTON_LABEL_IS_EMPTY';
  }

  if (isEmpty(imageData?.image?.base64)) {
    mappedErrors.image = 'IMAGE_IS_REQUIRED';
  }

  return { type: EDIT_SLIDER_FAILURE, payload: { error: mappedErrors } };
};

const transformedSliderDataResponse = ({ sliderData, imageData }) => ({
  position: sliderData?.position,
  active: sliderData?.active,
  name: sliderData?.name,
  content: sliderData?.content,
  guestButtonLabel: sliderData?.guestButtonLabel,
  guestButtonUrl: sliderData?.guestButtonUrl,
  loggedUserButtonLabel: sliderData?.loggedUserButtonLabel,
  loggedUserButtonUrl: sliderData?.loggedUserButtonUrl,
  image: imageData,
});

const editSlider = ({ sliderData, imageData, sliderId, saveAndBack = false }) => async dispatch => {
  dispatch(editSliderInit());

  try {
    const sliderResponse = await Client.editSlider({
      slug: sliderId,
      data: transformedSliderDataResponse({ sliderData, imageData }),
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editSliderSuccess(sliderResponse.response));

    if (saveAndBack) {
      history.push(ROUTES.SLIDER_MANAGEMENT);
    }
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('slider.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editSliderFailure(error, sliderData, imageData));
  }
};

export default editSlider;
