import { GET_SINGLE_OFFER_INIT, GET_SINGLE_OFFER_SUCCESS, GET_SINGLE_OFFER_FAILURE } from '@store/action-types/offers';
import client from '@api-sdk/client';
import mapSingleOfferResponse from '../utils/responseMapper';

const getSingleOfferInit = () => ({ type: GET_SINGLE_OFFER_INIT });
const getSingleOfferSuccess = singleOffer => ({
  type: GET_SINGLE_OFFER_SUCCESS,
  payload: { singleOffer },
});
const getSingleOfferFailure = error => ({ type: GET_SINGLE_OFFER_FAILURE, payload: { error } });

const getSingleOffer = id => async dispatch => {
  dispatch(getSingleOfferInit());

  try {
    const singleOffer = await client.getSingleOffer({ slug: id });
    dispatch(getSingleOfferSuccess({ ...singleOffer, response: mapSingleOfferResponse(singleOffer.response) }));
  } catch (error) {
    dispatch(getSingleOfferFailure(error));
  }
};

export default getSingleOffer;
