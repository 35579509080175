import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { PositionSelect } from '@shared/components/OfferForm/components';
import { Button, IconButton, TextField } from '@material-ui/core';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import { RemoveCircle } from '@material-ui/icons';
import useMount from '@shared/utils/useMountHook';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const PieChart = ({
  setElementData,
  handleAddArrayToData,
  handleRemoveElementFromElement,
  handleAddElementInElement,
  arrayOfElementsSetPosition,
  setElementDataWithArray,
  elementValue,
}) => {
  const styles = useStyles();
  const [showColorPicker, setShowColorPicker] = useState([false]);

  useMount(() => {
    isEmpty(elementValue) && handleAddArrayToData();
  });

  const handleAddNewElement = () => {
    setShowColorPicker([...showColorPicker, false]);
    const position = elementValue?.elements.length + 1;
    handleAddElementInElement(position);
  };

  const handleSetElementData = e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementData(key, value);
  };

  const handleRemove = id => {
    if (showColorPicker[id]) {
      const hidePicker = [...showColorPicker];
      hidePicker[id] = false;
    }

    const removeShowColorPicker = showColorPicker?.filter((item, index) => index !== id);
    setShowColorPicker([...removeShowColorPicker]);

    handleRemoveElementFromElement(id);
  };

  const handleChange = (e, index) => {
    const key = e.target.name;
    const value = e.target.value;

    setElementDataWithArray(index, key, value);
  };

  const handleOpen = columnId => {
    const arr = [...showColorPicker];
    arr[columnId] = true;
    setShowColorPicker([...arr]);
  };

  const handleColorChange = (color, index) => {
    const key = 'color';
    const value = color.hex;

    setElementDataWithArray(index, key, value);
  };

  const handleSelectChange = (e, index) => {
    const newIndex = Number(e.target.value);

    arrayOfElementsSetPosition(index, newIndex);
  };

  const renderColumnRow = () => {
    return (
      <ul>
        {elementValue?.elements?.map?.((item, index) => {
          return (
            <li className="input-group my-2" key={index}>
              <PositionSelect
                handleChange={e => handleSelectChange(e, index)}
                numberOfOptions={elementValue?.elements?.length}
                id={index}
                className={styles.changePositionButton}
              />
              <input
                key={index}
                type="text"
                name="label"
                onChange={e => handleChange(e, index)}
                value={elementValue?.elements[index].label || ''}
                className="form-control"
                placeholder="Column label"
              />
              <input
                type="number"
                name="value"
                onChange={e => handleChange(e, index)}
                value={elementValue?.elements[index].value || ''}
                className="form-control"
                placeholder="Column value"
              />
              <div>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  key={`overlay-${index}`}
                  placement="bottom"
                  overlay={
                    <Popover id={`popover-${index}`}>
                      <SketchPicker
                        disableAlpha
                        color={elementValue?.elements[index].color || ''}
                        onChange={color => handleColorChange(color, index)}
                        presetColors={['#CDCBFF', '#9E87F8', '#615FCF']}
                      />
                    </Popover>
                  }
                >
                  <Button
                    style={{ background: elementValue?.elements[index].color }}
                    className={styles.pickColorButton}
                    variant="outlined"
                    onClick={() => handleOpen(index)}
                  >
                    Color
                  </Button>
                </OverlayTrigger>
                <IconButton onClick={() => handleRemove(index)} style={{ marginLeft: 10 }} size="small">
                  <RemoveCircle />
                </IconButton>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <div className="d-flex">
        <TextField
          size="small"
          variant="outlined"
          name="title"
          label="Title"
          value={elementValue?.title || ''}
          onChange={handleSetElementData}
        />
        <Button variant="outlined" onClick={() => handleAddNewElement()}>
          Add new
        </Button>
      </div>
      <div>{renderColumnRow()}</div>
    </div>
  );
};

PieChart.propTypes = {
  handleAddArrayToData: func.isRequired,
  handleRemoveElementFromElement: func.isRequired,
  handleAddElementInElement: func.isRequired,
  arrayOfElementsSetPosition: func.isRequired,
  setElementData: func.isRequired,
  setElementDataWithArray: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default PieChart;
