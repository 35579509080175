const changePosition = (arr, oldIndex, newIndex, callback) => {
  if (oldIndex === newIndex) return;

  const newArray = [...arr];
  const temp = newArray[oldIndex];
  const tempPosition = newArray[oldIndex].position;

  newArray[oldIndex].position = newArray[newIndex].position;
  newArray[newIndex].position = tempPosition;

  newArray[oldIndex] = newArray[newIndex];
  newArray[newIndex] = temp;

  callback({ newArray });
};

export default changePosition;
