import React from 'react';
import { string } from 'prop-types';
import clsx from 'clsx';

const CardTitle = ({ title, subtitle }) => {
  return (
    <h3 className={clsx('align-items-start flex-column m-0', subtitle && 'd-flex')}>
      <span className="card-label font-weight-bolder text-dark">{title}</span>
      {subtitle && <span className="text-muted mt-3 font-weight-bold font-size-sm">{subtitle}</span>}
    </h3>
  );
};

CardTitle.propTypes = {
  title: string.isRequired,
  subtitle: string,
};

CardTitle.defaultProps = {
  subtitle: undefined,
};

export default CardTitle;
