import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getCountriesAction } from '@store/actions/investors';
import { createUserAction } from '@store/actions/user-management';
import { getErrors } from '@store/reducers/user-management/selectors';
import { getCountries, getCountriesStatus } from '@store/reducers/investors/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  countries: getCountries(state),
  countriesStatus: getCountriesStatus(state),
  errors: getErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getCountriesAction,
      createUserAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
