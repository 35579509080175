import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import SliderForm from '@screens/slider/components/SliderForm';
import { SliderModel } from '@screens/slider/models';

const Main = ({ actions, errors }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onCreateSlider = async ({ sliderData, imageData, saveAndBack }) => {
    await setIsLoading(true);
    await actions.createSliderAction({ sliderData, imageData, saveAndBack });
    await setIsLoading(false);
  };

  return (
    <SliderForm
      sliderErrors={errors}
      isLoading={isLoading}
      onCreateSlider={onCreateSlider}
      isCreateMode
      slider={SliderModel}
    />
  );
};

Main.propTypes = {
  actions: shape({ createSliderAction: func }),
  errors: shape({}),
};

Main.defaultProps = {
  actions: {},
  errors: {},
};

export default Main;
