import {
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  GET_SINGLE_ROLE_INIT,
  GET_SINGLE_ROLE_FAILURE,
  GET_SINGLE_ROLE_SUCCESS,
  CREATE_ROLE_INIT,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_FAILURE,
  EDIT_ROLE_INIT,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  DELETE_ROLE_INIT,
  DELETE_ROLES_SUCCESS,
  DELETE_ROLES_FAILURE,
  GET_ROLE_PERMISSIONS_INIT,
  GET_ROLE_PERMISSIONS_SUCCESS,
  GET_ROLE_PERMISSIONS_FAILURE,
  ADD_PERMISSION_TO_ROLE_INIT,
  ADD_PERMISSION_TO_ROLE_SUCCESS,
  ADD_PERMISSION_TO_ROLE_FAILURE,
  REMOVE_PERMISSION_FROM_ROLE_INIT,
  REMOVE_PERMISSION_FROM_ROLE_SUCCESS,
  REMOVE_PERMISSION_FROM_ROLE_FAILURE,
  GET_ROLE_USERS_INIT,
  GET_ROLE_USERS_SUCCESS,
  GET_ROLE_USERS_FAILURE,
  ADD_USER_TO_ROLE_INIT,
  ADD_USER_TO_ROLE_SUCCESS,
  ADD_USER_TO_ROLE_FAILURE,
  REMOVE_USER_FROM_ROLE_INIT,
  REMOVE_USER_FROM_ROLE_SUCCESS,
  REMOVE_USER_FROM_ROLE_FAILURE,
} from '@store/action-types/roles';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allRoles: [],
  allRolesError: undefined,
  allRolesStatus: FETCH_STATUS.IDLE,
  role: {},
  roleError: undefined,
  roleStatus: FETCH_STATUS.IDLE,
  rolePermissions: {
    permissions: [],
    clickedPermissionIds: [],
    permissionError: undefined,
    permissionStatus: FETCH_STATUS.IDLE,
  },
  roleUsers: {
    users: [],
    clickedUsersIds: [],
    userError: undefined,
    userStatus: FETCH_STATUS.IDLE,
  },
};

const roleReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ROLES_INIT: {
      return {
        ...state,
        allRolesStatus: FETCH_STATUS.LOADING,
        allRolesError: undefined,
      };
    }
    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        allRoles: payload.allRoles,
        allRolesStatus: FETCH_STATUS.SUCCESS,
        allRolesError: undefined,
      };
    }
    case GET_ROLES_FAILURE: {
      return {
        ...state,
        allRolesStatus: FETCH_STATUS.FAILURE,
        allRolesError: payload.error,
      };
    }

    case GET_SINGLE_ROLE_INIT: {
      return {
        ...state,
        roleError: undefined,
        roleStatus: FETCH_STATUS.LOADING,
      };
    }
    case GET_SINGLE_ROLE_SUCCESS: {
      return {
        ...state,
        role: payload.role,
        roleError: undefined,
        roleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_ROLE_FAILURE: {
      return { ...state, roleError: payload.error, roleStatus: FETCH_STATUS.FAILURE };
    }

    case CREATE_ROLE_INIT: {
      return {
        ...state,
        roleError: undefined,
        roleStatus: FETCH_STATUS.LOADING,
      };
    }
    case CREATE_ROLE_SUCCESS: {
      return {
        ...state,
        role: payload.role,
        roleError: undefined,
        roleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case CREATE_ROLE_FAILURE: {
      return {
        ...state,
        roleError: payload.error,
        roleStatus: FETCH_STATUS.FAILURE,
      };
    }

    case EDIT_ROLE_INIT: {
      return {
        ...state,
        roleError: undefined,
        roleStatus: FETCH_STATUS.LOADING,
      };
    }
    case EDIT_ROLE_SUCCESS: {
      return {
        ...state,
        role: payload.role,
        roleError: undefined,
        roleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_ROLE_FAILURE: {
      return {
        ...state,
        roleError: payload.error,
        roleStatus: FETCH_STATUS.FAILURE,
      };
    }

    case DELETE_ROLE_INIT: {
      return {
        ...state,
        roleError: undefined,
        roleStatus: FETCH_STATUS.LOADING,
      };
    }
    case DELETE_ROLES_SUCCESS: {
      return {
        ...state,
        role: payload.role,
        roleStatus: FETCH_STATUS.SUCCESS,
        roleError: undefined,
      };
    }
    case DELETE_ROLES_FAILURE: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.FAILURE,
        roleError: payload.error,
      };
    }

    case GET_ROLE_PERMISSIONS_INIT: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.LOADING,
        rolePermissions: {
          ...state.rolePermissions,
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case GET_ROLE_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.SUCCESS,
        rolePermissions: {
          ...state.rolePermissions,
          permissions: payload.response,
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case GET_ROLE_PERMISSIONS_FAILURE: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.FAILURE,
        rolePermissions: {
          ...state.rolePermissions,
          permissionError: payload.error,
          permissionStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    case ADD_PERMISSION_TO_ROLE_INIT: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: state.rolePermissions?.clickedPermissionIds?.concat?.([payload?.clickedPermissionId]),
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case ADD_PERMISSION_TO_ROLE_SUCCESS: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: [],
          permissions: Object.values(payload.response),
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case ADD_PERMISSION_TO_ROLE_FAILURE: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: [],
          permissionError: payload.error,
          permissionStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    case REMOVE_PERMISSION_FROM_ROLE_INIT: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: state.rolePermissions?.clickedPermissionIds?.concat?.([payload?.clickedPermissionId]),
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case REMOVE_PERMISSION_FROM_ROLE_SUCCESS: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: [],
          permissions: Object.values(payload.response),
          permissionError: undefined,
          permissionStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case REMOVE_PERMISSION_FROM_ROLE_FAILURE: {
      return {
        ...state,
        rolePermissions: {
          ...state.rolePermissions,
          clickedPermissionIds: [],
          permissionError: payload.error,
          permissionStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    case GET_ROLE_USERS_INIT: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.LOADING,
        roleUsers: {
          ...state.roleUsers,
          userError: undefined,
          userStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case GET_ROLE_USERS_SUCCESS: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.SUCCESS,
        roleUsers: {
          ...state.roleUsers,
          users: payload.response,
          userError: undefined,
          userStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case GET_ROLE_USERS_FAILURE: {
      return {
        ...state,
        roleStatus: FETCH_STATUS.FAILURE,
        roleUsers: {
          ...state.roleUsers,
          userError: payload.error,
          userStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    case ADD_USER_TO_ROLE_INIT: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: state.roleUsers?.clickedUsersIds?.concat?.([payload?.clickedUserId]),
          userError: undefined,
          userStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case ADD_USER_TO_ROLE_SUCCESS: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: [],
          users: Object.values(payload.response),
          userError: undefined,
          userStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case ADD_USER_TO_ROLE_FAILURE: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: [],
          userError: payload.error,
          userStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    case REMOVE_USER_FROM_ROLE_INIT: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: state.roleUsers?.clickedUsersIds?.concat?.([payload?.clickedUserId]),
          userError: undefined,
          userStatus: FETCH_STATUS.LOADING,
        },
      };
    }
    case REMOVE_USER_FROM_ROLE_SUCCESS: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: [],
          users: Object.values(payload.response),
          userError: undefined,
          userStatus: FETCH_STATUS.SUCCESS,
        },
      };
    }
    case REMOVE_USER_FROM_ROLE_FAILURE: {
      return {
        ...state,
        roleUsers: {
          ...state.roleUsers,
          clickedUsersIds: [],
          userError: payload.error,
          userStatus: FETCH_STATUS.FAILURE,
        },
      };
    }

    default:
      return state;
  }
};

export default roleReducer;
