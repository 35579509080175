import {
  GET_EMAIL_TEMPLATES_INIT,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAILURE,
  GET_EMAIL_TEMPLATES_CONFIG_INIT,
  GET_EMAIL_TEMPLATES_CONFIG_SUCCESS,
  GET_EMAIL_TEMPLATES_CONFIG_FAILURE,
  GET_SINGLE_EMAIL_TEMPLATE_INIT,
  GET_SINGLE_EMAIL_TEMPLATE_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATE_FAILURE,
  EDIT_EMAIL_TEMPLATE_INIT,
  EDIT_EMAIL_TEMPLATE_SUCCESS,
  EDIT_EMAIL_TEMPLATE_FAILURE,
  SEND_TEST_EMAIL_INIT,
  SEND_TEST_EMAIL_SUCCESS,
  SEND_TEST_EMAIL_FAILURE,
} from '@store/action-types/email-templates';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allTemplates: [],
  allTemplatesError: undefined,
  allTemplatesStatus: FETCH_STATUS.IDLE,
  allTemplatesConfig: [],
  allTemplatesConfigError: undefined,
  allTemplatesConfigStatus: FETCH_STATUS.IDLE,
  singleTemplate: {},
  singleTemplateError: undefined,
  singleTemplateStatus: FETCH_STATUS.IDLE,
};

const emailReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_EMAIL_TEMPLATES_INIT: {
      return { ...state, allTemplatesError: undefined, allTemplatesStatus: FETCH_STATUS.LOADING };
    }
    case GET_EMAIL_TEMPLATES_SUCCESS: {
      return {
        ...state,
        allTemplates: payload.response,
        allTemplatesError: undefined,
        allTemplatesStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_EMAIL_TEMPLATES_FAILURE: {
      return { ...state, allTemplatesError: payload.error, allTemplatesStatus: FETCH_STATUS.FAILURE };
    }
    case GET_EMAIL_TEMPLATES_CONFIG_INIT: {
      return {
        ...state,
        allTemplatesConfigError: undefined,
        allTemplatesConfigStatus: FETCH_STATUS.LOADING,
      };
    }
    case GET_EMAIL_TEMPLATES_CONFIG_SUCCESS: {
      return {
        ...state,
        allTemplatesConfig: payload.response,
        allTemplatesConfigError: undefined,
        allTemplatesConfigStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_EMAIL_TEMPLATES_CONFIG_FAILURE: {
      return { ...state, allTemplatesConfigError: payload.error, allTemplatesConfigStatus: FETCH_STATUS.FAILURE };
    }
    case GET_SINGLE_EMAIL_TEMPLATE_INIT: {
      return { ...state, singleTemplateError: undefined, singleTemplateStatus: FETCH_STATUS.LOADING };
    }
    case GET_SINGLE_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        singleTemplate: payload.response,
        singleTemplateError: undefined,
        singleTemplateStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_EMAIL_TEMPLATE_FAILURE: {
      return { ...state, singleTemplateError: payload.error, singleTemplateStatus: FETCH_STATUS.FAILURE };
    }
    case EDIT_EMAIL_TEMPLATE_INIT: {
      return { ...state, singleTemplateError: undefined, singleTemplateStatus: FETCH_STATUS.LOADING };
    }
    case EDIT_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        singleTemplate: payload.response,
        singleTemplateError: undefined,
        singleTemplateStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case EDIT_EMAIL_TEMPLATE_FAILURE: {
      return { ...state, singleTemplateError: payload.error, singleTemplateStatus: FETCH_STATUS.FAILURE };
    }
    case SEND_TEST_EMAIL_INIT: {
      return { ...state, singleTemplateStatus: FETCH_STATUS.LOADING };
    }
    case SEND_TEST_EMAIL_SUCCESS: {
      return { ...state, singleTemplateStatus: FETCH_STATUS.SUCCESS };
    }
    case SEND_TEST_EMAIL_FAILURE: {
      return { ...state, singleTemplateStatus: FETCH_STATUS.FAILURE };
    }
    default:
      return state;
  }
};

export default emailReducer;
