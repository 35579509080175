import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Main from './Main';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
