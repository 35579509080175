import React from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router-dom';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import FaqForm from '@screens/faq/components/FaqForm';

const Main = ({ actions, singleFaq, faqStatus, errors, editStatus, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSingleFaqAction(params.id);
  });

  const handleRefresh = () => actions.getSingleFaqAction(params.id);

  return (
    <FaqForm
      isViewMode={!isUserPermitted(PERMISSIONS.FAQ_EDIT)}
      onRefreshFaq={handleRefresh}
      onSaveFaq={actions.editFaqAction}
      faq={singleFaq}
      isLoading={faqStatus === FETCH_STATUS.IDLE || editStatus === FETCH_STATUS.IDLE}
      faqErrors={errors}
    />
  );
};

Main.propTypes = {
  actions: shape({ getSingleFaqAction: func, editFaqAction: func }).isRequired,
  singleFaq: shape({}),
  errors: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  faqStatus: string,
  editStatus: string,
};
Main.defaultProps = {
  singleFaq: {},
  errors: [],
  permittedPermissions: [],
  faqStatus: undefined,
  editStatus: undefined,
};

export default Main;
