import React from 'react';
import { Message } from 'semantic-ui-react';

const ERRORS = {};

const mapErrorCodes = ({ errorCode, t }) => {
  switch (errorCode) {
    default: {
      return (
        <Message size="big" color="red">
          {t('errors.genericError')}
        </Message>
      );
    }
  }
};

export default mapErrorCodes;
