import { GET_ROLES_INIT, GET_ROLES_SUCCESS, GET_ROLES_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';

const getRolesInit = () => ({ type: GET_ROLES_INIT });
const getRolesSuccess = allRoles => ({
  type: GET_ROLES_SUCCESS,
  payload: { allRoles },
});
const getRolesFailure = error => ({ type: GET_ROLES_FAILURE, payload: { error } });

const getRoles = () => async dispatch => {
  dispatch(getRolesInit());

  try {
    const allRoles = await client.getRoles();
    dispatch(getRolesSuccess(allRoles.response));
  } catch (error) {
    dispatch(getRolesFailure(error?.errors?.[0]?.code));
  }
};

export default getRoles;
