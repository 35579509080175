import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  segment: {
    paddingBottom: 0,
  },
  label: {
    width: 'fit-content',
    display: 'flex !important',
  },
}));

export default useStyles;
