import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  tableCell: {
    display: 'table-cell !important',
    verticalAlign: 'middle !important',
  },
}));

export default useStyles;
