import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Main from './Main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
