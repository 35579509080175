import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  justifyEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
