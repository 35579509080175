import React from 'react';
import { func, shape, string, arrayOf } from 'prop-types';
import { useParams } from 'react-router';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import PageForm from '@screens/page/components/PageForm';

const Main = ({ singlePage, actions, pageErrors, pageStatus, permittedPermissions }) => {
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSinglePageAction(params.id);
  });

  return (
    <PageForm
      page={singlePage}
      loading={pageStatus === FETCH_STATUS.IDLE}
      onRefreshPage={actions.getSinglePageAction}
      onSavePage={actions.editPageAction}
      pageErrors={pageErrors}
      isViewMode={!isUserPermitted(PERMISSIONS.PAGE_EDIT)}
    />
  );
};

Main.propTypes = {
  actions: shape({ getSinglePageAction: func, editPageAction: func }).isRequired,
  singlePage: shape({}),
  pageStatus: string,
  pageErrors: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
};
Main.defaultProps = {
  singlePage: {},
  pageStatus: 'IDLE',
  pageErrors: [],
  permittedPermissions: [],
};

export default Main;
