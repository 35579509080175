import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getLoggedUser } from '@store/reducers/user/selectors';
import RouteWithLayout from './RouteWithLayout';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

const mapStateToProps = state => ({
  user: getLoggedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteWithLayout);
