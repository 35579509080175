import React from 'react';
import { array, bool, element, oneOfType, string } from 'prop-types';
import clsx from 'clsx';
import useStyles from './styles';

const CardBody = ({ children, transparent, ...props }) => {
  const styles = useStyles();

  return (
    <div {...props} className={clsx(styles.root, transparent && styles.transparentBackground)}>
      {children}
    </div>
  );
};

CardBody.propTypes = {
  children: oneOfType([element, array, string]),
  transparent: bool,
};

CardBody.defaultProps = {
  children: undefined,
  transparent: false,
};

export default CardBody;
