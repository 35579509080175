import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { createSliderAction } from '@store/actions/slider';
import { getErrors } from '@store/reducers/slider/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  errors: getErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ createSliderAction }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
