import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAllFaq, getFaqStatus, getDeleteFaqStatus, getAllFaqQueryParams } from '@store/reducers/faq/selectors';
import { getFaqAction, deleteFaqAction, clearStatus } from '@store/actions/faq';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allFaq: getAllFaq(state),
  faqStatus: getFaqStatus(state),
  deleteStatus: getDeleteFaqStatus(state),
  queryParams: getAllFaqQueryParams(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFaqAction,
      deleteFaqAction,
      clearStatus,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
