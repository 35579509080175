import React from 'react';
import ReactDOM from 'react-dom';
import store from '@store/index';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import history from '@store/history';
import * as serviceWorker from './serviceWorker';
import App from './App';
import theme from './theme';
import '@translations/i18n';
import 'semantic-ui-css/semantic.min.css';
import { MetronicLayoutProvider, MetronicSplashScreenProvider, MetronicSubheaderProvider } from './metronic/providers';

ReactDOM.render(
  <MetronicLayoutProvider>
    <MetronicSubheaderProvider>
      <MetronicSplashScreenProvider>
        <React.StrictMode>
          <I18nextProvider i18n={i18next}>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <Router history={history}>
                  <App />
                </Router>
              </ThemeProvider>
            </Provider>
          </I18nextProvider>
        </React.StrictMode>
      </MetronicSplashScreenProvider>
    </MetronicSubheaderProvider>
  </MetronicLayoutProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
