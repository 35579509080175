import {
  DELETE_PERMISSION_INIT,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
} from '@store/action-types/permissions';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import getPermissions from '@store/actions/permissions/getPermissions';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deletePermissionInit = () => ({ type: DELETE_PERMISSION_INIT });
const deletePermissionSuccess = () => ({ type: DELETE_PERMISSION_SUCCESS });
const getSingleSeriesFailure = error => ({ type: DELETE_PERMISSION_FAILURE, payload: { error } });

const deletePermission = id => async dispatch => {
  dispatch(deletePermissionInit());

  try {
    const singleSeriesDeleteResponse = await client.deletePermission({ slug: id });
    await dispatch(deletePermissionSuccess(singleSeriesDeleteResponse));
    dispatch(getPermissions());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('permission.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('permission.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(getSingleSeriesFailure(error));
  }
};

export default deletePermission;
