import { CREATE_ROLE_INIT, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';
import history from '@store/history';
import { enqueueSnackbar } from '@store/actions/toast';
import { isEmpty } from 'lodash';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';

const createRoleInit = () => ({ type: CREATE_ROLE_INIT });
const createRoleSuccess = role => ({
  type: CREATE_ROLE_SUCCESS,
  payload: { role },
});
const createRoleFailure = error => ({ type: CREATE_ROLE_FAILURE, payload: { error } });

const deleteRole = ({ roleData, saveAndBack = false }) => async dispatch => {
  dispatch(createRoleInit());
  const errors = {};

  if (isEmpty(roleData?.name)) {
    errors.name = 'NAME_IS_EMPTY';
  }

  if (isEmpty(roleData?.description)) {
    errors.description = 'DESCRIPTION_IS_EMPTY';
  }

  if (!isEmpty(errors)) {
    return dispatch(createRoleFailure(errors));
  }

  try {
    const deleteRoleResponse = await client.createRole({
      data: { name: roleData?.name, description: roleData?.description },
    });

    await dispatch(createRoleSuccess(deleteRoleResponse.response));

    if (saveAndBack) {
      history.push(ROUTES.ROLES);
    } else {
      history.push(`/roles/${deleteRoleResponse.response.id}/edit`);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(createRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default deleteRole;
