import {
  GET_SINGLE_SLIDER_INIT,
  GET_SINGLE_SLIDER_SUCCESS,
  GET_SINGLE_SLIDER_FAILURE,
} from '@store/action-types/slider';
import client from '@api-sdk/client';

const getSingleSliderInit = () => ({ type: GET_SINGLE_SLIDER_INIT });
const getSingleSliderSuccess = singleSlider => ({ type: GET_SINGLE_SLIDER_SUCCESS, payload: { singleSlider } });
const getSingleSliderFailure = error => ({ type: GET_SINGLE_SLIDER_FAILURE, payload: { error } });

const getSingleSlider = slideId => async dispatch => {
  dispatch(getSingleSliderInit());

  try {
    const singleSlider = await client.getSingleSlider({ slug: slideId });
    dispatch(getSingleSliderSuccess(singleSlider.response));
  } catch (error) {
    dispatch(getSingleSliderFailure(error));
  }
};

export default getSingleSlider;
