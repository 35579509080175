import {
  GET_SINGLE_AGREEMENT_INIT,
  GET_SINGLE_AGREEMENT_SUCCESS,
  GET_SINGLE_AGREEMENT_FAILURE,
} from '@store/action-types/agreements';
import Client from '@api-sdk/client';

const getSingleAgreementInit = () => ({ type: GET_SINGLE_AGREEMENT_INIT });
const getSingleAgreementSuccess = singleAgreement => ({
  type: GET_SINGLE_AGREEMENT_SUCCESS,
  payload: { singleAgreement },
});
const getSingleAgreementFailure = error => ({ type: GET_SINGLE_AGREEMENT_FAILURE, payload: { error } });

const getSingleAgreement = agreementId => async dispatch => {
  dispatch(getSingleAgreementInit());

  try {
    const singleAgreement = await Client.getSingleAgreement({ slug: agreementId });
    dispatch(getSingleAgreementSuccess(singleAgreement.response));
  } catch (error) {
    dispatch(getSingleAgreementFailure(error));
  }
};

export default getSingleAgreement;
