import React from 'react';
import { func, shape } from 'prop-types';
import { Button, IconButton, TextField } from '@material-ui/core';
import { PositionSelect } from '@shared/components/OfferForm/components';
import { RemoveCircle } from '@material-ui/icons';
import useMount from '@shared/utils/useMountHook';
import { isEmpty } from 'lodash';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { SketchPicker } from 'react-color';
import useStyles from './styles';

const BarChart = ({
  handleAddArrayToData,
  handleAddElementInElement,
  setElementData,
  setElementDataWithArray,
  handleRemoveElementFromElement,
  arrayOfElementsSetPosition,
  elementValue,
}) => {
  const styles = useStyles();

  useMount(() => {
    isEmpty(elementValue) && handleAddArrayToData();
  });

  const handleSetColumnSettings = (e, columnId) => {
    const key = e.target.name;
    const value = e.target.value;

    setElementDataWithArray(columnId, key, value);
  };

  const handleAddColumn = () => {
    const position = elementValue?.elements.length + 1;
    handleAddElementInElement(position);
  };

  const handleColorChange = color => {
    const key = 'color';
    const value = color.hex;

    setElementData(key, value);
  };

  const handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;
    setElementData(key, value);
  };

  const handleSelectChange = (e, columnId) => {
    const newIndex = e.target.value;
    arrayOfElementsSetPosition(columnId, newIndex);
  };

  const handleRemoveItem = columnId => {
    handleRemoveElementFromElement(columnId);
  };

  const renderColumnRow = () => {
    return (
      <ul>
        {elementValue?.elements?.map?.((item, index) => {
          return (
            <li key={index} className="input-group my-2">
              <div className="input-group my-2">
                <PositionSelect
                  handleChange={e => handleSelectChange(e, index)}
                  numberOfOptions={elementValue?.elements.length}
                  id={index}
                />
                <input
                  type="text"
                  data-id={index}
                  name="label"
                  onChange={e => handleSetColumnSettings(e, index)}
                  className="form-control"
                  placeholder="Column label"
                  value={elementValue?.elements[index].label}
                />
                <input
                  type="text"
                  data-id={index}
                  name="value"
                  onChange={e => handleSetColumnSettings(e, index)}
                  className="form-control"
                  placeholder="Column value"
                  value={elementValue?.elements[index].value}
                />
                <IconButton onClick={() => handleRemoveItem(index)} style={{ marginLeft: 10 }} size="small">
                  <RemoveCircle />
                </IconButton>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div>
      <div className={styles.headerWrapper}>
        <div className={styles.leftHeaderWrapper}>
          <TextField
            className={styles.labelInput}
            value={elementValue?.title || ''}
            variant="outlined"
            size="small"
            name="title"
            label="Title"
            onChange={handleChange}
          />
          <OverlayTrigger
            trigger="click"
            rootClose
            placement="bottom"
            overlay={
              <Popover id="popover-bar0-chart">
                <SketchPicker
                  disableAlpha
                  color={elementValue?.color || ''}
                  onChange={color => handleColorChange(color)}
                  presetColors={['#CDCBFF', '#9E87F8', '#615FCF']}
                />
              </Popover>
            }
          >
            <Button style={{ background: elementValue?.color }} className={styles.pickColorButton} variant="outlined">
              Color
            </Button>
          </OverlayTrigger>
          <Button className={styles.button} variant="outlined" color="primary" onClick={handleAddColumn}>
            add
          </Button>
        </div>
      </div>
      <div>{renderColumnRow()}</div>
    </div>
  );
};

BarChart.propTypes = {
  handleAddArrayToData: func.isRequired,
  handleAddElementInElement: func.isRequired,
  setElementData: func.isRequired,
  setElementDataWithArray: func.isRequired,
  handleRemoveElementFromElement: func.isRequired,
  arrayOfElementsSetPosition: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default BarChart;
