import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { string, elementType, shape } from 'prop-types';
import ROUTES from '@shared/consts/routes';
import { getIsUserLoggedInFromStorage } from '@api-sdk/utils';

const PrivateRouteWithLayout = props => {
  const { layout: Layout, component: Component, user, ...rest } = props;
  const isLoggedIn = getIsUserLoggedInFromStorage();

  return (
    <Route
      {...rest}
      render={matchProps =>
        isLoggedIn ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: ROUTES.LOGIN }} />
        )
      }
    />
  );
};

PrivateRouteWithLayout.propTypes = {
  component: elementType.isRequired,
  layout: elementType.isRequired,
  path: string,
  user: shape({}).isRequired,
};

PrivateRouteWithLayout.defaultProps = {
  path: undefined,
};

export default PrivateRouteWithLayout;
