import {
  GET_STATIC_CONTENT_INIT,
  GET_STATIC_CONTENT_SUCCESS,
  GET_STATIC_CONTENT_FAILURE,
  GET_STATIC_CONTENT_PARENT_INIT,
  GET_STATIC_CONTENT_PARENT_SUCCESS,
  GET_STATIC_CONTENT_PARENT_FAILURE,
  GET_STATIC_CONTENT_ELEMENT_INIT,
  GET_STATIC_CONTENT_ELEMENT_SUCCESS,
  GET_STATIC_CONTENT_ELEMENT_FAILURE,
  EDIT_STATIC_CONTENT_INIT,
  EDIT_STATIC_CONTENT_SUCCESS,
  EDIT_STATIC_CONTENT_FAILURE,
} from '@store/action-types/static-content';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  status: FETCH_STATUS.IDLE,
  staticContentList: {},
  staticContentParent: {},
  staticContentElement: {},
  error: undefined,
  parentStatus: FETCH_STATUS.IDLE,
  elementStatus: FETCH_STATUS.IDLE,
  breadcrumbs: [],
};

const staticContentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STATIC_CONTENT_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        staticContentList: {},
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        breadcrumbs: payload?.staticContent?.breadcrumbs,
        staticContentList: payload?.staticContent?.data.children,
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        staticContentList: {},
        error: payload?.error,
      };
    }
    case GET_STATIC_CONTENT_PARENT_INIT: {
      return {
        ...state,
        parentStatus: FETCH_STATUS.IDLE,
        staticContentParent: {},
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_PARENT_SUCCESS: {
      return {
        ...state,
        parentStatus: FETCH_STATUS.SUCCESS,
        staticContentParent: payload?.staticContentParent?.data,
        breadcrumbs: payload?.staticContentParent?.breadcrumbs,
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_PARENT_FAILURE: {
      return {
        ...state,
        parentStatus: FETCH_STATUS.FAILURE,
        staticContentParent: {},
        error: payload?.error,
      };
    }
    case GET_STATIC_CONTENT_ELEMENT_INIT: {
      return {
        ...state,
        staticContentElement: {},
        elementStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_ELEMENT_SUCCESS: {
      return {
        ...state,
        staticContentElement: payload?.staticContentElement?.data,
        breadcrumbs: payload?.staticContentElement?.breadcrumbs,
        elementStatus: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_STATIC_CONTENT_ELEMENT_FAILURE: {
      return {
        ...state,
        staticContentElement: {},
        elementStatus: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case EDIT_STATIC_CONTENT_INIT: {
      return {
        ...state,
      };
    }
    case EDIT_STATIC_CONTENT_SUCCESS: {
      return {
        ...state,
      };
    }
    case EDIT_STATIC_CONTENT_FAILURE: {
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export default staticContentReducer;
