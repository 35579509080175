import client from '@api-sdk/client';
import {
  GET_EMAIL_TEMPLATES_INIT,
  GET_EMAIL_TEMPLATES_SUCCESS,
  GET_EMAIL_TEMPLATES_FAILURE,
} from '@store/action-types/email-templates';

const getTemplatesInit = () => ({ type: GET_EMAIL_TEMPLATES_INIT });
const getTemplatesSuccess = response => ({ type: GET_EMAIL_TEMPLATES_SUCCESS, payload: { response } });
const getTemplatesFailure = error => ({ type: GET_EMAIL_TEMPLATES_FAILURE, payload: { error } });

const getTemplates = () => async dispatch => {
  dispatch(getTemplatesInit());

  try {
    const templatesResponse = await client.getTemplates();

    dispatch(getTemplatesSuccess(templatesResponse.response));
  } catch (error) {
    dispatch(getTemplatesFailure(error));
  }
};

export default getTemplates;
