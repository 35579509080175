import client from '@api-sdk/client';
import {
  GET_EMAIL_TEMPLATES_CONFIG_INIT,
  GET_EMAIL_TEMPLATES_CONFIG_SUCCESS,
  GET_EMAIL_TEMPLATES_CONFIG_FAILURE,
} from '@store/action-types/email-templates';

const getTemplatesConfigInit = () => ({ type: GET_EMAIL_TEMPLATES_CONFIG_INIT });
const getTemplatesConfigSuccess = response => ({ type: GET_EMAIL_TEMPLATES_CONFIG_SUCCESS, payload: { response } });
const getTemplatesConfigFailure = error => ({ type: GET_EMAIL_TEMPLATES_CONFIG_FAILURE, payload: { error } });

const getTemplatesConfig = () => async dispatch => {
  dispatch(getTemplatesConfigInit());

  try {
    const templatesConfigResponse = await client.getTemplatesConfig();

    dispatch(getTemplatesConfigSuccess(templatesConfigResponse.response));
  } catch (error) {
    dispatch(getTemplatesConfigFailure(error));
  }
};

export default getTemplatesConfig;
