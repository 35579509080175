const getStaticContentStatus = state => state?.staticContent?.status;
const getStaticContent = state => state?.staticContent?.staticContentList;
const getStaticContentParentStatus = state => state?.staticContent?.parentStatus;
const getStaticContentParent = state => state?.staticContent?.staticContentParent;
const getStaticContentElement = state => state?.staticContent?.staticContentElement;
const getStaticContentElementStatus = state => state?.staticContent?.elementStatus;
const getBreadcrumbs = state => state?.staticContent?.breadcrumbs;

export {
  getStaticContentStatus,
  getStaticContent,
  getStaticContentParent,
  getStaticContentParentStatus,
  getStaticContentElement,
  getStaticContentElementStatus,
  getBreadcrumbs,
};
