import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getMaintenanceConfig } from '@store/reducers/global/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  config: getMaintenanceConfig(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({}, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
