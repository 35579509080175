import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
  },
  mobileImage: {
    transform: 'scale(0.4)',
    transformOrigin: 'bottom right',
  },
  tabletImage: {
    transform: 'scale(0.6)',
    transformOrigin: 'bottom right',
  },
  image: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: -1,
  },
  languageSwitcherWrapper: {
    position: 'absolute',
    right: 5,
    top: 8,
  },
});

export default useStyles;
