import React, { useEffect, useMemo, useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import { Button } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { isEmpty } from 'lodash';
import './styles.scss';

const Main = ({
  allFaq,
  faqStatus,
  actions,
  queryParams,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.FAQ);
  const [isLoading, setIsLoading] = useState(true);

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMountWithTranslate(() => {
    setIsLoading(true);
    actions.getFaqAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.FAQ]) {
      if (
        !isUserPermitted(PERMISSIONS.FAQ_CREATE) ||
        !isUserPermitted(PERMISSIONS.FAQ_DELETE) ||
        !isUserPermitted(PERMISSIONS.FAQ_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_FAQ_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.FAQ);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.FAQ_CREATE) && PERMISSIONS.FAQ_CREATE,
          !isUserPermitted(PERMISSIONS.FAQ_DELETE) && PERMISSIONS.FAQ_DELETE,
          !isUserPermitted(PERMISSIONS.FAQ_EDIT) && PERMISSIONS.FAQ_EDIT,
          !isUserPermitted(PERMISSIONS.GET_FAQ_BY_ID) && PERMISSIONS.GET_FAQ_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  useEffect(() => {
    faqStatus !== FETCH_STATUS.IDLE && faqStatus !== undefined && setIsLoading(false);
  }, [faqStatus, actions]);

  useEffect(() => {
    return () => actions.clearStatus();
  }, [actions]);

  const refreshPage = () => actions.getFaqAction();

  const columns = useMemo(
    () => [
      {
        Header: t('question'),
        accessor: 'question',
        columnName: 'question-cell',
        columnOrder: 'question',
      },
      {
        Header: t('position'),
        accessor: 'position',
        className: 'text-center',
        columnName: 'position',
        columnOrder: 'position',
      },
      {
        Header: t('active'),
        accessor: 'active',
        className: 'text-center',
        columnName: 'active',
        columnOrder: 'active',
        Cell: ({ row }) => {
          const isActive = row?.original?.active;
          return isActive ? t('yes') : t('no');
        },
      },
      {
        Header: t('homepage'),
        accessor: 'homepage',
        className: 'text-center',
        columnName: 'isFeatured',
        columnOrder: 'isFeatured',
        Cell: () => {
          const isHomepage = false;
          return isHomepage ? t('yes') : t('no');
        },
      },
      {
        accessor: 'edit',
        className: 'text-right',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.GET_FAQ_BY_ID) ? (
            <Button as={Link} to={`/faq/${row?.original?.id}`}>
              {t('edit')}
            </Button>
          ) : null,
      },
      {
        accessor: 'remove',
        className: 'text-right',
        Cell: ({ row }) =>
          isUserPermitted(PERMISSIONS.FAQ_DELETE) ? (
            <Button
              color="red"
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals,no-alert
                if (confirm(`${t('deleteFaqDisclaimer')} ${row.original.question}?`)) {
                  actions.deleteFaqAction(row.original.id);
                }
              }}
            >
              {t('remove')}
            </Button>
          ) : null,
      },
    ],
    [t],
  );

  const renderContent = () => (
    <Table
      className="table-responsive"
      isLoading={isLoading || faqStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}
      data={allFaq}
      columns={columns}
      queryParams={queryParams}
      searchAction={actions.getFaqAction}
      reloadAction={refreshPage}
      notFoundMessage={
        <NotFoundMessage
          title={t('notFoundFaq')}
          subtitle={t('clickBelow')}
          buttonText={t('create')}
          to={ROUTES.ADD_FAQ}
        />
      }
    />
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle title={t('title')} />
        <CardToolbar>
          <Button disabled={faqStatus === FETCH_STATUS.LOADING} onClick={refreshPage} color="black">
            {t('reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.FAQ_CREATE) ? (
            <Button as={Link} primary to={ROUTES.ADD_FAQ}>
              {t('create')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>{renderContent()}</CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({
    getFaqAction: func,
    deleteFaqAction: func,
  }).isRequired,
  row: shape({}),
  allFaq: arrayOf(shape({})),
  faqStatus: string,
  deleteStatus: string,
  queryParams: shape({}),
  permittedPermissions: arrayOf(shape({})),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};
Main.defaultProps = {
  allFaq: [],
  row: {},
  faqStatus: undefined,
  deleteStatus: undefined,
  queryParams: {},
  permittedPermissions: [],
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
