import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getOffersAction } from '@store/actions/offers';
import { getSingleFeaturedOfferAction, editFeaturedOfferAction } from '@store/actions/featured-offers';
import { getSingleFeaturedOffer, getFeaturedOffersStatus, getErrors } from '@store/reducers/featured-offers/selectors';
import { getFeaturedOffersSelectOptions, getOfferStatus } from '@store/reducers/offers/selectors';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  featuredOffer: getSingleFeaturedOffer(state),
  status: getFeaturedOffersStatus(state),
  errors: getErrors(state),
  offerOptions: getFeaturedOffersSelectOptions(state),
  offerStatus: getOfferStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getOffersAction, getSingleFeaturedOfferAction, editFeaturedOfferAction }, dispatch),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
