import {
  GET_PAGES_SUCCESS,
  GET_PAGES_INIT,
  GET_PAGES_FAILURE,
  GET_SINGLE_PAGE_INIT,
  GET_SINGLE_PAGE_FAILURE,
  GET_SINGLE_PAGE_SUCCESS,
  EDIT_PAGE_INIT,
  EDIT_PAGE_FAILURE,
  CREATE_PAGE_INIT,
  CREATE_PAGE_FAILURE,
} from '@store/action-types/page';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allPages: [],
  singlePage: {},
  status: FETCH_STATUS.LOADING,
  error: undefined,
  singlePageErrors: undefined,
  queryParams: {},
};

const pageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAGES_INIT: {
      return { ...initialState, queryParams: {} };
    }
    case GET_PAGES_SUCCESS: {
      return {
        allPages: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_PAGES_FAILURE: {
      return {
        allPages: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_PAGE_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        singlePage: {},
      };
    }
    case EDIT_PAGE_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        singlePageErrors: undefined,
      };
    }
    case CREATE_PAGE_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
        singlePageErrors: undefined,
      };
    }
    case EDIT_PAGE_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singlePageErrors: payload.error,
      };
    }
    case CREATE_PAGE_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        singlePageErrors: payload.error,
      };
    }
    case GET_SINGLE_PAGE_SUCCESS: {
      return {
        ...state,
        singlePage: payload.page,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_PAGE_FAILURE: {
      return {
        ...state,
        singlePage: {},
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    default:
      return state;
  }
};

export default pageReducer;
