const transformedSliderData = data => {
  return {
    position: data?.position,
    active: data?.active,
    name: {
      pl: data?.name?.pl,
      en: data?.name?.en,
    },
    content: {
      pl: data?.content?.pl,
      en: data?.content?.en,
    },
    guestButtonLabel: {
      pl: data?.guestButtonLabel?.pl,
      en: data?.guestButtonLabel?.en,
    },
    guestButtonUrl: {
      pl: data?.guestButtonUrl?.pl,
      en: data?.guestButtonUrl?.en,
    },
    loggedUserButtonLabel: {
      pl: data?.loggedUserButtonLabel?.pl,
      en: data?.loggedUserButtonLabel?.en,
    },
    loggedUserButtonUrl: {
      pl: data?.loggedUserButtonUrl?.pl,
      en: data?.loggedUserButtonUrl?.en,
    },
  };
};

export default transformedSliderData;
