import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleFaq, getSingleStatus, getFaqError, getEditFaqStatus } from '@store/reducers/faq/selectors';
import { getSingleFaqAction, editFaqAction } from '@store/actions/faq';
import { getPermittedPermissions } from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  singleFaq: getSingleFaq(state),
  faqStatus: getSingleStatus(state),
  errors: getFaqError(state),
  editStatus: getEditFaqStatus(state),
  permittedPermissions: getPermittedPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getSingleFaqAction,
      editFaqAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
