import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  footer: {
    background: '#f6f6f6',
    borderTop: '1px solid #e0e0e0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  navLink: {
    margin: '0 10px',
  },
});

export default useStyles;
