import {
  GET_PAYPAL_CONFIG_INIT,
  GET_PAYPAL_CONFIG_SUCCESS,
  GET_PAYPAL_CONFIG_FAILURE,
} from '@store/action-types/paypal';
import client from '@api-sdk/client';

const getPaypalConfigInit = () => ({ type: GET_PAYPAL_CONFIG_INIT });
const getPaypalConfigSuccess = paypalConfig => ({ type: GET_PAYPAL_CONFIG_SUCCESS, payload: { paypalConfig } });
const getPaypalConfigFailure = error => ({ type: GET_PAYPAL_CONFIG_FAILURE, payload: { error } });

const getPaypalConfig = () => async dispatch => {
  dispatch(getPaypalConfigInit());

  try {
    const paypalConfigResponse = await client.getPaypalConfig();
    return dispatch(getPaypalConfigSuccess(paypalConfigResponse.response));
  } catch (error) {
    return dispatch(getPaypalConfigFailure(error));
  }
};

export default getPaypalConfig;
