import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getFeaturedOffersAction } from '@store/actions/featured-offers';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import { getFeaturedOffers, getFeaturedOffersStatus } from '@store/reducers/featured-offers/selectors';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  featuredOffers: getFeaturedOffers(state),
  status: getFeaturedOffersStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getFeaturedOffersAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
