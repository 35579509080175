import React from 'react';
import { shape } from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useStyles from './styles';

const ParentLink = ({ item }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  return (
    <Link to={`/static-content/${item.id}`} icon labelPosition="right">
      <div className={styles.wrapper}>
        <Icon name="box" style={{ color: 'rgba(0,0,0,.6)' }} />
        <Typography className={styles.name}>{t(item.name)}</Typography>
      </div>
    </Link>
  );
};

ParentLink.propTypes = {
  item: shape({}),
};

ParentLink.defaultProps = {
  item: {},
};

export default ParentLink;
