import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { oneOfType, arrayOf, elementType, node, shape, string } from 'prop-types';
import { useHtmlClassService } from '@metronic/providers';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Aside, Header, HeaderMobile } from './components';
import LayoutInit from './LayoutInit';
import useStyles from './styles';

const Main = props => {
  const { children, location, actions, menuItems, splashScreenStatus, user } = props;
  const classes = useStyles();
  const uiService = useHtmlClassService();

  useMountWithTranslate(() => {
    actions.getSplashScreen();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const layoutProps = useMemo(() => {
    return {
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);

  return (
    <div className={clsx({ [classes.root]: true })}>
      <HeaderMobile getMenu={actions.getMenuAction} />
      {layoutProps.asideDisplay && (
        <Aside logoutUser={actions.logoutUser} sidebarMenuItems={menuItems} menuStatus={splashScreenStatus} />
      )}
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper" style={{ paddingTop: 0 }}>
            <Header getMenu={actions.getMenuAction} logoutUser={actions.logoutUser} user={user} />
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
            >
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  <div className={layoutProps.contentContainerClasses}>{children}</div>
                </div>
              )}
              {layoutProps.contentExtended && { children }}
            </div>
          </div>
        </div>
      </div>
      <LayoutInit />
    </div>
  );
};

Main.propTypes = {
  children: oneOfType([arrayOf(elementType), elementType, node]).isRequired,
  location: shape({}).isRequired,
  actions: shape({}).isRequired,
  menuItems: arrayOf(shape({})).isRequired,
  user: shape({}),
  splashScreenStatus: string,
};

Main.defaultProps = {
  splashScreenStatus: undefined,
  user: {},
};

export default withRouter(Main);
