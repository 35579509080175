import FETCH_STATUS from '@shared/consts/fetchStatuses';
import {
  CHECK_PERMISSION_FAILURE,
  CHECK_PERMISSION_INIT,
  CHECK_PERMISSION_SUCCESS,
  SET_IS_PERMISSION_CHECKED,
} from '@store/action-types/check-permission';
import SCREEN_NAMES from '@shared/consts/screenNames';

const initialState = {
  permittedPermissions: [],
  status: undefined,
  isPermissionChecked: {
    [SCREEN_NAMES.INVESTMENTS]: false,
    [SCREEN_NAMES.TRANSACTIONS]: false,
    [SCREEN_NAMES.ORDERS]: false,
    [SCREEN_NAMES.SERIES]: false,
    [SCREEN_NAMES.OFFERS]: false,
    [SCREEN_NAMES.PAGES]: false,
    [SCREEN_NAMES.FEATURED_OFFERS]: false,
    [SCREEN_NAMES.STATIC_CONTENT]: false,
    [SCREEN_NAMES.FAQ]: false,
    [SCREEN_NAMES.SLIDER]: false,
    [SCREEN_NAMES.AGREEMENTS]: false,
    [SCREEN_NAMES.EMAIL_TEMPLATES]: false,
    [SCREEN_NAMES.USERS]: false,
    [SCREEN_NAMES.PERMISSIONS]: false,
    [SCREEN_NAMES.ROLES]: false,
    [SCREEN_NAMES.SETTINGS]: false,
  },
};

const checkPermissionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECK_PERMISSION_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.IDLE,
      };
    }
    case CHECK_PERMISSION_SUCCESS: {
      return {
        ...state,
        status: FETCH_STATUS.SUCCESS,
        permittedPermissions: payload?.permittedPermissions,
      };
    }
    case CHECK_PERMISSION_FAILURE: {
      return {
        ...state,
        status: FETCH_STATUS.FAILURE,
        permittedPermissions: {},
      };
    }
    case SET_IS_PERMISSION_CHECKED: {
      return {
        ...state,
        isPermissionChecked: {
          ...state?.isPermissionChecked,
          [payload?.screenName]: true,
        },
      };
    }
    default:
      return state;
  }
};

export default checkPermissionReducer;
