import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getCounterValue } from '@store/reducers/counter/selectors';
import { decrementCounter, incrementCounter } from '@store/actions/counter';
import Main from './Main';

const mapStateToProps = state => ({
  counterValue: getCounterValue(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      incrementCounter,
      decrementCounter,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
