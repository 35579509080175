import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  deleteButton: {
    color: '#f44336',
  },
  button: {
    margin: '0 0 16px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  list: {
    background: 'rgba(245, 245, 245, 0.4)',
    border: '1px solid rgba(130, 130, 130, 0.33)',
    borderRadius: '5px',
    padding: 0,
    '&:empty': {
      background: 'none',
      border: 'none',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 3,
    borderTop: '1px solid rgba(130, 130, 130, 0.33)',
    '&:first-child': {
      borderTop: 'none',
    },
  },
  listItemCell: {
    padding: '4px',
  },
  image: {
    borderRadius: '50px',
    zoom: 0.075,
  },
  listItemLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '4px',
    fontWeight: 700,
    color: 'rgba(130, 130, 130, 0.7)',
  },
}));

export default useStyles;
