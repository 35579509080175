import { DELETE_USER_INIT, DELETE_USER_SUCCESS, DELETE_USER_FAILURE } from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import getUsersAction from '@store/actions/user-management/getUsers';
import translateToastMessage from '@shared/utils/translateToastMessage';

const deleteUserInit = () => ({ type: DELETE_USER_INIT });
const deleteUserSuccess = () => ({ type: DELETE_USER_SUCCESS });
const getSingleSeriesFailure = error => ({ type: DELETE_USER_FAILURE, payload: { error } });

const deleteUser = userId => async dispatch => {
  dispatch(deleteUserInit());

  try {
    await client.deleteUser({ slug: userId });
    await dispatch(deleteUserSuccess());
    dispatch(getUsersAction());
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.error.delete'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(getSingleSeriesFailure(error));
  }
};

export default deleteUser;
