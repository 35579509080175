import React, { useEffect, useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import useMount from '@shared/utils/useMountHook';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar, NotFoundMessage, Table } from '@shared/components';
import { Skeleton } from '@shared/layout-components';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import ROUTES from '@shared/consts/routes';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';
import { SKELETON_VARIANT } from '@shared/layout-components/Skeleton/consts';

const Main = ({
  allPermissions,
  permissionStatus,
  actions,
  permittedPermissions,
  isPermissionChecked,
  checkPermissionStatus,
}) => {
  const [t] = useTranslation(TRANSLATIONS.PERMISSIONS);
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getPermissionsAction();
  });

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.PERMISSIONS]) {
      if (
        !isUserPermitted(PERMISSIONS.PERMISSION_CREATE) ||
        !isUserPermitted(PERMISSIONS.PERMISSION_DELETE) ||
        !isUserPermitted(PERMISSIONS.PERMISSION_EDIT) ||
        !isUserPermitted(PERMISSIONS.GET_PERMISSION_BY_ID)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.PERMISSIONS);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.PERMISSION_CREATE) && PERMISSIONS.PERMISSION_CREATE,
          !isUserPermitted(PERMISSIONS.PERMISSION_DELETE) && PERMISSIONS.PERMISSION_DELETE,
          !isUserPermitted(PERMISSIONS.PERMISSION_EDIT) && PERMISSIONS.PERMISSION_EDIT,
          !isUserPermitted(PERMISSIONS.GET_PERMISSION_BY_ID) && PERMISSIONS.GET_PERMISSION_BY_ID,
        ]);
      }
    }
  }, [permittedPermissions]);

  const refreshPage = () => actions.getPermissionsAction();

  const columns = useMemo(
    () => [
      {
        Header: t('form.name'),
        accessor: 'name',
      },
      {
        Header: t('form.description'),
        accessor: 'description',
      },
      {
        accessor: 'action',
        className: 'text-right',
        Cell: ({ row }) => (
          <>
            {isUserPermitted(PERMISSIONS.PERMISSION_EDIT) ? (
              <Button as={Link} to={`/permission/${row.original.id}`}>
                {t('actionButtons.edit')}
              </Button>
            ) : null}

            {isUserPermitted(PERMISSIONS.PERMISSION_DELETE) ? (
              <Button
                color="red"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals,no-alert
                  if (confirm(`${t('deletePermissionsDisclaimer')} ${row.original.name}?`)) {
                    actions.deletePermissionAction(row.original.id);
                  }
                }}
              >
                {t('actionButtons.remove')}
              </Button>
            ) : null}
          </>
        ),
      },
    ],
    [actions, t],
  );

  const renderTable = () => {
    if (isEmpty(allPermissions)) {
      return (
        <NotFoundMessage
          title={t('notFoundPermission')}
          subtitle={t('clickBelow')}
          buttonText={t('actionButtons.add')}
          to={ROUTES.PERMISSION_CREATE}
        />
      );
    }

    return <Table hideFilters hideFooter data={allPermissions} columns={columns} />;
  };

  return (
    <Card width="md">
      <CardHeader>
        <CardTitle title={t('form.title')} />
        <CardToolbar>
          <Button color="black" disabled={permissionStatus === FETCH_STATUS.IDLE} onClick={refreshPage}>
            {t('actionButtons.reload')}
          </Button>
          {isUserPermitted(PERMISSIONS.PERMISSION_CREATE) ? (
            <Button primary as={Link} to={ROUTES.PERMISSION_CREATE}>
              {t('actionButtons.add')}
            </Button>
          ) : null}
        </CardToolbar>
      </CardHeader>
      <CardBody transparent>
        {permissionStatus === FETCH_STATUS.IDLE || checkPermissionStatus === FETCH_STATUS.IDLE ? (
          <Skeleton variant={SKELETON_VARIANT.TABLE} />
        ) : (
          renderTable()
        )}
      </CardBody>
    </Card>
  );
};

Main.propTypes = {
  actions: shape({}).isRequired,
  allPermissions: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  permissionStatus: string,
  row: shape({}),
  isPermissionChecked: shape({}),
  checkPermissionStatus: string,
};

Main.defaultProps = {
  allPermissions: [],
  permittedPermissions: [],
  permissionStatus: undefined,
  row: {},
  isPermissionChecked: {},
  checkPermissionStatus: undefined,
};

export default Main;
