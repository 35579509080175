import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const TableOrder = ({ column, queryParams, order, onChangeOrder }) => {
  const [t] = useTranslation(TRANSLATIONS.COMMON);
  const orderPrototype = queryParams?.configuredOrder?.find?.(item => item.requestName === column.columnOrder);
  const orderFilter = Object?.values?.(order || {})?.find?.(item => item.column === column.columnOrder);
  const matchOrder = queryParams?.appliedOrder?.find?.(item => item.requestName === column.columnOrder);

  if (orderPrototype) {
    return (
      <div
        className="common-table--headers-title"
        onClick={() => {
          const newIndex = Object?.values?.({ ...order });
          delete newIndex.type;

          onChangeOrder({
            column: orderPrototype.requestName,
            direction: orderFilter?.direction === 'ASC' ? 'DESC' : 'ASC',
            backendIndex: orderFilter?.backendIndex || Object?.values?.({ ...newIndex })?.length + 1 || 1,
          });
        }}
      >
        {!orderFilter && orderPrototype && <Icon color="black" size="large" name="arrows alternate vertical" />}
        {orderFilter && orderPrototype && matchOrder && (
          <div className="active-order-filter">
            <Icon
              color="black"
              size="large"
              name={orderFilter?.direction === 'ASC' ? 'long arrow alternate up' : 'long arrow alternate down'}
            />
            <span>{orderFilter?.direction === 'ASC' ? t('commonTable.ASC') : t('commonTable.DESC')}</span>
          </div>
        )}
        {column.render('Header')}
      </div>
    );
  }

  return column.render('Header');
};

export default TableOrder;
