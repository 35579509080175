import client from '@api-sdk/client';
import {
  CHECK_PERMISSION_FAILURE,
  CHECK_PERMISSION_INIT,
  CHECK_PERMISSION_SUCCESS,
} from '@store/action-types/check-permission';

const checkPermissionInit = () => ({ type: CHECK_PERMISSION_INIT });
const checkPermissionSuccess = permittedPermissions => ({
  type: CHECK_PERMISSION_SUCCESS,
  payload: { permittedPermissions },
});
const checkPermissionFailure = error => ({ type: CHECK_PERMISSION_FAILURE, payload: { error } });

const checkPermission = permissions => async dispatch => {
  dispatch(checkPermissionInit());

  try {
    const permittedPermissions = await client.permissionCheck({
      params: { permissionCheck: { type: 'permissionCheck', permissionsToCheck: permissions?.filter(Boolean) } },
    });
    dispatch(checkPermissionSuccess(permittedPermissions.response));
  } catch (error) {
    dispatch(checkPermissionFailure(error));
  }
};

export default checkPermission;
