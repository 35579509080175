import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAllPages, getPagesStatus, getAllPagesQueryParams } from '@store/reducers/page/selectors';
import { getPagesAction, deletePageAction } from '@store/actions/page';
import {
  getPermittedPermissions,
  getIsPermissionChecked,
  getPermissionCheckStatus,
} from '@store/reducers/check-permission/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import Main from './Main';

const mapStateToProps = state => ({
  allPages: getAllPages(state),
  queryParams: getAllPagesQueryParams(state),
  pageStatus: getPagesStatus(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getPagesAction,
      deletePageAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
