import { EDIT_FAQ_FAILURE, EDIT_FAQ_INIT, EDIT_FAQ_SUCCESS } from '@store/action-types/faq';
import Client from '@api-sdk/client';
import { isEmpty, set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import ROUTES from '@shared/consts/routes';
import history from '@store/history';

const editFaqInit = () => ({ type: EDIT_FAQ_INIT });
const editFaqSuccess = () => ({ type: EDIT_FAQ_SUCCESS });
const editFaqFailure = error => {
  if (!error?.errors) {
    return { type: EDIT_FAQ_FAILURE, payload: { error } };
  }

  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: EDIT_FAQ_FAILURE, payload: { error: mappedErrors } };
};

const editFaq = ({ faqData, saveAndBack = false }) => async dispatch => {
  dispatch(editFaqInit());

  if (isEmpty(String(faqData?.position))) {
    return dispatch(editFaqFailure({ position: 'POSITION_IS_REQUIRED' }));
  }

  try {
    await Client.editFaq({
      slug: faqData?.id,
      data: {
        active: faqData?.active,
        position: faqData?.position,
        isFeatured: faqData?.isFeatured,
        question: {
          pl: faqData?.question?.pl,
          en: faqData?.question?.en,
        },
        answer: {
          pl: faqData?.answer?.pl,
          en: faqData?.answer?.en,
        },
      },
    });

    if (saveAndBack) {
      history.push(ROUTES.FAQ);
    }

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('faq.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    dispatch(editFaqSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('faq.error.edit'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(editFaqFailure(error));
  }
};

export default editFaq;
