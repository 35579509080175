import {
  GET_SPLASH_SCREEN_INIT,
  GET_SPLASH_SCREEN_SUCCESS,
  GET_SPLASH_SCREEN_FAILURE,
} from '@store/action-types/splash-screen';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  menu: [],
  status: FETCH_STATUS.IDLE,
};

const splashScreenReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SPLASH_SCREEN_INIT: {
      return {
        ...state,
        menu: [],
        status: FETCH_STATUS.IDLE,
      };
    }
    case GET_SPLASH_SCREEN_SUCCESS: {
      return {
        ...state,
        menu: payload?.splashScreen?.menu,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SPLASH_SCREEN_FAILURE: {
      return {
        ...state,
        menu: [],
        status: FETCH_STATUS.FAILURE,
      };
    }
    default:
      return state;
  }
};

export default splashScreenReducer;
