import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getSingleRole, getSingleRoleError, getSingleRoleStatus } from '@store/reducers/roles/selectors';
import { createRoleAction } from '@store/actions/roles';
import Main from './Main';

const mapStateToProps = state => ({
  singleRole: getSingleRole(state),
  singleRoleError: getSingleRoleError(state),
  singleRoleStatus: getSingleRoleStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createRoleAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
