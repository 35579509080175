const USER_LOGIN_INIT = '@user/USER_LOGIN_INIT';
const USER_LOGIN_SUCCESS = '@user/USER_LOGIN_SUCCESS';
const USER_LOGIN_FAILURE = '@user/USER_LOGIN_FAILURE';

const USER_LOGOUT = '@user/USER_LOGOUT';

const GET_USER_INFO_INIT = '@user/GET_USER_INFO_INIT';
const GET_USER_INFO_SUCCESS = '@user/GET_USER_INFO_SUCCESS';
const GET_USER_INFO_FAILURE = '@user/GET_USER_INFO_FAILURE';

const REFRESH_TOKEN_INIT = '@user/REFRESH_TOKEN_INIT';
const REFRESH_TOKEN_SUCCESS = '@user/REFRESH_TOKEN_SUCCESS';
const REFRESH_TOKEN_FAILURE = '@user/REFRESH_TOKEN_FAILURE';

const RESET_PASSWORD_INIT = '@user/RESET_PASSWORD_INIT';
const RESET_PASSWORD_SUCCESS = '@user/RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAILURE = '@user/RESET_PASSWORD_FAILURE';

const SET_MAINTENANCE_MODE = '@splash-screen/SET_MAINTENANCE_MODE';

const CLEAR_ERROR = '@user/CLEAR_ERROR';

export {
  USER_LOGOUT,
  GET_USER_INFO_INIT,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  REFRESH_TOKEN_INIT,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  USER_LOGIN_INIT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  RESET_PASSWORD_INIT,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_ERROR,
  SET_MAINTENANCE_MODE,
};
