import React, { useEffect, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, CardTitle, CardToolbar } from '@shared/components';
import { Button, Form, Grid, Table, Popup, Icon } from 'semantic-ui-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import InputField from '@shared/components/translatable/InputField';
import ROUTES from '@shared/consts/routes';
import PERMISSIONS from '@shared/consts/permissions';
import { isEmpty } from 'lodash';
import SCREEN_NAMES from '@shared/consts/screenNames';

const Main = ({
  actions,
  singleRole,
  singleRoleError,
  singleRoleStatus,
  allPermissions,
  rolePermissions,
  rolePermissionsStatus,
  roleUsers,
  roleUsersStatus,
  clickedPermissionIds,
  allUsers,
  clickedUserIds,
  permittedPermissions,
  checkPermissionStatus,
  isPermissionChecked,
}) => {
  const params = useParams();
  const [t] = useTranslation(TRANSLATIONS.ROLES);
  const [roleData, setRoleData] = useState(singleRole);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [searchAddedUserValue, setSearchAddedUserValue] = useState('');
  const [searchPermissionValue, setSearchPermissionValue] = useState('');
  const [searchAddedPermissionValue, setSearchAddedPermissionValue] = useState('');

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  const isViewMode = !isUserPermitted(PERMISSIONS.PERMISSION_EDIT);

  useEffect(() => {
    if (!isEmpty(permittedPermissions) && !isPermissionChecked?.[SCREEN_NAMES.EDIT_ROLE]) {
      if (
        !isUserPermitted(PERMISSIONS.ROLE_EDIT) ||
        !isUserPermitted(PERMISSIONS.ROLE_DELETE) ||
        !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.GET_USER_LIST) ||
        !isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) ||
        !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) ||
        !isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) ||
        !isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE)
      ) {
        actions.setIsPermissionCheckedAction(SCREEN_NAMES.EDIT_ROLE);
        actions.checkPermissionAction([
          !isUserPermitted(PERMISSIONS.ROLE_EDIT) && PERMISSIONS.ROLE_EDIT,
          !isUserPermitted(PERMISSIONS.ROLE_DELETE) && PERMISSIONS.ROLE_DELETE,
          !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) &&
            PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE,
          !isUserPermitted(PERMISSIONS.GET_USER_LIST) && PERMISSIONS.GET_USER_LIST,
          !isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) && PERMISSIONS.GET_PERMISSION_LIST,
          !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) &&
            PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE,
          !isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) && PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE,
          !isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) && PERMISSIONS.ADD_PERMISSION_TO_ROLE,
          !isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) && PERMISSIONS.ADD_USER_TO_ROLE,
          !isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) && PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE,
          !isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE) && PERMISSIONS.REMOVE_USER_FROM_ROLE,
        ]);
      }
    }
  }, [permittedPermissions]);

  const loadRoleInformations = () => {
    actions.getSingleRoleAction(params.id);

    isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) && actions.getPermissionsAction();
    isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) && actions.getRolePermissionsAction(params.id);
    isUserPermitted(PERMISSIONS.GET_USER_LIST) && actions.getUsersAction({ adminOnly: true });
    isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) && actions.getRoleUsersAction(params.id);

    setRoleData(singleRole);
  };

  const refreshPage = () => {
    loadRoleInformations();
  };

  useMountWithTranslate(() => {
    if (checkPermissionStatus === FETCH_STATUS.SUCCESS) {
      loadRoleInformations();
    }
  }, [checkPermissionStatus]);

  useEffect(() => {
    setRoleData(singleRole);
  }, [singleRole]);

  const onChangeContent = (e, { value, name }) => setRoleData(prevState => ({ ...prevState, [name]: value }));

  const onSaveRole = ({ saveAndBack }) => {
    actions.editRoleAction({ roleId: params.id, roleData, saveAndBack });
  };

  const onDeleteRole = () => {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (confirm(`${t('actionButtons.deleteDisclaimer')} ${params.id}?`)) {
      actions.deleteRoleAction(params.id);
    }
  };

  const renderRoleInfo = () => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('singleRole.roleInformation')}</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell className="table-left">{t('singleRole.roleId')}</Table.Cell>
            <Table.Cell textAlign="right">{singleRole?.id}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-left">{t('singleRole.nameField')}</Table.Cell>
            <Table.Cell textAlign="right">
              <Grid>
                <InputField
                  disabled={isViewMode}
                  name="name"
                  disableIcon
                  onChange={onChangeContent}
                  value={roleData?.name}
                  errorMessage={singleRoleError?.name}
                />
              </Grid>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className="table-left">{t('singleRole.descriptionField')}</Table.Cell>
            <Table.Cell textAlign="right">
              <Grid>
                <InputField
                  disabled={isViewMode}
                  name="description"
                  icon="font"
                  onChange={onChangeContent}
                  value={roleData?.description}
                  rows={5}
                  multiline
                  errorMessage={singleRoleError?.description}
                />
              </Grid>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  };

  const renderRolePermissions = () => {
    return (
      <>
        {isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) ? (
          <Grid.Column className="edit-permissions-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('permissionsHeaders.all')}</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '60px' }} />
                </Table.Row>
              </Table.Header>
              <div style={{ padding: '10px' }}>
                <InputField
                  icon="search"
                  value={searchPermissionValue}
                  label={t('searchPermission')}
                  onChange={(e, { value }) => setSearchPermissionValue(value)}
                />
              </div>
              <Table.Body>
                {allPermissions?.length > 0 ? (
                  allPermissions
                    ?.filter?.(item => item?.name?.includes?.(searchPermissionValue))
                    ?.map?.(permission => {
                      return (
                        <Table.Row key={permission.id}>
                          <Table.Cell
                            textAlign={isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) ? 'right' : 'center'}
                            verticalAlign="middle"
                          >
                            <Popup
                              trigger={<Button>{permission?.name}</Button>}
                              content={permission?.description}
                              basic
                            />
                          </Table.Cell>

                          {isUserPermitted(PERMISSIONS.ADD_PERMISSION_TO_ROLE) ? (
                            <Table.Cell collapsing textAlign="center" style={{ width: '60px' }}>
                              {clickedPermissionIds?.includes?.(permission.id) &&
                              rolePermissionsStatus === FETCH_STATUS.LOADING ? (
                                <CircularProgress active size="2rem" />
                              ) : (
                                <Button
                                  icon
                                  color="green"
                                  className="m-0"
                                  onClick={() =>
                                    actions.addPermissionToRoleAction({
                                      roleId: params.id,
                                      permissionId: permission.id,
                                    })
                                  }
                                >
                                  <Icon name="angle double right" style={{ color: 'white' }} />
                                </Button>
                              )}
                            </Table.Cell>
                          ) : null}
                        </Table.Row>
                      );
                    })
                ) : (
                  <div className="empty-list">{t('permissionsHeaders.emptyList')}</div>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        ) : null}

        {isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE) ? (
          <Grid.Column className="edit-permissions-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: '60px' }} />
                  <Table.HeaderCell textAlign="right">{t('permissionsHeaders.current')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <div style={{ padding: '10px' }}>
                <InputField
                  icon="search"
                  value={searchAddedPermissionValue}
                  label={t('searchPermission')}
                  onChange={(e, { value }) => setSearchAddedPermissionValue(value)}
                />
              </div>
              <Table.Body>
                {rolePermissions?.length > 0 ? (
                  rolePermissions
                    ?.filter?.(item => item?.name?.includes?.(searchAddedPermissionValue))
                    ?.map?.(permission => {
                      return (
                        <Table.Row key={permission.id}>
                          {isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) ? (
                            <Table.Cell collapsing verticalAlign="middle" style={{ width: '60px' }}>
                              {clickedPermissionIds?.includes?.(permission.id) &&
                              rolePermissionsStatus === FETCH_STATUS.LOADING ? (
                                <CircularProgress active size="2rem" />
                              ) : (
                                <Button
                                  icon
                                  color="red"
                                  className="m-0"
                                  onClick={() =>
                                    actions.removePermissionFromRoleAction({
                                      roleId: params.id,
                                      permissionId: permission.id,
                                    })
                                  }
                                >
                                  <Icon name="angle double left" style={{ color: 'white' }} />
                                </Button>
                              )}
                            </Table.Cell>
                          ) : null}
                          <Table.Cell
                            textAlign={isUserPermitted(PERMISSIONS.REMOVE_PERMISSION_FROM_ROLE) ? 'left' : 'center'}
                          >
                            <Popup
                              trigger={<Button>{permission?.name}</Button>}
                              content={permission?.description}
                              basic
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                ) : (
                  <div className="empty-list">{t('permissionsHeaders.emptyList')}</div>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        ) : null}
      </>
    );
  };

  const renderRoleUsers = () => {
    return (
      <>
        {isUserPermitted(PERMISSIONS.GET_USER_LIST) ? (
          <Grid.Column className="edit-users-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('usersHeaders.all')}</Table.HeaderCell>
                  <Table.HeaderCell style={{ width: '60px' }} />
                </Table.Row>
              </Table.Header>
              <div style={{ padding: '10px' }}>
                <InputField
                  icon="search"
                  label={t('searchUser')}
                  value={searchUserValue}
                  onChange={(e, { value }) => setSearchUserValue(value)}
                />
              </div>
              <Table.Body>
                {allUsers?.length > 0 ? (
                  allUsers
                    ?.filter?.(
                      item =>
                        item?.lastName?.includes?.(searchUserValue) ||
                        item?.firstName?.includes?.(searchUserValue) ||
                        item?.email?.includes?.(searchUserValue),
                    )
                    ?.map?.(user => {
                      return (
                        <Table.Row key={user.id}>
                          <Table.Cell textAlign={isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) ? 'right' : 'center'}>
                            <Popup
                              trigger={<Button>{`${user?.firstName} ${user?.lastName} - ${user?.email}`}</Button>}
                              flowing
                              hoverable
                            >
                              <div className="d-flex flex-column justify-content-center">
                                <p>{user?.email}</p>
                                <Button as={Link} to={`/users/${user?.id}`}>
                                  {t('actionButtons.view')}
                                </Button>
                              </div>
                            </Popup>
                          </Table.Cell>
                          {isUserPermitted(PERMISSIONS.ADD_USER_TO_ROLE) ? (
                            <Table.Cell collapsing verticalAlign="middle" style={{ width: '60px' }}>
                              {clickedUserIds?.includes?.(user.id) && roleUsersStatus === FETCH_STATUS.LOADING ? (
                                <CircularProgress active size="2rem" />
                              ) : (
                                <Button
                                  icon
                                  color="green"
                                  className="m-0"
                                  onClick={() => actions.addUserToRoleAction({ roleId: params.id, userId: user.id })}
                                >
                                  <Icon name="angle double right" style={{ color: 'white' }} />
                                </Button>
                              )}
                            </Table.Cell>
                          ) : null}
                        </Table.Row>
                      );
                    })
                ) : (
                  <div className="empty-list">{t('usersHeaders.emptyList')}</div>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        ) : null}

        {isUserPermitted(PERMISSIONS.GET_USERS_ASSIGNED_TO_ROLE) ? (
          <Grid.Column className="edit-users-table">
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width: '60px' }} />
                  <Table.HeaderCell>{t('usersHeaders.current')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <div style={{ padding: '10px' }}>
                <InputField
                  icon="search"
                  value={searchAddedUserValue}
                  label={t('searchUser')}
                  onChange={(e, { value }) => setSearchAddedUserValue(value)}
                />
              </div>
              <Table.Body>
                {roleUsers?.length > 0 ? (
                  roleUsers
                    ?.filter?.(
                      item =>
                        item?.lastName?.includes?.(searchAddedUserValue) ||
                        item?.firstName?.includes?.(searchAddedUserValue) ||
                        item?.email?.includes?.(searchAddedUserValue),
                    )
                    ?.map?.(user => {
                      return (
                        <Table.Row key={user.id}>
                          {isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE) ? (
                            <Table.Cell collapsing verticalAlign="middle" style={{ width: '60px' }}>
                              {clickedUserIds?.includes?.(user.id) && roleUsersStatus === FETCH_STATUS.LOADING ? (
                                <CircularProgress active size="2rem" />
                              ) : (
                                <Button
                                  icon
                                  color="red"
                                  className="m-0"
                                  onClick={() =>
                                    actions.removeUserFromRoleAction({ roleId: params.id, userId: user.id })
                                  }
                                >
                                  <Icon name="angle double left" style={{ color: 'white' }} />
                                </Button>
                              )}
                            </Table.Cell>
                          ) : null}
                          <Table.Cell
                            textAlign={isUserPermitted(PERMISSIONS.REMOVE_USER_FROM_ROLE) ? 'left' : 'center'}
                          >
                            <Popup
                              trigger={<Button>{`${user?.firstName} ${user?.lastName} - ${user?.email}`}</Button>}
                              content={user?.email}
                              flowing
                              hoverable
                            >
                              <div className="d-flex flex-column justify-content-center">
                                <p>{user?.email}</p>
                                <Button as={Link} to={`/users/${user?.id}`}>
                                  {t('actionButtons.view')}
                                </Button>
                              </div>
                            </Popup>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })
                ) : (
                  <div className="empty-list">{t('usersHeaders.emptyList')}</div>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        ) : null}
      </>
    );
  };

  const renderMenuButtons = () => {
    return (
      <CardHeader>
        <CardTitle title={t('singleRole.mainTitle')} />
        <CardToolbar>
          <Button style={{ marginRight: 5 }} as={Link} type="button" to={ROUTES.ROLES}>
            {t('actionButtons.goBack')}
          </Button>

          {isUserPermitted(PERMISSIONS.ROLE_DELETE) ? (
            <Button
              color="red"
              style={{ marginRight: 5 }}
              disabled={singleRoleStatus === FETCH_STATUS.LOADING}
              onClick={onDeleteRole}
            >
              {t('actionButtons.delete')}
            </Button>
          ) : null}

          <Button
            color="black"
            style={{ marginRight: 5 }}
            disabled={singleRoleStatus === FETCH_STATUS.LOADING}
            onClick={refreshPage}
          >
            {t('actionButtons.reload')}
          </Button>

          {isViewMode ? null : (
            <Button
              primary
              style={{ marginRight: 5 }}
              disabled={singleRoleStatus === FETCH_STATUS.LOADING}
              onClick={onSaveRole}
            >
              {t('actionButtons.save')}
            </Button>
          )}

          {isViewMode ? null : (
            <Button
              color="facebook"
              disabled={singleRoleStatus === FETCH_STATUS.LOADING}
              onClick={() => onSaveRole({ saveAndBack: true })}
            >
              {t('actionButtons.saveAndBack')}
            </Button>
          )}
        </CardToolbar>
      </CardHeader>
    );
  };

  return (
    <Card className="admin-roles">
      {renderMenuButtons()}
      <CardBody transparent>
        <Form loading={singleRoleStatus === FETCH_STATUS.LOADING || checkPermissionStatus === FETCH_STATUS.IDLE}>
          <Grid columns={1}>
            <Grid.Column>{renderRoleInfo()}</Grid.Column>
          </Grid>
          <Grid
            columns={
              !isUserPermitted(PERMISSIONS.GET_USER_LIST) ||
              !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE)
                ? 1
                : 2
            }
          >
            {renderRoleUsers()}
          </Grid>
          <Grid
            columns={
              !isUserPermitted(PERMISSIONS.GET_PERMISSION_LIST) ||
              !isUserPermitted(PERMISSIONS.GET_PERMISSIONS_ASSIGNED_TO_ROLE)
                ? 1
                : 2
            }
          >
            {renderRolePermissions()}
          </Grid>
        </Form>
      </CardBody>
      {renderMenuButtons()}
    </Card>
  );
};

Main.propTypes = {};

Main.defaultProps = {};

export default Main;
