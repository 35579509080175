import React, { useMemo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useHtmlClassService } from '@metronic/providers';
import AsideMenuList from './AsideMenuList';

const AsideMenu = ({ sidebarMenuItems, menuStatus, logoutUser }) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      // asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true),
    };
  }, [uiService]);

  return (
    <>
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <AsideMenuList
          logoutUser={logoutUser}
          menuStatus={menuStatus}
          layoutProps={layoutProps}
          sidebarMenuItems={sidebarMenuItems}
        />
      </div>
    </>
  );
};

AsideMenu.propTypes = {
  sidebarMenuItems: arrayOf(shape({})),
  menuStatus: string.isRequired,
  logoutUser: func.isRequired,
};

AsideMenu.defaultProps = {
  sidebarMenuItems: [],
};

export default AsideMenu;
