import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Button, Form, Grid, Icon, Popup, Table } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { CircularProgress, Container } from '@material-ui/core';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Link } from 'react-router-dom';
import ROUTES from '@shared/consts/routes';
import InputField from '@shared/components/translatable/InputField';

const UserRolesList = ({
  allRoles,
  clickedRolesIds,
  userRoles,
  allRolesStatus,
  status,
  addRoleToUser,
  removeRoleFromUser,
  handleRefresh,
  showAssignButtons,
  showRemoveButtons,
}) => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation(TRANSLATIONS.USERS);
  const [searchUserValue, setSearchUserValue] = useState('');
  const [searchAddedUserValue, setSearchAddedUserValue] = useState('');

  const handleAddRole = async roleId => {
    await setIsLoading(true);
    await addRoleToUser(roleId, params.id);
    await setIsLoading(false);
  };

  const renderAllRoles = () => (
    <Grid.Column className="edit-users-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colspan={2}>{t('userRoles.availableRoles')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <div style={{ padding: '10px' }}>
          <InputField
            icon="search"
            label={t('searchRole')}
            value={searchUserValue}
            onChange={(e, { value }) => setSearchUserValue(value)}
          />
        </div>
        <Table.Body>
          {allRoles?.length > 0 ? (
            allRoles
              ?.filter?.(item => item?.name?.toLowerCase?.()?.includes?.(searchUserValue?.toLowerCase?.()))
              ?.map?.(role => (
                <Table.Row key={role?.id}>
                  <Table.Cell>
                    <Popup trigger={<Button>{role?.name}</Button>} content={role?.description} basic />
                  </Table.Cell>
                  {showAssignButtons ? (
                    <Table.Cell collapsing textAlign="center" style={{ width: '60px' }}>
                      {clickedRolesIds?.includes?.(role?.id) && status === FETCH_STATUS.LOADING ? (
                        <CircularProgress active size="2rem" />
                      ) : (
                        <Button color="green" icon className="m-0" onClick={() => handleAddRole(role?.id)}>
                          <Icon name="angle double right" style={{ color: '#ffffff' }} />
                        </Button>
                      )}
                    </Table.Cell>
                  ) : null}
                </Table.Row>
              ))
          ) : (
            <div className="empty-list">{t('emptyList')}</div>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );

  const renderUserRoles = () => (
    <Grid.Column className="edit-users-table">
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colspan={2}>{t('userRoles.userRoles')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <div style={{ padding: '10px' }}>
          <InputField
            icon="search"
            value={searchAddedUserValue}
            label={t('searchRole')}
            onChange={(e, { value }) => setSearchAddedUserValue(value)}
          />
        </div>
        <Table.Body>
          {userRoles?.length > 0 ? (
            userRoles
              ?.filter?.(item => item?.name?.toLowerCase?.()?.includes?.(searchAddedUserValue?.toLowerCase?.()))
              ?.map?.(role => (
                <Table.Row key={role?.id}>
                  {showRemoveButtons ? (
                    <Table.Cell collapsing textAlign="center" style={{ width: '60px' }}>
                      {clickedRolesIds?.includes?.(role?.id) && status === FETCH_STATUS.LOADING ? (
                        <CircularProgress active size="2rem" />
                      ) : (
                        <Button
                          color="red"
                          icon
                          className="m-0"
                          onClick={() => removeRoleFromUser(role?.id, params.id)}
                        >
                          <Icon name="angle double left" style={{ color: '#ffffff' }} />
                        </Button>
                      )}
                    </Table.Cell>
                  ) : null}
                  <Table.Cell textAlign="right">
                    <Popup trigger={<Button>{role?.name}</Button>} content={role?.description} basic />
                  </Table.Cell>
                </Table.Row>
              ))
          ) : (
            <div className="empty-list">{t('emptyList')}</div>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );

  return (
    <Form loading={status === FETCH_STATUS.IDLE || allRolesStatus === FETCH_STATUS.IDLE || isLoading}>
      <Container maxWidth="md" className="py-10">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="mb-5">{t('userRoles.title')}</h2>
          <div>
            <Button as={Link} type="button" to={ROUTES.USERS}>
              {t('actionButtons.goBack')}
            </Button>
            <Button onClick={handleRefresh} color="black">
              {t('actionButtons.reload')}
            </Button>
          </div>
        </div>
        <Grid columns={2}>
          {renderAllRoles()}
          {renderUserRoles()}
        </Grid>
      </Container>
    </Form>
  );
};

UserRolesList.propTypes = {
  allRoles: arrayOf(shape({})),
  clickedRolesIds: arrayOf(string),
  userRoles: arrayOf(shape({})),
  allRolesStatus: string,
  status: string,
  addRoleToUser: func,
  removeRoleFromUser: func,
  handleRefresh: func,
  showAssignButtons: bool,
  showRemoveButtons: bool,
};

UserRolesList.defaultProps = {
  allRoles: [],
  clickedRolesIds: [],
  userRoles: [],
  allRolesStatus: undefined,
  status: undefined,
  addRoleToUser: () => {},
  removeRoleFromUser: () => {},
  handleRefresh: () => {},
  showAssignButtons: false,
  showRemoveButtons: false,
};

export default UserRolesList;
