import {
  GET_INVESTORS_INIT,
  GET_INVESTORS_SUCCESS,
  GET_INVESTORS_FAILURE,
  GET_SINGLE_INVESTOR_SUCCESS,
  GET_SINGLE_INVESTOR_INIT,
  GET_SINGLE_INVESTOR_FAILURE,
  GET_COUNTRIES_INIT,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
} from '@store/action-types/investors';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  investors: [],
  singleInvestor: {},
  countries: [],
  countryStatus: undefined,
  singleStatus: undefined,
  status: FETCH_STATUS.IDLE,
  error: undefined,
};

const investorsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INVESTORS_INIT: {
      return { ...initialState };
    }
    case GET_INVESTORS_SUCCESS: {
      return {
        ...state,
        investors: payload.investors,
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_INVESTORS_FAILURE: {
      return {
        ...state,
        investors: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_INVESTOR_INIT: {
      return {
        ...state,
        singleInvestor: {},
        singleStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case GET_SINGLE_INVESTOR_SUCCESS: {
      return {
        ...state,
        singleInvestor: payload.singleInvestor.data,
        singleStatus: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_SINGLE_INVESTOR_FAILURE: {
      return {
        ...state,
        singleInvestor: {},
        singleStatus: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case GET_COUNTRIES_INIT: {
      return {
        ...state,
        countries: [],
        countryStatus: FETCH_STATUS.IDLE,
        error: undefined,
      };
    }
    case GET_COUNTRIES_SUCCESS: {
      return {
        ...state,
        countries: [...payload.countries],
        countryStatus: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_COUNTRIES_FAILURE: {
      return {
        ...state,
        countries: [],
        countryStatus: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    default:
      return state;
  }
};

export default investorsReducer;
