import { GET_PAGES_FAILURE, GET_PAGES_INIT, GET_PAGES_SUCCESS } from '@store/action-types/page';
import Client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getPagesInit = () => ({ type: GET_PAGES_INIT });
const getPagesSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_PAGES_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getPagesFailure = error => ({ type: GET_PAGES_FAILURE, payload: { error } });

const getPages = ({ activeFilters, pagination, order } = {}) => async dispatch => {
  dispatch(getPagesInit());
  const urlQueryParams = getUrlQueryParams();

  try {
    const pages = await Client.getPages({ params: activeFilters || urlQueryParams?.all });
    dispatch(
      getPagesSuccess({
        response: pages.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getPagesFailure(error));
  }
};

export default getPages;
