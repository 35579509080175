import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { uploadSingleFileAction, uploadSinglePhotoAction } from '@store/actions/single-upload';
import TranslatableRichTextField from './TranslatableRichTextField';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      uploadSingleFileAction,
      uploadSinglePhotoAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(TranslatableRichTextField);
