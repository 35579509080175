import { ADD_USER_TO_ROLE_INIT, ADD_USER_TO_ROLE_SUCCESS, ADD_USER_TO_ROLE_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const addUserToRoleInit = clickedUserId => ({ type: ADD_USER_TO_ROLE_INIT, payload: { clickedUserId } });
const addUserToRoleSuccess = response => ({
  type: ADD_USER_TO_ROLE_SUCCESS,
  payload: { response },
});
const addUserToRoleFailure = error => ({ type: ADD_USER_TO_ROLE_FAILURE, payload: { error } });

const addUserToRole = ({ roleId, userId }) => async dispatch => {
  dispatch(addUserToRoleInit(userId));

  try {
    const addUserToRoleResponse = await client.addUserToRole({
      slugs: [roleId, 'users', userId],
    });

    dispatch(addUserToRoleSuccess(addUserToRoleResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.user.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(addUserToRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.user.error.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default addUserToRole;
