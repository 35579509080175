import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: '#e5e5e5',
    margin: '8px 0',
    padding: 8,
    fontSize: 20,
    borderRadius: 4,
    color: 'rgba(0,0,0,.6)',
    display: 'flex',
  },
  name: {
    marginLeft: 8,
  },
}));

export default useStyles;
