import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  formWrapper: {
    flexDirection: 'column !important',
  },
  imagePreview: {
    maxWidth: '100%',
  },
  deletedPhoto: {
    filter: 'opacity(0.4)',
  },
}));

export default useStyles;
