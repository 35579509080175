import {
  GET_PAYPAL_CONFIG_INIT,
  GET_PAYPAL_CONFIG_SUCCESS,
  GET_PAYPAL_CONFIG_FAILURE,
  EDIT_PAYPAL_CONFIG_INIT,
  EDIT_PAYPAL_CONFIG_SUCCESS,
  EDIT_PAYPAL_CONFIG_FAILURE,
} from '@store/action-types/paypal';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  paypalConfig: {},
  status: FETCH_STATUS.IDLE,
  error: undefined,
};

const paypalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAYPAL_CONFIG_INIT: {
      return { ...initialState };
    }
    case GET_PAYPAL_CONFIG_SUCCESS: {
      return {
        paypalConfig: payload?.paypalConfig,
        status: FETCH_STATUS.SUCCESS,
        error: undefined,
      };
    }
    case GET_PAYPAL_CONFIG_FAILURE: {
      return {
        paypalConfig: {},
        status: FETCH_STATUS.FAILURE,
        error: payload?.error,
      };
    }
    case EDIT_PAYPAL_CONFIG_INIT: {
      return { ...state };
    }
    case EDIT_PAYPAL_CONFIG_SUCCESS: {
      return { ...state };
    }
    case EDIT_PAYPAL_CONFIG_FAILURE: {
      return { ...state };
    }
    default:
      return state;
  }
};

export default paypalReducer;
