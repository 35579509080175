import {
  GET_PERSONAL_PERMISSIONS_INIT,
  GET_PERSONAL_PERMISSIONS_SUCCESS,
  GET_PERSONAL_PERMISSIONS_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';

const getPersonalPermissionsInit = () => ({ type: GET_PERSONAL_PERMISSIONS_INIT });
const getPersonalPermissionsSuccess = personalPermissions => ({
  type: GET_PERSONAL_PERMISSIONS_SUCCESS,
  payload: { personalPermissions },
});
const getPersonalPermissionsFailure = personalPermissionsErrors => ({
  type: GET_PERSONAL_PERMISSIONS_FAILURE,
  payload: { personalPermissionsErrors },
});

const getPersonalPermissions = userId => async dispatch => {
  dispatch(getPersonalPermissionsInit());

  try {
    const personalPermissions = await client.getPersonalPermissions({ slug: `${userId}/personal-permissions` });
    dispatch(getPersonalPermissionsSuccess(personalPermissions?.response));
  } catch (error) {
    dispatch(getPersonalPermissionsFailure(error));
  }
};

export default getPersonalPermissions;
