import React, { useLayoutEffect } from 'react';
import { KTUtil } from '@metronic/js/util';
import KTLayoutAside from '@metronic/js/aside';
import KTLayoutAsideToggle from '@metronic/js/aside-toggle';
import KTLayoutAsideMenu from '@metronic/js/aside-menu';
import KTLayoutContent from '@metronic/js/content';

const LayoutInit = () => {
  useLayoutEffect(() => {
    KTUtil.ready(() => {
      KTLayoutAside.init('kt_aside');
      KTLayoutAsideToggle.init('kt_aside_toggle');
      KTLayoutAsideMenu.init('kt_aside_menu');
      KTLayoutContent.init('kt_content');
    });
  }, []);
  return <></>;
};

export default LayoutInit;
