import { GET_USERS_INIT, GET_USERS_SUCCESS, GET_USERS_FAILURE } from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import { getUrlQueryParams } from '@shared/components/Table/utils';

const getUsersInit = () => ({ type: GET_USERS_INIT });
const getUsersSuccess = ({ response, activeFilters, pagination, order }) => ({
  type: GET_USERS_SUCCESS,
  payload: {
    items: response?.items,
    activeFilters,
    configuredFilters: response?.configuredFilters,
    configuredOrder: response?.configuredOrder,
    appliedOrder: response?.appliedOrder,
    configuredPagination: response?.pagination,
    pagination,
    order,
  },
});
const getUsersFailure = error => ({ type: GET_USERS_FAILURE, payload: { error } });

const getUsers = ({ activeFilters, pagination, adminOnly = false, order } = {}) => async dispatch => {
  dispatch(getUsersInit());
  const urlQueryParams = getUrlQueryParams();
  const paramsRequest = activeFilters || urlQueryParams?.all;

  try {
    const allUsers = await client.getUsers({ params: adminOnly ? { ...paramsRequest, onlyAdmin: 1 } : paramsRequest });
    dispatch(
      getUsersSuccess({
        response: allUsers.response,
        activeFilters: activeFilters || urlQueryParams?.activeFilters,
        pagination: pagination || urlQueryParams?.pagination,
        order: order || urlQueryParams?.order,
      }),
    );
  } catch (error) {
    dispatch(getUsersFailure(error));
  }
};

export default getUsers;
