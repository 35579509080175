import React, { PureComponent } from 'react';
import clsx from 'clsx';
import { Dropdown, Form, Grid, Icon, Label } from 'semantic-ui-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { withStyles } from '@material-ui/core/styles';
import { arrayOf, bool, func, shape, string } from 'prop-types';

const styles = () => ({
  errorLabel: {
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'center !important',
    width: 'fit-content !important',
  },
  icon: {
    '&:hover': { cursor: 'pointer' },
  },
});

class Select extends PureComponent {
  render() {
    const {
      name,
      value,
      label,
      onChange,
      placeholder,
      errorMessage,
      labelClassName,
      tooltip,
      classes,
      options,
      button,
      iconPosition,
      ...props
    } = this.props;

    return (
      <Grid.Column>
        <Form.Field>
          {errorMessage && (
            <Label color="red" className={clsx('mb-5', classes.errorLabel, labelClassName)}>
              {errorMessage}
            </Label>
          )}
          <Form.Field className="d-flex justify-content-between">
            <label>
              {label}{' '}
              {tooltip && (
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip">{tooltip}</Tooltip>}
                >
                  <Icon className={classes.icon} name="info circle" />
                </OverlayTrigger>
              )}
            </label>
          </Form.Field>
          <Dropdown
            button={button}
            placeholder={placeholder}
            iconPosition={iconPosition}
            name={name}
            options={options}
            value={value}
            selection
            onChange={onChange}
            {...props}
          />
        </Form.Field>
      </Grid.Column>
    );
  }
}

Select.propTypes = {
  name: string,
  value: string,
  label: string,
  placeholder: string,
  onChange: func,
  errorMessage: string,
  labelClassName: string,
  tooltip: string,
  classes: shape({}),
  options: arrayOf(shape({})),
  button: bool,
  iconPosition: string,
};

Select.defaultProps = {
  name: '',
  value: '',
  label: '',
  placeholder: '',
  onChange: func,
  errorMessage: '',
  labelClassName: '',
  tooltip: '',
  classes: {},
  options: [],
  button: true,
  iconPosition: '',
};

export default withStyles(styles)(Select);
