import {
  GET_SERIES_OPTIONS_INIT,
  GET_SERIES_OPTIONS_SUCCESS,
  GET_SERIES_OPTIONS_FAILURE,
} from '@store/action-types/series';
import client from '@api-sdk/client';

const getSeriesOptionsInit = () => ({ type: GET_SERIES_OPTIONS_INIT });
const getSeriesOptionsSuccess = seriesOptions => ({ type: GET_SERIES_OPTIONS_SUCCESS, payload: { seriesOptions } });
const getSeriesOptionsFailure = error => ({ type: GET_SERIES_OPTIONS_FAILURE, payload: { error } });

const getSeries = () => async dispatch => {
  dispatch(getSeriesOptionsInit());

  try {
    const series = await client.getSeries();
    dispatch(
      getSeriesOptionsSuccess(
        series?.response?.items?.map?.(item => ({ key: item.id, text: item.name, value: item.id })),
      ),
    );
  } catch (error) {
    dispatch(getSeriesOptionsFailure(error));
  }
};

export default getSeries;
