import React from 'react';
import { func, shape } from 'prop-types';
import { PositionSelect } from '@shared/components/OfferForm/components';
import { InputAdornment, TextField, Button, IconButton } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import useMount from '@shared/utils/useMountHook';
import { isEmpty } from 'lodash';
import useStyles from './styles';

const Counter = ({
  handleAddArrayToData,
  handleAddElementInElement,
  setElementData,
  setElementDataWithArray,
  handleRemoveElementFromElement,
  arrayOfElementsSetPosition,
  elementValue,
}) => {
  const styles = useStyles();

  useMount(() => {
    isEmpty(elementValue) && handleAddArrayToData();
  });

  const handleChange = e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementData(key, value);
  };

  const handleElementChange = counterId => e => {
    const key = e.target.name;
    const value = e.target.value;

    setElementDataWithArray(counterId, key, value);
  };

  const handleAddNewRow = () => {
    const position = elementValue?.elements.length + 1;
    handleAddElementInElement(position);
  };

  const handleRemoveRow = counterId => {
    handleRemoveElementFromElement(counterId);
  };

  const handleSelectChange = (e, counterId) => {
    const newIndex = Number(e.target.value);
    arrayOfElementsSetPosition(counterId, newIndex);
  };

  const renderCounterElements = () => {
    return elementValue?.elements?.map?.((item, index) => (
      <li className={styles.listItem} key={index}>
        <TextField
          variant="outlined"
          size="small"
          name="label"
          label="Label"
          className={styles.inputText}
          onChange={handleElementChange(index)}
          value={elementValue?.elements[index].label || ''}
        />
        <TextField
          variant="outlined"
          size="small"
          name="countFrom"
          label="Count from"
          type="number"
          className={styles.inputNumber}
          onChange={handleElementChange(index)}
          value={elementValue?.elements[index].countFrom || ''}
          InputProps={{
            startAdornment: <InputAdornment position="start">From</InputAdornment>,
          }}
        />
        <TextField
          variant="outlined"
          size="small"
          name="countTo"
          label="Count to"
          type="number"
          className={styles.inputNumber}
          onChange={handleElementChange(index)}
          value={elementValue?.elements[index].countTo || ''}
          InputProps={{
            startAdornment: <InputAdornment position="start">To</InputAdornment>,
          }}
        />
        <PositionSelect
          handleChange={e => handleSelectChange(e, index)}
          numberOfOptions={elementValue?.elements?.length}
          id={index}
          className={styles.select}
        />
        {elementValue?.elements?.length > 1 && (
          <IconButton onClick={() => handleRemoveRow(index)}>
            <RemoveCircleIcon />
          </IconButton>
        )}
      </li>
    ));
  };

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div>
          <TextField
            onChange={handleChange}
            value={elementValue?.title || ''}
            name="title"
            className="mr-4 mb-4"
            variant="outlined"
            size="small"
            label="Title"
          />
          <TextField
            className="mr-4 mb-4"
            variant="outlined"
            type="number"
            size="small"
            name="animationDuration"
            label="Animation Duration"
            onChange={handleChange}
            value={elementValue?.animationDuration || ''}
            InputProps={{
              endAdornment: <InputAdornment position="end">sec</InputAdornment>,
            }}
          />
        </div>
        <Button onClick={handleAddNewRow} className="mb-4" size="small" variant="outlined">
          add
        </Button>
      </div>
      <div>
        <ul>{renderCounterElements()}</ul>
      </div>
    </div>
  );
};

Counter.propTypes = {
  handleAddArrayToData: func.isRequired,
  handleAddElementInElement: func.isRequired,
  setElementData: func.isRequired,
  setElementDataWithArray: func.isRequired,
  handleRemoveElementFromElement: func.isRequired,
  arrayOfElementsSetPosition: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default Counter;
