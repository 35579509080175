import {
  GET_FAQ_INIT,
  GET_FAQ_FAILURE,
  GET_FAQ_SUCCESS,
  GET_SINGLE_FAQ_SUCCESS,
  GET_SINGLE_FAQ_INIT,
  GET_SINGLE_FAQ_FAILURE,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_INIT,
  DELETE_FAQ_FAILURE,
  EDIT_FAQ_INIT,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_FAILURE,
  CREATE_FAQ_INIT,
  CREATE_FAQ_FAILURE,
  CREATE_FAQ_SUCCESS,
  CLEAR_STATUS,
} from '@store/action-types/faq';
import FETCH_STATUS from '@shared/consts/fetchStatuses';

const initialState = {
  allFaq: [],
  singleFaq: {},
  status: FETCH_STATUS.IDLE,
  singleStatus: undefined,
  deleteStatus: undefined,
  editStatus: undefined,
  createStatus: undefined,
  error: undefined,
  queryParams: {},
};

const faqReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_FAQ_INIT: {
      return {
        ...state,
        status: FETCH_STATUS.LOADING,
        queryParams: {},
      };
    }
    case GET_FAQ_SUCCESS: {
      return {
        allFaq: payload?.items,
        queryParams: {
          configuredFilters: payload?.configuredFilters,
          configuredPagination: payload?.configuredPagination,
          configuredOrder: payload?.configuredOrder,
          appliedOrder: payload?.appliedOrder,
          activeFilters: payload?.activeFilters,
          pagination: payload?.pagination,
          order: payload?.order,
        },
        error: undefined,
        status: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_FAQ_FAILURE: {
      return {
        allFaq: [],
        error: payload.error,
        status: FETCH_STATUS.FAILURE,
      };
    }
    case GET_SINGLE_FAQ_INIT: {
      return {
        ...state,
        singleStatus: FETCH_STATUS.IDLE,
      };
    }
    case GET_SINGLE_FAQ_SUCCESS: {
      return {
        ...state,
        singleFaq: { ...payload.singleFaq },
        error: undefined,
        singleStatus: FETCH_STATUS.SUCCESS,
      };
    }
    case GET_SINGLE_FAQ_FAILURE: {
      return {
        ...state,
        singleFaq: {},
        error: payload.error,
        singleStatus: FETCH_STATUS.FAILURE,
      };
    }
    case DELETE_FAQ_INIT: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.IDLE,
        error: {},
      };
    }
    case DELETE_FAQ_SUCCESS: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case DELETE_FAQ_FAILURE: {
      return {
        ...state,
        deleteStatus: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case EDIT_FAQ_INIT: {
      return {
        ...state,
        editStatus: FETCH_STATUS.IDLE,
        error: {},
      };
    }
    case EDIT_FAQ_SUCCESS: {
      return {
        ...state,
        editStatus: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case EDIT_FAQ_FAILURE: {
      return {
        ...state,
        editStatus: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CREATE_FAQ_INIT: {
      return {
        ...state,
        createStatus: FETCH_STATUS.IDLE,
        error: {},
      };
    }
    case CREATE_FAQ_SUCCESS: {
      return {
        ...state,
        createStatus: FETCH_STATUS.SUCCESS,
        error: {},
      };
    }
    case CREATE_FAQ_FAILURE: {
      return {
        ...state,
        createStatus: FETCH_STATUS.FAILURE,
        error: payload.error,
      };
    }
    case CLEAR_STATUS: {
      return {
        ...state,
        status: undefined,
      };
    }
    default:
      return state;
  }
};

export default faqReducer;
