import { Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React from 'react';
import { string } from 'prop-types';

const NotFoundMessage = ({ title, subtitle, buttonText, to }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center" style={{ minHeight: 320 }}>
      <Header size="large" className="my-2" disabled style={{ height: 'fit-content' }}>
        {title}
      </Header>
      {subtitle && (
        <Header className="mt-2 mb-4" size="medium" disabled style={{ height: 'fit-content' }}>
          {subtitle}
        </Header>
      )}
      {buttonText && to ? (
        <Button primary as={Link} disabled={!to} to={to}>
          {buttonText}
        </Button>
      ) : null}
    </div>
  );
};

NotFoundMessage.propTypes = {
  title: string.isRequired,
  subtitle: string,
  buttonText: string,
  to: string,
};

NotFoundMessage.defaultProps = {
  subtitle: undefined,
  buttonText: 'Create',
  to: '',
};

export default NotFoundMessage;
