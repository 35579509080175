import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    background: 'none !important',
    borderRadius: 0,
  },
}));

export default useStyles;
