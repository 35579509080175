import {
  GET_USER_AGREEMENTS_INIT,
  GET_USER_AGREEMENTS_SUCCESS,
  GET_USER_AGREEMENTS_FAILURE,
} from '@store/action-types/agreements';
import client from '@api-sdk/client';

const getUserAgreementsInit = () => ({ type: GET_USER_AGREEMENTS_INIT });
const getUserAgreementsSuccess = userAgreements => ({ type: GET_USER_AGREEMENTS_SUCCESS, payload: { userAgreements } });
const getUserAgreementsFailure = errors => ({ type: GET_USER_AGREEMENTS_FAILURE, payload: { errors } });

const getUserAgreements = userId => async dispatch => {
  dispatch(getUserAgreementsInit());

  try {
    const userAgreements = await client.getUserAgreements({ slug: userId });
    dispatch(getUserAgreementsSuccess(userAgreements.response));
  } catch (error) {
    dispatch(getUserAgreementsFailure(error));
  }
};

export default getUserAgreements;
