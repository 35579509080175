import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getAgreementsAction, getSingleAgreementAction, deleteAgreementAction } from '@store/actions/agreements';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import {
  getAllAgreements,
  getAgreementsStatus,
  getSingleAgreementStatus,
  getSingleAgreement,
  getDeleteStatus,
  getAllAgreementsQueryParams,
} from '@store/reducers/agreements/selectors';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  allAgreements: getAllAgreements(state),
  agreementsStatus: getAgreementsStatus(state),
  singleStatus: getSingleAgreementStatus(state),
  singleAgreement: getSingleAgreement(state),
  deleteStatus: getDeleteStatus(state),
  queryParams: getAllAgreementsQueryParams(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getAgreementsAction,
      getSingleAgreementAction,
      deleteAgreementAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
