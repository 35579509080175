const newPageObject = () => ({
  active: false,
  image: {},
  slug: {
    pl: '',
  },
  seoTitle: {
    pl: '',
  },
  seoDescription: {
    pl: '',
  },
  title: {
    pl: '',
  },
  subTitle: {
    pl: '',
  },
  content: {
    pl: '',
  },
  attachments: [],
});

export default newPageObject;
