const STATIC_CONTENT_TYPE = {
  TEXT: 'text',
  COLLECTION: 'collection',
  IMAGE: 'image',
  RICH_TEXT: 'richText',
};

const ICONS = {
  TEXT: 'font',
  IMAGE: 'image',
  RICH_TEXT: 'newspaper',
};

export { STATIC_CONTENT_TYPE, ICONS };
