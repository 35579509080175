import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { getStaticContentAction } from '@store/actions/static-content';
import { getStaticContentStatus, getStaticContent } from '@store/reducers/static-content/selectors';
import { setIsPermissionCheckedAction, checkPermissionAction } from '@store/actions/check-permission';
import {
  getIsPermissionChecked,
  getPermissionCheckStatus,
  getPermittedPermissions,
} from '@store/reducers/check-permission/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  status: getStaticContentStatus(state),
  staticContent: getStaticContent(state),
  permittedPermissions: getPermittedPermissions(state),
  isPermissionChecked: getIsPermissionChecked(state),
  checkPermissionStatus: getPermissionCheckStatus(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getStaticContentAction,
      setIsPermissionCheckedAction,
      checkPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
