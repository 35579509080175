import {
  ADD_PERMISSION_TO_ROLE_INIT,
  ADD_PERMISSION_TO_ROLE_SUCCESS,
  ADD_PERMISSION_TO_ROLE_FAILURE,
} from '@store/action-types/roles';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const addPermissionToRoleInit = clickedPermissionId => ({
  type: ADD_PERMISSION_TO_ROLE_INIT,
  payload: { clickedPermissionId },
});
const addPermissionToRoleSuccess = response => ({
  type: ADD_PERMISSION_TO_ROLE_SUCCESS,
  payload: { response },
});
const addPermissionToRoleFailure = error => ({ type: ADD_PERMISSION_TO_ROLE_FAILURE, payload: { error } });

const addPermissionToRole = ({ roleId, permissionId }) => async dispatch => {
  dispatch(addPermissionToRoleInit(permissionId));

  try {
    const addPermissionToRoleResponse = await client.addPermissionToRole({
      slugs: [roleId, 'permissions', permissionId],
    });

    dispatch(addPermissionToRoleSuccess(addPermissionToRoleResponse.response));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.permission.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(addPermissionToRoleFailure(error?.errors?.[0]?.code));
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('role.permission.error.add'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
  }
};

export default addPermissionToRole;
