const getAllInvestors = state => state?.investors?.investors;
const getAllInvestorsStatus = state => state?.investors?.status;
const getSingleInvestor = state => state?.investors?.singleInvestor;
const getSingleInvestorData = state => state?.investors?.singleInvestor?.user;
const getSingleStatus = state => state?.investors?.singleStatus;
const getCountries = state => state?.investors?.countries;
const getCountriesStatus = state => state?.investors?.countryStatus;
const getCountriesOptions = state =>
  state?.investors?.countries?.map(country => ({ text: country.name, value: country.id, key: country.id }));
const getStates = state =>
  state?.investors?.countries?.map(country => ({
    countryId: country.id,
    states: country.states.map(countryState => ({
      text: countryState.name,
      value: countryState.id,
      key: countryState.id,
    })),
  }));

export {
  getAllInvestors,
  getAllInvestorsStatus,
  getSingleInvestor,
  getSingleStatus,
  getCountries,
  getCountriesStatus,
  getSingleInvestorData,
  getCountriesOptions,
  getStates,
};
