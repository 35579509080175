import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import loginUser from '@store/actions/user/loginUser';
import getUserInfo from '@store/actions/user/getUserInfo';
import { getError } from '@store/reducers/user/selectors';
import Main from './Main';

const mapStateToProps = state => ({
  loginError: getError(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      loginUser,
      getUserInfo,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
