const GET_PAYPAL_CONFIG_INIT = '@paypal/GET_PAYPAL_CONFIG_INIT';
const GET_PAYPAL_CONFIG_SUCCESS = '@paypal/GET_PAYPAL_CONFIG_SUCCESS';
const GET_PAYPAL_CONFIG_FAILURE = '@paypal/GET_PAYPAL_CONFIG_FAILURE';

const EDIT_PAYPAL_CONFIG_INIT = '@paypal/EDIT_PAYPAL_CONFIG_INIT';
const EDIT_PAYPAL_CONFIG_SUCCESS = '@paypal/EDIT_PAYPAL_CONFIG_SUCCESS';
const EDIT_PAYPAL_CONFIG_FAILURE = '@paypal/EDIT_PAYPAL_CONFIG_FAILURE';

export {
  GET_PAYPAL_CONFIG_INIT,
  GET_PAYPAL_CONFIG_SUCCESS,
  GET_PAYPAL_CONFIG_FAILURE,
  EDIT_PAYPAL_CONFIG_INIT,
  EDIT_PAYPAL_CONFIG_SUCCESS,
  EDIT_PAYPAL_CONFIG_FAILURE,
};
