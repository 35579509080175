import React from 'react';
import { array, element, oneOfType, string } from 'prop-types';
import clsx from 'clsx';

const CardHeader = ({ children, className }) => {
  return <div className={clsx('d-flex justify-content-between align-items-start', className)}>{children}</div>;
};

CardHeader.propTypes = {
  children: oneOfType([element, string, array]).isRequired,
  className: string,
};

CardHeader.defaultProps = {
  className: '',
};

export default CardHeader;
