import React from 'react';
import { useParams } from 'react-router-dom';
import { arrayOf, func, shape, string } from 'prop-types';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import useMountWithTranslate from '@shared/utils/useMountWithTranslate';
import AgreementsForm from '@screens/agreements/components/AgreementsForm';
import PERMISSIONS from '@shared/consts/permissions';

const Main = ({
  singleAgreement,
  singleStatus,
  editStatus,
  errors,
  actions,
  targets,
  types,
  typeStatus,
  permittedPermissions,
}) => {
  const params = useParams();

  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSingleAgreementAction(params.id);
  });

  useMountWithTranslate(() => {
    actions.getTypesAndTargetsAction();
  });

  return (
    <AgreementsForm
      onSaveAgreement={actions.editAgreementAction}
      onRefreshAgreement={actions.getSingleAgreementAction}
      targets={targets}
      types={types}
      agreement={singleAgreement}
      agreementErrors={errors}
      isViewMode={!isUserPermitted(PERMISSIONS.AGREEMENT_EDIT)}
      isLoading={
        typeStatus === FETCH_STATUS.IDLE || editStatus === FETCH_STATUS.IDLE || singleStatus === FETCH_STATUS.IDLE
      }
    />
  );
};

Main.propTypes = {
  actions: shape({ getSingleAgreementAction: func, editAgreementAction: func }).isRequired,
  errors: shape({}),
  singleAgreement: shape({}),
  targets: arrayOf(shape({})),
  types: arrayOf(shape({})),
  permittedPermissions: arrayOf(shape({})),
  typeStatus: string,
  singleStatus: string,
  editStatus: string,
};

Main.defaultProps = {
  errors: {},
  singleAgreement: {},
  targets: [],
  types: [],
  permittedPermissions: [],
  typeStatus: undefined,
  singleStatus: undefined,
  editStatus: undefined,
};

export default Main;
