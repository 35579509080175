import React, { useState } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';
import { useParams } from 'react-router';
import useMount from '@shared/utils/useMountHook';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import PERMISSIONS from '@shared/consts/permissions';
import SliderForm from '@screens/slider/components/SliderForm';
import transformedSliderData from '@screens/slider/edit-slider/utils';

const Main = ({ actions, singleSlider, errors, status, permittedPermissions }) => {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams();
  const isUserPermitted = permission =>
    permittedPermissions?.find?.(permittedPermission => permittedPermission?.permission === permission)?.isPermitted;

  useMount(() => {
    actions.getSingleSliderAction(params.id);
  });

  const onSaveSlider = async ({ sliderData, imageData, sliderId, saveAndBack }) => {
    await setIsLoading(true);
    await actions.editSliderAction({ sliderData, imageData, sliderId, saveAndBack });
    await setIsLoading(false);
  };

  return (
    <SliderForm
      slider={transformedSliderData(singleSlider)}
      isLoading={status === FETCH_STATUS.IDLE || isLoading}
      onRefreshSlider={actions.getSingleSliderAction}
      onSaveSlider={onSaveSlider}
      imageUrl={singleSlider?.imageUrl}
      sliderErrors={errors}
      isViewMode={!isUserPermitted(PERMISSIONS.SLIDER_EDIT)}
    />
  );
};

Main.propTypes = {
  actions: shape({ getSingleSliderItemAction: func }).isRequired,
  singleSlider: shape({}),
  errors: shape({}),
  status: string,
  permittedPermissions: arrayOf(shape({})),
};

Main.defaultProps = {
  errors: {},
  singleSlider: {},
  status: undefined,
  permittedPermissions: [],
};

export default Main;
