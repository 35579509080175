const GET_INVESTORS_INIT = '@investor/GET_INVESTORS_INIT';
const GET_INVESTORS_SUCCESS = '@investor/GET_INVESTORS_SUCCESS';
const GET_INVESTORS_FAILURE = '@investor/GET_INVESTORS_FAILURE';

const GET_SINGLE_INVESTOR_INIT = '@investor/GET_SINGLE_INVESTOR_INIT';
const GET_SINGLE_INVESTOR_SUCCESS = '@investor/GET_SINGLE_INVESTOR_SUCCESS';
const GET_SINGLE_INVESTOR_FAILURE = '@investor/GET_SINGLE_INVESTOR_FAILURE';

const GET_COUNTRIES_INIT = '@investor/GET_COUNTRIES_INIT';
const GET_COUNTRIES_SUCCESS = '@investor/GET_COUNTRIES_SUCCESS';
const GET_COUNTRIES_FAILURE = '@investor/GET_COUNTRIES_FAILURE';

export {
  GET_INVESTORS_INIT,
  GET_INVESTORS_SUCCESS,
  GET_INVESTORS_FAILURE,
  GET_SINGLE_INVESTOR_INIT,
  GET_SINGLE_INVESTOR_SUCCESS,
  GET_SINGLE_INVESTOR_FAILURE,
  GET_COUNTRIES_INIT,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
};
