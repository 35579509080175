import React from 'react';
import { arrayOf, shape } from 'prop-types';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';
import { Typography } from '@material-ui/core';
import ROUTES from '@shared/consts/routes';

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 14,
    color: '#181c32',
    fontWeight: 600,
  },
  separator: {
    fontSize: 14,
    color: '#313245',
  },
}));

const Breadcrumbs = ({ breadcrumbs }) => {
  const styles = useStyles();
  const [t] = useTranslation(TRANSLATIONS.STATIC_CONTENT);

  return (
    <MaterialBreadcrumbs classes={{ separator: styles.separator }}>
      <Link color="inherit" className={styles.link} to={ROUTES.STATIC_CONTENT}>
        {t('list')}
      </Link>
      {breadcrumbs?.map((item, index) => {
        if (index + 1 === breadcrumbs?.length) {
          return <Typography className={styles.link}>{t(item.name)}</Typography>;
        }

        return (
          <Link className={styles.link} to={`${ROUTES.STATIC_CONTENT}/${item.id}`}>
            {t(item.name)}
          </Link>
        );
      })}
    </MaterialBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  breadcrumbs: arrayOf(shape({})),
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
};

export default Breadcrumbs;
