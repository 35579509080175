import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  textBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  labelInput: {
    marginBottom: '16px',
    marginRight: '16px',
  },
}));

export default useStyles;
