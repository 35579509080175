import { connect } from 'react-redux';
import { createPermissionAction } from '@store/actions/permissions';
import { getPermissionErrors } from '@store/reducers/permissions/selectors';
import { bindActionCreators, compose } from 'redux';
import Main from './Main';

const mapStateToProps = state => ({
  errors: getPermissionErrors(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createPermissionAction,
    },
    dispatch,
  ),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Main);
