import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  fitContent: {
    maxWidth: 'fit-content',
  },
  fixPosition: {
    transform: 'translateX(3px)',
  },
}));

export default useStyles;
