import {
  REMOVE_ROLE_FROM_USER_INIT,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
} from '@store/action-types/userManagement';
import client from '@api-sdk/client';
import getUserRolesAction from '@store/actions/user-management/getUserRoles';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const removeRoleFromUserInit = () => ({ type: REMOVE_ROLE_FROM_USER_INIT });
const removeRoleFromUserSuccess = () => ({ type: REMOVE_ROLE_FROM_USER_SUCCESS });
const removeRoleFromUserFailure = errors => ({
  type: REMOVE_ROLE_FROM_USER_FAILURE,
  payload: { errors },
});

const removeRoleFromUser = (roleId, userId) => async dispatch => {
  dispatch(removeRoleFromUserInit(roleId));

  try {
    await client.removeRoleFromUser({ slug: `${roleId}/users/${userId}` });
    await dispatch(getUserRolesAction(userId));
    await dispatch(removeRoleFromUserSuccess());

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.role.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('user.role.error.remove'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    dispatch(removeRoleFromUserFailure(error));
  }
};

export default removeRoleFromUser;
