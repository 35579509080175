import {
  UPLOAD_SINGLE_FILE_INIT,
  UPLOAD_SINGLE_FILE_SUCCESS,
  UPLOAD_SINGLE_FILE_FAILURE,
} from '@store/action-types/single-upload';
import client from '@api-sdk/client';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';

const uploadSingleFileInit = () => ({ type: UPLOAD_SINGLE_FILE_INIT });
const uploadSingleFileSuccess = file => ({ type: UPLOAD_SINGLE_FILE_SUCCESS, payload: { file } });
const uploadSingleFileFailure = error => ({ type: UPLOAD_SINGLE_FILE_FAILURE, payload: { error } });

const uploadSingleFile = ({ base64, fileName }) => async dispatch => {
  dispatch(uploadSingleFileInit());

  try {
    const uploadFileResponse = await client.uploadSingleFile({
      data: { file: { base64, fileName } },
    });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('upload.singleFile'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );
    return dispatch(uploadSingleFileSuccess(uploadFileResponse.response));
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('upload.error.singleFile'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(uploadSingleFileFailure(error));
  }
};

export default uploadSingleFile;
