const GET_USERS_INIT = '@users-management/GET_USERS_INIT';
const GET_USERS_SUCCESS = '@users-management/GET_USERS_SUCCESS';
const GET_USERS_FAILURE = '@users-management/GET_USERS_FAILURE';

const GET_SINGLE_USER_INIT = '@users-management/GET_SINGLE_USER_INIT';
const GET_SINGLE_USER_SUCCESS = '@users-management/GET_SINGLE_USER_SUCCESS';
const GET_SINGLE_USER_FAILURE = '@users-management/GET_SINGLE_USER_FAILURE';

const CREATE_USER_INIT = '@users-management/CREATE_USER_INIT';
const CREATE_USER_SUCCESS = '@users-management/CREATE_USER_SUCCESS';
const CREATE_USER_FAILURE = '@users-management/CREATE_USER_FAILURE';

const EDIT_USER_INIT = '@users-management/EDIT_USER_INIT';
const EDIT_USER_SUCCESS = '@users-management/EDIT_USER_SUCCESS';
const EDIT_USER_FAILURE = '@users-management/EDIT_USER_FAILURE';

const DELETE_USER_INIT = '@users-management/DELETE_USER_INIT';
const DELETE_USER_SUCCESS = '@users-management/DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = '@users-management/DELETE_USER_FAILURE';

const GET_PERSONAL_PERMISSIONS_INIT = '@users-management/GET_PERSONAL_PERMISSIONS_INIT';
const GET_PERSONAL_PERMISSIONS_SUCCESS = '@users-management/GET_PERSONAL_PERMISSIONS_SUCCESS';
const GET_PERSONAL_PERMISSIONS_FAILURE = '@users-management/GET_PERSONAL_PERMISSIONS_FAILURE';

const GET_USER_ROLES_INIT = '@users-management/GET_USER_ROLES_INIT';
const GET_USER_ROLES_SUCCESS = '@users-management/GET_USER_ROLES_SUCCESS';
const GET_USER_ROLES_FAILURE = '@users-management/GET_USER_ROLES_FAILURE';

const ADD_PERSONAL_PERMISSION_TO_USER_INIT = '@users-management/ADD_PERSONAL_PERMISSION_TO_USER_INIT';
const ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS = '@users-management/ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS';
const ADD_PERSONAL_PERMISSION_TO_USER_FAILURE = '@users-management/ADD_PERSONAL_PERMISSION_TO_USER_FAILURE';

const REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT = '@users-management/REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT';
const REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS = '@users-management/REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS';
const REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE = '@users-management/REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE';

const ADD_ROLE_TO_USER_INIT = '@users-management/ADD_ROLE_TO_USER_INIT';
const ADD_ROLE_TO_USER_SUCCESS = '@users-management/ADD_ROLE_TO_USER_SUCCESS';
const ADD_ROLE_TO_USER_FAILURE = '@users-management/ADD_ROLE_TO_USER_FAILURE';

const REMOVE_ROLE_FROM_USER_INIT = '@users-management/REMOVE_ROLE_FROM_USER_INIT';
const REMOVE_ROLE_FROM_USER_SUCCESS = '@users-management/REMOVE_ROLE_FROM_USER_SUCCESS';
const REMOVE_ROLE_FROM_USER_FAILURE = '@users-management/REMOVE_ROLE_FROM_USER_FAILURE';

export {
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  GET_SINGLE_USER_INIT,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILURE,
  CREATE_USER_INIT,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  EDIT_USER_INIT,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAILURE,
  DELETE_USER_INIT,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  GET_PERSONAL_PERMISSIONS_INIT,
  GET_PERSONAL_PERMISSIONS_SUCCESS,
  GET_PERSONAL_PERMISSIONS_FAILURE,
  GET_USER_ROLES_INIT,
  GET_USER_ROLES_SUCCESS,
  GET_USER_ROLES_FAILURE,
  ADD_PERSONAL_PERMISSION_TO_USER_INIT,
  ADD_PERSONAL_PERMISSION_TO_USER_SUCCESS,
  ADD_PERSONAL_PERMISSION_TO_USER_FAILURE,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_INIT,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_SUCCESS,
  REMOVE_PERSONAL_PERMISSION_FROM_USER_FAILURE,
  ADD_ROLE_TO_USER_INIT,
  ADD_ROLE_TO_USER_SUCCESS,
  ADD_ROLE_TO_USER_FAILURE,
  REMOVE_ROLE_FROM_USER_INIT,
  REMOVE_ROLE_FROM_USER_SUCCESS,
  REMOVE_ROLE_FROM_USER_FAILURE,
};
