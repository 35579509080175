import {
  CREATE_AGREEMENT_INIT,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
} from '@store/action-types/agreements';
import Client from '@api-sdk/client';
import { set, toPath } from 'lodash';
import { enqueueSnackbar } from '@store/actions/toast';
import translateToastMessage from '@shared/utils/translateToastMessage';
import history from '@store/history';
import ROUTES from '@shared/consts/routes';

const createAgreementInit = () => ({ type: CREATE_AGREEMENT_INIT });
const createAgreementSuccess = () => ({ type: CREATE_AGREEMENT_SUCCESS });
const createAgreementFailure = error => {
  const mappedErrors = error?.errors?.reduce?.((acc, item) => {
    const path = toPath(item.context)?.filter(
      key => key !== 'children' && key !== 'data' && key !== 'pl' && key !== 'en',
    );
    set(acc, path, item.code?.split?.(':')?.pop?.() || item.message);
    return acc;
  }, {});

  return { type: CREATE_AGREEMENT_FAILURE, payload: { error: mappedErrors } };
};

const createAgreement = ({ agreementData, saveAndBack = false }) => async dispatch => {
  dispatch(createAgreementInit());

  try {
    const agreementResponse = await Client.createAgreement({ data: agreementData });

    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'success',
        },
      }),
    );

    if (saveAndBack) {
      history.push(ROUTES.AGREEMENTS);
    } else {
      history.push(`/agreements/${agreementResponse.response?.id}`);
    }

    return dispatch(createAgreementSuccess());
  } catch (error) {
    dispatch(
      enqueueSnackbar({
        message: translateToastMessage('agreements.error.create'),
        options: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          variant: 'error',
        },
      }),
    );
    return dispatch(createAgreementFailure(error));
  }
};

export default createAgreement;
