import { GET_CONFIG_FAILURE, GET_CONFIG_SUCCESS, GET_CONFIG_INIT } from '@store/action-types/settings';
import Client from '@api-sdk/client';

const getConfigInit = () => ({ type: GET_CONFIG_INIT });
const getConfigSuccess = config => ({ type: GET_CONFIG_SUCCESS, payload: { config } });
const getConfigFailure = error => ({ type: GET_CONFIG_FAILURE, payload: { error } });

const getConfig = () => async dispatch => {
  dispatch(getConfigInit());

  try {
    const config = await Client.getConfig();
    dispatch(getConfigSuccess(config.response));
  } catch (error) {
    dispatch(getConfigFailure(error));
  }
};

export default getConfig;
