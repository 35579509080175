import { GET_SINGLE_ROLE_INIT, GET_SINGLE_ROLE_SUCCESS, GET_SINGLE_ROLE_FAILURE } from '@store/action-types/roles';
import client from '@api-sdk/client';

const getSingleRoleInit = () => ({ type: GET_SINGLE_ROLE_INIT });
const getSingleRoleSuccess = role => ({
  type: GET_SINGLE_ROLE_SUCCESS,
  payload: { role },
});
const getSingleRoleFailure = error => ({ type: GET_SINGLE_ROLE_FAILURE, payload: { error } });

const getSingleRole = roleId => async dispatch => {
  dispatch(getSingleRoleInit());

  try {
    const singleRoleResponse = await client.getSingleRole({ slug: roleId });

    dispatch(getSingleRoleSuccess(singleRoleResponse.response));
  } catch (error) {
    dispatch(getSingleRoleFailure(error?.errors?.[0]?.code));
  }
};

export default getSingleRole;
