const GET_AGREEMENTS_INIT = '@agreements/GET_AGREEMENTS_INIT';
const GET_AGREEMENTS_SUCCESS = '@agreements/GET_AGREEMENTS_SUCCESS';
const GET_AGREEMENTS_FAILURE = '@agreements/GET_AGREEMENTS_FAILURE';

const GET_SINGLE_AGREEMENT_INIT = '@agreements/GET_SINGLE_AGREEMENT_INIT';
const GET_SINGLE_AGREEMENT_SUCCESS = '@agreements/GET_SINGLE_AGREEMENT_SUCCESS';
const GET_SINGLE_AGREEMENT_FAILURE = '@agreements/GET_SINGLE_AGREEMENT_FAILURE';

const CREATE_AGREEMENT_INIT = '@agreements/CREATE_AGREEMENT_INIT';
const CREATE_AGREEMENT_SUCCESS = '@agreements/CREATE_AGREEMENT_SUCCESS';
const CREATE_AGREEMENT_FAILURE = '@agreements/CREATE_AGREEMENT_FAILURE';

const EDIT_AGREEMENT_INIT = '@agreements/EDIT_AGREEMENT_INIT';
const EDIT_AGREEMENT_SUCCESS = '@agreements/EDIT_AGREEMENT_SUCCESS';
const EDIT_AGREEMENT_FAILURE = '@agreements/EDIT_AGREEMENT_FAILURE';

const DELETE_AGREEMENT_INIT = '@agreements/DELETE_AGREEMENT_INIT';
const DELETE_AGREEMENT_SUCCESS = '@agreements/DELETE_AGREEMENT_SUCCESS';
const DELETE_AGREEMENT_FAILURE = '@agreements/DELETE_AGREEMENT_FAILURE';

const GET_TYPES_AND_TARGETS_INIT = '@agreements/GET_TYPES_AND_TARGETS_INIT';
const GET_TYPES_AND_TARGETS_SUCCESS = '@agreements/GET_TYPES_AND_TARGETS_SUCCESS';
const GET_TYPES_AND_TARGETS_FAILURE = '@agreements/GET_TYPES_AND_TARGETS_FAILURE';

const GET_USER_AGREEMENTS_INIT = '@agreements/GET_USER_AGREEMENTS_INIT';
const GET_USER_AGREEMENTS_SUCCESS = '@agreements/GET_USER_AGREEMENTS_SUCCESS';
const GET_USER_AGREEMENTS_FAILURE = '@agreements/GET_USER_AGREEMENTS_FAILURE';

export {
  GET_AGREEMENTS_INIT,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENTS_FAILURE,
  GET_SINGLE_AGREEMENT_INIT,
  GET_SINGLE_AGREEMENT_SUCCESS,
  GET_SINGLE_AGREEMENT_FAILURE,
  CREATE_AGREEMENT_INIT,
  CREATE_AGREEMENT_SUCCESS,
  CREATE_AGREEMENT_FAILURE,
  EDIT_AGREEMENT_INIT,
  EDIT_AGREEMENT_SUCCESS,
  EDIT_AGREEMENT_FAILURE,
  DELETE_AGREEMENT_INIT,
  DELETE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT_FAILURE,
  GET_TYPES_AND_TARGETS_INIT,
  GET_TYPES_AND_TARGETS_SUCCESS,
  GET_TYPES_AND_TARGETS_FAILURE,
  GET_USER_AGREEMENTS_INIT,
  GET_USER_AGREEMENTS_SUCCESS,
  GET_USER_AGREEMENTS_FAILURE,
};
