import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { Grid, TextField, InputAdornment, Button, Switch, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './styles';

const MainContent = ({ headerData: data, handleChangeMainContent }) => {
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => setIsOpen(prevState => !prevState);

  const handleBlur = (key, value) => {
    handleChangeMainContent(key, value);
  };

  const handleSetDate = (key, date) => {
    handleChangeMainContent(key, date);
  };

  const handleChange = (e, isBool) => {
    const key = e.target.name;

    if (isBool) {
      handleToggle();
      handleChangeMainContent(key, !isOpen);
      return;
    }

    const value = e.target.value;
    handleChangeMainContent(key, value);
  };

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">General</span>
        </h3>
      </div>
      <div className="card-body pt-3 pb-0">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className={styles.justifyEnd}>
            <FormControlLabel
              control={<Switch color="primary" name="isOpen" onChange={e => handleChange(e, true)} checked={isOpen} />}
              label="Open"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              value={data?.title || ''}
              onChange={handleChange}
              name="title"
              variant="outlined"
              label="Title"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              value={data?.slug || ''}
              onChange={handleChange}
              name="slug"
              variant="outlined"
              label="Slug"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                onChange={date => {
                  const key = 'createdAt';
                  handleSetDate(key, date);
                }}
                autoOk
                className="m-0 p-0"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                label="Created at"
                value={data?.createdAt || ''}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CKEditor
              editor={ClassicEditor}
              onInit={editor => {
                editor.setData(data?.description);
              }}
              onBlur={(e, editor) => {
                const editorData = editor.getData();
                const key = 'description';
                handleBlur(key, editorData);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              label="Project Type"
              value={data?.projectType || ''}
              name="projectType"
              onChange={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                value={data?.investmentStart || ''}
                onChange={date => {
                  const key = 'investmentStart';
                  handleSetDate(key, date);
                }}
                className="m-0 p-0"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                label="Start of investment"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk
                onChange={date => {
                  const key = 'investmentEnd';
                  handleSetDate(key, date);
                }}
                value={data?.investmentEnd || ''}
                className="m-0 p-0"
                disableToolbar
                variant="inline"
                inputVariant="outlined"
                format="dd/MM/yyyy"
                margin="normal"
                label="End of investment"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Total Cost"
              value={data?.totalCost || ''}
              name="totalCost"
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Cost per stock"
              value={data?.costPerStock || ''}
              name="costPerStock"
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              name="minStock"
              onChange={handleChange}
              variant="outlined"
              value={data?.minStock || ''}
              fullWidth
              type="number"
              label="Min. stock"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Developer Equity"
              value={data?.developerEquity || ''}
              name="developerEquity"
              onChange={handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Investors Equity"
              name="investorsEquity"
              onChange={handleChange}
              value={data?.investorsEquity || ''}
              InputProps={{
                endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Debt"
              name="debt"
              onChange={handleChange}
              value={data?.debt || ''}
              InputProps={{
                endAdornment: <InputAdornment position="start">PLN</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Investors Return"
              name="investorReturn"
              onChange={handleChange}
              value={data?.investorReturn || ''}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              name="redemptionCapRate"
              onChange={handleChange}
              label="Redemption cap rate"
              value={data?.redemptionCapRate || ''}
              InputProps={{
                endAdornment: <InputAdornment position="start">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" fullWidth style={{ minHeight: '100%' }} startIcon={<CloudUploadIcon />}>
              Upload main photo
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <CKEditor
              editor={ClassicEditor}
              onInit={editor => editor.setData(data?.tenants || '')}
              onBlur={(e, editor) => {
                const editorData = editor.getData();
                const key = 'tenants';
                handleBlur(key, editorData);
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className="card-footer pb-5 pt-0 border-0" />
    </div>
  );
};

MainContent.propTypes = {
  headerData: shape({}).isRequired,
  handleChangeMainContent: func.isRequired,
};

export default MainContent;
