import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    position: 'relative',
  },
  formContainer: {
    minWidth: 360,
    display: 'flex',
    flexDirection: 'column',
  },
  formContainerMobile: {
    minWidth: 240,
    maxWidth: 255,
  },
  input: {
    background: '#f5f5f5',
    margin: '12px 0',
    position: 'relative',
  },
  helperText: {
    position: 'absolute',
    bottom: -20,
  },
  confirmationMessage: {
    textAlign: 'center',
    marginBottom: 8,
  },
  successMessageWrapper: {
    padding: 10,
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 36,
  },
  resendButton: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  backToLoginWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  backToLogin: {
    textAlign: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: 32,
    height: 28,
    [theme.breakpoints.up('md')]: {
      height: 32,
    },
  },
}));

export default useStyles;
