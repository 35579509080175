import React from 'react';
import { func, shape } from 'prop-types';
import { TextField } from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useStyles from './styles';

const TextBox = ({ setElementData, elementValue }) => {
  const styles = useStyles();

  const handleSetElementData = (e, isEditor) => {
    let key;
    let value;

    if (isEditor) {
      key = 'content';
      value = e;

      setElementData(key, value);
      return;
    }

    key = e.target.name;
    value = e.target.value;

    setElementData(key, value);
  };

  return (
    <>
      <div>
        <div className={styles.textBoxHeader}>
          <TextField
            size="small"
            className={styles.labelInput}
            label="Label"
            variant="outlined"
            name="label"
            onChange={handleSetElementData}
            value={elementValue.label || ''}
          />
        </div>

        <CKEditor
          editor={ClassicEditor}
          onBlur={(e, editor) => {
            const editorData = editor.getData();
            handleSetElementData(editorData, true);
          }}
        />
      </div>
    </>
  );
};

TextBox.propTypes = {
  setElementData: func.isRequired,
  elementValue: shape({}).isRequired,
};

export default TextBox;
