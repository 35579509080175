import {
  GET_TYPES_AND_TARGETS_INIT,
  GET_TYPES_AND_TARGETS_SUCCESS,
  GET_TYPES_AND_TARGETS_FAILURE,
} from '@store/action-types/agreements';
import Client from '@api-sdk/client';
import getErrorCode from '@shared/utils/getErrorCode';

const changeStructure = array => {
  const newArray = [];

  for (let i = 0; i < array?.length; i += 1) {
    const element = {
      key: array?.[i]?.value,
      value: array?.[i]?.value,
      text: array?.[i]?.label,
    };

    newArray.push(element);
  }

  return newArray;
};

const getTypesAndTargetsInit = () => ({ type: GET_TYPES_AND_TARGETS_INIT });
const getTypesAndTargetsSuccess = ({ types, targets }) => ({
  type: GET_TYPES_AND_TARGETS_SUCCESS,
  payload: { types: changeStructure(types), targets: changeStructure(targets) },
});
const getTypesAndTargetsFailure = error => ({ type: GET_TYPES_AND_TARGETS_FAILURE, payload: { error } });

const getTypesAndTargets = () => async dispatch => {
  dispatch(getTypesAndTargetsInit());

  try {
    const types = await Client.getTypesAndTargets();

    dispatch(getTypesAndTargetsSuccess(types?.response));
  } catch (error) {
    dispatch(getTypesAndTargetsFailure(getErrorCode(error?.errors)));
  }
};

export default getTypesAndTargets;
