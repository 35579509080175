import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Divider, Form, Grid } from 'semantic-ui-react';
import FETCH_STATUS from '@shared/consts/fetchStatuses';
import { SwitchField } from '@screens/offers/NewOfferForm/components';
import { InputField } from '@shared/components/translatable';
import { useTranslation } from 'react-i18next';
import TRANSLATIONS from '@translations/translationNamespaces';

const PaypalConfig = ({ isLoading, status, configData, setConfigData }) => {
  const [t] = useTranslation(TRANSLATIONS.SETTINGS);

  const onSwitchChange = (e, { name }) =>
    setConfigData(prevState => ({
      ...prevState,
      defaultCredentials: {
        ...prevState?.defaultCredentials,
        [name]: !prevState[name],
      },
    }));

  const onDefaultCredentialsChange = (e, { name, value }) =>
    setConfigData(prevState => ({
      ...prevState,
      defaultCredentials: {
        ...prevState?.defaultCredentials,
        [name]: value,
      },
    }));

  const onAmountChange = (e, { value, name }) =>
    setConfigData(prevState => ({
      ...prevState,
      [name]: value,
    }));

  return (
    <div>
      <Form loading={status === FETCH_STATUS.IDLE || isLoading}>
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              onChange={onAmountChange}
              name="maxAmount"
              value={configData?.maxAmount}
              label={t('paypal.maxAmount')}
              icon="dollar"
            />
            <SwitchField
              className="text-center"
              name="productionMode"
              value={configData?.defaultCredentials?.productionMode}
              label={t('paypal.productionMode')}
              onChange={onSwitchChange}
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              onChange={onDefaultCredentialsChange}
              value={configData?.defaultCredentials?.sandboxClientId}
              label={t('paypal.sandboxClientId')}
              name="sandboxClientId"
              icon="font"
            />
            <InputField
              onChange={onDefaultCredentialsChange}
              value={configData?.defaultCredentials?.sandboxClientSecret}
              label={t('paypal.sandboxClientSecret')}
              name="sandboxClientSecret"
              icon="user secret"
            />
          </Grid>
        </Form.Group>
        <Divider />
        <Form.Group>
          <Grid container stackable columns={2} divided>
            <InputField
              onChange={onDefaultCredentialsChange}
              value={configData?.defaultCredentials?.productionClientId}
              label={t('paypal.productionClientId')}
              name="productionClientId"
              icon="font"
            />
            <InputField
              onChange={onDefaultCredentialsChange}
              value={configData?.defaultCredentials?.productionClientSecret}
              label={t('paypal.productionClientSecret')}
              name="productionClientSecret"
              icon="user secret"
            />
          </Grid>
        </Form.Group>
      </Form>
    </div>
  );
};

PaypalConfig.propTypes = {
  isLoading: bool,
  status: string,
  configData: shape({}),
  setConfigData: func,
};

PaypalConfig.defaultProps = {
  isLoading: false,
  status: undefined,
  configData: {},
  setConfigData: () => {},
};

export default PaypalConfig;
