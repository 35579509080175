const getErrorCode = error => {
  const codes = error?.map?.(err => err?.code?.split?.(':')?.pop?.());

  const array = [];

  for (let i = 0; i < error?.length; i += 1) {
    array.push(`${codes?.[i]}`);
  }

  return array;
};

export default getErrorCode;
