import { GET_SINGLE_PAGE_INIT, GET_SINGLE_PAGE_SUCCESS, GET_SINGLE_PAGE_FAILURE } from '@store/action-types/page';
import client from '@api-sdk/client';

const getSinglePageInit = () => ({ type: GET_SINGLE_PAGE_INIT });
const getSinglePageSuccess = page => ({ type: GET_SINGLE_PAGE_SUCCESS, payload: { page } });
const getSinglePageFailure = error => ({ type: GET_SINGLE_PAGE_FAILURE, payload: { error } });

const getSinglePage = id => async dispatch => {
  dispatch(getSinglePageInit());

  try {
    const page = await client.getSinglePage({ slug: id });
    dispatch(
      getSinglePageSuccess({
        ...page.response,
        attachments: page.response.attachments.map((attachment, index) => ({
          ...attachment,
          file: { fileName: attachment.displayName, delete: false },
          isNewFile: false,
          fileItemIndex: index,
        })),
      }),
    );
  } catch (error) {
    dispatch(getSinglePageFailure(error));
  }
};

export default getSinglePage;
